import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { prettifyPrice, prettifyDate } from '../datavase-api';
import placeholder from '../../assets/datavase_logo_icon_grey.png';
import '../../css/history-table.css';

export default class AcquisitionsHistoryTable extends Component {
  render() {
    let acquisitionRows = this.props.acquisitions.map((acquisition, i) => {
      const acquired = acquisition.acquired_organization;
      const resourceType            = acquired.resource_type_name;
      const acquiredOrgProfileImage = acquired.thumb_profile_image_url;
      const acquiredOrgName         = acquired.organization_name;
      const acquiredOrgLink         = `/${resourceType}/${acquired.slug || acquired.id}/`;
      const price = prettifyPrice(
        acquisition.price, acquisition.currency_code, 'Undisclosed'
      );
      const date  = prettifyDate(
        acquisition.date_of_acquisition,
        acquisition.precision_of_date_of_acquisition
      );

      return(
        <tr key={i}>
          <td>
            <img
              className="history-table-image"
              src={acquiredOrgProfileImage || placeholder}
              alt={acquiredOrgName}
            />
            <a href={acquiredOrgLink}>{acquiredOrgName}</a>
          </td>
          <td>{price}</td>
          <td>{date}</td>
        </tr>
      );
    });

    return(
      <Table
        striped
        responsive
        hover
        id="acquisitions-table"
      >
        <thead>
          <tr>
            <th>Acquired Organization</th>
            <th>Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {acquisitionRows}
        </tbody>
      </Table>
    );
  }
}

