import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormControl} from 'react-bootstrap';

import BlockSpinner from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/credit-card-form.json';

export default class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {
        number: '',
        name: null,
        expiration_month: 1,
        expiration_year: 2018,
        security_code: null
      },
      isFormOkay: false
    };
    this._onFormChange    = this._onFormChange.bind(this);
    this._onSubmitClicked = this._onSubmitClicked.bind(this);
  }

  _onSubmitClicked() {
    if(this.props.onSubmitClicked) {
      this.props.onSubmitClicked();
    }

    DatavaseApi.generateCreditCardToken(
      this.state.card,
      (token) => {
        this.props.onSuccess(token);
      },
      (statusCode, message) => {
        this.props.onFailure(message)
      }
    );
  }

  _onFormChange(e) {
    const newCard = Object.assign({}, this.state.card, {
      [e.target.name]: e.target.value
    });

    let isFormOkay = false;
    if(newCard.number &&
       newCard.name &&
       newCard.expiration_month &&
       newCard.expiration_year &&
       newCard.security_code) {
      isFormOkay = true;
    }
    this.setState({
      card: newCard,
      isFormOkay: isFormOkay
    });
  }

  render () {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
        <form style={{textAlign: 'left', marginBottom: '20px', display: 'inline-block', width: '100%'}}
              autoComplete="address-line3">
        <div style={{display: "flex",justifyContent:"center",alignItems:"center",margin: "0 12.5%"}}>
          <img src="/icons/vendor/cc/v.jpg" style={{width: "10%",margin:"0 1em",height:"auto"}} alt="VISA"/>
          <img src="/icons/vendor/cc/m.svg" style={{width: "10%",margin:"0 1em",height:"auto"}} alt="Mastercard"/>
        </div>
        <div style={{textAlign: "center",marginTop:"1em"}}>
          <p>上記のブランドのカードがご利用可能です。<br/>カード情報は弊社を経由せず、SSLを利用し安全に送信されます。</p>
        </div>
        <Col sm={18} lg={18} style={{padding: '0px',marginTop:"5%"}}>
          <ControlLabel>{lang.invitationCode}</ControlLabel>
          <FormControl
              type="text"
              name="number"
              autoComplete="address-line3"
              onChange={this._onFormChange}
          />
        </Col>
        <Col sm={12} lg={12} style={{padding: '0px'}}>
          <ControlLabel>{lang.creditCardLabel}</ControlLabel>
          <FormControl
              type="text"
              name="number"
              autoComplete="address-line3"
              placeholder={lang.creditCardPlaceholder}
              onChange={this._onFormChange}
          />
        </Col>
        <Col sm={6} lg={6} style={{padding: '0px'}}>
          <ControlLabel>{lang.cardholderNameLabel}</ControlLabel>
          <FormControl
              type="text"
              autoComplete="address-line3"
              name="name"
              placeholder={lang.cardholderNamePlaceholder}
              onChange={this._onFormChange}
          />
        </Col>
        <Col sm={2} lg={2} style={{padding: '0px'}}>
          <ControlLabel>{lang.expMonthLabel}</ControlLabel>
          <FormControl
              autoComplete="address-line3"
              componentClass="select"
              name="expiration_month"
              onChange={this._onFormChange}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </FormControl>
        </Col>
        <Col sm={2} lg={2} style={{padding: '0px'}}>
          <ControlLabel>{lang.expYearLabel}</ControlLabel>
          <FormControl
              componentClass="select"
              name="expiration_year"
              autoComplete="address-line3"
              onChange={this._onFormChange}
          >
            <option value="2018">18</option>
            <option value="2019">19</option>
            <option value="2020">20</option>
            <option value="2021">21</option>
            <option value="2022">22</option>
            <option value="2023">23</option>
            <option value="2024">24</option>
            <option value="2025">25</option>
            <option value="2026">26</option>
            <option value="2027">27</option>
            <option value="2028">28</option>
            <option value="2029">29</option>
            <option value="2030">30</option>
          </FormControl>
        </Col>
        <Col sm={2} lg={2} style={{padding: '0px'}}>
          <ControlLabel>{lang.cvcLabel}</ControlLabel>
          <FormControl
              type="text"
              autoComplete="address-line3"
              name="security_code"
              placeholder={lang.cvcPlaceholder}
              onChange={this._onFormChange}
          />
        </Col>
        {
          this.props.isProcessing ?
          <BlockSpinner visible={true}/> :
          <Button
            block
            id="creditCardFormCheckoutButton"
            disabled={!this.state.isFormOkay}
            style={{display: 'inline-block', marginTop: '10px'}}
            bsStyle="primary"
            className="auth-confirm-button"
            onClick={this._onSubmitClicked}
          >
            {this.props.submitButtonText || lang.submitButton}
          </Button>
        }
        </form>
    );
  }
}
