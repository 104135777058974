import React, { Component } from 'react';
import { Panel, Col } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import jp_techcrunch_logo      from '../../assets/jp_techcrunch_logo.png';
import us_techcrunch_logo      from '../../assets/us_techcrunch_logo.png';
import thebridge_logo          from '../../assets/thebridge_logo.png';
import hackletter_logo         from '../../assets/hackletter_logo.png';

export default class NewsArticlesByResourcePanel extends Component {
  render() {
    const newsArticles = this.props.newsArticles;
    const newsArticleViews = newsArticles.map((newsArticle, i) => {
      return(
        <NewsArticleView
          newsArticle={newsArticle}
          key={i}
        />
      );
    });

    return(
      <Panel>
        {newsArticleViews}
      </Panel>
    );
  }
}

class NewsArticleView extends Component {
  render() {
    const newsArticle   = this.props.newsArticle;
    const publishedDate = DatavaseApi.prettifyDatetime(newsArticle.published_at);
    const hostname      = DatavaseApi.extractHostname(newsArticle.url);
    let image;
    if(hostname === 'jp.techcrunch.com') {
      image = jp_techcrunch_logo;
    }else if(hostname === 'techcrunch.com') {
      image = us_techcrunch_logo;
    }else if(hostname === 'thebridge.jp') {
      image = thebridge_logo;
    }else if(hostname === 'hackletter.com') {
      image = hackletter_logo;
    }

    return(
      <div>
        <Col sm={4} lg={3} style={{padding: '4px 0'}}>
          <span style={{color: '#aaa'}}>{publishedDate}</span>
        </Col>
        <Col sm={8} lg={9} style={{padding: '4px 0'}}>
          <img alt={newsArticle.title} src={image} style={{width: '25px', marginRight: '8px'}}/>
          <a
            style={{fontWeight: 'bold'}}
            href={newsArticle.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {newsArticle.title}
          </a>
        </Col>
      </div>
    );
  }
}
