import React, {Component} from 'react';
import {Col, Label} from 'react-bootstrap';
import marked from 'marked';
import {Link, withRouter} from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import * as DatavaseApi from './datavase-api';
import * as ENV from '../constants/environment';
import BlockSpinner from './partials/block-spinner';
import ShareButtons from './partials/share-buttons';
import articleEyeCatching from '../assets/datavase_article_eyecatching.png';
import i18n from '../i18n/datavase-article-details-pane.json';
import '../css/datavase-article-markdown.css';
import '../css/markdown.css';

const eyeCatchingImageStyle = {
  maxWidth: '800px',
  margin: 'auto',
};

class DatavaseArticleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      article: {},
    };
  }

  _getId() {
    let { id } = this.props.match.params;
    return id;
  }

  componentDidMount() {
    this._fetchDatavaseArticle();
  }

  _fetchDatavaseArticle() {
    const id = this._getId();
    DatavaseApi.fetchDatavaseArticleWithRelatedResources(id)
      .then(res => {
        this.setState({
          hasLoaded: true,
          article: res.data.article
        });
      })
      .catch(res => {
        console.error(res);
      });
  }

  _upgradeToReadMoreButton() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const article = this.state.article;

    if (DatavaseApi.isUserAccessibleToArticle(article)) {
      return null;
    } else {
      const plansThatCanAccess = DatavaseApi.articleAccessiblePlansList(article).map((plan, i) => {
        return (
          <Label key={i} style={{ margin: '0 4px' }}>{plan}</Label>
        );
      });

      return (
        <div>
          <div style={{ textAlign: 'center', maxWidth: '500px', margin: '40px auto' }}>
            {lang.upgradeToReadMore}
            <div style={{ display: 'inline-block', width: '100%', margin: '4px auto' }}>
              {plansThatCanAccess}
            </div>
          </div>
          <Link to="/upgrade/" target="_blank" rel="noopener noreferrer">
            <img style={{ width: '100%' }} src="/upgrade-info.png" alt="ClickHereToUpgrade" />
          </Link>
        </div>
      );
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return (
        <BlockSpinner visible={true} />
      );
    }

    const article = this.state.article;
    const title = article.title;
    const author_name = article.author.writer_name || "Datavase Insight Editors";
    //const published_at = DatavaseApi.prettifyDatetime(article.published_at)
    const rawMarkdownText = article.raw_markdown_text;
    let imageSource = articleEyeCatching;
    if (article.primary_images.length > 0) {
      imageSource = article.primary_images[0].image
    }


    return (
      <div className="clearfix datavase-article-container">
        <MetaTags>
          <title>{title}</title>
          <meta name="description" content={article.abstract} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={imageSource} />
        </MetaTags>
        <Col sm={12}>
          <div style={eyeCatchingImageStyle}>
            <img alt={title} style={{ width: '100%', marginTop: '20px' }} src={imageSource} />
          </div>
          <div className="datavase-article-title-preview">
            <h1>{title}</h1>
          </div>
          <hr />
          By {author_name}
          <hr />
          <div
            className="datavase-article-markdown-preview markdown-body"
            dangerouslySetInnerHTML={{ __html: marked(rawMarkdownText, { gfm: true, smartLists: true }) }}
          />
          <div style={{ paddingBottom: '20px' }}>
            <ShareButtons
              url={`${ENV.API_ENDPOINT}/datavase_articles/${this._getId()}/share/%3Fredirect%3D${encodeURIComponent(window.location.href)}`}
              title={article.title}
            />
          </div>
          {this._upgradeToReadMoreButton()}
        </Col>
      </div>
    );
  }
}

export default withRouter(DatavaseArticleDetails);
