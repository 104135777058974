import { ENV } from '../env';

let apiEndpoint, cdnEndpoint, omisePublicKey, webAppEndpoint;
if(ENV === 'development') {
  apiEndpoint = 'https://local.datavase.io:9292';
  cdnEndpoint = 'https://staging-cdn.datavase.io';
  omisePublicKey = 'pkey_test_5cmvevixd0zatyjrks1';
  webAppEndpoint = 'http://0.0.0.0:3001';
}else if(ENV === 'staging') {
  apiEndpoint = 'https://staging-api.datavase.io';
  cdnEndpoint = 'https://staging-cdn.datavase.io';
  omisePublicKey = 'pkey_test_5cmvevixd0zatyjrks1';
  webAppEndpoint = 'https://staging.datavase.io';
}else if(ENV === 'production'){
  apiEndpoint = 'https://api.datavase.io';
  cdnEndpoint = 'https://cdn.datavase.io';
  omisePublicKey = 'pkey_5cpbi4zk7376fpvehvc';
  webAppEndpoint = 'https://datavase.io';
}

const ENVIRONMENT  = ENV;
const API_ENDPOINT = apiEndpoint;
const CDN_ENDPOINT = cdnEndpoint;
const OMISE_PUBLIC_KEY = omisePublicKey;
const WEB_APP_ENDPOINT = webAppEndpoint;

export {
  ENVIRONMENT,
  API_ENDPOINT,
  CDN_ENDPOINT,
  OMISE_PUBLIC_KEY,
  WEB_APP_ENDPOINT
};

