import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import store from 'store';

import { getLangCode } from '../datavase-api';
import datavase_logo from '../../assets/datavase_logo.png';
import i18n from '../../i18n/footer.json';

import '../../css/footer.css';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }
  _toggleThemeChanger() {
    if(store.get("newTheme") !== true && store.get("newTheme") !== false) {
      store.set("newTheme", true);
    } else {
      store.set("newTheme", !store.get("newTheme"));
    }
    window.location.reload();
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _languageChangeLink() {
    const langCode = getLangCode();
    if(langCode === 'ja'){
      return (<span className="footer-lang-link" onClick={this._onClickEnglish}>English</span>);
    }else{
      return (<span className="footer-lang-link" onClick={this._onClickJapanese}>日本語</span>);
    }
  }

  render() {
    const langCode = getLangCode();
    const lang     = i18n[langCode];

    return(
      <div id="footer" style={{paddingLeft: this.props.page ? '0' : null}}>
        <div id="footer-inner-container" className="clearfix">
          <div>
            <Col lg={3}>
              <a id="footer-logo" href="/"><img src={datavase_logo} alt="datavase.io"/>datavase.io</a><br/>
              <div>
                <span className="footer-link" onClick={() => this._toggleThemeChanger()}>
                  テーマを変更する
                </span>
              </div>
            </Col>
            <Col lg={3}>
              <span className="footer-column-header">{lang.whoWeAre}</span>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="http://www.hackjpn.com">{lang.about}</a>
              </div>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="https://hunterbook.org/app/company/59eb860f0edf7e0005246e99">{lang.careers}</a>
              </div>
              <div>
                <a className="footer-link" href="mailto:team@hackjpn.com">{lang.contactUs}</a>
              </div>
            </Col>
            <Col lg={3}>
              <span className="footer-column-header">{lang.support}</span>
              <div>
                <a className="footer-link" href="mailto:team@hackjpn.com">{lang.help}</a>
              </div>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="/terms_and_privacy">{lang.terms}</a>
              </div>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="/specified_commercial_transaction_law">{lang.specifiedCommercialTransactionLaw}</a>
              </div>
            </Col>
            <Col lg={3}>
              <span className="footer-column-header">datavase.io</span>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="/upgrade/">{lang.pricing}</a>
              </div>
              <div>
                <a className="footer-link" target="_blank" rel="noopener noreferrer" href="/features">{lang.features}</a>
              </div>
            </Col>
          </div>
          <Col lg={12} style={{textAlign: 'center'}}>
            {this._languageChangeLink()}
            <span id="footer-copyright">© 2018 hackjpn inc. All rights reserved.</span>
          </Col>
        </div>
      </div>
    );
  }
}
