import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

import NewsArticlePreview from './news-article-preview';

export default class NewsArticlePreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouseNewsArticleId: null
    };

    this._onHide = this._onHide.bind(this);
  }

  _onHide() {
    if(this.props.onHide) {
      this.props.onHide();
    }
  }

    UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.article && newProps.article.warehouse_news_article_id) {
      this.setState({
        warehouseNewsArticleId: newProps.article.warehouse_news_article_id
      });
    }
  }

  render() {
    return(
      <Modal show={this.props.show} keyboard onHide={this._onHide}>
        <NewsArticlePreview
          warehouseNewsArticleId={this.state.warehouseNewsArticleId}
        />
      </Modal>
    );
  }
}
