import React, {Component} from 'react';
import {Button, Checkbox, FormControl, InputGroup} from 'react-bootstrap';
import ReviewStarPanel from './review-star-panel';
import i18nReview from '../../i18n/organization-review-modal.json';
import camelCase from '../../utils/camel-case';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRetweet} from '@fortawesome/free-solid-svg-icons'
import i18n from '../../i18n/review-lists-panel.json';
import * as DatavaseApi from '../datavase-api';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

import '../../css/organization-review-lists-panel.css';
import ShareButtons from "./share-buttons";
import {Link} from 'react-router-dom';

export default class Review extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showComment: false,
      comment: '',
      anonymous: false,
      review: this.props.review,
    };

    this._toggleAnonymous = this._toggleAnonymous.bind(this);
    this._editComment = this._editComment.bind(this);
    this._submitComment = this._submitComment.bind(this);
    this._toggleComments = this._toggleComments.bind(this);
    this._toggleSharePanel = this._toggleSharePanel.bind(this);
    this._postOrganizationReviewLike = this._postOrganizationReviewLike.bind(this);
    this._postOrganizationReviewDislike = this._postOrganizationReviewDislike.bind(this);
    this._updateOrganizationReviewLikeStatus = this._updateOrganizationReviewLikeStatus.bind(this);
  }

  _toggleAnonymous(_e) {
    this.setState({
      anonymous: !this.state.anonymous,
    });
  }

  _editComment(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  _submitComment(_e) {
    DatavaseApi.postOrganizationReviewComment(this.state.review.organization_id, {
      id: this.state.review.id,
      anonymous: this.state.anonymous,
      comment: this.state.comment,
    }).then(_res => window.location.reload());
  }
  _renderSharePanel() {
    if(this.state.showSharePanel) {
      var msg='';
      if(this.state.review.good_point.length !==0) {
        msg+=`良い点: ${this.state.review.good_point}/`
      }
      if(this.state.review.bad_point.length !==0) {
        msg+=`改善点: ${this.state.review.bad_point}`
      }
      if(this.state.review.total_review.length !==0) {
        msg+=`総合評価: ${this.state.review.total_review}`
      }
      return (
          <div style={{marginTop: '30px',paddingBottom: '20px'}}>
            <ShareButtons
                url={`https://api.datavase.io/${encodeURIComponent("organizations/"+this.state.review.organization_id+"/organization_reviews/"+this.state.review.id+"/share")}`}
                title={`★${this.state.review.rating}: ${msg}`}
            />
          </div>
      );
    }
  }
  _toggleSharePanel() {
    this.setState({showSharePanel: !this.state.showSharePanel});
  }
  _renderComments() {
    const comments = this.state.review.comments.map(comment => (
        <div id={comment.id} style={{margin: "5px 0", wordBreak: "break-all"}}>
          <img src={comment.user.avatar == null ? placeholder : comment.user.avatar}
               style={{width: '50px', height: '50px', margin: '0 10px 0 0'}} alt="avatar"/>
          <b>{comment.user.screen_name} <img
              src={comment.user.paid || comment.user.premium ? "/icons/verified_enterprise.png" : "/icons/non_verified.png"}
              alt={comment.user.verified ? comment.user.premium ? "Enterprise user" : "Pro user" : "Free or General user"}
              style={{width: "1.3em"}}/></b>:<br/><br/> {comment.comment}
        </div>
    ));
    if (this.state.showComment) {
      return (
        <div>
          <div style={{margin: '20px 0'}}>
            <InputGroup className="mb-3">
              <InputGroup.Addon>
                <Checkbox inline onChange={this._toggleAnonymous}> Anonymous </Checkbox>
              </InputGroup.Addon>
              <FormControl componentClass="textarea"
                           type="text"
                           style={{resize: 'vertical'}}
                           onChange={this._editComment} />
              <InputGroup.Button>
                <Button bsStyle="primary" onClick={this._submitComment}> Submit </Button>
              </InputGroup.Button>
            </InputGroup>
          </div>
          {comments}
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  _toggleComments() {
    this.setState({showComment: !this.state.showComment});
  }

  _postOrganizationReviewLike(e) {
    let likeVal = 0;
    if (this.state.review.current_like_status !== 1) {
      likeVal = 1;
    }
    DatavaseApi.postOrganizationReviewLike(this.state.review.organization_id, {
      id: this.state.review.id,
      like: likeVal,
    }).then(res => {
      this._updateOrganizationReviewLikeStatus(res.data, likeVal);
    });
  }

  _postOrganizationReviewDislike(e) {
    let likeVal = 0;
    if (this.state.review.current_like_status !== -1) {
      likeVal = -1;
    }
    DatavaseApi.postOrganizationReviewLike(this.state.review.organization_id, {
      id: this.state.review.id,
      like: likeVal,
    }).then(res => {
      this._updateOrganizationReviewLikeStatus(res.data, likeVal);
    });
  }

  _updateOrganizationReviewLikeStatus(data, state_expected) {
    const review = this.state.review;
    review.likes = data.like;
    review.dislikes = data.dislike;
    review.current_like_status = state_expected;
    this.setState({
      review,
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const formLang = i18nReview[langCode];

    let goodPoint = '';
    if (this.state.review.good_point.length > 0) {
      goodPoint = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.reason}
        </div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {lang.goodPoint}
        </div>
        <div className='comment' style={{lineHeight: "1.6"}}>
          {this.state.review.good_point}
        </div>
      </div>);
    }

    let badPoint = '';
    if (this.state.review.bad_point.length > 0) {
      badPoint = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {lang.badPoint}
        </div>
        <div style={{lineHeight: "1.6"}}>
          {this.state.review.bad_point}
        </div>
      </div>);
    }

    let totalReview = '';
    if (this.state.review.total_review.length > 0) {
      totalReview = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.reason}
        </div>
        <div className='comment' style={{lineHeight: "1.6", whiteSpace: "pre-wrap"}}>
          {this.state.review.total_review}
        </div>
        <br/><br/>
      </div>);
    }

    let interviewPreparationOther = '';
    if (this.state.review.interview_preparation_other.length > 0) {
      interviewPreparationOther = (
          <div className='comment'>
            {this.state.review.interview_preparation_other}
          </div>
      );
    }

    let interviewPreparation = '';
    if (this.state.review.interview_preparation.length > 0) {
      interviewPreparation = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.interviewPreparation}
        </div>
        {this.state.review.interview_preparation.map(key => (
            <div className='comment' key={key}>
              {formLang[camelCase(key)]}
            </div>
        ))}
        {interviewPreparationOther}
      </div>);
    }

    let interviewStyle = '';
    if (this.state.review.interview_style !== null && this.state.review.interview_style.length > 0) {
      interviewStyle = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.listeningStyle}
        </div>
        <div className='comment'>
          {formLang[camelCase(this.state.review.interview_style)]}
        </div>
      </div>);
    }

    let investStyle = '';
    if (this.state.review.invest_style !== null && this.state.review.invest_style.length > 0) {
      investStyle = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.investStyle}
        </div>
        <div className='comment'>
          {formLang[camelCase(this.state.review.invest_style)]}
        </div>
      </div>);
    }

    let supportFrequency = '';
    if (this.state.review.support_frequency !== null && this.state.review.support_frequency.length > 0) {
      supportFrequency = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.supportFrequency}
        </div>
        <div className='comment'>
          {formLang[camelCase(this.state.review.support_frequency)]}
        </div>
      </div>);
    }

    let supportReceivedOther = '';
    if ( this.state.review.support_received_other.length > 0) {
      supportReceivedOther = (
          <div className='comment'>
            {this.state.review.support_received_other}
          </div>
      );
    }

    let supportReceived = '';
    if ( this.state.review.support_received.length > 0) {
      supportReceived = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.supportReceived}
        </div>
        { this.state.review.support_received.map(key => (
            <div className='comment' key={key}>
              {formLang[camelCase(key)]}
            </div>
        ))}
        {supportReceivedOther}
      </div>);
    }

    let valueToFocus = '';
    if ( this.state.review.value_to_focus.length > 0) {
      valueToFocus = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.keyPoint}
        </div>
        { this.state.review.value_to_focus.map(key => (
            <div className='comment' key={key}>
              {formLang[camelCase(key)]}
            </div>
        ))}
      </div>);
    }
    let position = 'その他';
    if(this.state.review.position !== null) {
      if(this.state.review.position === 1) {
        position = '出資者';
      } else if(this.state.review.position === 2) {
        position = '出資先';
      } else if(this.state.review.position === 3) {
        position = 'ユーザー';
      } else if(this.state.review.position === 4) {
        position = 'パートナー';
      } else if (this.state.review.position === 5) {
        position = '求職者';
      } else if (this.state.review.position === 6) {
        position = 'チーム'
      }
    }

    let recommendCharacteristic = '';
    if ( this.state.review.recommend_characteristic.length > 0) {
      recommendCharacteristic = (
      <div>
        <div style={{marginTop: "5px", fontWeight: "bold"}}>
          {formLang.peopleRecommended}
        </div>
        { this.state.review.recommend_characteristic.map(key => (
            <div className='comment' key={key}>
              {formLang[camelCase(key)]}
            </div>
        ))}
      </div>);
    }

    return(
        <div style={{borderBottom: "solid 1px gray", marginBottom: "20px", paddingBottom: "20px"}}
             id={`review-${this.state.review.organization_id}-${this.state.review.id}`}>
          <img src={this.state.review.publisher.avatar == null ? placeholder : this.state.review.publisher.avatar}
               style={{width: '50px', height: '50px', margin: '0 10px 1em 0'}} alt="avatar"/><br/>
          Author: {this.state.review.publisher.screen_name} <img
            src={this.state.review.publisher.verified ? this.state.review.publisher.premium ? "/icons/verified_enterprise.png" : "/icons/verified_pro.png" : "/icons/non_verified.png"}
            alt={this.state.review.publisher.verified ? this.state.review.publisher.premium ? "Enterprise user" : "Pro user" : "Free or General user"}
            style={{width: "1.3em"}}/><b>{position}</b>
          <ReviewStarPanel
              rating={this.state.review.rating}
          />
          {valueToFocus}
          {interviewStyle}
          {investStyle}
          {interviewPreparation}
          {recommendCharacteristic}
          {supportFrequency}
        {supportReceived}
        {totalReview}
        {goodPoint}
        {badPoint}
          <p><Link to={`${this.props.resource_uri}/review/${this.state.review.id}`}
                   onlyActiveOnIndex>このクチコミの詳細ページへ</Link></p>

        <div style={{width: "100%", textAlign: "center"}}>
          <Button bsStyle={ this.state.review.current_like_status === 1 ? 'success' : 'default' } onClick={this._postOrganizationReviewLike}>
            <i className="material-icons" style={{verticalAlign: 'middle'}}>thumb_up</i>
            <span> { this.state.review.likes } </span>
          </Button>

          <Button bsStyle={ this.state.review.current_like_status === -1 ? 'danger' : 'default' } onClick={this._postOrganizationReviewDislike}>
            <i className="material-icons" style={{verticalAlign: 'middle'}}>thumb_down</i>
            <span> { this.state.review.dislikes } </span>
          </Button>

          <Button bsStyle="default" onClick={this._toggleComments}>
            <i className="material-icons" style={{verticalAlign: 'middle'}}>comment</i>
            <span> {this.state.review.comments.length} </span>
          </Button>

          <Button bsStyle="default" style={{fontSize: '1.2em'}} onClick={this._toggleSharePanel}>
            <i style={{verticalAlign: 'middle'}}><FontAwesomeIcon icon={faRetweet}/></i>
          </Button>
        </div>
          {this._renderSharePanel()}
          {this._renderComments()}
        </div>
    );
  }
}
