import React, { Component } from 'react'
import { Panel, Button } from 'react-bootstrap'

import BlockSpinner               from './block-spinner';
import ListTable                  from './list-table';
import AddCompaniesFilterModal    from './add-companies-filter-modal';
import NumericalFilterConfiguration from './numerical-filter-configuration.js';
import PriceFilterConfiguration   from './price-filter-configuration.js';
import * as DatavaseApi           from '../datavase-api';
import i18n                       from '../../i18n/companies-advanced-search-view.json';
import '../../css/filter-configuration.css';
import '../../css/table-pane.css';

const RESOURCE_NAME = 'investors';
const COLUMN_WIDTH = ['20px', '50px', 'null', '150px'];

export default class InvestorsAdvancedSearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      loadedPage: 0,
      nextPageLoaded: false,
      prefixSearchTimeoutId: null,
      showAddFilterModal: false,
      filterConfigurations: [],
      query: {
        filter: {
          "$and": [
            { resource_type: "investors" },
          ],
        },
        sort: {
          "review_count": -1,
        },
        page: 1,
        per_page: 30,
      },
    }
    this._showAddFilterModal = this._showAddFilterModal.bind(this);
    this._hideAddFilterModal = this._hideAddFilterModal.bind(this);
    this._search             = this._search.bind(this);
    this._updateFilter       = this._updateFilter.bind(this);
    this._removeFilter       = this._removeFilter.bind(this);
    this._addFilter          = this._addFilter.bind(this);
  }

  componentDidMount() {
    this._loadNextPage();
  }

  _showAddFilterModal() {
    this.setState({showAddFilterModal: true});
  }

  _hideAddFilterModal() {
    this.setState({showAddFilterModal: false});
  }

  _columnNames() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return [
      '#',
      lang.logoColumnHeader,
      lang.investorNameColumnHaeder,
      lang.dateOfFoundationColumnHaeder,
    ];
  }

  _constructEntriesList(companies) {
    const columnNames = this._columnNames();
    const newEntries = companies.map((company, i) => {
      let date = DatavaseApi.prettifyDate(
        company.founded_date, company.precision_of_date
      );
      const entry = {
        [columnNames[0]]: this.state.loadedPage * 30 + i + 1,
        [columnNames[1]]: company.thumb_url || company.image_url,
        [columnNames[2]]: company.name,
        [columnNames[3]]: date,
        'id': company.id,
        slug: company.slug
      };

      return entry;
    });

    return newEntries;
  }

  _loadNextPage() {
    const pageToLoad = this.state.loadedPage + 1;
    this._fetchCompanies(pageToLoad);
  }

  _updateFilter(index, filter) {
    const newConditions = this.state.query.filter["$and"].slice();
    newConditions[index+1] = filter;

    const newFilter = Object.assign({}, this.state.query.filter, {
      "$and": newConditions,
    });
    const newQuery = Object.assign({}, this.state.query, {
      filter: newFilter
    });

    this.setState({query: newQuery});
  }

  _removeFilter(index) {
    const newConditions = this.state.query.filter["$and"].slice();
    newConditions.splice(index+1, 1);
    const newFilter = Object.assign({}, this.state.query.filter, {
      "$and": newConditions
    });
    const newQuery = Object.assign({}, this.state.query, {
      filter: newFilter
    });
    const newFilterConfigs = this.state.filterConfigurations.slice();
    newFilterConfigs.splice(index, 1);

    this.setState({
      query: newQuery,
      filterConfigurations: newFilterConfigs
    });
  }

  _addFilter(subject, name, filterType) {
    const filterConfigurations = this.state.filterConfigurations.slice();
    let newConfigs = filterConfigurations.concat([{subject: subject, name: name, filterType: filterType}]);

    const newConditions = this.state.query.filter["$and"].slice().concat([{[subject]: {"$eq": null}}]);
    const newFilter = Object.assign({}, this.state.query.filter, {
      "$and": newConditions
    });
    const newQuery = Object.assign({}, this.state.query, {
      filter: newFilter
    });

    this.setState({
      filterConfigurations: newConfigs,
      showAddFilterModal: false,
      query: newQuery,
    });
  }

  _search(){
    this.setState({entries: []});
    this.setState({loadedPage: 0})
    this._fetchCompanies(1);
  }

  _fetchCompanies(page) {
    this.setState({nextPageLoaded: false});
    const query = Object.assign({}, this.state.query, {
      page: page,
      per_page: 30,
    });

    DatavaseApi.searchOrganizations(query)
    .then((res) => {
      let newEntries = this._constructEntriesList(res.data.organizations);
      this.setState({
        count: res.data.count,
        entries: this.state.entries.concat(newEntries),
        loadedPage: page,
        nextPageLoaded: true,
      });
    }).catch((res) => {
      console.error(res);
    });
  }

  _constructFilterConfigurations() {
    const configs = this.state.filterConfigurations;
    return configs.map((config, i) => {
      switch(config.filterType) {
        case "price": {
          return(
            <PriceFilterConfiguration
              key={i}
              index={i}
              filterName={config.name}
              subject={config.subject}
              onChange={this._updateFilter}
              onRemove={this._removeFilter}
            />
          );
        }
        default: {
          return(
            <NumericalFilterConfiguration
              key={i}
              index={i}
              filterName={config.name}
              subject={config.subject}
              onChange={this._updateFilter}
              onRemove={this._removeFilter}
            />
          );
        }
      }
    });
  }

  _isSearchButtonActive() {
    const conditions = this.state.query.filter["$and"];
    let active = true;
    conditions.forEach((condition) => {
      for(let key in condition) {
        if(key) {
          for(let comp in condition[key]) {
            if(!condition[key][comp]) {
              active = false;
              break;
            }
          }
          if(!active) break;
        }
      }
    });
    return active;
  }

  render(){
    const columnNames = this._columnNames();
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const filterConfigurations = this._constructFilterConfigurations();
    const isSearchButtonActive = this._isSearchButtonActive();

    return (
      <div>
        <AddCompaniesFilterModal
          show={this.state.showAddFilterModal}
          onCancel={this._hideAddFilterModal}
          onAddFilter={this._addFilter}
        />
        <div style={{margin: '5px 20px'}}>
          {filterConfigurations}
          <div>
            <Button style={{backgroundColor: 'transparent', border: 'none', padding: '0', margin: '5px 0px 13px 0px'}} onClick={this._showAddFilterModal}>
              <i className="material-icons" style={{verticalAlign: 'middle'}}>add</i>
              {lang.addFilterButton}
            </Button>
          </div>
          <div>
            <Button disabled={!isSearchButtonActive} onClick={this._search} bsStyle="primary">
              {lang.searchButton}
            </Button>
          </div>
        </div>
        <div style={{margin: '5px 20px'}}>
          <span style={{color: '#666'}}>{lang.totalHit}: </span><span style={{color: '#666'}}>{this.state.count}</span>
        </div>
        <div style={{display: 'inline-block', overflowY: 'scroll', backgroundColor: '#fff', borderBottom: '2px solid #ddd', borderTop: '2px solid #ddd', paddingBottom: '0', width: '100%', height: '800px'}}>
          <Panel>
            <ListTable
              hover
              striped
              condensed
              entries={this.state.entries}
              columnNames={columnNames}
              columnWidth={COLUMN_WIDTH}
              linkColumnName={columnNames[2]}
              imageColumnName={columnNames[1]}
              resourceName={RESOURCE_NAME}
            />
          </Panel>
          <div style={{textAlign: 'center'}}>
          {
            this.state.nextPageLoaded ?
            <Button style={{margin: '0px auto 20px'}} bsStyle="primary" onClick={this._loadNextPage.bind(this)}>
              {lang.loadMoreButton}
            </Button> :
            <BlockSpinner visible/>
          }
          </div>
        </div>
      </div>
    );
  }
}
