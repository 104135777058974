import React, { Component } from 'react';
import { Panel, Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddToListModal             from './add-to-list-modal';
import ContributeModal            from './contribute-modal';
import UpgradeToProceedModal      from './upgrade-to-proceed-modal';
import ShareButtons               from './share-buttons';

import * as DatavaseApi           from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n from '../../i18n/contact-social-panel.json';

class ContactSocialPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddToListModal: false,
      showContributeModal: false
    };
    this._onContributeButtonClick      = this._onContributeButtonClick.bind(this);
    this._showUpgradeToContributeModal = this._showUpgradeToContributeModal.bind(this);
    this._hideUpgradeToContributeModal = this._hideUpgradeToContributeModal.bind(this);
    this._showContributeModal          = this._showContributeModal.bind(this);
    this._hideContributeModal          = this._hideContributeModal.bind(this);
    this._showAddToListModal           = this._showAddToListModal.bind(this);
    this._hideAddToListModal           = this._hideAddToListModal.bind(this);
  }

  _contactRow(title, value, link) {
    return(
      <li key={title}>
        <Col xs={4} lg={4}>
          <span>{title}</span>
        </Col>
        <Col xs={8} lg={8}>
          <a href={`${link}`} target="_blank" rel="noopener noreferrer">
            {value} <i className="material-icons" style={{fontSize: '11px'}}>open_in_new</i>
          </a>
        </Col>
      </li>
    );
  }

  _onContributeButtonClick() {
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    if(DatavaseApi.getUserStatus() === 'Free') {
      this._showUpgradeToContributeModal();
      return;
    }

    this._showContributeModal();
  }

  _showUpgradeToContributeModal() {
    this.setState({showUpgradeToContributeModal: true});
  }

  _hideUpgradeToContributeModal() {
    this.setState({showUpgradeToContributeModal: false});
  }

  _showContributeModal() {
    this.setState({showContributeModal: true});
  }

  _hideContributeModal() {
    this.setState({showContributeModal: false});
  }

  _showAddToListModal() {
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    this.setState({showAddToListModal: true});
  }

  _hideAddToListModal() {
    this.setState({showAddToListModal: false});
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const url = this.props.websiteUrl;
    const socialMediaList = this.props.socialMediaList;

    let contacts = [];
    if(url){
      contacts.push(this._contactRow(`${lang.website}:`, url, DatavaseApi.formatURL(url)));
    }
    if(socialMediaList.twitter_id) {
      const id = socialMediaList.twitter_id;
      contacts.push(this._contactRow('Twitter:', lang.viewOnTwitter, `https://twitter.com/${id}`));
    }
    if(socialMediaList.facebook_page_id) {
      const id = socialMediaList.facebook_page_id;
      contacts.push(this._contactRow('Facebook:', lang.viewOnFacebook, `https://facebook.com/${id}`));
    }
    if(socialMediaList.instagram_id) {
      const id = socialMediaList.instagram_id;
      contacts.push(this._contactRow('Instagram:', lang.viewOnInstagram, `https://instagram.com/${id}`));
    }
    if(socialMediaList.youtube_id) {
      const id = socialMediaList.youtube_id;
      contacts.push(this._contactRow('YouTube:', lang.viewOnYouTube, `https://youtube.com/user/${id}`));
    }
    return(
      <Panel id="contributers-panel">
        <ShareButtons
          url={this.props.url}
          title={this.props.title}
        />
        <hr/>
        <UpgradeToProceedModal
          show={this.state.showUpgradeToContributeModal}
          title={lang.upgradeToContributeModalTitle}
          body={lang.upgradeToContributeModalBody}
          upgradeButton={lang.upgradeButton}
          cancelButton={lang.cancelButton}
          onCancelClick={this._hideUpgradeToContributeModal}
        />
        <ContributeModal
          show={this.state.showContributeModal}
          onCancel={this._hideContributeModal}
          resourceType={this.props.resourceType}
          subject={this.props.resource}
        />
        <AddToListModal
          show={this.state.showAddToListModal}
          onCancelClick={this._hideAddToListModal}
          resourceType={this.props.resourceType}
          resourceId={this.props.resourceId}
        />
        <div>
          <Button
            block
            onClick={this._showAddToListModal}
            className="add-to-list-button"
          >
            <i className="material-icons add-to-list-button-icon">star_border</i>{lang.addToList}
          </Button>
          <Button
            block
            onClick={this._onContributeButtonClick}
            className="contribute-button"
          >
            <i className="material-icons add-to-list-button-icon">edit</i>{lang.contribute}
          </Button>
          <hr/>
          <ul style={{marginTop: '10px'}}>
            {contacts}
          </ul>
        </div>
      </Panel>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactSocialPanel);
