import React, { Component } from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BlockSpinner from './partials/block-spinner';
import OrganizationAbstractPanel from './partials/organization-abstract-panel';
import ContributionsPanel from './partials/contributions-panel';
import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/investor-details-pane.json'
import '../css/details-pane.css';

class InvestorEditsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      contributions: null,
      hasLoaded: false,
      id: null,
    };

    this._onTabSelect = this._onTabSelect.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchInvestorWithRelatedResources(id)
      .then(res => {
        this.setState({
          resources: res.data,
          hasLoaded: true,
          id: res.data.investor.id,
        });
      }).catch((res) => {
        console.error(res);
      })
  }

  _onTabSelect(key) {
    if (key === "contributions") {
      if (!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('investors', id)
          .then(res => {
            this.setState({ contributions: res.data });
          })
          .catch(res => {
            console.error(res);
          });
      }
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return (<BlockSpinner visible={true} />);
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const { resources, id, contributions } = this.state;
    const investor = resources.investor;
    return (
      <div id="details-page-contents">
        <Col sm={12} md={12} lg={12}>
          <OrganizationAbstractPanel
            organization={investor}
            profileImage={investor.profile_image_url}
            id={id}
          />
        </Col>
        <div className="clearfix">
          <div className="clearfix" style={{ 'paddingLeft': '15px' }}>
            <Tabs id="controlled-tab-example" activeKey="edits" onSelect={k => window.location.href = "/investors/" + this._getId() + "/" + k}>
              <Tab eventKey="details" title={lang.detailTabTitle} />
              <Tab eventKey="review" title={lang.review} />
              <Tab eventKey="edits" title={lang.editLogTabTitle} />
              <Tab eventKey="history" title={lang.timelineEventsTitle} />
              <Tab eventKey="qas" title="Q&A" />
            </Tabs>
          </div>
          <Col sm={12} md={12} lg={12}>
            <ContributionsPanel
              subjectUrl={investor.url}
              subjectId={id}
              contributions={contributions}
            />
          </Col>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvestorEditsPane));
