import React, { Component } from 'react'
import { MatchMediaHOC } from 'react-match-media';
import { Navbar, NavItem } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import color from '../styles/colors.js';

import * as authActions from '../../actions/auth';
import datavase_logo    from '../../assets/datavase_header_logo.png';

import '../../css/top-navbar.css';

class TopNavbarMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false
    };
  }

  componentDidMount() {
    this.props.onMobileNavbarMounted();
  }

  componentWillUnmount() {
    this.props.onMobileNavbarUnmounted();
  }

  _setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }

  _closeNav() {
    this.setState({ navExpanded: false });
  }

  render() {
    /*第一優先はStateのSearchがonの場合にする。緊急デプロイに備えて必ずelseになるようにする*/
    if(this.props.pathCategory === 'investors' && this.props.pathCategory !== 'investors') {
      return(
          <MobHead id="top-navbar">
            <MobHeadInner onToggle={this.props.onSidebarVisibilityToggled}
                    expanded={this.state.navExpanded}>
              <MobHeadContent>
                <MobHeadBrand>
                  <MobHeadItem onClick={() => window.history.back()}>
                    ←
                  </MobHeadItem>
                </MobHeadBrand>
                <Navbar.Collapse>
                  <Navbar pullRight>
                    <MobHeadItem eventKey={1} href="#">検索アイコン</MobHeadItem>
                  </Navbar>
                </Navbar.Collapse>
              </MobHeadContent>
            </MobHeadInner>
          </MobHead>
      )
    } else {
      return (
          <MobHead id="top-navbar">
            <MobHeadInner onToggle={this.props.onSidebarVisibilityToggled}
                    expanded={this.state.navExpanded}>
              <MobHeadContent>
                <MobHeadBrand>
                  <MobHeadItem href="/">
                    <Logo src={datavase_logo} alt="datavase.io"/>
                  </MobHeadItem>
                </MobHeadBrand>
                <MobHeadToggle/>
              </MobHeadContent>
            </MobHeadInner>
          </MobHead>
      );
    }
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch){
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default MatchMediaHOC(connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNavbarMobile), '(max-width: 767px)');


const MobHead = styled.header`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 16px;
  height: 56px;
  background: ${color.primary};
`

const MobHeadInner = styled(Navbar)`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
`

const MobHeadContent = styled(Navbar.Header)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
`

const MobHeadBrand = styled(Navbar.Brand)`
  width: 120px;
`

const MobHeadItem = styled(NavItem)`
  padding: 15px 0;
`

const MobHeadToggle = styled(Navbar.Toggle)`
  margin: 0;
  padding: 4px 8px;
  height: 40px;
`

const Logo = styled.img`
  width: 140px;
`
