import React, { Component } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import color from "../styles/colors";
import PanelTitle from "../atoms/panel-title-sub.js";
import PanelList from "../molecules/panel-list.js";
import * as DatavaseApi from '../datavase-api';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

export default class SidePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelTitle: '',
      moreText: '',
      moreUrl: '',
      entities: [],
    };

    this._fetchData = this._fetchData.bind(this);
    this._fetchData();
  }

  async _fetchData() {
    const params = {
      keyword: '',
      page: 1,
      perPage: 5,
      sort: 'updated_at'
    };
    if (this.props.mode === 'investors') {
      const res = await DatavaseApi.listLatestInvestorReview(params);
      this.setState({
        panelTitle: '投資家•VCの新着口コミ',
        moteText: '投資家•VCの口コミをもっと見る',
        moreUrl: '/investors/',
        entities: res.data.reviews,
      });
    } else if (this.props.mode === 'companies') {
      const res = await DatavaseApi.listLatestCompaniesReview(params);
      this.setState({
        panelTitle: '企業•スタートアップ',
        moreText: '企業•スタートアップの口コミをもっと見る',
        moreUrl: '/companies/',
        entities: res.data.reviews,
      });
    } else if (this.props.mode === 'articles') {
      const res = await DatavaseApi.getHotRank("articles");
      this.setState({
        panelTitle: '人気記事ランキング',
        moreText: 'レポートをもっと見る',
        moreUrl: '/articles/',
        entities: res.data,
      });
    } else if (this.props.mode === 'investors_rank') {
      const res = await DatavaseApi.getHotRank("investors");
      this.setState({
        panelTitle: '投資家•VC人気ランキング',
        moteText: '投資家•VCをもっと見る',
        moreUrl: '/investors/',
        entities: res.data,
      });
    } else if (this.props.mode === 'companies_rank') {
      const res = await DatavaseApi.getHotRank("companies");
      this.setState({
        panelTitle: '企業•スタートアップ人気ランキング',
        moreText: '企業•スタートアップをもっと見る',
        moreUrl: '/companies/',
        entities: res.data,
      });
    }
  }

  render() {
    let lists = [];

    if (this.props.mode === 'companies' || this.props.mode === 'investors') {
      lists = this.state.entities.map((entity, i) => (
        <PanelList
          link={`/${entity.resource_type_name}/${entity.slug || entity.id}/review/${entity.review_id}`}
          title={entity.organization_name}
          thambnailSrc={entity.profile_image_url}
          review={entity.rating}
          good={entity.review_likes}
          key={i}
          rank={i + 1}
        />
      ));
    } else if (this.props.mode === 'articles') {
      //console.log(this.state.entities);
      for (let [i, entity] of Object.entries(this.state.entities)) {
        let image = entity["primary_images"][0] === undefined ? placeholder : entity["primary_images"][0]["image"]["url"];
        lists.push(
          <PanelList
            link={`/articles/${entity.datavase_article_id}`}
            title={entity.title}
            thambnailSrc={image}
            review={null}
            good={null}
            key={i}
            rank={i}
          />
        );
      }
    } else if (this.props.mode === "investors_rank" || this.props.mode === "companies_rank") {
      for (let [i, entity] of Object.entries(this.state.entities)) {
        lists.push(
          <PanelList
            link={`/${entity.resource_type_name}/${entity.slug || entity.id}/`}
            title={entity.organization_name}
            thambnailSrc={entity.profile_image_url}
            review={entity.average_rating}
            good={null}
            key={i}
            rank={i}
          />
        );
      }
    }


    return (
      <Wrapper>
        <PanelTitle panelTitle={this.state.panelTitle} />
        <Panel>
          {lists}
        </Panel>
        <More to={this.state.moreUrl}>{this.state.moreText}</More>
      </Wrapper>
    );
  }
}


const Wrapper = styled.div`
  margin: 0 0 40px;
`;

const Panel = styled.ul`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
`;

const More = styled(Link)`
  display: block;
  margin: 12px 4px 0 0;
  color: ${color.accent};
  font-size: 12px;
  font-weight: 400;
  transition: 0.3s;
  text-align: right;
  text-decoration: underline !important;
  &:hover {
    color: ${color.primary};
    transition: 0.3s;
  }
`;
