import React, { Component } from 'react';

import placeholder      from '../../assets/datavase_logo_icon_grey.png';
import admin_icon       from '../../assets/datavase_logo_icon.png';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/contributor-tooltip-contents.json';

export default class ContributorTooltipContents extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const { contributor, subjectResourceDomainName } = this.props;
    const contributionCount = contributor.contribution_count;
    const praise            = contributor.praise;
    const softBlame         = contributor.soft_blame;

    let screenName, img, domainName;
    let borderStyle = '2px solid #aaa';
    if(contributor.contributor_type === 'Admin') {
      img         = admin_icon;
      screenName  = 'datavase.io';
      domainName  = 'datavase.io';
      borderStyle = '2px solid #669287';
    }else{
      img        = contributor.thumb_profile_image_url || placeholder;
      screenName = contributor.screen_name || 'Anonymous User';
      domainName = contributor.domain_name;
      if(subjectResourceDomainName === domainName) {
        borderStyle = '2px solid #BE3D33';
      }
    }

    return(
      <div id="contributor-tooltip" style={{padding: '2px 0'}}>
        <span>{lang.contributedBy}</span>
        <div>
          <img
            alt={screenName}
            src={img}
            style={{width: '26px', borderRadius: '13px', marginRight: '4px', border: borderStyle}}
          />
          <span style={{fontWeight: 'bold'}}>{screenName}</span>
        </div>
        {
          screenName !== 'datavase.io' ?
          <div>
            <div>
              <span>{lang.contributionsCount}</span>
              <span>{contributionCount || 0}</span>
            <div>
            </div>
              <span>{lang.upvotedCount}</span>
              <span>{praise || 0}</span>
            <div>
            </div>
              <span>{lang.downvotedCount}</span>
              <span>{softBlame || 0}</span>
            </div>
          </div>
          :
          null
        }
      </div>
    );
  }
}

