import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
//TODO: これとOrgの投稿項目を同じにする。BackendにちょっとController/Migration追加要するかも(見積:数時間)
//TODO: ついでに無限投稿をPersonでも有効化する(Unique外す)
export default class CreateReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      isConfirmationModalOpen: false,
        anonymous: false,
      rating: 1,
      total_review: ''
    };

    this._showConfirmationModal = this._showConfirmationModal.bind(this);
    this._closeConfirmationModal = this._closeConfirmationModal.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._submit = this._submit.bind(this);
  }

  _showConfirmationModal(){
    this.setState({
      isConfirmationModalOpen: true
    });
  }

  _closeConfirmationModal(){
    this.setState({
      isConfirmationModalOpen: false
    });
  }

  _onFormChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  _submit(){
      const id = this.props.id;
    const params ={
        anonymous: this.state.anonymous,
      rating:     this.state.rating,
      good_point: this.state.good_point,
      bad_point:  this.state.bad_point,
      total_review: this.state.total_review,
    };

    let apiCall = this.props.organization ? DatavaseApi.postOrganizationReviews(id, params)
                                          : DatavaseApi.postPersonReviews(id, params);
    apiCall.then(res => {
        window.location.href = `/action_done/review?goto=${window.location.href}review/${res.data.id}`;
    }).catch((err) => {
      this.setState({
        isConfirmationModalOpen: false,
        errorMessages: Object.values(err.response.data)
      });
    });
  }

  render() {
    return(
      <div>
        <Modal
          show={this.state.isConfirmationModalOpen}
          onHide={this._closeConfirmationModal}
          style={{padding:"5px"}}
        >
          <Modal.Body>
            <p style={{textAlign:"center",fontSize:"16px"}}>
              以下の内容で投稿しますか？
            </p>
            <ControlLabel>総合評価</ControlLabel>
            <p>{this.state.rating}</p>
            <ControlLabel>総合評価</ControlLabel>
            <p>{this.state.total_review}</p>
            <div style={{width:"50%",margin:"40px auto 0px"}}>
              <Button
                className="cancel-button"
                onClick={this._closeConfirmationModal}
              >
                編集する
              </Button>
              <Button
                className="submit-button"
                bsStyle="success"
                onClick={this._submit}
              >
                投稿する
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.props.show}
          onHide={this.props.onCancel}
        >
          <Modal.Body>
            <div style={{color: 'red', fontWeight: 'bold', margin: '10px'}}>
              {this.state.errorMessages.map((err, i) => <div key={i}>{err}</div>)}
            </div>
            <FormGroup>
                <ControlLabel style={{marginTop: "0.5cm"}}>投稿形式</ControlLabel>
                <div>
                    <Button bsStyle={!this.state.anonymous ? "primary" : "default"} name="anonymous" onClick={() => {
                        this.setState({anonymous: false})
                    }}>実名</Button>
                    <Button bsStyle={this.state.anonymous ? "primary" : "default"} name="anonymous" onClick={() => {
                        this.setState({anonymous: true})
                    }}>匿名</Button>
                </div>
                <br/>
              <ControlLabel>総合評価</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                name="rating"
                onChange={this._onFormChange}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </FormControl>
              {/*<ControlLabel>良かった点</ControlLabel>
              <FormControl
                componentClass="textarea"
                type="text"
                name="good_point"
                placeholder="良かった点"
                onChange={this._onFormChange}
              />
              <ControlLabel>悪かった点</ControlLabel>
              <FormControl
                componentClass="textarea"
                type="text"
                name="bad_point"
                placeholder="悪かった点"
                onChange={this._onFormChange}
              />*/}
              <ControlLabel>総合評価</ControlLabel>
              <FormControl
                  componentClass="textarea"
                  type="text"
                  name="total_review"
                  placeholder="総合評価"
                  onChange={this._onFormChange}
              />
              <div style={{width:"50%",margin:"40px auto 0px"}}>
                <Button
                  className="cancel-button"
                  onClick={this.props.onCancel}
                >
                  キャンセルする
                </Button>
                <Button
                  type="submit"
                  className="submit-button"
                  bsStyle="success"
                  onClick={this._showConfirmationModal}
                >
                  投稿する
                </Button>
              </div>
            </FormGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
