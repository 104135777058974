import React, { Component } from 'react';
import AbstractPanel        from './abstract-panel';
import * as DatavaseApi     from '../datavase-api';

export default class PersonAbstractPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const id = this.props.id;
    DatavaseApi.fetchPersonAverageReviews(id)
    .then(res => {
      this.setState({
        averageReviews: res.data.average_rating,
      });
    }).catch((res) => {
      console.error(res);
    });
}

  render() {
    const langCode      = DatavaseApi.getLangCode();
    const person        = this.props.person;
    const profileImage  = this.props.profileImage;
    const name          = DatavaseApi.extractAndFormatPersonName(person, langCode);
    const desc          = DatavaseApi.extractDescription(person, langCode);
    const averageReviews = this.state.averageReviews || 0.0;

    return (
      <AbstractPanel
        profileImage={profileImage}
        abstractTitle={name}
        subHeading={"Person"}
        abstractDescription={desc}
        averageReviews={averageReviews}
      />
    );
  }
}
