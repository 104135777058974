import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import store from 'store';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import ListDigestsColumnView from './partials/list-digests-column-view';
import UpgradeToCreateMoreListsModal from './partials/upgrade-to-create-more-lists-modal';
import BlockSpinner from './partials/block-spinner';
import CreateListModal from './partials/create-list-modal';
import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/list-pane.json';

const addListButtonOuterStyle = {
  display: 'inline-block',
  margin: '0 9px',
  width: '340px',
  whiteSpace: 'normal',
  verticalAlign: 'top'
};

const addListButtonStyle = {
  display: 'inline-block',
  width: "280px",
  margin: '0 4px 8px 16px',
  padding: '8px',
  fontSize: '16px',
};

class ListsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      hasLoaded: false,
      showUpgradeToCreateMoreListsModal: false,
      showCreateListModal: false,
    };
    this._showCreateListModal               = this._showCreateListModal.bind(this);
    this._hideCreateListModal               = this._hideCreateListModal.bind(this);
    this._showUpgradeToCreateMoreListsModal = this._showUpgradeToCreateMoreListsModal.bind(this);
    this._hideUpgradeToCreateMoreListsModal = this._hideUpgradeToCreateMoreListsModal.bind(this);
    this._onAddButtonClicked                = this._onAddButtonClicked.bind(this);
    this._onListCreated                     = this._onListCreated.bind(this);
  }

  componentDidMount() {
    this._fetchLists();
  }

    UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      lists: nextProps.sharedResources.lists,
    });
  }

  _fetchLists() {
    const uid = store.get('uid');
    DatavaseApi.listLists({uid: uid})
    .then(res => {
      this.setState({
        lists: res.data,
        hasLoaded: true
      });
      this.props.sharedResourcesActions.setMylists(res.data);
    })
    .catch(err => {
      console.error(err.response);
    });
  }

  _showUpgradeToCreateMoreListsModal() {
    this.setState({showUpgradeToCreateMoreListsModal: true});
  }

  _hideUpgradeToCreateMoreListsModal() {
    this.setState({showUpgradeToCreateMoreListsModal: false});
  }

  _showCreateListModal() {
    this.setState({showCreateListModal: true});
  }

  _hideCreateListModal() {
    this.setState({showCreateListModal: false});
  }

  _onAddButtonClicked() {
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    if(DatavaseApi.getUserStatus() === 'Free' &&
      this.state.lists.length >= 1) {
      this._showUpgradeToCreateMoreListsModal();
    }else{
      this._showCreateListModal();
    }
  }

  _onListCreated() {
    this._fetchLists();
    this._hideCreateListModal();
  }

  render(){
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const listDigestsColumns = this.state.lists.map(list => {
      return (
        <ListDigestsColumnView
          key={list.id}
          list={list}
        />
      );
    });

    return (
      <div>
        <div>
          <CreateListModal
              show={this.state.showCreateListModal}
              onCancelClick={this._hideCreateListModal}
              onSuccess={this._onListCreated}
          />
          <UpgradeToCreateMoreListsModal
            show={this.state.showUpgradeToCreateMoreListsModal}
            onCancelClick={this._hideUpgradeToCreateMoreListsModal}
          />
        </div>
        <div className="clearfix" style={{padding: '10px', whiteSpace: 'nowrap', overflowX: 'scroll'}}>
          {
            this.state.hasLoaded ?
            <div>
              {listDigestsColumns}
              <div style={addListButtonOuterStyle}>
                <Button
                  onClick={this._onAddButtonClicked}
                  bsStyle="primary"
                  style={addListButtonStyle}
                >
                  <i className="material-icons" style={{verticalAlign: 'middle'}}>
                    add
                  </i>
                  {lang.addMylistButtonName}
                </Button>
              </div>
            </div>
            :
            <BlockSpinner visible/>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

const ReduxedListsPane = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListsPane);
export default ReduxedListsPane;
