import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import media from "styled-media-query";

import * as DatavaseApi from '../datavase-api';
import * as stateCacheActions from '../../actions/state-cache';

import CompaniesColumnsView from '../organisms/companies-columns-view';
import SidePanel from '../organisms/side-panel.js';

const COLUMN_VIEW = 'COLUMN_VIEW';
const ADVANCED_SEARCH = 'ADVANCED_SEARCH';
class CompaniesPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToDisplay: COLUMN_VIEW,
      showUpgradeToProceedModal: false,
    };
    this._onAdvancedSearchClicked = this._onAdvancedSearchClicked.bind(this);
    this._onColumnViewClicked = this._onColumnViewClicked.bind(this);
    this._showUpgradeToProceedModal = this._showUpgradeToProceedModal.bind(this);
    this._hideUpgradeToProceedModal = this._hideUpgradeToProceedModal.bind(this);
  }

  _showUpgradeToProceedModal() {
    this.setState({ showUpgradeToProceedModal: true });
  }

  _hideUpgradeToProceedModal() {
    this.setState({ showUpgradeToProceedModal: false });
  }

  componentDidMount() {
    const cachedState = this.props.stateCache.companiesPaneState;
    if (cachedState) {
      this.setState({
        componentToDisplay: cachedState.componentToDisplay
      });
    }
    DatavaseApi.getNotification();
  }

  componentWillUnmount() {
    this.props.stateCacheActions.setCompaniesPaneState(this.state);
  }

  _onAdvancedSearchClicked() {
    let userStatus = DatavaseApi.getUserStatus();
    if (userStatus === 'Free') {
      this._showUpgradeToProceedModal();
      return;
    }

    this.setState({ componentToDisplay: ADVANCED_SEARCH });
  }

  _onColumnViewClicked() {
    this.setState({ componentToDisplay: COLUMN_VIEW });
  }

  render() {
    let viewToRender;
    viewToRender = (
      <CompaniesColumnsView />
    );

    return (
      <Wrapper>
        <MainColumn>
          {viewToRender}
        </MainColumn>
        <SideColumn>
          <SidePanel mode="companies_rank" />
        </SideColumn>
      </Wrapper>
    );
  }
}
function mapStateToProps(state) {
  return {
    stateCache: state.stateCache
  };
}
function mapDispatchToProps(dispatch) {
  return {
    stateCacheActions: bindActionCreators(stateCacheActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesPane);


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan("medium")`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const MainColumn = styled.div`
  width: 65%;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const SideColumn = styled.div`
  width: 30%;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;
