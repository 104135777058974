import React, { Component }       from 'react'

import NewsArticlesColumnsView    from './partials/news-articles-columns-view';

export default class LatestNewsPane extends Component {
  render() {
    return(
      <div className="clearfix">
        <NewsArticlesColumnsView/>
      </div>
    );
  }
}
