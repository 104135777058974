import React, { Component } from 'react';
import { Panel, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";

import { getLangCode } from '../datavase-api';
import i18n from '../../i18n/error-message-modal';
import * as sharedResourcesActions from '../../actions/shared-resources';

class ErrorMessageModal extends Component {
  constructor(props) {
    super(props);
    this._dismissError = this._dismissError.bind(this);
    this._onClickOverlay = this._onClickOverlay.bind(this);
  }

  _dismissError(redirectPath) {
    return () => {
      if (redirectPath) {
        window.location.href = redirectPath;
      } else {
        this.props.sharedResourcesActions.flushErrorMessage();
      }
    };
  }

  _onClickOverlay(e) {
    if (e.target.id === 'modal-bg') {
      this._dismissError()(); // Tricky lambda due to legacy code.
    }
  }

  _constructMessageHeader(error) {
    const langCode = getLangCode();
    const lang = i18n[langCode];

    if (error.message === '401') {
      return lang.unauthorizedErrorHeader;
    } else if (error.message === '400') {
      return lang.badRequestErrorHeader;
    } else if (error.message === '403') {
      return lang.forbiddenErrorHeader;
    } else if (error.message === '500') {
      return lang.serverErrorHeader;
    } else {
      return lang.defaultErrorHeader;
    }
  }

  _constructMessageBody(error) {
    const langCode = getLangCode();
    const lang = i18n[langCode];

    if (error.message === '401') {
      return (<span>{lang.unauthorizedErrorMessage}</span>);
    } else if (error.message === '400') {
      return (<span>{lang.badRequestErrorMessage}</span>);
    } else if (error.message === '403') {
      return (<span>{lang.forbiddenErrorMessage}</span>);
    } else if (error.message === '500') {
      return (<span>{lang.serverErrorMessage}</span>);
    } else {
      return (<span>{error.message}</span>);
    }
  }

  _constructMessageFooter(error) {
    const langCode = getLangCode();
    const lang = i18n[langCode];
    const next = window.location.href;

    if (error.message === '401') {
      return (
        <div>
          <Button
            onClick={this._dismissError(`/login?next=${next}`)}
            bsStyle="primary"
          >
            {lang.toLoginPageButton}
          </Button>
          <Button
            onClick={this._dismissError('/signup')}
            bsStyle="default"
          >
            {lang.toSignupPageButton}
          </Button>
        </div>
      );
    } else if (['400', '403', '500'].includes(error.message)) {
      return (
        <Button
          onClick={this._dismissError()}
          bsStyle="primary"
        >OK</Button>
      );
    } else {
      return (
        <Button
          onClick={this._dismissError(error.redirectPath)}
          bsStyle="primary"
        >{error.message}</Button>
      );
    }
  }

  render() {
    const error = this.props.sharedResources.error || {};
    if (this.props.show) {
      return (
        <Overlay id="modal-bg" onClick={this._onClickOverlay}>
          <Panel>
            <ListGroup>
              <ListGroupItem>
                {this._constructMessageHeader(error)}
              </ListGroupItem>
              <ListGroupItem>
                {this._constructMessageBody(error)}
              </ListGroupItem>
              <ListGroupItem>
                {this._constructMessageFooter(error)}
              </ListGroupItem>
            </ListGroup>
          </Panel>
        </Overlay>
      );
    } else {
      return (<div></div>);
    }
  }
}

function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorMessageModal);

