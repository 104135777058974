import React, {Component} from "react";
import {Link} from 'react-router-dom';
import styled from "styled-components";
import color from "../styles/colors";

import Thambnail from "../atoms/square-thambnail.js";
import MiniDescription from "../atoms/mini-description.js";
import ReviewStar from '../partials/review-star-panel.js';

const ThambnailInfo = props => (
  <Thambnail thambImg={props.thambnailSrc} title={props.title} rank={props.rank} />
);


export default class PanelList extends Component {
  render() {
    const {
      link,
      thambnailSrc,
      title,
      rank,
      review,
      //good,
      date,
    } = this.props;
    return (
      <Wrapper to={link}>
        <Inner>
          <ThambnailInfo thambnailSrc={thambnailSrc} title={title} rank={rank} />
          <TextArea>
            <Title>{title}</Title>
            <Descriptions>
              <Right>
                <MiniDescription text={review} />
                {/* <Star review={review} /> */}
                {review === null ? null : <ReviewStar rating={review}/>}
                {/* good === null ? null : <Heart good={good} /> */}
              </Right>
              <Left>
                <MiniDescription text={date} />
              </Left>
            </Descriptions>
          </TextArea>
        </Inner>
      </Wrapper>
    );
  }
}


const Wrapper = styled(Link)``;

const Inner = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  &:hover {
    background: ${color.black04};
  }
`;

const TextArea = styled.div`
  width: calc(100% - 58px);
  height: 100%;
`;

const Title = styled.h1`
  margin: 0 0 8px;
  color: ${color.black87};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Descriptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Left = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
