import axios from 'axios';
import * as DatavaseApi from '../components/datavase-api';
import * as Auth from '../constants/auth';
import * as ENV from '../constants/environment';

export function logout() {
  return (dispatch) => {
    axios.delete(`${ENV.API_ENDPOINT}/auth/sign_out`).then((res) => {
      DatavaseApi.forgetTokens();
      dispatch(logoutResult(true, 'Log out secceeded.'));
      window.location.href = '/login';
    }).catch((res) => {
      DatavaseApi.forgetTokens();
      dispatch(logoutResult(false, res.response.data.errors));
      window.location.href = '/login';
    });
  }
}
function logoutResult(result, messages) {
  return {
    type: Auth.LOGOUT_RESULT,
    result: result,
    errors: messages,
  }
}
