import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../css/error-page.css'

export default class Error404Page extends Component {
  render() {
    return(
      <div id="error-page">
        <div id="error-jumbotron">
          <h1 id="error-heading">404</h1>
          <p id="error-text">The web page you are trying to access does not exist or has moved.</p>
        </div>
        <div id="error-footer">
          <Link to="/">datavase.io</Link>
        </div>
      </div>
    );
  }
}
