import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/confirm-subscription-cancel-modal.json';
import BlockSpinner     from './block-spinner';

export default class ConfirmSubscriptionCancelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
    };
    this._onCancelClick = this._onCancelClick.bind(this);
    this._onConfirmClick = this._onConfirmClick.bind(this);
  }

  _onCancelClick() {
    if(this.props.onCancelClick) { this.props.onCancelClick(); }
  }

  _onConfirmClick() {
    this.setState({isProcessing: true});
    DatavaseApi.cancelSubscription()
    .then(res => {
      if(this.props.onCancelSubscriptionSuccess) {
        this.props.onCancelSubscriptionSuccess();
      }
    })
    .catch(res => {
      this.setState({isProcessing: false})
      if(this.props.onCancelSubscriptionFailure) {
        this.props.onCancelSubscriptionFailure();
      }
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.confirmSubscriptionCancelModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.areYouSureMessage}
        </Modal.Body>
        <Modal.Footer>
          {
            this.state.isProcessing ?
            <BlockSpinner visible={true}/> :
            <div>
              <Button
                bsStyle="danger"
                onClick={this._onConfirmClick}
              >
                {lang.cancelSubscriptionButton}
              </Button>
              <Button
                onClick={this._onCancelClick}
              >
                {lang.neverMindButton}
              </Button>
            </div>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
