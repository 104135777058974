import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../../css/review-panel.css';
import * as DatavaseApi from '../datavase-api';
import {Panel} from "react-bootstrap";
import BlockSpinner from "../partials/block-spinner";
import ShareButtons from "../partials/share-buttons";
import store from "store";

import media from "styled-media-query";
import styled from 'styled-components';
import color from '../styles/colors.js';

import Tag from "../atoms/tag.js"
import Thambnail from "../atoms/square-thambnail.js"
import InputTitle from "../atoms/input-title.js"
import InputText from "../atoms/input-text.js"

export default class QuestionBoardsPane extends Component {
  //コメントを回答にしてPOSTにする、初期取得は一覧をとって(それにトピックが入ってるから)、それからクリックされたら詳細を取る感じでいいかな
  //新規投稿…いいねは後日実装としても、うん
  constructor(props) {
    super(props);
    this.state = {
      topics: {},
      replies: {},
      current_like_status: [],
      likes: [],
      mePics: '',
      anonymous: false,
      open_question: false,
      loading: true,
      isLoggedIn: true,
      showNewQuestion: false,
      title: '',
      body: '',
      tag: 99,
      standing_tag: 99,
      restrict_tag_id: -1,
      isActive: false,
    };
    this._editTitle = this._editTitle.bind(this);
    this._editBody = this._editBody.bind(this);
    this._createThread = this._createThread.bind(this);
    this._openQuestion = this._openQuestion.bind(this);
  }

  componentDidMount() {
    DatavaseApi.fetchQuestionBoardsTimeline().then(res => {
      this.setState({
        topics: res.data,
        loading: false,
      });
    }).catch((res) => {
      console.error(res);
    });
    if (store.get('access-token') === undefined ||
      store.get('uid') === undefined ||
      store.get('client') === undefined) {
      let mePic = Math.floor(Math.random() * (20 - 1) + 1);
      this.setState({
        isLoggedIn: false,
        mePics: `/avatars/avatar_${mePic}.png`
      });
    } else {
      DatavaseApi.fetchContributor().then(res => {
        this.setState({
          mePics: res.data.thumb_profile_image_url
        });
      }).catch((res) => {
        console.error(res);
      });
    }
    DatavaseApi.getNotification();
  }

  _createThread(e) {
    e.preventDefault();
    DatavaseApi.createQuestionBoard({
      organization_id: null,
      title: this.state.title,
      body: this.state.body,
      tag_id: this.state.tag, //Tmp
      standing_tag_id: this.state.standing_tag,
      anonymous: this.state.anonymous,
    }).then(_res => window.location.reload());
  }

  _editTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  _editBody(e) {
    this.setState({
      body: e.target.value,
    });
  }

  _editAnswerBody(i, e) {
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    topics[i].answer.body = e.target.value;
    this.setState({ topics })
  }

  _editAnswerAnonymity(i) {
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    if (topics[i].answer.anonymous === undefined) {
      topics[i].answer.anonymous = true;
    } else {
      topics[i].answer.anonymous = !topics[i].answer.anonymous;
    }
    this.setState({ topics })
  }

  _setRestrictTagId(id) {
    this.setState({ restrict_tag_id: id })
  }


  _postVote(id, flag, orig_i) {
    if (!this.state.isLoggedIn) {
      DatavaseApi.fetchContributor().then(res => {
        //console.log(res);
      }).catch((res) => {
        console.error(res)
      })
    } else {
      DatavaseApi.postVote(id, { rating: flag }).then(res => {
        let likes = this.state.current_like_status;
        likes[id] = flag;
        let like_count = this.state.topics;
        if (flag === null) {
          like_count[orig_i].likes--;
        } else {
          like_count[orig_i].likes++;
        }
        this.setState({
          current_like_status: likes,
          topics: like_count,
        });
      }).catch((res) => {
        console.error(res);
      });
    }
  }


  _toggleAnswerPanel(i) {
    if (!this.state.isLoggedIn) {
      DatavaseApi.fetchContributor().then(res => {
        //console.log(res);
      }).catch((res) => {
        console.error(res)
      })
    } else {
      let entries = this.state.topics;
      if (this.state.topics[i].showAnswerPanel === undefined) {
        entries[i].showAnswerPanel = false;
      }
      entries[i].showAnswerPanel = !this.state.topics[i].showAnswerPanel;
      this.setState({ topics: entries });
    }
  }


  _toggleSharePanel(i) {
    let entries = this.state.topics;
    if (this.state.topics[i].showSharePanel === undefined) {
      entries[i].showSharePanel = false;
    }
    entries[i].showSharePanel = !this.state.topics[i].showSharePanel;
    this.setState({ topics: entries });
  }


  _renderSharePanel(i) {
    if (this.state.topics[i].showSharePanel) {
      return (
        <div>
          <ShareButtons
            url={`https://datavase.io/qa_boards/${this.state.topics[i].id}`}
            title={`${this.state.topics[i].title}`}
          />
        </div>
      );
    }
  }

  // リプライ
  _renderAnswerPanel(orig_i, topic, replies, i) {
    if (this.state.topics[orig_i].showAnswerPanel) {
      return (
        <ReplyArea>
          {replies[i] !== undefined ? replies[i].map((reply, i) => {
            return (
              <ReplyWrapper
                key={`question-board-topic-${reply.id}`}
              >
                {/* これそもそもタイトルのインプットなくない？↓ */}
                <ReplyTitle>{reply.title}</ReplyTitle>
                <ReplyTop>
                  <Thambnail
                    thambImg={reply.thumb_profile_image_url || reply.profile_image_url}
                    alt="profileImg"
                    width="24px"
                    height="24px"
                  />
                  <ReplyWriter>
                    {reply.name || reply.screen_name}
                  </ReplyWriter>
                  <ReplyDate>
                    {DatavaseApi.prettifyDatetime(reply.created_at)}
                  </ReplyDate>
                </ReplyTop>
                <ReplyBody>{reply.body}</ReplyBody>
                <ReplyGood>
                  <span
                    className="click"
                    onClick={() => this._postVote(reply.id, reply.current_like_status === true ? null : true, reply.orig_i_number)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "14px",
                    }}
                  >
                    <span
                      className={reply.current_like_status === true ? "list-review-panel-like qbpane" : "list-review-panel-like-empty qbpane"}
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "block",
                        marginRight: "2px",
                        fontSize: "12px",
                      }}
                    >
                    </span>
                    <span
                      style={{
                        width: "14px",
                        height: "14px",
                        display: "block",
                        fontSize: "12px",
                      }}
                    >
                      {reply.likes}
                    </span>
                  </span>
                </ReplyGood>
              </ReplyWrapper>
            )
          })
          :
            <ReplyTitle>回答がありません。</ReplyTitle>
          }
          <ReplyFormWrapper>
            <ReplyTitle>回答・アドバイスする</ReplyTitle>
            <ReplyForm>
              <InputText placeholder="本文" onChange={(e) => this._editAnswerBody(topic.orig_i_number, e)} />
              <SubmitWrap>
                <CheckBoxWrap>
                  <input
                      onClick={() => this._editAnswerAnonymity(topic.orig_i_number)}
                      type="checkbox"
                      name="checkboxAnonymous"
                      value="チェックを入れると匿名になります"
                      id="checkboxAnonymous"
                      style={{display: "none"}}
                  />
                  <CheckBox for="checkboxAnonymous">チェックを入れると匿名になります</CheckBox>
                </CheckBoxWrap>
                <Submit onClick={(e) => this._postAnswer(topic.orig_i_number, e)}>回答する</Submit>
              </SubmitWrap>
            </ReplyForm>
          </ReplyFormWrapper>
        </ReplyArea>
      );
    }
  }

  _isDeletable(uid, orig_i_number) {
    //Adminの場合のものもつけておこう。Adminはサーバサイドでの判断にしたほうがいい気はする
    return uid === 2 || this.state.topics[orig_i_number].user_id === uid;
  }
  _deleteTopic(orig_i_number) {
    DatavaseApi.deleteTopic(this.state.topics[orig_i_number].id).then(res => {
      window.location.reload();
    }).catch((res) => {
      console.error(res);
    });
  }

  _postAnswer(i, e) {
    e.preventDefault();
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    if (topics[i].answer.anonymous === undefined) {
      topics[i].answer.anonymous = false;
    }
    if (topics[i].answer.title === undefined) {
      topics[i].answer.title = "";
    }
    if (topics[i].answer.body === undefined) {
      topics[i].answer.body = "";
    }
    topics[i].answer.organization_id = topics[i].organization_id;
    topics[i].answer.tag_id = topics[i].tag;
    topics[i].answer.standing_tag = topics[i].standing_tag;
    DatavaseApi.addAnswer(topics[i].id, topics[i].answer).then(_res => window.location.reload());
  }

  _clickNewQuestion() {
    if (!this.state.isLoggedIn) {
      DatavaseApi.fetchContributor().then(res => {
        //console.log(res);
      }).catch((res) => {
        console.error(res)
      })
    } else {
      this.setState({
        showNewQuestion: !this.state.showNewQuestion
      })
    }
  }

  _openQuestion() {
    this.setState({
      open_question: true
    });
  }
  render() {
    if (this.state.loading) {
      return (
        <div>
          <Panel style={{ marginLeft: '12.5%', width: '75%' }} className="panel-qa">
            <BlockSpinner visible />
          </Panel>
        </div>
      );
    }
    let replies = [];
    let topics = [];
    // eslint-disable-next-line
    this.state.topics.map((topic, i) => {
      if (topic.question_board_id !== null) {
        if (replies[65536 - topic.question_board_id] === undefined) {
          replies[65536 - topic.question_board_id] = []
        }
        if (replies[65536 - topic.question_board_id].answer_count === undefined) {
          replies[65536 - topic.question_board_id].answer_count = 1;
        } else {
          replies[65536 - topic.question_board_id].answer_count++;
        }
        replies[65536 - topic.question_board_id][65536 - topic.id] = topic;
        replies[65536 - topic.question_board_id][65536 - topic.id].current_like_status = this.state.current_like_status[topic.id] === undefined ? topic.current_like_status : this.state.current_like_status[topic.id];
        replies[65536 - topic.question_board_id][65536 - topic.id].orig_i_number = i;
      } else if (this.state.restrict_tag_id === -1 || this.state.restrict_tag_id === topic.tag) {
        if (topics[65536 - topic.id] === undefined) {
          topics[65536 - topic.id] = []
        }
        topics[65536 - topic.id] = topic;
        topics[65536 - topic.id].current_like_status = this.state.current_like_status[topic.id] === undefined ? topic.current_like_status : this.state.current_like_status[topic.id];
        topics[65536 - topic.id].orig_i_number = i;
      }
    });
    return (
      <div>
        <div>
          <TagWrapper>
            <Tag onClick={() => this._setRestrictTagId(-1)} active={this.state.restrict_tag_id === -1} text="全てを表示"/>
            <Tag onClick={() => this._setRestrictTagId(5)} active={this.state.restrict_tag_id === 5} text="企業調査"/>
            <Tag onClick={() => this._setRestrictTagId(6)} active={this.state.restrict_tag_id === 6} text="市場調査"/>
            <Tag onClick={() => this._setRestrictTagId(1)} active={this.state.restrict_tag_id === 1} text="ファイナンス"/>
            <Tag onClick={() => this._setRestrictTagId(7)} active={this.state.restrict_tag_id === 7} text="マーケティング"/>
            <Tag onClick={() => this._setRestrictTagId(8)} active={this.state.restrict_tag_id === 8} text="セールス"/>
            <Tag onClick={() => this._setRestrictTagId(3)} active={this.state.restrict_tag_id === 3} text="バックオフィス"/>
            <Tag onClick={() => this._setRestrictTagId(9)} active={this.state.restrict_tag_id === 9} text="HR"/>
            <Tag onClick={() => this._setRestrictTagId(10)} active={this.state.restrict_tag_id === 10} text="PR"/>
            <Tag onClick={() => this._setRestrictTagId(11)} active={this.state.restrict_tag_id === 11} text="Academy(学生)"/>
            <Tag onClick={() => this._setRestrictTagId(99)} active={this.state.restrict_tag_id === 99} text="その他"/>
          </TagWrapper>
          <Wrapper>
            {this.state.open_question ? (
                <FormPanel>
                  <ThambWrap>
                    <Thambnail thambImg={this.state.mePics} alt="YourPic"/>
                  </ThambWrap>
                  <Form>
                    {/* submitで入力内容が反映されない問題 */}
                    <InputTitle type="text" placeholder="タイトル" onChange={this._editTitle}/>
                    <InputText placeholder="PRで一番コスパの良いメディアは何ですか?" onChange={this._editBody}/>
                    {/* <FormControl componentClass="input" type="text" placeholder="タイトル" style={{ resize: 'vertical' }} onChange={this._editTitle} /> */}
                    {/* <FormControl componentClass="textarea" type="text" placeholder="本文" style={{ resize: 'vertical', marginBottom: '1em' }} onChange={this._editBody} /> */}
                    <TagArea>
                      <Top>
                        <Tag notBtn={true} name="anonymous" onClick={() => {
                          this.setState({anonymous: false})
                        }} active={!this.state.anonymous} text="実名" padding="4px 12px" margin="0 4px 0 0" radius="5px"/>
                        <Tag notBtn={true} onClick={() => {
                          this.setState({anonymous: true})
                        }} active={this.state.anonymous} text="匿名" padding="4px 12px" radius="5px"/>
                      </Top>
                      <Bottom>
                        <Tag onClick={() => this.setState({tag: 5})} active={this.state.tag === 5} text="企業調査"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 6})} active={this.state.tag === 6} text="市場調査"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 1})} active={this.state.tag === 1} text="ファイナンス"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 7})} active={this.state.tag === 7} text="マーケティング"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 8})} active={this.state.tag === 8} text="セールス"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 3})} active={this.state.tag === 3} text="バックオフィス"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 9})} active={this.state.tag === 9} text="HR"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 10})} active={this.state.tag === 10} text="PR"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 11})} active={this.state.tag === 11} text="Academy(学生)"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                        <Tag onClick={() => this.setState({tag: 99})} active={this.state.tag === 99} text="その他"
                             radius="5px" padding="4px 6px" margin="4px 4px 0 0"/>
                      </Bottom>
                    </TagArea>
                    <SubmitWrap>
                      <Submit onClick={this._createThread}>質問する</Submit>
                    </SubmitWrap>
                  </Form>
                </FormPanel>) : (
                <SubmitWrapToOpen><Submit onClick={this._openQuestion}>質問する</Submit></SubmitWrapToOpen>)}
          </Wrapper>

          {topics.map((topic, i) => {
            /* Todo: 無限スクロール(120件に絞る)、3件以上コメントは隠す? シェアページでページ番号を割り振るようにする*/
            //Extremely temporary table. Might migrate to the DB
            let tag = '';
            if (topic.tag === 1) {
              tag = "ファイナンス"
            } else if (topic.tag === 2) {
              tag = "事業選定" //Deprecated
            } else if (topic.tag === 3) {
              tag = "バックオフィス"
            } else if (topic.tag === 4) {
              tag = "人材"
            } else if (topic.tag === 5) {
              tag = "企業調査"
            } else if (topic.tag === 6) {
              tag = "市場調査"
            } else if (topic.tag === 7) {
              tag = "マーケティング"
            } else if (topic.tag === 8) {
              tag = "セールス"
            } else if (topic.tag === 9) {
              tag = "HR"
            } else if (topic.tag === 10) {
              tag = "PR"
            } else if (topic.tag === 11) {
              tag = "Academy(学生)"
            } else {
              tag = 'その他';
            }

            return (
              <Wrapper>
                <AnswerPanel key={`question-board-topic-${topic.id}`}>
                  <AnswerTitleWrap>
                    <AnswerTitle to={`/qa_boards/${topic.id}`} onlyActiveOnIndex>
                      {topic.title}
                    </AnswerTitle>
                  </AnswerTitleWrap>
                  <AnswerMetaWrap>
                    <Thambnail thambImg={topic.thumb_profile_image_url || topic.profile_image_url} alt="profileImg" width="32px" height="32px" />
                    <AnswerWriter>
                      {topic.name || topic.screen_name}
                    </AnswerWriter>
                    <AnswerDate>
                      {DatavaseApi.prettifyDatetime(topic.created_at)}
                    </AnswerDate>
                    {topic.org_name !== null ?
                      <TagLink to={`/${topic.resource_type_name}/${topic.slug || topic.organization_id}/`}>
                        {topic.org_name}
                      </TagLink>
                    : null
                    }
                    <Tag onClick={() => this._setRestrictTagId(topic.tag)} active={true} text={tag} padding="4px 6px" radius="5px" />
                  </AnswerMetaWrap>
                  <AnswerBody>
                    {topic.body}
                  </AnswerBody>
                  <AnswerAction>
                    {/* ハート */}
                    <span
                      className="click"
                      onClick={() => this._postVote(topic.id, topic.current_like_status === true ? null : true, topic.orig_i_number)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "18px",
                        marginRight: "10px",
                        lineHeight: "18px",
                        textAlign: "center",
                      }}
                    >
                      <span
                        className={topic.current_like_status === true ? "list-review-panel-like qbpane" : "list-review-panel-like-empty qbpane"}
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "block",
                          marginRight: "4px",
                          fontSize: "14px",
                        }}
                      >
                      </span>
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "block",
                          fontSize: "14px",
                        }}
                      >
                        {topic.likes}
                      </span>
                    </span>
                    {/* リプライ */}
                    <span
                      onClick={() => this._toggleAnswerPanel(topic.orig_i_number)}
                      className="click"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "18px",
                        marginRight: "10px",
                        fontSize: "14px",
                        lineHeight: "18px",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "block",
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                        className="material-icons qbpane"
                      >
                        question_answer
                      </span>
                      <span
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "block",
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                      >
                        {replies[i] === undefined ? 0 : replies[i].answer_count}
                      </span>
                    </span>

                    {/* シェア */}
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "18px",
                        marginRight: "10px",
                        fontSize: "14px",
                        lineHeight: "18px",
                        textAlign: "center",
                      }}
                      onClick={() => this._toggleSharePanel(topic.orig_i_number)}
                      className="click"
                    >
                      <span
                        className="list-review-panel-share"
                        style={{
                          width: "14px",
                          height: "14px",
                          display: "block",
                          fontSize: "14px",
                          marginRight: "4px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                      >
                      </span>
                      <span
                        style={{
                          height: "18px",
                          display: "block",
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                      >
                        シェアする
                      </span>
                    </span>
                    {/* 削除 */}
                    {this._isDeletable(DatavaseApi.getUserId(), topic.orig_i_number) ?
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "18px",
                          marginRight: "10px",
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "center",
                        }}
                        className="click material-icons" onClick={() => this._deleteTopic(topic.orig_i_number)}
                      >
                        delete
                      </span>
                    :
                      null
                    }
                  </AnswerAction>
                  {this._renderSharePanel(topic.orig_i_number)}
                  {this._renderAnswerPanel(topic.orig_i_number, topic, replies, i)}
                </AnswerPanel>
              </Wrapper>
            )
          })}
        </div>
      </div>
    );
  }
}



const Wrapper = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  max-width: 800px;
`;

const TagWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  margin: 0 auto 24px;
  ${media.lessThan("medium")`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const FormPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  padding: 16px;
`;

const Form = styled.form`
  width: calc(100% - 64px);
  margin: 0 0 0 16px;
  ${media.lessThan("medium")`
    width: 100%;
    margin: 0;
  `}
`;

const TagArea = styled.div`
  margin: 8px 0;
`;

const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 8px 0 4px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 8px;
`;

const SubmitWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0;
  padding: 8px 0 0;
`;
const SubmitWrapToOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 8px 0 0;
`;

const Submit = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background: ${color.accent};
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
`;

const AnswerPanel = styled.div`
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  padding: 16px 32px;
  ${media.lessThan("medium")`
    padding: 16px 8px;
  `}
`;

const AnswerTitleWrap = styled.p`
  margin: 0 0 8px;
`;

const AnswerTitle = styled(Link)`
  color: ${color.black87};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin: 0;
  ${media.lessThan("medium")`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const AnswerMetaWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 12px;
  ${media.lessThan("medium")`
    align-items: flex-start;
  `}
`;

const AnswerWriter = styled.p`
  margin: 0 12px;
  color: ${color.black87};
  font-size: 16px;
  font-weight: bold;
  ${media.lessThan("medium")`
    font-size: 12px;
    margin: 2px 8px 0;
  `}
`;

const AnswerDate = styled.p`
  margin: 0 12px 0 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
  ${media.lessThan("medium")`
    margin: 2px 12px 0 0;
  `}
`;

const TagLink = styled(Link)`
  margin: 0 4px 0 0;
  border-radius: 8px;
  padding: 4px 6px;
  color: ${color.white100};
  font-size: 14px;
  font-weight: 400;
  background: ${color.primary};
  cursor: pointer;
`;

const AnswerBody = styled.p`
  color: ${color.black87};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  margin: 0;
  ${media.lessThan("medium")`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.28px;
  `}
`;

const AnswerAction = styled.div`
  display: flex;
  margin: 16px 0 0;
`;

const ReplyFormWrapper = styled.div`
  margin: 16px 0 0;
`;

const ReplyArea = styled.div`
  margin: 16px 0 0;
`;

const ReplyWrapper = styled.div`
  margin: 16px 0 0;
  padding: 0 0 8px;
  border-bottom: 1px solid ${color.black12};
`;

const ReplyTop = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
  ${media.lessThan("medium")`
    align-items: flex-start;
  `}
`;

const ReplyTitle = styled.p`
  margin: 16px 0 8px;
  color: ${color.black87};
  font-size: 16px;
  font-weight: 400;
  ${media.lessThan("medium")`
    font-size: 14px;
  `}
`;

const ReplyWriter = styled.p`
  margin: 0 8px;
  color: ${color.black87};
  font-size: 12px;
  font-weight: 400;
  ${media.lessThan("medium")`
    font-size: 12px;
  `}
`;

const ReplyDate = styled.p`
  margin: 2px 0 0 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
`;

const ReplyBody = styled.p`
  margin: 0;
  padding: 0 2px;
  color: ${color.black87};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  ${media.lessThan("medium")`
    font-size: 12px;
    line-height: 16px;
  `}
`;

const ReplyGood = styled.div`
  margin: 4px 0 0;
`;

const ReplyForm = styled.form`
  width: 100%;
  /* margin: 0 0 0 16px; */
`;

const CheckBoxWrap = styled.div`
  margin: 0 auto 0 0;
`;

const CheckBox = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0;
  padding: 0 0 0 28px;
  cursor: pointer;
  color: ${color.black54};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  ${media.lessThan("medium")`
    font-size: 12px;
  `}
  &:hover {
    &:after {
      border-color: ${color.accent};
    }
  }
  &:after {
    -webkit-transition: border-color 0.2s linear;
    transition: border-color 0.2s linear;
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    margin-top: -10px;
    width: 18px;
    height: 18px;
    border: 2px solid ${color.black12};
    border-radius: 6px;
    content: "";
  }
  &:before {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 50%;
    left: 12px;
    display: block;
    margin-top: -9px;
    width: 6px;
    height: 12px;
    border-right: 2px solid ${color.accent};
    border-bottom: 2px solid ${color.accent};
    content: "";
    opacity: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    input[type="checkbox"]:checked + & {
      opacity: 1;
    }
  }
`;

const ThambWrap = styled.div`
  ${media.lessThan("medium")`
    display: none;
  `}
`;
