import React, { Component } from 'react';
import { Panel, Col, Row, Tabs, Tab } from 'react-bootstrap';

import { getLangCode, prettifyPrice, prettifyDate } from '../datavase-api';

import i18n from '../../i18n/ipo-panel.json';
import '../../css/ipo-panel.css';

export default class IpoPanel extends Component {
  render() {
    const langCode = getLangCode();
    const lang     = i18n[langCode];

    const ipo               = this.props.ipo;
    const googleFinanceUrl  = `https://finance.google.com/finance?q=${ipo.stock_symbol}`;
    const valuation         = prettifyPrice(ipo.valuation, ipo.currency_code);
    const initialSharePrice = prettifyPrice(ipo.initial_share_price, ipo.currency_code);
    const raisedAmount      = prettifyPrice(ipo.raised_amount, ipo.currency_code);
    const dateOfIpo         = prettifyDate(ipo.date_of_ipo, ipo.precision_of_date_of_ipo);

    return(
      <Panel>
        <Tabs id="ipo-tabs">
          <Tab eventKey={1} title={lang.title}>
            <Panel id="ipo-detail-panel">
              <Row className="ipo-panel-row">
                <Col lg={3}>
                  <span className="ipo-details-heading">{lang.stockSymbol}</span>
                </Col>
                <Col lg={9}>
                  <span className="ipo-details-value">
                    <a href={googleFinanceUrl} target="_blank" rel="noopener noreferrer">
                      {ipo.stock_symbol}
                    </a>
                  </span>
                </Col>
              </Row>
              <Row className="ipo-panel-row">
                <Col lg={3}>
                  <span className="ipo-details-heading">{lang.initialValuation}</span>
                </Col>
                <Col lg={9}>
                  <span className="ipo-details-value">{valuation}</span>
                </Col>
              </Row>
              <Row className="ipo-panel-row">
                <Col lg={3}>
                  <span className="ipo-details-heading">{lang.initialSharePrice}</span>
                </Col>
                <Col lg={9}>
                  <span className="ipo-details-value">{initialSharePrice}</span>
                </Col>
              </Row>
              <Row className="ipo-panel-row">
                <Col lg={3}>
                  <span className="ipo-details-heading">{lang.amountRaised}</span>
                </Col>
                <Col lg={9}>
                  <span className="ipo-details-value">{raisedAmount}</span>
                </Col>
              </Row>
              <Row className="ipo-panel-row">
                <Col lg={3}>
                  <span className="ipo-details-heading">{lang.date}</span>
                </Col>
                <Col lg={9}>
                  <span className="ipo-details-value">{dateOfIpo}</span>
                </Col>
              </Row>
            </Panel>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}
