import React, { Component } from 'react'

import * as DatavaseApi     from '../datavase-api';
import i18n                         from '../../i18n/review-star-panel.json'

import '../../css/review-star-panel.css';
import styled from 'styled-components';

export default class reviewStarPanel extends Component {
  constructor(props) {
    super(props);
    this._returnRevieweStars = this._returnRevieweStars.bind(this);
  }

  _returnRevieweStars(rating){
    const roundedRating = Math.floor(rating);
    const reviewStars = Array.apply(null, Array(roundedRating)).map((el, i) => {
        return(<Star key={i} className="material-icons">star</Star>);
      }
    );
    let remainder = Math.floor((rating-roundedRating)*12)+5;
    if(rating-roundedRating === 0) {remainder = 0;}
    reviewStars.push(
      <Star
        key={5}
        style={{
          background: `linear-gradient(90deg, orange, orange ${remainder}px, transparent ${remainder}px, transparent 100%)`,
          WebkitBackgroundClip: "text"
        }}
        className="material-icons partial"
      >
        star
      </Star>
  );
    return reviewStars;
  }

  _returnComment(rating){
    const langCode      = DatavaseApi.getLangCode();
    const lang          = i18n[langCode];
    const reviewComment = lang[rating];
    return reviewComment;
  }

  render() {
    const rating = this.props.rating;
    // const ceiledAverageReviews = Math.ceil(rating);
    const reviewStars = this._returnRevieweStars(rating);
    // const reviewComment = this._returnComment(ceiledAverageReviews);

    return (
      <div className='rate'>
        <StarWrapper className="rating-stars-border">
          <Star className="material-icons">star_border</Star>
          <Star className="material-icons">star_border</Star>
          <Star className="material-icons">star_border</Star>
          <Star className="material-icons">star_border</Star>
          <Star className="material-icons">star_border</Star>
        </StarWrapper>
        <StarWrapper className="rating-stars-fill">
          {reviewStars}
        </StarWrapper>
      </div>
    );
  }
}

const Star = styled.i`
  font-size: 14px;
`

const StarWrapper = styled.div`
  margin-top: 5px;
  max-width: 80%;
`