import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import ReviewStarPanel from './review-star-panel';
import BlockSpinner from './block-spinner';

import i18n from '../../i18n/review-lists-panel.json';
import * as DatavaseApi from '../datavase-api';

import '../../css/organization-review-lists-panel.css';
import placeholder from "../../assets/datavase_logo_icon_grey.png";

export default class PersonReviewListsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReviewModalOpen: false,
            loading: true,
            reviews: []
        };

        this._openReviewModal = this._openReviewModal.bind(this);
        this._closeReviewModal = this._closeReviewModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            reviews: this.props.reviews,
            loading: false,
        });
    }

    _openReviewModal() {
        this.setState({isReviewModalOpen: true});
    }

    _closeReviewModal() {
        this.setState({isReviewModalOpen: false});
    }

    render() {
        if (this.state.loading) {
            return (
                <Panel>
                    <BlockSpinner visiable/>
                </Panel>
            );
        }

        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];
        const reviews = this.state.reviews.map((review, i) => <Review who={this.props.who} review={review} key={i}/>);
        return (
            <Panel>
                {
                    this.state.reviews.length === 0 ?
                        <span className="no-review">
              <b>{lang.noReview}</b>
          </span> : null
                }
                {reviews}
            </Panel>
        );
    }
}

class Review extends Component {
    render() {
        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];

        const rating = this.props.review.rating;
        const good_point = this.props.review.good_point;
        const bad_point = this.props.review.bad_point;
        const total_review = this.props.review.total_review;
        return (
            <Panel>
                <img src={this.props.review.reviewer_image == null ? placeholder : this.props.review.reviewer_image}
                     style={{width: '50px', height: '50px', margin: '0 10px 0 0'}} alt="reviewer_image"/><br/>
                Author: {this.props.review.screen_name}<br/>
                To: {DatavaseApi.extractAndFormatPersonName(this.props.who, langCode)}
                <ReviewStarPanel
                    rating={rating}
                />
                <div
                    style={(good_point === null || good_point === undefined) && (bad_point === null || bad_point === undefined) ? {display: "none"} : {}}>
                    <div className='tab' style={{"margin-top": "5px"}}>
                        <b>{lang.goodPoint}</b>
                    </div>
                    <div className='comment tab'>
                        {good_point}
                    </div>
                    <div className='tab' style={{"margin-top": "2px"}}>
                        <b>{lang.badPoint}</b>
                    </div>
                    <div className='comment'>
                        {bad_point}
                    </div>
                </div>
                <div style={(total_review === null || total_review === undefined) ? {display: "none"} : {}}>
                    <div className='tab' style={{"margin-top": "2px"}}>
                        <b>{lang.totalReview}</b>
                    </div>
                    <div className='comment'>
                        {total_review}
                    </div>
                </div>
                <a href="../">この人の評価をもっと見る</a>
            </Panel>
        );
    }
}
