import React, { Component } from 'react';

import UpgradePlanWindow from '../partials/upgrade-plan-window';
import store from "store";

export default class UpgradePage extends Component {
  render() {
    if(store.get("surpriseTheme")) {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme_surprise');
    } else if(store.get("newTheme")) {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme1');
    }
    return(
        <div style={{margin: '10px'}}>
          <h1 id="auth-page-heading" className="datavase"><a href="/" style={{color:'white',textDecoration:'none'}}>datavase.io</a></h1>
          <UpgradePlanWindow/>
        </div>
    );
  }
}
