import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import ResearchRequestPanel     from './research-request-panel';
import FundraisingsHistoryTable from './fundraisings-history-table';
import InvestmentsHistoryTable  from './investments-history-table';
import { getLangCode }          from '../datavase-api';
import i18n from '../../i18n/investor-history-details-panel.json';

export default class InvestorHistoryDetailsPanel extends Component {
  _investments() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const investor      = this.props.investor;
    const investments   = this.props.investments;

    if(investments.length !== 0) {
      return(
        <Panel id="company-history-details">
          <Tabs id="investments-history-tabs">
            <Tab eventKey={1} title={lang.investmentsTableTitle}>
              <Panel className="side-padded-panel">
                <InvestmentsHistoryTable investments={investments}/>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(investor.might_have_investments) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.investmentsTableTitle}
          resourceType={'organizations'}
          id={investor.id}
          datatable
        />
      );
    }else{
      return null;
    }
  }

  _fundraisings() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const investor      = this.props.investor;
    const fundraisings  = this.props.fundraisings;

    if(fundraisings.length !== 0) {
      return(
        <Panel id="company-history-details">
          <Tabs id="fundraisings-history-tabs">
            <Tab eventKey={1} title={lang.fundsRaisedTableTitle}>
              <Panel className="side-padded-panel">
                <FundraisingsHistoryTable fundraisings={fundraisings}/>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(investor.might_have_fundings) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.fundsRaisedTableTitle}
          resourceType={'organizations'}
          id={investor.id}
          datatable
        />
      );
    }else{
      return null;
    }
  }

  render() {
    return(
      <div>
        {this._investments()}
        {this._fundraisings()}
      </div>
    );
  }
}

