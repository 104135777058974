import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import NumberOfInvestmentsGraph   from './number-of-investments-graph';
import { getLangCode }            from '../datavase-api';
import i18n                       from '../../i18n/person-graphs-panel.json';

export default class PersonGraphsPanel extends Component {
  _investmentVolume() {
    const langCode    = getLangCode();
    const lang        = i18n[langCode];
    const investments = this.props.investments;

    if(investments.length !== 0) {
      return(
        <Panel id="investment-volume-graph-panel">
          <Tabs id="investment-volume-graph-tabs">
            <Tab eventKey={1} title={lang.investmentVolumeGraphTitle}>
              <Panel className="side-padded-panel">
                <NumberOfInvestmentsGraph
                  graphName="bb"
                  investments={investments}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else{
      return null;
    }
  }

  render() {
    return(
      <div>
        {this._investmentVolume()}
      </div>
    );
  }
}


