// 記事パネル
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import media from "styled-media-query";

import Stars from '../partials/review-star-panel.js';

import color from '../styles/colors.js';

export default class AccessRankingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rank: '',
      title: '',
      review: '',
      image: '',
      num: '',
      id: '',
      resource: '',
    }
  }
  render() {
    return (
        <Card to={`/${this.props.resource}/${this.props.id}/`}>
        <ThambnailWrap>
          <Rank id="rank">{this.props.rank}</Rank>
          <Thambnail>
            <ThambnailImg src={this.props.image} alt={this.props.title} />
          </Thambnail>
        </ThambnailWrap>
        <Contents>
          <Title>{this.props.title}</Title>
          <ReviewWrap>
            <Review>★{Math.round(this.props.review * 10) / 10}</Review>
            {/*<Number>({this.props.num}件)</Number>*/}
          </ReviewWrap>
          <StarWrap>
            <Stars rating={Math.floor(this.props.review)}/>
          </StarWrap>
        </Contents>
      </Card>
    );
  }
}

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 18%;
  height: 100%;
  border-radius: 8px;
  box-shadow: -1px 0px 4px 4px ${color.black04};
  margin: 0 1%;
  padding: 16px 8px;
  ${media.lessThan("medium")`
    flex-direction: row;
    width: 100%;
    padding: 8px 16px;
    margin: 0 0 12px;
  `}
`;

const ThambnailWrap = styled.div`
  position: relative;
  margin: 0 0 10px;
  ${media.lessThan("medium")`
    margin: 0 12px 0 0;
  `}
`;

const Rank = styled.p`
  z-index: 1;
  position: absolute;
  top: -8px;
  left: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${color.primary};
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
`;

const Thambnail = styled.p`
  width: 72px;
  height: 72px;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin: 0;
  overflow: hidden;
  ${media.lessThan("medium")`
    width: 56px;
    height: 56px;
  `}
`;

const ThambnailImg = styled.img`
  margin: 0;
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  ${media.lessThan("medium")`
    justify-content: flex-start;
    align-items: start;
  `}
`;

const Title = styled.h1`
  margin: 0 0 8px;
  color: ${color.black87};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  height: 40px;
  overflow: hidden;
  ${media.lessThan("medium")`
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 4px;
    text-align: left;
    height: 28px;
  `}
`;

const ReviewWrap = styled.div`
  margin: 0 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 14px;
  ${media.lessThan("medium")`
    margin: 0 0 2px;
    justify-content: flex-start;
  `}
`;

const Review = styled.p`
  margin: 0;
  color: ${color.black87};
  font-size: 12px;
  font-weight: 400;
  ${media.lessThan("medium")`
    font-size: 12px;
    text-align: left;
  `}
`;

/*const Number = styled.p`
  margin: 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
  ${media.lessThan("medium")`
    font-size: 12px;
    text-align: left;
  `}
`;*/

const StarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`;
