import React, { Component } from 'react'
import { MatchMediaHOC } from 'react-match-media';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import store from 'store';
import styled from 'styled-components'

import * as authActions from '../../actions/auth';
import * as DatavaseApi from '../datavase-api';
import datavase_logo from '../../assets/datavase_header_logo.png';
import placeholder from '../../assets/datavase_logo_icon_grey.png'; //検索結果のロゴのplaceholder
import i18n from '../../i18n/top-navbar.json';
import '../../css/top-navbar.css';

import color from "../styles/colors";


function getSuggestionValue(suggestion) {
  const langCode = DatavaseApi.getLangCode();
  const type = suggestion.resource_type_name;
  let name;
  if (['companies', 'investors', 'schools'].includes(type)) {
    name = DatavaseApi.extractOrganizationName(suggestion, langCode);
  } else if (type === 'people') {
    name = DatavaseApi.extractAndFormatPersonName(suggestion, langCode);
  } else if (type === 'events') {
    name = DatavaseApi.extractEventName(suggestion, langCode);
  }

  return name;
}

// 検索結果の表示
function renderSuggestion(suggestion) {
  const langCode = DatavaseApi.getLangCode();
  const type = suggestion.resource_type_name;
  let name;
  if (['companies', 'investors', 'schools'].includes(type)) {
    name = DatavaseApi.extractOrganizationName(suggestion, langCode);
  } else if (type === 'people') {
    name = DatavaseApi.extractAndFormatPersonName(suggestion, langCode);
  } else if (type === 'events') {
    name = DatavaseApi.extractEventName(suggestion, langCode);
  }

  return (
    <SearchResult>
      <SearchResultImg
        alt={name}
        src={suggestion.thumb_profile_image_url || placeholder}
      />
      <SearchResultText>
        {name}
      </SearchResultText>
    </SearchResult>
  );
}

// 検索結果のセクションのタイトル
function renderSectionTitle(section) {
  return (
    <SearchTitle>{section.title}</SearchTitle>
  );
}

function getSectionSuggestions(section) {
  return section.entities;
}


// メインのところヘッダー
class TopNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: '',
      suggestions: [],
      isLoading: false,
      prefixSearchTimeoutId: null,
    }
  }

  // ヘッダーの右のボタン達
  _getAuthButtons() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    if (store.get('access-token') === undefined ||
      store.get('uid') === undefined ||
      store.get('client') === undefined) {
      return (
        <BtnWrapper pullRight>
          {/* アカウント登録ボタン */}
          <HeaderBtn onClick={() => { window.location.href = '/signup' }}>{lang.signup}</HeaderBtn>
          {/* ログインボタンスタイリング変更あり */}
          <LoginBtn onClick={() => { window.location.href = '/login' }}>{lang.login}</LoginBtn>
        </BtnWrapper>
      );
    } else {
      return (
        <HeadNav pullRight>
          <NavDropdown eventKey={3} title="My Account" id="my-account-dropdown">
            <HeadMenuItem
              eventKey={3.1}
              onClick={() => { window.location.href = '/account_settings'; }}
            >{lang.accountSettings}</HeadMenuItem>
            <HeadMenuItem onClick={this.props.authActions.logout}>{lang.logout}</HeadMenuItem>
          </NavDropdown>
        </HeadNav>
        // <DropDownMenu pullRight>
        //   {/* マイアカウントボタン */}
        //   <NavWrapper eventKey={3} title="My Account" id="my-account-dropdown">
        //     {/* ドロワーメニュー */}
        //     <NavItemWrapper>
        //       <NavItem
        //         eventKey={3.1}
        //         onClick={() => { window.location.href = '/account_settings'; }}
        //       >
        //         {lang.accountSettings}
        //       </NavItem>
        //       {/* 棒線のスタイル？ */}
        //       <MenuItem divider/>
        //       <NavItem onClick={this.props.authActions.logout}>{lang.logout}</NavItem>
        //     </NavItemWrapper>
        //   </NavWrapper>
        // </DropDownMenu>
      );
    }
  }

  _loadSuggestions(val) {
    this.setState({ isLoading: true });
    DatavaseApi.crossModelSearch(val, 5)
      .then(res => {
        let entities = [];
        for (let key in res.data) {
          if (res.data[key].length !== 0) {
            entities.push({
              title: key.charAt(0).toUpperCase() + key.slice(1),
              entities: res.data[key],
            });
          }
        }
        this.setState({
          isLoading: true,
          suggestions: entities,
        });
      })
      .catch(res => {
        this.setState({ isLoading: false });
        console.error(res);
      });
  }

  _onSuggestionsFetchRequested({ value }) {
    if (this.state.prefixSearchTimeoutId !== null) {
      clearTimeout(this.state.prefixSearchTimeoutId);
    }
    const timeoutId = setTimeout(() => {
      this._loadSuggestions(value);
    }, 300);
    this.setState({ prefixSearchTimeoutId: timeoutId });
  }

  _onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  _onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    const resourseType = suggestion.resource_type_name;
    const id = suggestion.slug || suggestion.id;
    window.location.href = `/${resourseType}/${id}/`;
  }

  _onChangeForm(e, { newValue }) {
    this.setState({ formValue: newValue });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    let inputProps = {
      placeholder: lang.formPlaceholder,
      value: this.state.formValue,
      onChange: this._onChangeForm.bind(this),
    };


    // 最終的なみため↓
    return (
      <Header id="top-navbar">
        <HeaderInner>
          <HeaderLogo href="/">
            <HeaderLogoImg src={datavase_logo} alt="datavase.io" />
          </HeaderLogo>
          <HeaderSearchForm id="searchform">
            <SearchResultAutosuggest
              multiSection={true}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this._onSuggestionsFetchRequested.bind(this)}
              onSuggestionsClearRequested={this._onSuggestionsClearRequested.bind(this)}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={this._onSuggestionSelected.bind(this)}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={inputProps}
              theme={autosuggestTheme}
            />
          </HeaderSearchForm>
          <HeaderNav>
            {this._getAuthButtons()}
          </HeaderNav>
        </HeaderInner>
        {/* <Navbar collapseOnSelect>
        // ロゴ
          <Navbar.Header>
            <Navbar.Brand>
              <NavItem href="/">
                <img src={datavase_logo} alt="datavase.io"/>datavase.io
              </NavItem>
            </Navbar.Brand>
          </Navbar.Header>
          // フォーム
          <Navbar.Form>
            <Autosuggest
              multiSection={true}
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this._onSuggestionsFetchRequested.bind(this)}
              onSuggestionsClearRequested={this._onSuggestionsClearRequested.bind(this)}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={this._onSuggestionSelected.bind(this)}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={inputProps}
              theme={autosuggestTheme}
            />
            // ログインボタンとか
            {this._getAuthButtons()}
          </Navbar.Form>
        </Navbar> */}
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default MatchMediaHOC(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavbar), '(min-width: 768px)');

// styling

const Header = styled.header`
	position: fixed;
  z-index: 3;
  width: 100%;
  height: 72px;
  padding: 0 16px;
  color: white;
  background-color: ${color.primary};
`

const HeaderInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const HeaderLogo = styled.a`
  display: block;
`

const HeaderLogoImg = styled.img`
  height: 32px;
`

const HeaderSearchForm = styled.div`
  width: 600px;
  height: 40px;
  background: ${color.placeholder};
  border-radius: 8px;
`

const HeaderNav = styled.div`
  display: dlex;
  justify-content: flex-end;
`

// ヘッダーのインプット
const autosuggestTheme = {
  input: {
    width: '100%',
    height: '40px',
    padding: '0 16px',
    color: `${color.black87}`,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '40px',
  }
};

const SearchResultAutosuggest = styled(Autosuggest)`
  background: #fff;
`

const SearchResult = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 4px;
  cursor: pointer;
`

const SearchTitle = styled.p`
  background: #FFF;
  width: 100%;
  padding: 4px;
  color: ${color.black87};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

const SearchResultImg = styled.img`
  width: 32px;
  margin-right: 8px;
  border: solid 1px #ddd;
`

const SearchResultText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${color.black87};
  line-height: 40px;
`

const BtnWrapper = styled.div`
  display: flex;
`

const LoginBtn = styled.a`
  display: block;
  padding: 0 32px;
  margin-left: 32px;
  border-radius: 8px;
  background: ${color.accent};
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  &:hover {
    color: ${color.white70};
  }
`

const HeaderBtn = styled.a`
  display: block;
  margin-left: 16px;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  &:hover {
    color: ${color.white70};
  }
`

const HeadNav = styled(Nav)`
  display: block;
  color: ${color.white100};
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  &:hover {
    color: ${color.white70};
  }
`

const HeadMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${color.black87};
  font-size: 14px;
  font-weight: 400;
  &:hover {
    background: ${color.black04};
  }
`
