import React, { Component } from 'react'
import { Button, Panel, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import axios from 'axios';
import store from 'store';

import BlockSpinner     from './block-spinner';
import * as ENV from '../../constants/environment';
import { getLangCode } from '../datavase-api';
import i18n from '../../i18n/password-reset-window.json';
import '../../css/auth-pane.css';

export default class PasswordResetWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      password: '',
      passwordConfirmation: '',
      messages: [],
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _languageChangeLink() {
    const langCode = getLangCode();
    if(langCode === 'ja'){
      return(<Link onClick={this._onClickEnglish}>English</Link>);
    }else{
      return(<Link onClick={this._onClickJapanese}>日本語</Link>);
    }
  }

  _onSubmit() {
    this.setState({isProcessing: true});

    let params = {
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
    };

    let headers = {
      'uid': this.props.query.uid,
      'client': this.props.query.client_id,
      'access-token': this.props.query.token,
    }

    axios.put(`${ENV.API_ENDPOINT}/auth/password`, params, {headers})
    .then(res => {
      console.log('Password has been changed! Redirecting...');
      store.set('access-token', '');
      store.set('client', '');
      store.set('uid', '');
      store.set('trial', null);
      window.location.href = '/login'
    })
    .catch(res => {
      console.error(res);
    })
  }

  _onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _constructErrorsList() {
    const errorRows = this.state.messages.map((message, i) => {
      return(<li key={i}>{message}</li>);
    });

    return( <ul>{errorRows}</ul> );
  }

  render() {
    const langCode = getLangCode();
    const lang = i18n[langCode];

    return (
      <Panel className="auth-panel" header={lang.passwordResetWindowTitle}>
        {this._constructErrorsList()}
        <div className="auth-panel-content">
          <div className="social-auth">
            <h5>{lang.passwordResetMessage}</h5>
          </div>
          <div className="auth-form">
            <FormGroup>
              <ControlLabel>{lang.passwordLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="password"
                placeholder={lang.passwordConfirmationLabel}
                name="password"
                onChange={this._onChange}
              />
              <ControlLabel>{lang.passwordConfirmationLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="password"
                placeholder={lang.passwordConfirmationPlaceholder}
                name="passwordConfirmation"
                onChange={this._onChange}
              />
            </FormGroup>
            {
              this.state.isProcessing ?
              <BlockSpinner visible={true}/>
              :
              <Button bsStyle="primary" className="auth-confirm-button" onClick={this._onSubmit}>
                {lang.passwordResetButton}
              </Button>
            }
            <Link className="auth-link" to="/signup">{lang.signupLinkMessage}</Link>
            {this._languageChangeLink()}
          </div>
        </div>
      </Panel>
    )
  }
}

