import React, { Component } from 'react'
import styled from 'styled-components';
import * as DatavaseApi     from '../datavase-api';
import i18n                         from '../../i18n/review-star-panel.json'

import color from "../styles/colors.js";
import '../../css/review-star-panel.css';

export default class reviewStarPanelOnListPanel extends Component {
  constructor(props) {
    super(props);
    this._returnRevieweStars = this._returnRevieweStars.bind(this);
  }

  _returnRevieweStars(rating){
    const roundedRating = Math.floor(rating);
    const reviewStars = Array.apply(null, Array(roundedRating)).map((el, i) => {
        return(<Star key={i} className="material-icons">star</Star>);
      }
    );
    let remainder = Math.floor((rating-roundedRating)*12)+5;
    if(rating-roundedRating === 0) {remainder = 0;}
    reviewStars.push(
      <Star
        key={5}
        style={{
          background: `linear-gradient(90deg, orange, orange ${remainder}px, transparent ${remainder}px, transparent 100%)`,
          WebkitBackgroundClip: "text"
        }}
        className="material-icons partial"
      >
        star
      </Star>
  );
    return reviewStars;
  }

  _returnComment(rating){
    const langCode      = DatavaseApi.getLangCode();
    const lang          = i18n[langCode];
    const reviewComment = lang[rating];
    return reviewComment;
  }

  render() {
    const rating = this.props.rating;
    const reviewStars = this._returnRevieweStars(rating);

    return (
      <Wrapper>
        <Label>{rating.toPrecision(2)}</Label>
        <div>
          <Stars className="rating-stars-border">
            <Star className="material-icons">star_border</Star>
            <Star className="material-icons">star_border</Star>
            <Star className="material-icons">star_border</Star>
            <Star className="material-icons">star_border</Star>
            <Star className="material-icons">star_border</Star>
          </Stars>
          <Stars className="rating-stars-fill">
            {reviewStars}
          </Stars>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Stars = styled.div`
  margin-top: -6px;
`

const Star = styled.i`
  font-size: 12px;
`

const Label = styled.p`
  color: ${color.black87};
  font-size: 14px;
  font-weight: 400;
  margin: 0 8px 0 0;
`