import React, {Component} from 'react';
import * as DatavaseApi from '../datavase-api';
import BlockSpinner from "../partials/block-spinner";
import Tag from "../atoms/tag";
import styled from "styled-components";
import media from "styled-media-query";
import {Button} from "react-bootstrap";

//Simple flow writing method rev.01062020-01 by Takuro Fukuda <fukudato - mail uc edu>
class RecruitsPane extends Component {
    constructor(props) {
        //Basic statement section
        super(props);
        //State variable section
        this.state = {
            recruits: [],
            condition: {
                working: null,
                stage: null,
                role: null,
            },
            formValue: '',
            suggestions: [],
            isLoading: false,
            nextPageLoaded: false //LoadedPageは今のところなしでOK(全件読込にする)
        };
        //Bind variable section
        this._trackScrolling = this._trackScrolling.bind(this);
    }

    componentDidMount() {
        this._loadNextPage();
    }

    static enc_ml(x,y){
        var t=x+y;
        var s="",moji="";
        for(var i=0;i<t.length;i++){
            moji=t.charCodeAt(i);
            s +=String.fromCharCode(moji+1);
        }
        return s;
    }

    render() {
        //User-defined variable section for render purpose
        let m1='mai';
        let m2='lto:';
        let em_shtml=m1+m2+RecruitsPane.enc_ml(String.fromCharCode(115,100,96,108,63,103,96,98),String.fromCharCode(106,105,111,109,45,98,110,108));
        return (
            <div id="recruits-pane">
                <TagWrapper4>
                    <Button bsStyle="primary" style={{borderRadius: "10px", height: "3em", fontSize: '10px', cursor: "auto"}} notBtn>採用形式</Button>
                    <Tag onClick={() => this._setRestrictTagId("working", "Intern")}
                         active={this.state.condition.working === "Intern"} text="インターン"/>
                    <Tag onClick={() => this._setRestrictTagId("working", "Parttime")}
                         active={this.state.condition.working === "Parttime"} text="パートタイム"/>
                    <Tag onClick={() => this._setRestrictTagId("working", "Fulltime")}
                         active={this.state.condition.working === "Fulltime"} text="フルタイム"/>
                </TagWrapper4>
                <TagWrapper4>
                    <Button bsStyle="primary" style={{borderRadius: "10px", height: "3em", fontSize: '10px', cursor: "auto"}} notBtn>役職</Button>
                    <Tag onClick={() => this._setRestrictTagId("role", "CXO")}
                         active={this.state.condition.role === "CXO"} text="CXO"/>
                    <Tag onClick={() => this._setRestrictTagId("role", "Engineer")}
                         active={this.state.condition.role === "Engineer"} text="エンジニア"/>
                    <Tag onClick={() => this._setRestrictTagId("role", "Marketing")}
                         active={this.state.condition.role === "Marketing"} text="マーケティング"/>
                </TagWrapper4>
                <TagWrapper>
                    <Button bsStyle="primary" style={{borderRadius: "10px", height: "3em", fontSize: '10px', cursor: "auto"}} notBtn>ステージ</Button>
                    <Tag onClick={() => this._setRestrictTagId("stage", "Seed")}
                         active={this.state.condition.stage === "Seed"} text="シード"/>
                    <Tag onClick={() => this._setRestrictTagId("stage", "Middle")}
                         active={this.state.condition.stage === "Middle"} text="ミドル"/>
                    <Tag onClick={() => this._setRestrictTagId("stage", "Later")}
                         active={this.state.condition.stage === "Later"} text="レイター"/>
                    <Tag onClick={() => this._setRestrictTagId("stage", "IPO")}
                         active={this.state.condition.stage === "Unicorn"} text="IPO"/>
                </TagWrapper>

                <div style={{width: "calc(100% + 28px)",display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginLeft: "-14px"}}>
                    {this.state.nextPageLoaded ? this.state.recruits.map((recruit, i) => {
                        if ((this.state.condition.stage === null || this.state.condition.stage === recruit.stage) &&
                            (this.state.condition.role === null || this.state.condition.role === recruit.role) &&
                            (this.state.condition.working === null || this.state.condition.working === recruit.working)) {
                            return (
                                <Recruit>
                                    <RecruitLeft>
                                        <p><img src={recruit.image_uri || recruit.profile_image_url} alt="Organization."
                                                style={{width: "100%", height: "27.97vh", objectFit: "contain",margin: "auto", borderRadius: "10px", display: "block"}}/></p>
                                    </RecruitLeft>
                                    <RecruitRight>
                                    <p style={{fontSize:"1.84em"}}>{recruit.title}</p>
                                    <p>法人名: {recruit.organization_name}(ステージ: {recruit.stage})</p>
                                    <p>エリア/ポジション: {recruit.region}</p>
                                    <p>ポジション: {recruit.role}({recruit.working})</p>
                                    <p>必要/推奨スキル: {recruit.requirements}</p>
                                    <p>給与: {Number(recruit.min_salary).toLocaleString()}~{Number(recruit.salary).toLocaleString()}円</p>
                                    <p>ストックオプション: {recruit.min_stock}~{recruit.stock}%</p>
                                    <p>採用担当: {recruit.name}</p>
                                    <Button bsStyle="primary" onClick={() => {
                                        this._recruitApply(`${window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2]}/${recruit.resource_type_name}/${recruit.slug || recruit.organization_id}/recruits/`)
                                    }} style={{float: "right"}}>Apply Now</Button>
                                    </RecruitRight>
                                    <br style={{clear: "both"}}/>
                                </Recruit>
                            )
                        } else {
                            return "";
                        }
                    }) : <BlockSpinner visible={true}/>}
                </div>
                <p><a href={em_shtml}>求人掲載についてはこちらをクリックしてメールをお願いいたします。</a></p>
            </div>
        )
    }

    //User-defined function section
    _trackScrolling() {
        const wrappedElement = document.getElementById('root');
        if (this._isBottom(wrappedElement)) {
            document.removeEventListener('scroll', this._trackScrolling);
            this._loadNextPage();
        }
    }

    _loadNextPage() {
        this.setState({
            nextPageLoaded: false
        });
        DatavaseApi.fetchRecruits().then((res) => {
            this.setState({
                recruits: this.state.recruits.concat(res.data)
            })
        }).finally(() => {
            this.setState({
                nextPageLoaded: true
            })
        })
    }

    _recruitApply(pointerUri) {
        window.location.href = pointerUri;
    }

    _setRestrictTagId(genre, code) {
        let rewriteCond = this.state.condition;
        if (rewriteCond[genre] === code) {
            rewriteCond[genre] = null;
        } else {
            rewriteCond[genre] = code;
        }
        this.setState(rewriteCond);
    }
}

const TagWrapper4 = styled.div`
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  margin: 0 auto 24px;
  ${media.lessThan("medium")`
    grid-template-columns: repeat(4, 1fr);
  `}
`;
const TagWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  margin: 0 auto 24px;
  ${media.lessThan("medium")`
    grid-template-columns: repeat(5, 1fr);
  `}
`;
const Recruit = styled.div`
background-color: white;
padding: 15px;
width: calc(100% - 30px);
margin-bottom: 20px;
border-radius: 10px;
${media.lessThan("medium")`
    width: calc(100% - 30px);
  `}
`;
const RecruitLeft = styled.div`
float: left;
min-width: 50%;
max-width: 50%;
${media.lessThan("medium")`
    min-width: 100%;
    max-width: 100%;
`}
`;
const RecruitRight = styled.div`
float: right;
min-width: 50%;
max-width: 50%;
${media.lessThan("medium")`
    min-width: 100%;
    max-width: 100%;
`}
`;
export default RecruitsPane;
