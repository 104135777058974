import React, {Component} from 'react';
import {Button, Label, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import BlockSpinner from './block-spinner';
import InvestmentSnapshotView from './investment-snapshot-view';
import ContributorDetailsView from './contributor-details-view';
import * as DatavaseApi from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n from '../../i18n/contribution-details-modal.json';

class ContributionDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snapshot:          null,
      previousSnapshot:  null,
      requestingRemoval: false,
      deletingInformation: false,
      showConfirmDeletionModal: false,
      contribution:      {},
      hasLoaded:         false,
    };

    this._showConfirmDeletionModal    = this._showConfirmDeletionModal.bind(this);
    this._hideConfirmDeletionModal    = this._hideConfirmDeletionModal.bind(this);
    this._onCancelClick               = this._onCancelClick.bind(this);
    this._onRequestRemovalClick       = this._onRequestRemovalClick.bind(this);
    this._onCancelRequestRemovalClick = this._onCancelRequestRemovalClick.bind(this);
    this._onDeleteInformationClick    = this._onDeleteInformationClick.bind(this);
    this._deleteCreatedInformation    = this._deleteCreatedInformation.bind(this);
  }

    UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.contribution && newProps.contribution.id !== this.state.contribution.id) {
      this.setState({
        contribution: newProps.contribution,
      });
      this._fetchContributionDetails(newProps.contribution.id);
    }
  }

  _showConfirmDeletionModal() {
    this.setState({showConfirmDeletionModal: true});
  }

  _hideConfirmDeletionModal() {
    this.setState({showConfirmDeletionModal: false});
  }

  _fetchContributionDetails(id) {
    this.setState({hasLoaded: false});
    DatavaseApi.fetchDetailedContributionSnapshot(id)
    .then(res => {
      const snapshots = res.data;
      this.setState({
        hasLoaded:        true,
        snapshot:         snapshots.snapshot,
        previousSnapshot: snapshots.previous_snapshot,
      });
    })
    .catch(err => {
      console.error(err);
    });
  }

  _onCancelClick() {
    if(this.props.onCancel) { this.props.onCancel(); }
  }

  _onRequestRemovalClick() {
    this._requestInformationRemoval();
  }

  _onCancelRequestRemovalClick() {
    this._cancelRequestInformationRemoval(this.state.contribution.id);
  }

  _onDeleteInformationClick() {
    this._showConfirmDeletionModal();
  }

  _requestInformationRemoval() {
    const id = this.state.contribution.id;
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    this.setState({requestingRemoval: true});
    DatavaseApi.requestInformationRemoval(id)
    .then(res => {
      this.setState({requestingRemoval: false});
      this.props.sharedResourcesActions.setInformationRemovalRequests(res.data);
    })
    .catch(res => {
      console.error(res);
    });
  }

  _cancelRequestInformationRemoval() {
    const id = this.state.contribution.id;
    this.setState({requestingRemoval: true});
    DatavaseApi.cancelRequestInformationRemoval(id)
    .then(res => {
      this.setState({requestingRemoval: false});
      this.props.sharedResourcesActions.setInformationRemovalRequests(res.data);
    })
    .catch(res => {
      console.error(res);
    });
  }

  _deleteCreatedInformation() {
    const id = this.state.contribution.id;
    this.setState({deletingInformation: true});
    DatavaseApi.deleteCreatedInformation(id)
    .then(res => {
      window.location.reload();
    })
    .catch(res => {
      console.error(res);
    });
  }

  _requestInformationRemovalButton(isRemovalRequestSent) {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    if(this.state.requestingRemoval) {
      return(<BlockSpinner visible/>);
    }

    return(
      isRemovalRequestSent ?
      <div style={{textAlign: 'center'}}>
        <div style={{marginBottom: '4px'}}>
          <Label bsStyle="danger">
            {lang.informationRemovalRequestedLabel}
          </Label>
        </div>
        <Button
          onClick={this._onCancelRequestRemovalClick}
          bsStyle="danger"
        >
          {lang.cancelRequestInformationRemovalButton}
        </Button>
      </div>
      :
      <Button
        onClick={this._onRequestRemovalClick}
        bsStyle="danger"
        block
      >
        {lang.requestInformationRemovalButton}
      </Button>
    );
  }

  _snapshotView(snapshot, contribution) {
    if(!snapshot) return;

    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    let snapshotView;
    switch(contribution.information_type) {
      case "Investment": {
        snapshotView = (
          <InvestmentSnapshotView
            hideEditButton={this.props.hideEditButton}
            snapshot={snapshot}
            contribution={contribution}
          />
        );
        break;
      }
      default: {}
    }

    const removalRequests = this.props.sharedResources.informationRemovalRequests || [];
    const isRemovalRequestSent = removalRequests.findIndex(
      req => req.information_type === contribution.information_type &&
             req.information_id   === contribution.information_id
    ) !== -1;
    const requestInformationRemovalButton = this._requestInformationRemovalButton(
      isRemovalRequestSent
    );
    const isCurrentUserOwnerOfInformation = (this.props.information || {}).owner_id === DatavaseApi.getUserId();

    return(
      <div>
        <span>{lang[contribution.contribution_type]}</span>
        {snapshotView}
        {
          this.props.hideDeleteButton ?
          null
          :
            isCurrentUserOwnerOfInformation ?
            <Button
              onClick={this._onDeleteInformationClick}
              bsStyle="danger"
              block
            >
              {lang.deleteInformationButton}
            </Button>
            :
            requestInformationRemovalButton
        }
      </div>
    );
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const { snapshot, previousSnapshot, contribution } = this.state;
    const subjectResourceDomainName = this.props.subjectResourceDomainName;
    const contributionType = contribution.contribution_type;

    let snapshotViews;
    if(contributionType === 'create') {
      snapshotViews = (
        <div>
          {this._snapshotView(snapshot, contribution)}
        </div>
      );
    }else if(contributionType === 'edit') {
      snapshotViews = (
        <div>
          {this._snapshotView(snapshot, contribution)}
        </div>
      );
    }else if(contributionType === 'delete') {
      snapshotViews = (
        <div>
          {this._snapshotView(previousSnapshot, contribution)}
        </div>
      );
    }

    return(
      <Modal keyboard show={this.props.show} onHide={this._onCancelClick}>
        <Modal.Header>
          {lang.contributionDetailsModalTitle}
        </Modal.Header>
        <Modal.Body>
          {
            this.state.hasLoaded ?
            <div>
              <ConfirmDeletionModal
                show={this.state.showConfirmDeletionModal}
                onCancel={this._hideConfirmDeletionModal}
                onConfirm={this._deleteCreatedInformation}
                isProcessing={this.state.deletingInformation}
              />
              <ContributorDetailsView
                contributor={contribution.contributor}
                subjectResourceDomainName={subjectResourceDomainName}
              />
              {snapshotViews}
            </div>
            :
            <BlockSpinner visible/>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onCancelClick}>
            {lang.dismissButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributionDetailsModal);

class ConfirmDeletionModal extends Component {
  constructor(props) {
    super(props);

    this._onCancelClick  = this._onCancelClick.bind(this);
    this._onConfirmClick = this._onConfirmClick.bind(this);
  }

  _onCancelClick() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  _onConfirmClick() {
    if(this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show} keyboard onHide={this._onCancelClick}>
        <Modal.Header>
          {lang.confirmDeletionModalTitle}
        </Modal.Header>
        <Modal.Body>
          {lang.confirmDeletionModalBody}
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.isProcessing ?
            <BlockSpinner visible/>
            :
            <div>
              <Button
                bsStyle="danger"
                onClick={this._onConfirmClick}
              >
                {lang.confirmButton}
              </Button>
              <Button
                onClick={this._onCancelClick}
              >
                {lang.cancelButton}
              </Button>
            </div>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
