import React, { Component } from 'react';
import { Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/date-form.json';

function getDefaultValues(dateStr, precision='date') {
  if(!dateStr) return {};

  const defaultValues = dateStr.split('-');
  let year, month, date;
  year = parseInt(defaultValues[0], 10);
  if(['date', 'month'].includes(precision)) {
    month = parseInt(defaultValues[1], 10);
  }
  if(precision === 'date') {
    date = parseInt(defaultValues[2], 10);
  }

  return {
    year: year,
    month: month,
    date: date
  };
}

export default class DateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValidationState: null,
      year: undefined,
      month: undefined,
      date: undefined
    };

    this._onChange = this._onChange.bind(this);
    if(props.defaultDate) {
      const defaultValues = getDefaultValues(
        props.defaultDate, props.defaultPrecision
      );
      this.state = Object.assign({}, this.state, {
        year: defaultValues.year,
        month: defaultValues.month,
        date: defaultValues.date
      });
    }
  }

  _onChange(e) {
    let values = Object.assign({}, this.state, {
      [e.target.name]: e.target.value
    });
    let { year, month, date } = values;
    year  = parseInt(year, 10);
    month = parseInt(month, 10);
    date  = parseInt(date, 10);
    this._validateDate(year, month, date);
    this.setState({[e.target.name]: e.target.value});
  }

  _validateDate(year, month, date) {
    if(!year && !month && !date){
      this.setState({dateValidationState: null});
      this.props.onChange(null);
      return;
    };

    if( (!year && (month || date)) || (!month && date) ) {
      if(this.props.onChange) { this.props.onChange(null); }
      this.setState({dateValidationState: "error"});
      return;
    }

    let nd = new Date(year, (month || 1) - 1, (date || 1));
    if(nd.getMonth()+1 === (month || 1)) {
      if(this.props.onChange) {
        let dateStr = `${nd.getFullYear()}`;
        if(month) { dateStr += `-${nd.getMonth()+1}`; }
        if(date)  { dateStr += `-${nd.getDate()}`;    }
        this.props.onChange(dateStr);
      }
      this.setState({dateValidationState: "success"});
      return;
    }else{
      if(this.props.onChange) { this.props.onChange(null); }
      this.setState({dateValidationState: "error"});
      return;
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const defaultValues = getDefaultValues(this.props.defaultDate, this.props.defaultPrecision);
    const dateOptions = new Array(31);
    for(let i = 1; i <= 31; i++) {
      dateOptions[i] = (<option key={i} value={i}>{i}</option>);
    }
    const dateValidationState = this.state.dateValidationState;

    return(
      <div>
        <ControlLabel>{this.props.label}</ControlLabel>
        <Form inline>
          <FormGroup validationState={dateValidationState}>
            <FormControl
              type="text"
              placeholder={lang.yearPlaceholder}
              name="year"
              defaultValue={defaultValues.year}
              onChange={this._onChange}
            />
          </FormGroup>
          <FormGroup validationState={dateValidationState}>
            <FormControl
              componentClass="select"
              name="month"
              defaultValue={defaultValues.month}
              onChange={this._onChange}
            >
              <option value="">{lang.monthPlaceholder}</option>
              <option value={1}>{lang.january}</option>
              <option value={2}>{lang.february}</option>
              <option value={3}>{lang.march}</option>
              <option value={4}>{lang.april}</option>
              <option value={5}>{lang.may}</option>
              <option value={6}>{lang.june}</option>
              <option value={7}>{lang.july}</option>
              <option value={8}>{lang.august}</option>
              <option value={9}>{lang.september}</option>
              <option value={10}>{lang.october}</option>
              <option value={11}>{lang.november}</option>
              <option value={12}>{lang.december}</option>
            </FormControl>
          </FormGroup>
          <FormGroup validationState={dateValidationState}>
            <FormControl
              componentClass="select"
              name="date"
              defaultValue={defaultValues.date}
              onChange={this._onChange}
            >
              <option value="">{lang.datePlaceholder}</option>
              {dateOptions}
            </FormControl>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
