// @flow
import React from "react";
import styled from "styled-components";
import color from "../styles/colors";

export default function MiniDescription({
  text,
}) {
  return (
    <Wrapper>
      <Text>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

const Text = styled.p`
  margin: 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;
