import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';

import EditInvestmentModal   from './edit-investment-modal';
import UpgradeToProceedModal from './upgrade-to-proceed-modal';
import * as DatavaseApi      from '../datavase-api';
import i18n                  from '../../i18n/investment-snapshot-view.json';
import placeholder           from '../../assets/datavase_logo_icon_grey.png';

export default class InvestmentSnapshotView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditInvestmentModal: false,
      showUpgradeToEditModal: false,
    };

    this._showEditInvestmentModal = this._showEditInvestmentModal.bind(this);
    this._hideEditInvestmentModal = this._hideEditInvestmentModal.bind(this);
    this._showUpgradeToEditModal  = this._showUpgradeToEditModal.bind(this);
    this._hideUpgradeToEditModal  = this._hideUpgradeToEditModal.bind(this);
    this._onEditInformationClick  = this._onEditInformationClick.bind(this);
  }

  _showEditInvestmentModal() {
    this.setState({showEditInvestmentModal: true});
  }

  _hideEditInvestmentModal() {
    this.setState({showEditInvestmentModal: false});
  }

  _showUpgradeToEditModal() {
    this.setState({showUpgradeToEditModal: true});
  }

  _hideUpgradeToEditModal() {
    this.setState({showUpgradeToEditModal: false});
  }

  _onEditInformationClick() {
    if(['Guest', 'Free'].includes(DatavaseApi.getUserStatus())) {
      this._showUpgradeToEditModal();
      return;
    }

    this._showEditInvestmentModal();
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const snapshot               = this.props.snapshot;
    const contribution           = this.props.contribution;
    const investingOrganizations = snapshot.investing_organizations;
    const investingPeople        = snapshot.investing_people;
    const contributionType       = contribution.contribution_type;
    const amount    = DatavaseApi.prettifyPrice(
      snapshot.amount, snapshot.currency_code, 'Undisclosed'
    );
    const date      = DatavaseApi.prettifyDate(
      snapshot.date_of_investment, snapshot.precision_of_date_of_investment, '-'
    );
    const editedAt  = DatavaseApi.prettifyDatetime(contribution.created_at);
    const investors = [...investingOrganizations, ...investingPeople].map((investor, i) => {
      return(
        <div key={i} style={{margin: '1px'}}>
          <img
            alt={investor.normalized_name}
            src={investor.thumb_profile_image_url || placeholder}
            style={{width: '30px', border: '1px solid #ddd', marginRight: '5px'}}
          />
          <span>{investor.normalized_name}</span>
        </div>
      );
    });
    const recipient = snapshot.recipient_organization || {};
    const investee = (
      <div style={{margin: '1px'}}>
        <img
          alt={recipient.normalized_name}
          src={recipient.thumb_profile_image_url || placeholder}
          style={{width: '30px', border: '1px solid #ddd', marginRight: '5px'}}
        />
        <span>{recipient.normalized_name}</span>
      </div>
    );
    const sourceUrls = snapshot.source_urls.map((url, i) => {
      return(
        <div key={i} style={{margin: '1px'}}>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {url.slice(0, 60)}{url.length > 60 ? '...' : ''}
          </a>
        </div>
      );
    });

    return(
      <div style={{marginBottom: '4px'}}>
        <EditInvestmentModal
          show={this.state.showEditInvestmentModal}
          onCancel={this._hideEditInvestmentModal}
          id={contribution.information_id}
          defaultInvesteeOrganization={snapshot.recipient_organization}
          defaultInvestingOrganizations={investingOrganizations}
          defaultInvestingPeople={investingPeople}
          defaultAmount={snapshot.amount}
          defaultCurrency={snapshot.currency_code}
          defaultFundingType={snapshot.funding_type_name}
          defaultDateOfFunding={snapshot.date_of_investment}
          defaultPrecisionOfDateOfFunding={snapshot.precision_of_date_of_investment}
          defaultSourceUrls={snapshot.source_urls}
        />
        <UpgradeToProceedModal
          show={this.state.showUpgradeToEditModal}
          title={lang.upgradeToEditModalTitle}
          body={lang.upgradeToEditModalBody}
          upgradeButton={lang.upgradeButton}
          cancelButton={lang.cancelButton}
          onCancelClick={this._hideUpgradeToEditModal}
        />
        <div className={`contribution-snapshot-${contributionType}`} style={{marginBottom: '5px'}}>
          <Table className="contribution-investment-snapshot-table">
            <tbody>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.investee}
                </td>
                <td>
                  {investee}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.amount}
                </td>
                <td>
                  {amount}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.fundingType}
                </td>
                <td>
                  {snapshot.funding_type_name}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.date}
                </td>
                <td>
                  {date}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.investors}
                </td>
                <td>
                  {investors}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.sourceUrl}
                </td>
                <td>
                  {sourceUrls}
                </td>
              </tr>
              <tr>
                <td className="contribution-investment-snapshot-table-label">
                  {lang.editedAt}
                </td>
                <td>
                  {editedAt}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {
          this.props.hideEditButton ?
          null
          :
          <Button
            onClick={this._onEditInformationClick}
            bsStyle="primary"
            block
          >
            {lang.editButton}
          </Button>
        }
      </div>
    );
  }
}
