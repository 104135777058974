import React, {Component} from 'react'

import BlockSpinner from '../partials/block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/investors-pane.json';
import '../../css/table-pane.css';
import store from 'store';
import PersonReviewListsPanel from "./person-review-lists-panel-details";

export default class InvestorsSearchViewDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: [],
            sort_date: 'newer',
            sort_good: 'good_first',
            loadedPage: 0,
            nextPageLoaded: false,
            prefixSearchTimeoutId: null,
            keyword: '',
        };
        this._onSearchFormChange = this._onSearchFormChange.bind(this);
        this._trackScrolling = this._trackScrolling.bind(this);
    }

    _columnNames() {
        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];
        return [
            '#',
            lang.logoColumnHeader,
            lang.investorNameColumnHaeder,
            lang.dateOfFoundationColumnHaeder
        ];
    }

    // If the user comes back via the browerback and there's cached state, use the cache.
    // Otherwise, load resources from the server.
    componentDidMount() {
        this._loadNextPage();
    }

    _loadNextPage() {
        this.setState({nextPageLoaded: false});
        const pageToLoad = this.state.loadedPage + 1;
        const keyword = this.state.keyword;
        this._fetchInvestors(pageToLoad, keyword);
    }

    _fetchInvestors(page, keyword) {
        const params = {
            keyword: keyword,
            page: page,
            perPage: 40,
            sort_date: store.get("sort_date"),
            sort_good: store.get("sort_good")
        };
        DatavaseApi.listLatestPersonDetailReview(this.props.id, this.props.review_id, params)
            .then((res) => {
                this.setState({
                    entries: res.data.person_reviews,
                    who: res.data.who,
                    loadedPage: page,
                    nextPageLoaded: true,
                    keyword: '',
                });
                if (res.data.reviews.length !== 0) {
                    document.addEventListener('scroll', this._trackScrolling);
                }
            }).catch((res) => {
            console.error(res);
            document.addEventListener('scroll', this._trackScrolling);
        });
    }

    _onSearchFormChange(e) {
        if (this.state.prefixSearchTimeoutId !== null) {
            clearTimeout(this.state.prefixSearchTimeoutId);
        }
        const keyword = e.target.value;
        this.setState({
            keyword: keyword,
            loadedPage: 0
        });
        const timeoutId = setTimeout(() => {
            this.setState({
                entries: [],
            });
            this._loadNextPage()
        }, 300);
        this.setState({prefixSearchTimeoutId: timeoutId})
    }

    _isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
    }

    _trackScrolling() {
        const wrappedElement = document.getElementById('root');
        if (this._isBottom(wrappedElement)) {
            document.removeEventListener('scroll', this._trackScrolling);
            this._loadNextPage();
        }
    };

    render() {
        const list = (
            <div>
                {this.state.nextPageLoaded ?
                    <PersonReviewListsPanel
                        reviews={this.state.entries}
                        who={this.state.who}
                        openReviewModal={this.openReviewModal}
                    /> :
                    <BlockSpinner visible={!this.state.nextPageLoaded}/>}
                <p style={this.state.nextPageLoaded ? {textAlign: "center"} : {display: "none"}}>全て読み込みました。</p>
            </div>
        );
        return (
            <div>
                {list}
            </div>
        );
    }
}
