import React, { Component } from 'react';
import { Label } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockSpinner     from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/chronological-feed-panels.json';

const dateStyle = {
  color: '#666',
  fontSize: '12px',
  margin: '10px'
};

const newLabelStyle = {
  marginRight: '5px'
};

const iconStyle = {
  verticalAlign: 'middle',
};

const imgStyle = {
  border: 'solid 1px #ddd',
  margin: '0 10px',
  width: '30px'
};

const feedPanelStyle = {
  padding: '10px',
  marginBottom: '10px',
  backgroundColor: '#fff',
}

export default class ChronologicalFeedPanels extends Component {
  _constructEntryPanel(digest, index, key, color) {
    let entry;
    if(digest.resource_type === 'investment_digest') {
      entry = (
        <InvestmentDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      );
    }else if(digest.resource_type === 'funding_round_digest') {
      entry = (
        <FundingRoundDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      );
    }else if(digest.resource_type === 'acquisition_digest') {
      entry = (
        <AcquisitionDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      );
    }else if(digest.resource_type === 'sell_off_digest') {
      entry = (
        <SellOffDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      );
    }else if(digest.resource_type === 'ipo_digest') {
      entry = (
        <IpoDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      );
    }else if(digest.resource_type === 'exit_digest') {
      entry = (
        <ExitDigestPanel
          index={index}
          key={key}
          digest={digest}
          color={color}
        />
      )
    }
    return entry;
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const chronologicalFeed = this.props.chronologicalFeed || [];
    const lastSeenAt = new Date(this.props.lastSeenAt);
    const t = new Date();
    t.setDate(t.getDate()-14);

    const unseenDigests = chronologicalFeed.filter(digest => {
      const happenedAt = new Date(digest.date);
      const addedAt    = new Date(digest.added_at);
      return (addedAt > lastSeenAt && happenedAt > t);
    });
    const unseenEntries = unseenDigests.map((digest, i) => {
      digest["unseen"] = true;
      return this._constructEntryPanel(digest, i);
    });
    const unseenDigestIds = unseenDigests.map(digest => digest.id);

    const seenDigests = chronologicalFeed.filter(digest => {
      return unseenDigestIds.indexOf(digest.id) === -1;
    });
    const seenEntries = seenDigests.map((digest, i) => this._constructEntryPanel(digest, i, i, this.props.color));

    return(
      <div>
        <BlockSpinner visible={this.props.isLoading}/>
        {!this.props.isLoading && chronologicalFeed.length === 0 ?
        <span>{lang.noContents}</span>
        : null}
        {unseenEntries}
        { unseenEntries.length !== 0 ?
        <hr style={{borderTop: 'solid 1px #ddd'}}/> : null}
        {seenEntries}
      </div>
    );
  }
}

class InvestmentDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const investor = digest.investing_entity_details;
    const investor_path = `/${investor.resource_type}/${investor.id}`
    const investor_name = DatavaseApi.extractAndFormatPersonName(investor) || DatavaseApi.extractOrganizationName(investor);
    const investor_image = DatavaseApi.resolveCdnFilePath(investor.profile_image.image);

    const recipient_org = digest.recipient_organization_details;
    const recipient_org_path = `/${recipient_org.resource_type}/${recipient_org.id}`
    const recipient_org_name = DatavaseApi.extractOrganizationName(recipient_org);
    const recipient_org_image = DatavaseApi.resolveCdnFilePath(recipient_org.profile_image.image);

    return(
      <div style={feedPanelStyle}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="warning">{lang.investmentLabel}</Label>
        <Link to={investor_path}>
          <img alt={investor_name} src={investor_image} style={imgStyle}/>
        </Link>
        <i className="material-icons" style={iconStyle}>chevron_right</i>
        <Link to={recipient_org_path}>
          <img alt={recipient_org_name} src={recipient_org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={investor_path}>
              {investor_name}
            </Link>
            {' invests in '}
            <Link to={recipient_org_path}>
              {recipient_org_name}
            </Link>
          </div>
          :
          <div>
            <Link to={investor_path}>
              {investor_name}
            </Link>
            {' が '}
            <Link to={recipient_org_path}>
              {recipient_org_name}
            </Link>
            {' に投資しました '}
          </div>
        }
      </div>
    );
  }
}

class FundingRoundDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const recipient_org = digest.recipient_organization_details;
    const recipient_org_path = `/${recipient_org.resource_type}/${recipient_org.id}`
    const recipient_org_name = DatavaseApi.extractOrganizationName(recipient_org);
    const recipient_org_image = DatavaseApi.resolveCdnFilePath(recipient_org.profile_image.image);

    const price = DatavaseApi.abbrPrice(digest.amount, digest.currency_code, '???');
    const fundingType = digest.funding_type;

    return(
      <div style={feedPanelStyle}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="info">{lang.fundingRoundLabel}</Label>
        <Link to={recipient_org_path}>
          <img alt={recipient_org_name} src={recipient_org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={recipient_org_path}>
              {recipient_org_name}
            </Link>
            {` raises ${price} ${fundingType}`}
          </div>
          :
          <div>
            <Link to={recipient_org_path}>
              {recipient_org_name}
            </Link>
            {` が ${fundingType}で${price}を調達しました `}
          </div>
        }
      </div>
    );
  }
}

class AcquisitionDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const acquiring_org       = digest.acquiring_organization_details;
    const acquiring_org_path  = `/${acquiring_org.resource_type}/${acquiring_org.id}`;
    const acquiring_org_name  = DatavaseApi.extractOrganizationName(acquiring_org);
    const acquiring_org_image = DatavaseApi.resolveCdnFilePath(acquiring_org.profile_image.image);

    const acquired_org       = digest.acquired_organization_details;
    const acquired_org_path  = `/${acquired_org.resource_type}/${acquired_org.id}`;
    const acquired_org_name  = DatavaseApi.extractOrganizationName(acquired_org);
    const acquired_org_image = DatavaseApi.resolveCdnFilePath(acquired_org.profile_image.image);

    const dealPrice = DatavaseApi.abbrPrice(digest.price, digest.currency_code, 'Undisclosed');

    return(
      <div style={feedPanelStyle}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="success">{lang.acquisitionLabel}</Label>
        <Link to={acquiring_org_path}>
          <img alt={acquiring_org_name} src={acquiring_org_image} style={imgStyle}/>
        </Link>
        <i className="material-icons" style={iconStyle}>chevron_left</i>
        <Link to={acquired_org_path}>
          <img alt={acquired_org_name} src={acquired_org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={acquiring_org_path}>
              {acquiring_org_name}
            </Link>
            {' acquired '}
            <Link to={acquired_org_path}>
              {acquired_org_name}
            </Link>
            {`. Deal Price: ${dealPrice}`}
          </div>
          :
          <div>
            <Link to={acquiring_org_path}>
              {acquiring_org_name}
            </Link>
            {' が '}
            <Link to={acquired_org_path}>
              {acquired_org_name}
            </Link>
            {` を買収しました。買収金額: ${dealPrice}`}
          </div>
        }
      </div>
    );
  }
}

class SellOffDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const acquiring_org       = digest.acquiring_organization_details;
    const acquiring_org_path  = `/${acquiring_org.resource_type}/${acquiring_org.id}`;
    const acquiring_org_name  = DatavaseApi.extractOrganizationName(acquiring_org);
    const acquiring_org_image = DatavaseApi.resolveCdnFilePath(acquiring_org.profile_image.image);

    const acquired_org       = digest.acquired_organization_details;
    const acquired_org_path  = `/${acquired_org.resource_type}/${acquired_org.id}`;
    const acquired_org_name  = DatavaseApi.extractOrganizationName(acquired_org);
    const acquired_org_image = DatavaseApi.resolveCdnFilePath(acquired_org.profile_image.image);

    const dealPrice = DatavaseApi.abbrPrice(digest.price, digest.currency_code, 'Undisclosed');

    return(
      <div style={{padding: '10px', marginBottom: '10px', backgroundColor: '#fff'}}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="danger">{lang.sellOffLabel}</Label>
        <Link to={acquired_org_path}>
          <img alt={acquired_org_name} src={acquired_org_image} style={imgStyle}/>
        </Link>
        <i className="material-icons" style={iconStyle}>chevron_right</i>
        <Link to={acquiring_org_path}>
          <img alt={acquiring_org_name} src={acquiring_org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={acquired_org_path}>
              {acquired_org_name}
            </Link>
            {' sold to '}
            <Link to={acquiring_org_path}>
              {acquiring_org_name}
            </Link>
            {`. Deal Price: ${dealPrice}`}
          </div>
          :
          <div>
            <Link to={acquired_org_path}>
              {acquired_org_name}
            </Link>
            {' が '}
            <Link to={acquiring_org_path}>
              {acquiring_org_name}
            </Link>
            {` に売却されました。売却金額: ${dealPrice}`}
          </div>
        }
      </div>
    );
  }
}

class IpoDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const org       = digest.organization_details;
    const org_path  = `/${org.resource_type}/${org.id}`;
    const org_name  = DatavaseApi.extractOrganizationName(org);
    const org_image = DatavaseApi.resolveCdnFilePath(org.profile_image.image);
    const stock_url = `https://finance.google.com/finance?q=${digest.stock_symbol}`;

    return(
      <div style={feedPanelStyle}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="danger">{lang.ipoLabel}</Label>
        <Link to={org_path}>
          <img alt={org_name} src={org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={org_path}>
              {org_name}
            </Link>
            {' went public. Stock symbol: '}
            <a href={stock_url} target='_blank' rel="noopener noreferrer">
              {digest.stock_symbol}
            </a>
          </div>
          :
          <div>
            <Link to={org_path}>
              {org_name}
            </Link>
            {' が上場しました。株式銘柄: '}
            <a href={stock_url} target='_blank' rel="noopener noreferrer">
              {digest.stock_symbol}
            </a>
          </div>
        }
      </div>
    );
  }
}

class ExitDigestPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const digest = this.props.digest;
    const unseen = digest.unseen;
    const date   = DatavaseApi.prettifyDate(digest.date, digest.precision_of_date);

    const investor_entity       = digest.investor_entity_details;
    const investor_entity_path  = `/${investor_entity.resource_type}/${investor_entity.id}`;
    const investor_entity_name  = DatavaseApi.extractOrganizationName(investor_entity) ||
                                  DatavaseApi.extractAndFormatPersonName(investor_entity);
    const investor_entity_image = DatavaseApi.resolveCdnFilePath(investor_entity.profile_image.image);

    const exiting_org           = digest.exiting_organization_details;
    const exiting_org_path      = `/${exiting_org.resource_type}/${exiting_org.id}`;
    const exiting_org_name      = DatavaseApi.extractOrganizationName(exiting_org);
    const exiting_org_image     = DatavaseApi.resolveCdnFilePath(exiting_org.profile_image.image);
    return(
      <div style={feedPanelStyle}>
        {unseen ? <Label style={newLabelStyle}>New</Label> : null}
        <span style={{display: 'inline-block', backgroundColor: this.props.color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
          {this.props.index+1}
        </span>
        <Label style={{display: 'inline-block', minWidth: '60px'}}  bsStyle="danger">{lang.portfolioExitLabel}</Label>
        <Link to={investor_entity_path}>
          <img alt={investor_entity_name} src={investor_entity_image} style={imgStyle}/>
        </Link>
        <i className="material-icons" style={iconStyle}>swap_horiz</i>
        <Link to={exiting_org_path}>
          <img alt={exiting_org_name} src={exiting_org_image} style={imgStyle}/>
        </Link>
        <span style={dateStyle}>{date}</span>
        {
          langCode === 'en' ?
          <div>
            <Link to={investor_entity_path}>
              {investor_entity_name}
            </Link>
            {': One of portfolio companies exits.'}
          </div>
          :
          <div>
            <Link to={investor_entity_path}>
              {investor_entity_name}
            </Link>
            {': 投資先企業がイグジットしました'}
          </div>
        }
      </div>
    );
  }
}
