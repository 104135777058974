import React, { Component } from 'react';
import PasswordResetRequestWindow from '../partials/password-reset-request-window';
import store from 'store';

export default class PasswordResetRequestPage extends Component {
  _get_lang() {
    return (new URL(window.location)).searchParams.get('lang') || store.get('lang');
  }

  render() {
    const lang = this._get_lang();

    return(
      <div style={{margin: '10px'}}>
        <h1 id="auth-page-heading" className="datavase">datavase.io</h1>
        <PasswordResetRequestWindow lang={lang}/>
      </div>
    );
  }
}
