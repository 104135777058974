import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';
import { Timeline } from 'react-twitter-widgets';

import { getLangCode } from '../datavase-api';
import i18n from '../../i18n/twitter-timeline-panel.json';
import '../../css/twitter-timeline-panel.css';

export default class TwitterTimelinePanel extends Component {
  render() {
    const langCode = getLangCode();
    const lang     = i18n[langCode];
    const screenName = this.props.screenName;
    const dataSource = {
      sourceType: 'profile',
      screenName: screenName,
    };
    const options = {
      chrome: 'noscrollbar noheader noborders',
      tweetLimit: 5,
    };
    return(
      <Panel>
        <Tabs id="twitter-timeline-tabs">
          <Tab eventKey={1} title={lang.twitterTimelineTitle}>
            <Panel>
              <Timeline
                dataSource={dataSource}
                options={options}
              />
            </Panel>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}
