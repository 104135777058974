import React, {Component} from 'react';
import '../../css/review-panel.css';
import * as DatavaseApi from '../datavase-api';
import {Button, Checkbox, FormControl, InputGroup, Panel} from "react-bootstrap";
import BlockSpinner from "./block-spinner";

export default class QuestionBoardsPanel extends Component {
    //コメントを回答にしてPOSTにする、初期取得は一覧をとって(それにトピックが入ってるから)、それからクリックされたら詳細を取る感じでいいかな
    //新規投稿…いいねは後日実装としても、うん
    constructor(props) {
        super(props);
        this._editTitle = this._editTitle.bind(this);
        this._editBody = this._editBody.bind(this);
        this._createThread = this._createThread.bind(this);
        this.state = {
            topics: {},
            replies: {},
            title: '',
            body: '',
            tag: 99,
            anonymous: false,
            loading: true,
        }
    }
    _createThread(e) {
        DatavaseApi.createQuestionBoard({
            organization_id: this.props.id,
            title: this.state.title,
            body: this.state.body,
            tag_id: this.state.tag, //Tmp
            anonymous: this.state.anonymous,
        }).then(_res => window.location.reload());
    }
    _editTitle(e) {
        this.setState({
            title: e.target.value,
        });
    }
    _editBody(e) {
        this.setState({
            body: e.target.value,
        });
    }
    _editAnswerBody(i,e) {
        let topics = this.state.topics;
        if(topics[i].answer === undefined) {
            topics[i].answer={}
        }
        topics[i].answer.body=e.target.value;
        this.setState({topics})
    }
    _editAnswerAnonymity(i) {
        let topics = this.state.topics;
        if(topics[i].answer === undefined) {
            topics[i].answer={}
        }
        if(topics[i].answer.anonymous===undefined) {
            topics[i].answer.anonymous=true;
        } else {
            topics[i].answer.anonymous = !topics[i].answer.anonymous;
        }
        this.setState({topics})
    }
    _postAnswer(i) {
        let topics = this.state.topics;
        if(topics[i].answer === undefined) {
            topics[i].answer={}
        }
        if(topics[i].answer.anonymous===undefined) {
            topics[i].answer.anonymous=false;
        }
        if(topics[i].answer.title===undefined) {
            topics[i].answer.title="";
        }
        if(topics[i].answer.body===undefined) {
            topics[i].answer.body="";
        }
        topics[i].answer.organization_id = this.props.id;
        topics[i].answer.tag_id = topics[i].tag;
        DatavaseApi.addAnswer(topics[i].id,topics[i].answer).then(_res => window.location.reload());
    }
    componentDidMount() {
        DatavaseApi.fetchQuestionBoards({organization_id:this.props.id}).then(res => {
            this.setState({
                topics: res.data,
                loading: false,
            });
        }).catch((res) => {
            console.error(res);
        });
    }

    render() {
        if(this.state.loading) {
            return(
                <Panel>
                    <BlockSpinner visible/>
                </Panel>
            );
        }
        let replies=[];
        let topics=[];
        // eslint-disable-next-line
        this.state.topics.map((topic,i) => {
            if(topic.question_board_id !== null) {
                if(replies[topic.question_board_id] === undefined) {
                    replies[topic.question_board_id] = []
                }
                replies[topic.question_board_id][topic.id]=topic;
            } else {
                if(topics[topic.id] === undefined) {
                    topics[topic.id] = []
                }
                topics[topic.id]=topic;
            }
        });
        return(
            <div>
                <Panel>
                    <div style={{margin: '20px 0'}}>
                        <p>質問する</p>
                        <InputGroup className="mb-3">
                            <FormControl componentClass="input"
                                         type="text"
                                         placeholder="タイトル"
                                         style={{resize: 'vertical'}}
                                         onChange={this._editTitle} />
                            <FormControl componentClass="textarea"
                                         type="text"
                                         placeholder="本文"
                                         style={{resize: 'vertical'}}
                                         onChange={this._editBody} />
                            <div>
                                <Checkbox name="anonymous" onClick={() => { this.setState({tag: 1}) }} checked={this.state.tag === 1} inline>ファイナンス</Checkbox>
                                <Checkbox name="anonymous" onClick={() => { this.setState({tag: 2}) }} checked={this.state.tag === 2} inline>事業選定</Checkbox>
                                <Checkbox name="anonymous" onClick={() => { this.setState({tag: 3}) }} checked={this.state.tag === 3} inline>バックオフィス</Checkbox>
                                <Checkbox name="anonymous" onClick={() => { this.setState({tag: 4}) }} checked={this.state.tag === 4} inline>人材</Checkbox>
                                <Checkbox name="anonymous" onClick={() => { this.setState({tag: 99}) }} checked={this.state.tag === 99} inline>その他</Checkbox>
                            </div>
                            <div>
                                <Checkbox name="anonymous" onClick={() => { this.setState({anonymous: false}) }} checked={!this.state.anonymous} inline> 実名 </Checkbox>
                                <Checkbox name="anonymous" onClick={() => { this.setState({anonymous: true}) }} checked={this.state.anonymous} inline> 匿名 </Checkbox>
                            </div>
                            <InputGroup.Button>
                                <Button bsStyle="primary" onClick={this._createThread}> Submit </Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </div>
                </Panel>
                <div>
                    {topics.map((topic, i)  => {
                        //Extremely temporary table. Might migrate to the DB
                        let tag = 'その他';
                        if(topic.tag === 1) {
                            tag = "ファイナンス"
                        } else if(topic.tag === 2) {
                            tag = "事業選定"
                        } else if(topic.tag === 3) {
                            tag = "バックオフィス"
                        } else if(topic.tag === 4) {
                            tag = "人材"
                        }
                        let answer = (
                            <div style={{margin: '20px 0'}}>
                                <InputGroup className="mb-3">
                                    <FormControl componentClass="textarea"
                                                 type="text"
                                                 placeholder="本文"
                                                 style={{resize: 'vertical'}}
                                                 onChange={(e)=>this._editAnswerBody(i,e)} />
                                    <div>
                                        <Checkbox name="anonymous" onClick={() => this._editAnswerAnonymity(i)} inline> チェックを入れると匿名になります </Checkbox>
                                    </div>
                                    <InputGroup.Button>
                                        <Button bsStyle="primary" onClick={()=>this._postAnswer(i)}> Submit </Button>
                                    </InputGroup.Button>
                                </InputGroup>
                            </div>
                        );
                        return(
                        <Panel>
                            <p style={{fontSize: '1.5em'}}>{topic.title}</p>
                            <img style={{width: '35px'}} src={topic.thumb_profile_image_url || topic.profile_image_url} alt="profileImg"/>
                            <span>{topic.name || topic.screen_name}&nbsp;</span>
                            <span>{DatavaseApi.prettifyDatetime(topic.created_at)}&nbsp;</span>
                            <span className="btn btn-primary" style={{marginLeft: '10px', borderRadius:"20px"}}>{tag}</span>
                            <p style={{fontSize: '1.25em'}}>{topic.body}</p>
                            {replies[i] !== undefined ? replies[i].map((reply,i)=>{
                                return(<div style={{paddingLeft: '1em', borderLeft: "solid 3px gray", marginLeft: '1em'}}>
                                    <p style={{fontSize: '1.1em'}}>{reply.title}</p>
                                    <img style={{width: '35px'}} src={reply.thumb_profile_image_url || reply.profile_image_url} alt="profileImg"/>
                                    <span style={reply.user_id === topic.user_id ? {fontWeight: "bold"}:{}}>{reply.name || reply.screen_name}&nbsp;</span>
                                    <span>{DatavaseApi.prettifyDatetime(reply.created_at)}</span>
                                    <p style={{fontSize: '1em'}}>{reply.body}</p>
                                </div>)
                            }): "回答がありません。"}
                            {answer}
                        </Panel>
                    )})}
                </div>
            </div>
        );
        }
        }