import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ChronologicalFeedPanels from './chronological-feed-panels';
import * as DatavaseApi from '../datavase-api';

const colors = [
  '#c0392b',
  '#d35400',
  '#f39c12',
  '#F1D53B',
  '#27ae60',
  '#16a085',
  '#2980b9',
  '#2c3e50',
  '#8e44ad',
];

export default class ListDigestsColumnView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      digests: [],
    };
    this._fetchDigestsByList = this._fetchDigestsByList.bind(this);
  }

  componentDidMount() {
    this._fetchDigestsByList(this.props.list.id);
  }

  _fetchDigestsByList(id) {
    DatavaseApi.fetchListChronologicalFeed(id)
      .then(res => {
        this.setState({
          digests: res.data
        })
      })
      .catch(err => {
        console.error(err.response);
      })
  }

  render() {
    const list = this.props.list;
    const color = colors[this.props.list.id % 9];

    return (
      <div style={{ display: 'inline-block' }}>
        <div style={{
          display: 'inline-block',
          margin: '0px 15px',
          width: '367px',
          padding: '8px',
          backgroundColor: '#fff',
          fontSize: '16px',
          fontWeight: 'bold'
        }}>
          <Link to={`/lists/${list.id}`} style={{ color: color, fontWeight: 'bold', textDecoration: 'none' }}>
            {list.list_name}
          </Link>
        </div>
        <div style={{ margin: '15px', height: '800px', width: '370px', overflowY: 'scroll', overflowX: 'hidden', whiteSpace: 'normal' }}>
          <ChronologicalFeedPanels
            color={color}
            chronologicalFeed={this.state.digests}
          />
        </div>
      </div>
    );
  }
}

