import React, { Component } from 'react';
import { Panel, Tabs, Tab, Table, Button } from 'react-bootstrap';

import BlockSpinner      from './block-spinner';
import ContributionDetailsModal from './contribution-details-modal';
import placeholder       from '../../assets/datavase_logo_icon_grey.png';
import admin_placeholder from '../../assets/datavase_logo_icon.png';
import * as DatavaseApi  from '../datavase-api';
import i18n              from '../../i18n/contributions-panel.json';
import '../../css/contributions-panel.css';

export default class ContributionsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContributionDetailsModal: false,
      contribution:                 null,
    };
    this._showContributionDetailsModal = this._showContributionDetailsModal.bind(this);
    this._hideContributionDetailsModal = this._hideContributionDetailsModal.bind(this);
    this._onSeeDetailsClick            = this._onSeeDetailsClick.bind(this);
  }

  _showContributionDetailsModal() {
    this.setState({showContributionDetailsModal: true});
  }

  _hideContributionDetailsModal() {
    this.setState({showContributionDetailsModal: false});
  }

  _onSeeDetailsClick(contribution) {
    this.setState({
      contribution: contribution
    });
    this._showContributionDetailsModal();
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const contributions             = this.props.contributions || [];
    const subjectId                 = this.props.subjectId;
    const subjectResourceDomainName = this.props.subjectUrl;
    const contributionRows = contributions.map((contribution, i) => {
      return(
        <ContributionRow
          contribution={contribution}
          subjectId={subjectId}
          subjectResourceDomainName={subjectResourceDomainName}
          onSeeDetailsClick={this._onSeeDetailsClick}
          key={i}
        />
      );
    });

    return(
      <div>
        <ContributionDetailsModal
          hideEditButton
          hideDeleteButton
          show={this.state.showContributionDetailsModal}
          contribution={this.state.contribution}
          subjectResourceDomainName={subjectResourceDomainName}
          onCancel={this._hideContributionDetailsModal}
        />
        <Panel>
          <Tabs id="contributions-tabs">
            <Tab eventKey={1} title={lang.contributionsPanelTitle}>
              {
                this.props.contributions ?
                <Table>
                  <tbody>
                    {contributionRows}
                  </tbody>
                </Table>
                :
                <BlockSpinner visible/>
              }
            </Tab>
          </Tabs>
        </Panel>
      </div>
    );
  }
}

class ContributionRow extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const contribution              = this.props.contribution;
    const subjectId                 = this.props.subjectId;
    const subjectResourceDomainName = this.props.subjectResourceDomainName;

    const contributor   = contribution.contributor;
    const createdAt     = DatavaseApi.prettifyDatetime(contribution.created_at);
    const detailsString = constructContributionDetailsString(
      langCode,
      contribution.contribution_type,
      contribution.information_snapshot,
      contribution.information_type,
      subjectId
    );

    const onClick = () => {
      this.props.onSeeDetailsClick(contribution);
    }

    return(
      <tr>
        <td>
          <span style={{lineHeight: '30px'}}>{createdAt}</span>
        </td>
        <td>
          <ContributorView
            contributor={contributor}
            subjectResourceDomainName={subjectResourceDomainName}
          />
          <span>{detailsString}</span>
        </td>
        <td>
          <Button bsStyle="primary" bsSize="small" onClick={onClick.bind(this)}>
            {lang.seeDetailsButton}
          </Button>
        </td>
      </tr>
    );
  }
}

class ContributorView extends Component {
  render() {
    const contributor = this.props.contributor;
    const subjectResourceDomainName = this.props.subjectResourceDomainName;

    let screenName, img, domainName;
    let borderStyle = '2px solid #aaa';
    if(contributor.contributor_type === 'Admin') {
      img         = admin_placeholder;
      screenName  = 'datavase.io'
      domainName  = 'datavase.io';
      borderStyle = '2px solid #669287';
    }else{
      img        = contributor.thumb_profile_image_url || placeholder;
      screenName = contributor.screen_name || 'Anonymous User';
      domainName = contributor.domain_name;
      if(subjectResourceDomainName === domainName) {
        borderStyle = '2px solid #BE3D33';
      }
    }

    return(
      <div style={{display: 'inline-block', marginRight: '10px'}}>
        <img
          alt={screenName}
          src={img}
          style={{width: '30px', borderRadius: '15px', marginRight: '4px', border: borderStyle}}
        />
        <span style={{fontWeight: 'bold'}}>{screenName}</span>
      </div>
    );
  }
}

function constructContributionDetailsString(langCode, contributionType, information, informationType, subjectId) {
  let informationTypeI18nKey = informationType;
  if(informationType === 'Investment') {
    if(information.recipient_organization_id === subjectId) {
      informationTypeI18nKey = 'FundingRound';
    }
  }else if(informationType === 'Acquisition') {
    if(information.acquired_organization_id === subjectId) {
      informationTypeI18nKey = 'SellOff';
    }
  }

  const lang = i18n[langCode]
  if(langCode === 'ja') {
    if(contributionType === 'create') {
      return `が新しい${lang[informationTypeI18nKey]}を追加しました。`;
    }else if(contributionType === 'edit') {
      return `が${lang[informationTypeI18nKey]}を編集・更新しました。`;
    }else if(contributionType === 'delete') {
      return `が${lang[informationTypeI18nKey]}を削除しました。`;
    }
  }else {
    if(contributionType === 'create') {
      return ` added new ${lang[informationTypeI18nKey]}.`;
    }else if(contributionType === 'edit') {
      return ` edited ${lang[informationTypeI18nKey]}.`;
    }else if(contributionType === 'delete') {
      return ` deleted ${lang[informationTypeI18nKey]}.`;
    }
  }
}
