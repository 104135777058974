import React, {Component} from 'react';
import store from 'store';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styled from 'styled-components';
import media from 'styled-media-query';

import TopNavbar from './components/organisms/top-navbar';
import TopNavbarMobile from './components/organisms/top-navbar-mobile.new.js';
import Sidebar from './components/organisms/sidebar';
import Footer from './components/organisms/footer';
import ErrorMessageModal from './components/partials/error-message-modal';
import * as sharedResourcesActions from './actions/shared-resources';
import './css/content-panel.css';
import './css/newTheme1.css';
import './css/defaultTheme.css';
import './css/newThemeAprilFool.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: true,
    };

    if (!store.get('langCode')) {
      const lang = (new URL(window.location)).searchParams.get('lang') || 'ja';
      store.set('langCode', lang);
    }

    this._hideSidebar = this._hideSidebar.bind(this);
    this._showSidebar = this._showSidebar.bind(this);
    this._toggleSidebarVisibility = this._toggleSidebarVisibility.bind(this);
  }

  _hideSidebar() {
    this.setState({ sidebarVisible: false });
  }

  _showSidebar() {
    this.setState({ sidebarVisible: true });
  }

  _toggleSidebarVisibility() {
    if (!this.state.sidebarVisible) {
      document.querySelector("#top-navbar > nav > div > div > button").setAttribute('class', 'navbar-toggle collapsed clicked');
      document.querySelector("#top-navbar > nav > div > div > button").innerHTML = '<span class="sr-only">Toggle navigation</span><span><i class="material-icons" style="margin-top: 1.5px;">close</i></span>';
    } else {
      document.querySelector("#top-navbar > nav > div > div > button").setAttribute('class', 'navbar-toggle collapsed');
      document.querySelector("#top-navbar > nav > div > div > button").innerHTML = '<span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span>';
    }
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  }

  _currentPathCategory() {
    const path = window.location.pathname;
    if (path.includes('companies')) {
      return 'companies';
    } else if (path.includes('investors')) {
      return 'investors';
    } else if (path.includes('schools')) {
      return 'schools';
    } else if (path.includes('people')) {
      return 'people';
    } else if (path.includes('events')) {
      return 'events';
    } else if (path.includes('lists')) {
      return 'lists';
    } else if (path.includes('articles')) {
      return 'articles';
    } else if (path.includes('news')) {
      return 'news';
    } else if (path.includes('mypage')) {
      return 'mypage';
    } else if (path.includes('qa_boards')) {
      return 'qa_boards';
    } else if (path.includes('recruits')) {
      return 'recruits';
    } else {
      return 'dashboard';
    }
  }

  render() {
    const category = this._currentPathCategory();
    if (store.get("surpriseTheme")) {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme_surprise');
    } else if (store.get("newTheme")) {
      document.getElementsByTagName('body')[0].setAttribute('class', 'theme1');
    } else {
      document.getElementsByTagName('body')[0].setAttribute('class', 'default_theme');
    }
    return (
      <div>
        <ErrorMessageModal show={this.props.sharedResources.error !== null} />
        <TopNavbar />
        <TopNavbarMobile
          pathCategory={category}
          onMobileNavbarUnmounted={this._showSidebar}
          onMobileNavbarMounted={this._hideSidebar}
          onSidebarVisibilityToggled={this._toggleSidebarVisibility}
        />
        <Sidebar
          pathCategory={category}
          visible={this.state.sidebarVisible}
        />
        <div id="content-panel">
          <Main>
            {this.props.children}
          </Main>
        </div>
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

const Main = styled.div`
  padding: 32px 48px;
  ${media.lessThan("medium")`
    padding: 0px 16px;
  `}
`;
