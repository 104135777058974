import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import EditInvestmentForm from './edit-investment-form';
import * as DatavaseApi   from '../datavase-api';
import i18n from '../../i18n/edit-investment-modal.json';

export default class EditInvestmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasDone: false,
      isProcessing: false,
    };

    this._onCancelClick = this._onCancelClick.bind(this);
  }

  _onCancelClick() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const {
      defaultInvesteeOrganization,
      defaultInvestingOrganizations,
      defaultInvestingPeople,
      defaultCurrency,
      defaultAmount,
      defaultFundingType,
      defaultDateOfFunding,
      defaultPrecisionOfDateOfFunding,
      defaultSourceUrls
    } = this.props;

    return(
      <Modal show={this.props.show} keyboard onHide={this._onCancelClick}>
        <Modal.Header>
          {lang.editInvestmentModalTitle}
        </Modal.Header>
        <Modal.Body>
          <EditInvestmentForm
            id={this.props.id}
            defaultInvesteeOrganization={defaultInvesteeOrganization}
            defaultInvestingOrganizations={defaultInvestingOrganizations}
            defaultInvestingPeople={defaultInvestingPeople}
            defaultAmount={defaultAmount}
            defaultCurrency={defaultCurrency}
            defaultFundingType={defaultFundingType}
            defaultDateOfFunding={defaultDateOfFunding}
            defaultPrecisionOfDateOfFunding={defaultPrecisionOfDateOfFunding}
            defaultSourceUrls={defaultSourceUrls}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onCancelClick}>
            {lang.cancelButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
