import React, { Component } from 'react';
import block_spinner from '../../assets/block_spinner.svg';

export default class BlockSpinner extends Component {
  render() {
    if(this.props.visible) {
      return(
        <div className="spinner-container" style={{width: "100%", "textAlign": "center"}}>
          <img src={block_spinner} alt=""/>
        </div>
      );
    }else{
      return(null);
    }
  }
}
