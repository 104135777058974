import React, { Component } from 'react'
import { Button, Panel, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import axios from 'axios';
import store from 'store';

import BlockSpinner     from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import * as ENV         from '../../constants/environment';
import i18n             from '../../i18n/password-reset-request-window.json';
import '../../css/auth-pane.css';

export default class PasswordResetRequestWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      hasPasswordResetRequestSent: false,
      email: '',
      messages: [],
    }
    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _languageChangeLink() {
    const langCode = DatavaseApi.getLangCode();
    if(langCode === 'ja'){
      return(<Link onClick={this._onClickEnglish}>English</Link>);
    }else{
      return(<Link onClick={this._onClickJapanese}>日本語</Link>);
    }
  }

  _onSubmit() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    this.setState({isProcessing: true});
    let params = {
      email: this.state.email,
      redirect_url: `${ENV.WEB_APP_ENDPOINT}/reset_password`
    };

    axios.post(`${ENV.API_ENDPOINT}/auth/password`, params)
    .then(res => {
      this.setState({
        isProcessing: false,
        hasPasswordResetRequestSent: true,
        messages: [lang.passwordResetRequsetSentMessage],
      })
    })
    .catch(res => {
      this.setState({
        isProcessing: false,
        messages: ['Error occurred. Please try again.'],
      })
    })
  }

  _onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _constructErrorsList() {
    let errorRows = this.state.messages.map((message, i) => {
      return(<li key={i} style={{color: '#666', fontWeight: 'bold'}}>{message}</li>);
    });

    return( <ul>{errorRows}</ul> );
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return (
      <Panel className="auth-panel" header={lang.passwordResetRequestWindowTitle}>
        {this._constructErrorsList()}
        { this.state.hasPasswordResetRequestSent ? null :
        <div className="auth-panel-content">
          <div className="social-auth">
            <h5>{lang.passwordResetRequestMessage}</h5>
          </div>
          <div className="auth-form">
            <FormGroup>
              <ControlLabel>{lang.emailLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="text"
                placeholder={lang.emailPlaceholder}
                name="email"
                onChange={this._onChange}
              />
            </FormGroup>
            {
              this.state.isProcessing ?
              <BlockSpinner visible={true}/>
              :
              <Button bsStyle="primary" className="auth-confirm-button" onClick={this._onSubmit}>
                {lang.passwordResetRequestButton}
              </Button>
            }
            <Link className="auth-link" to="/signup">{lang.signupLinkMessage}</Link>
            {this._languageChangeLink()}
          </div>
        </div>
        }
      </Panel>
    )
  }
}

