import React, { Component } from 'react';
import { Panel, Col, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import BlockSpinner               from './partials/block-spinner';

import * as DatavaseApi           from './datavase-api';
//import i18n                       from '../i18n/product-details-pane.json'
import '../css/details-pane.css';

class ProductDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      hasLoaded: false,
    };
  }

  _get_id() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._get_id();
    DatavaseApi.fetchProductWithRelatedResources(id)
    .then((res) => {
      this.setState({
        resources: {},
        hasLoaded: true,
      });
    }).catch((res) => {
      console.error(res);
    });
  }

  render () {
    //const resources = this.state.resources;
    //const lang = this._get_lang() || 'en';

    if(this.state.hasLoaded) {
      return (
        <div id="details-page-contents">
          <Col sm={12} md={12} lg={12}>
            <Panel>
              <BlockSpinner visible={true}/>
            </Panel>
          </Col>
          <Tabs id="details-main-tabs" className="jumbo-tabs">
            <Tab eventKey={1} title="Details" className="clearfix">
              <Col sm={12} md={8} lg={8}>
                <Panel>
                  <BlockSpinner visible={true}/>
                </Panel>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Panel>
                  <BlockSpinner visible={true}/>
                </Panel>
              </Col>
            </Tab>
          </Tabs>
        </div>
      );
    }else{
      return(<BlockSpinner visible={true}/>);
    }
  }
}

export default withRouter(ProductDetailsPane);
