import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import NewsArticlePreview from './partials/news-article-preview';

class SharedNewsArticlePane extends Component {
  _getId() {
    return this.props.match.params.id;
  }

  render() {
    const id = this._getId();
    return(
      <div style={{margin: 'auto', maxWidth: '1100px'}} className="clearfix">
        <Panel style={{marginBottom: '20px'}}>
          <NewsArticlePreview
            warehouseNewsArticleId={id}
          />
        </Panel>
      </div>
    );
  }
}

export default withRouter(SharedNewsArticlePane);
