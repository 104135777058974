import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BlockSpinner from './partials/block-spinner';
import OrganizationAbstractPanel from './partials/organization-abstract-panel';
import OrganizationReviewListsPanel from './partials/organization-review-lists-panel';
import {withRouter} from 'react-router-dom';
import placeholder from '../assets/datavase_logo_icon_grey.png';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/company-details-pane.json'
import '../css/details-pane.css';
import styled from "styled-components";
import color from "./styles/colors";
//import media from "styled-media-query";
import MetaTags from "react-meta-tags";

class CompanyReviewPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      h1: "",
      contributions: null,
      hasLoaded: false,
      id: null,
      ads: [],
      restrict_tag_id: -1,
    };

    this._onTabSelect = this._onTabSelect.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchAds(id).then(res => {
      this.setState({
        ads: res.data
      })
    });
    DatavaseApi.fetchCompanyWithRelatedResources(id)
        .then(res => {
          const realId = res.data.company.id;
          this.setState({
            resources: res.data,
            hasLoaded: true,
            id: res.data.company.id,
          });
          DatavaseApi.fetchOrganizationAverageReviews(realId)
              .then(res => {
                this.setState({
                  averageReviews: res.data.average_rating,
                });
              }).catch((res) => {
            console.error(res);
          });
          DatavaseApi.fetchOrganizationReviews(realId)
              .then(res => {
                if (res.data.organization_reviews.length !== 0) {
                  let msg = '';
                  if (res.data.organization_reviews[0].good_point.length !== 0) {
                    msg += `良い点: ${res.data.organization_reviews[0].good_point}/`
                  }
                  if (res.data.organization_reviews[0].bad_point.length !== 0) {
                    msg += `改善点: ${res.data.organization_reviews[0].bad_point}`
                  }
                  if (res.data.organization_reviews[0].total_review.length !== 0) {
                    msg = `${res.data.organization_reviews[0].total_review}`
                  }
                  this.setState({
                    h1: msg
                  });
                } else {
                  this.setState({
                    h1: "この会社にはまだレビューがありません。一番乗りでコメントしましょう!",
                  });
                }
                this.setState({
                  reviewNumber: res.data.organization_reviews.length,
                  hasLoaded: true
                });
              }).catch((res) => {
            console.error(res);
          });
        }).catch((res) => {
      console.error(res);
    })
  }

  _setRestrictTagId(id) {
    this.setState({
      restrict_tag_id: id,
    })
  }
  _onTabSelect(key) {
    if(key === "contributions") {
      if(!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('companies', id)
            .then(res => {
              this.setState({contributions: res.data});
            })
            .catch(res => {
              console.error(res);
            });
      }
    }
  }

  _structuredData() {
    let data = {
      "@context": "http://schema.org", //固定
      "@type": "Product", //固定
      "aggregateRating": { //固定
        "@type": "AggregateRating", //固定
        "reviewCount": `${this.state.reviewNumber}`, //暫定的に
        "bestRating": "5", //固定
        "worstRating": "0", //固定
        "ratingValue": `${this.state.averageReviews}`,
        "ratingCount": `${this.state.reviewNumber}`
      },
      "name": `${DatavaseApi.extractOrganizationName(this.state.resources.company, "ja")} | Datavase`,
      "image": `${this.state.resources.company.profile_image_url}`,
      "url": `https://datavase.io/companies/${this._getId()}`,
      "description": `${DatavaseApi.extractDescription(this.state.resources.company, "ja")}`,
      "photo": {
        "@type": "Photograph", "image": `${this.state.resources.company.profile_image_url || placeholder}`
      }
    };

    return JSON.stringify(data);
  };

  render() {
    if (!this.state.hasLoaded) {
      return (<BlockSpinner visible={true}/>);
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const {resources, id} = this.state;
    const company = resources.company;
    const ads = this.state.ads.map((ad, _i) => {
      return (<a href={ad.goto_uri} style={{marginBottom: "40px"}}><img src={ad.image_uri} alt={"Advertisement" + _i}/></a>)
    });
    return (
        <div id="details-page-contents">
          <MetaTags>
            <title>{company.organization_name}の評価/評判 by datavase.io</title>
            <meta name="description" content={company.description_ja || company.description_en}/>
            <meta name="keywords" content={company.organization_name + ",評判,レビュー,口コミ,良い,悪い,datavase,datavase.io"}/>
            <script className='structured-data-list' type="application/ld+json">{this._structuredData()}</script>
          </MetaTags>
          <h1 style={{display: "none"}}>{this.state.h1}</h1>
          <Col sm={12} md={12} lg={12}>
            <OrganizationAbstractPanel
                organization={company}
                profileImage={company.profile_image_url}
                id={id}
            />
          </Col>
          <div className="ads">
            <Col sm={12} md={8} lg={8} className="ads">
              {ads}
            </Col>
          </div>
          <div className="clearfix">
            <Col sm={12} md={8} lg={8} className="clearfix">
              <TagBtn eventKey="review" onClick={() => window.location.href = `/companies/${this._getId()}/`}
                      active={true}>{lang.review}</TagBtn>
              <TagBtn eventKey="details"
                      onClick={() => window.location.href = `/companies/${this._getId()}/details/`}>{lang.detailTabTitle}</TagBtn>
              <TagBtn eventKey="recruits"
                      onClick={() => window.location.href = `/companies/${this._getId()}/recruits/`}>{lang.recruitsTabTitle}</TagBtn>
            </Col>
            <Col sm={12} md={8} lg={8}>
              {/*<TagWrapper>
                <Tag onClick={() => this._setRestrictTagId(-1)} active={this.state.restrict_tag_id === -1}
                     text="全てを表示"/>
                <Tag onClick={() => this._setRestrictTagId(1)} active={this.state.restrict_tag_id === 1} text="出資者"/>
                <Tag onClick={() => this._setRestrictTagId(2)} active={this.state.restrict_tag_id === 2} text="出資先"/>
                <Tag onClick={() => this._setRestrictTagId(3)} active={this.state.restrict_tag_id === 3} text="ユーザー"/>
                <Tag onClick={() => this._setRestrictTagId(4)} active={this.state.restrict_tag_id === 4} text="パートナー"/>
                <Tag onClick={() => this._setRestrictTagId(5)} active={this.state.restrict_tag_id === 5} text="求職者"/>
                <Tag onClick={() => this._setRestrictTagId(6)} active={this.state.restrict_tag_id === 6} text="チーム"/>
                <Tag onClick={() => this._setRestrictTagId(99)} active={this.state.restrict_tag_id === 99} text="その他"/>
              </TagWrapper>*/}
              <OrganizationReviewListsPanel
                  id={id}
                  resource_uri={"/companies/" + this._getId()}
                  openReviewModal={this._openReviewModal}
                  restrict={this.state.restrict_tag_id}
              />
            </Col>
          </div>
        </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

const TagBtn = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 28.33%;
  margin: 2.5%;
  border: solid 2px ${color.primary};
  border-radius: 8px;
  padding: 8px;
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 12px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  &:checked {
    color: #fff;
    background: ${color.primary};
  }
`;
/*const TagWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  margin: 0 auto 24px;
  ${media.lessThan("medium")`
    grid-template-columns: repeat(4, 1fr);
  `}
`;*/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyReviewPane));
