// @flow
import React from "react";
import styled from "styled-components";
import color from "../styles/colors";

export default function PanelTitleSub({
  panelTitle,
}) {
  return (
    <Wrapper>
      <TitleText>{panelTitle}</TitleText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const TitleText = styled.p`
  padding-left: 12px;
  border-left: 3px solid ${color.primary};
  color: ${color.black54};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;