import * as Auth from '../constants/auth';

const initialState = {
  signupMessages: null,
  loginMessages: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Auth.SIGNUP_RESULT: {
      return Object.assign({}, state, {
        signupMessages: action.messages
      });
    }
    case Auth.LOGIN_RESULT: {
      return Object.assign({}, state, {
        loginMessages: action.messages
      });
    }
    case Auth.LOGOUT_RESULT: {
      return Object.assign({}, state);
    }
    default: {
      return state;
    }
  }
}
