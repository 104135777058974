import React, { Component } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BlockSpinner from './block-spinner';
import ListTable from './list-table';
import UpgradeToAddMoreResourcesToListModal from './upgrade-to-add-more-resources-to-list-modal';
import * as DatavaseApi from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n from '../../i18n/add-to-list-modal.json';
import '../../css/add-to-list-modal.css';

function doesContain(list, resourceType, resourceId) {
  const orgTypes = ['companies', 'investors', 'schools'];
  if(orgTypes.includes(resourceType)) { resourceType = 'organizations'; }
  return list[resourceType].includes(parseInt(resourceId, 10)) ? true : false;
}

class AddToListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingListIds: [],
      showUpgradeToAddMoreResourcesToListModal: false,
    };

    this._onCancelClick = this._onCancelClick.bind(this);
    this._addListToLoading = this._addListToLoading.bind(this);
    this._removeListFromLoading = this._removeListFromLoading.bind(this);
    this._addToList = this._addToList.bind(this);
    this._removeFromList = this._removeFromList.bind(this);
    this._showUpgradeToAddMoreResourcesToListModal = this._showUpgradeToAddMoreResourcesToListModal.bind(this);
    this._hideUpgradeToAddMoreResourcesToListModal = this._hideUpgradeToAddMoreResourcesToListModal.bind(this);
  }

  _showUpgradeToAddMoreResourcesToListModal() {
    this.setState({showUpgradeToAddMoreResourcesToListModal: true});
  }

  _hideUpgradeToAddMoreResourcesToListModal() {
    this.setState({showUpgradeToAddMoreResourcesToListModal: false});
  }


  _addListToLoading(list) {
    const ids = this.state.loadingListIds;
    ids.push(list.id);
    this.setState({loadingListIds: ids});
  }

  _removeListFromLoading(list) {
    const ids = this.state.loadingListIds;
    ids.splice(ids.indexOf(list.id), 1);
    this.setState({loadingListIds: ids});
  }

  _onCancelClick() {
    if(this.props.onCancelClick) {this.props.onCancelClick();}
  }

  _columnNames() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return [lang.listNameHeader, lang.actionHeader];
  }

  _columnWidth() { return [null, '40px']; }

  _addToList(list, resourceType, resourceId) {
    const entriesNum = list.organizations.length+
                       list.people.length+
                       list.products.length+
                       list.events.length;
    if(DatavaseApi.getUserStatus() === 'Free' && entriesNum >= 3) {
      this._showUpgradeToAddMoreResourcesToListModal();
      return;
    }

    const params = {
      list: {
        resource_type: resourceType,
        ids: [resourceId]
      }
    };

    this._addListToLoading(list);
    DatavaseApi.addResourcesToList(list.id, params)
    .then(res => {
      return DatavaseApi.listLists({perPage: 10000});
    })
    .then(res => {
      this.props.sharedResourcesActions.setMylists(res.data);
      this._removeListFromLoading(list);
    })
    .catch(res => {
      this._removeListFromLoading(list);
    })
  }

  _removeFromList(list, resourceType, resourceId) {
    const params = {
      list: {
        resource_type: resourceType,
        ids: [parseInt(resourceId, 10)]
      }
    };

    this._addListToLoading(list);
    DatavaseApi.removeResourcesFromList(list.id, params)
    .then(res => {
      return DatavaseApi.listLists({perPage: 10000});
    })
    .then(res => {
      this.props.sharedResourcesActions.setMylists(res.data);
      this._removeListFromLoading(list);
    })
    .catch(res => {
      this._removeListFromLoading(list);
    });
  }

  _listEntries(lists) {
    const columnNames   = this._columnNames();
    const resourceId    = this.props.resourceId;
    const resourceType  = this.props.resourceType;

    return lists.map((list, i) => {
      let actionButton = (
        <ActionButton
          addToList={this._addToList}
          removeFromList={this._removeFromList}
          loadingListIds={this.state.loadingListIds}
          list={list}
          resourceType={resourceType}
          resourceId={resourceId}
        />
      );

      return {
        [columnNames[0]]: list.list_name,
        [columnNames[1]]: actionButton,
        'id': list.id
      };
    });
  }

  render() {
    const langCode    = DatavaseApi.getLangCode();
    const columnNames = this._columnNames();
    const columnWidth = this._columnWidth();
    const listEntries = this._listEntries(this.props.sharedResources.lists || []);

    return(
      <div>
      <UpgradeToAddMoreResourcesToListModal
        show={this.state.showUpgradeToAddMoreResourcesToListModal}
        onCancelClick={this._hideUpgradeToAddMoreResourcesToListModal}
      />
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{i18n[langCode].addToListModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: '500px', overflowY: 'scroll'}}>
          <ListTable
            condensed
            disableHeaders
            entries={listEntries}
            columnNames={columnNames}
            columnWidth={columnWidth}
            linkColumnName={columnNames[0]}
            resourceName={'lists'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onCancelClick}>
            {i18n[langCode].doneButton}
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToListModal);

class ActionButton extends Component {
  render() {
    const langCode        = DatavaseApi.getLangCode();
    const loadingListIds  = this.props.loadingListIds;
    const list            = this.props.list;
    const resourceType    = this.props.resourceType;
    const resourceId      = this.props.resourceId;
    const addTooltip      = (<Tooltip id="add-to-list-toolip">{i18n[langCode].addTooltip}</Tooltip>);
    const removeTooltip   = (<Tooltip id="remove-from-list-tooltip">{i18n[langCode].removeTooltip}</Tooltip>);

    if(loadingListIds.includes(list.id)) {
      return (
        <BlockSpinner visible={true}/>
      );
    }else if(doesContain(list, resourceType, resourceId)) {
      return (
        <OverlayTrigger overlay={removeTooltip}>
          <Button
            onClick={()=>{this.props.removeFromList(list, resourceType, resourceId);}}
            className="add-button"
          >
            <i className="material-icons remove-from-list-icon">
              remove_circle
            </i>
          </Button>
        </OverlayTrigger>
      );
    }else{
      return (
        <OverlayTrigger
          onClick={()=>{this.props.addToList(list, resourceType, resourceId);}}
          overlay={addTooltip}
        >
          <Button className="add-button">
            <i className="material-icons add-to-list-icon">
              add_circle_outline
            </i>
          </Button>
        </OverlayTrigger>
      );
    }
  }
}
