import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import '../../css/error-page.css'
import store from "store";
import TopNavbar from "../organisms/top-navbar";
import TopNavbarMobile from "../organisms/top-navbar-mobile";
import Sidebar from "../organisms/sidebar";
import Footer from "../organisms/footer";

class ActionDonePage extends Component {
    moving() {
      let goto=null;
      if(this.props.match.params.type === "review") {
          if ((new URL(window.location)).searchParams.get('goto') === undefined) {
              goto="/";
          } else {
              //!!!URIチェック!!!
              let result = (new URL(window.location)).searchParams.get('goto').match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1];
              if(result === "local.datavase.io:3000" || result === "datavase.io" || result === "staging.datavase.io") {
                  goto = (new URL(window.location)).searchParams.get('goto');
              } else {
                  goto = "/";
              }
          }
      } else if(this.props.match.params.type === "upgrade") {
          //Upgrade用
          goto="/account_settings";
      } //サインアップ時は何もしないからこれでOK
      if (goto !== null) {
        setTimeout(function(){window.location.href = goto},3000);
      }
    }
    _currentPathCategory() {
        const path = window.location.pathname;
        if (path.includes('companies')) {
            return 'companies';
        } else if (path.includes('investors')) {
            return 'investors';
        } else if (path.includes('schools')) {
            return 'schools';
        } else if (path.includes('people')) {
            return 'people';
        } else if (path.includes('events')) {
            return 'events';
        } else if (path.includes('lists')) {
            return 'lists';
        } else if (path.includes('articles')) {
            return 'articles';
        } else if (path.includes('news')) {
            return 'news';
        } else if (path.includes('mypage')) {
            return 'mypage';
        } else {
            return 'dashboard';
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            sidebarVisible: true,
        }

        if(!store.get('langCode')) {
            const lang = (new URL(window.location)).searchParams.get('lang') || 'ja';
            store.set('langCode', lang);
        }

        this._hideSidebar = this._hideSidebar.bind(this);
        this._showSidebar = this._showSidebar.bind(this);
        this._toggleSidebarVisibility = this._toggleSidebarVisibility.bind(this);
    }

    _hideSidebar() {
        this.setState({sidebarVisible: false});
    }

    _showSidebar() {
        this.setState({sidebarVisible: true});
    }

    _toggleSidebarVisibility() {
        if(!this.state.sidebarVisible) {
            document.querySelector("#top-navbar > nav > div > div > button").setAttribute('class', 'navbar-toggle collapsed clicked');
            document.querySelector("#top-navbar > nav > div > div > button").innerHTML = '<span class="sr-only">Toggle navigation</span><span><i class="material-icons" style="margin-top: 1.5px;">close</i></span>';
        } else {
            document.querySelector("#top-navbar > nav > div > div > button").setAttribute('class', 'navbar-toggle collapsed');
            document.querySelector("#top-navbar > nav > div > div > button").innerHTML = '<span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span>';
        }
        this.setState({sidebarVisible: !this.state.sidebarVisible});
    }

    render() {
      const category = this._currentPathCategory();
      if(store.get("surpriseTheme")) {
          document.getElementsByTagName('body')[0].setAttribute('class', 'theme_surprise');
      } else if(store.get("newTheme")) {
          document.getElementsByTagName('body')[0].setAttribute('class', 'theme1');
      }
    return(
        <div>
            <TopNavbar/>
            <TopNavbarMobile
                pathCategory={category}
                onMobileNavbarUnmounted={this._showSidebar}
                onMobileNavbarMounted={this._hideSidebar}
                onSidebarVisibilityToggled={this._toggleSidebarVisibility}
            />
            <Sidebar
                pathCategory={category}
                visible={this.state.sidebarVisible}
            />
            <div id="content-panel">
                <div id="error-jumbotron">
                    <h1 id="error-heading">Congratulations!</h1>
                    {this.props.match.params.type === "signup"?
                        <p id="error-text">
                            ご登録のメールアドレスに確認メールが送信されました。メールのリンクをクリックして登録を完了してください。
                        </p>:null}
                    <p id="error-text">{this.props.match.params.type === "review" ? "クチコミ投稿が完了しました。リダイレクトまでお待ちください。":this.props.match.params.type === "upgrade"?"アップグレードが完了しました。リダイレクトまでお待ちください。":null}</p>
                </div>
                <div id="error-footer">
                    <Link to="/">datavase.io</Link>
                </div>
                {this.moving()}
            </div>
            <Footer/>
        </div>
    );
  }
}

export default withRouter(ActionDonePage);
