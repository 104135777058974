import React, {Component} from 'react';
import {Button, Col, Panel, Row, Tab, Tabs} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/account-settings-pane.json';
import AccountDetailsPanel from './partials/account-details-panel';
import UserAccountStatusPanel from './partials/user-account-status-panel';
import UpgradeToAddIntegrationModal from './partials/upgrade-to-add-integration-modal';
import BlockSpinner from './partials/block-spinner';
import chatwork_logo from '../assets/chatwork_logo.png';
import slack_logo from '../assets/slack_logo.png';
import '../css/account-settings-pane.css';

class AccountSettingsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatworkConsentPageUrl: null,
      loadingChatWork: false,
      slackConsentPageUrl: null,
      loadingSlack: false,
      hasLoaded: false,
      oauthStatus: {},
      showUpgradeToAddIntegrationModal: false,
    };
    this._showUpgradeToAddIntegrationModal = this._showUpgradeToAddIntegrationModal.bind(this);
    this._hideUpgradeToAddIntegrationModal = this._hideUpgradeToAddIntegrationModal.bind(this);
    this._onChatworkOAuthInitialize        = this._onChatworkOAuthInitialize.bind(this);
    this._onChatworkOAuthRevoke            = this._onChatworkOAuthRevoke.bind(this);
    this._onSlackOAuthInitialize           = this._onSlackOAuthInitialize.bind(this);
    this._onSlackOAuthRevoke               = this._onSlackOAuthRevoke.bind(this);
  }

  componentDidMount() {
    this._fetchAccountSettings();
  }

  _showUpgradeToAddIntegrationModal() {
    this.setState({showUpgradeToAddIntegrationModal: true});
  }

  _hideUpgradeToAddIntegrationModal() {
    this.setState({showUpgradeToAddIntegrationModal: false});
  }

  _fetchAccountSettings() {
    DatavaseApi.fetchOAuthConnectionStatus()
    .then(res => {
      this.setState({
        hasLoaded: true,
        oauthStatus: res.data,
      });
      this.props.sharedResourcesActions.setOAuthConnectionStatus(res.data);
    })
    .catch(res => {
      console.error(res);
    });
  }

  _onChatworkOAuthInitialize() {
      if (DatavaseApi.getUserStatus() === 'Free' || DatavaseApi.getUserStatus() === 'General' || DatavaseApi.getUserStatus() === "Startup") {
      this._showUpgradeToAddIntegrationModal();
      return;
    }

    this.setState({loadingChatWork: true});
    DatavaseApi.initializeChatWorkOAuth()
    .then(res => {
      this.setState({
        loadingChatWork: false,
        chatworkConsentPageUrl: res.data.consent_page
      });
    })
    .catch(res => {
      console.error(res);
    });
  }

  _onChatworkOAuthRevoke() {
    this.setState({loadingChatWork: true});
    DatavaseApi.revokeChatWorkOAuth()
    .then(res => {
      this.setState({
        loadingChatWork: false,
        oauthStatus: res.data,
      });
      this.props.sharedResourcesActions.setOAuthConnectionStatus(res.data);
    })
    .catch(res => {
      console.error(res);
    });
  }

  _onSlackOAuthInitialize() {
      if (DatavaseApi.getUserStatus() === 'Free' || DatavaseApi.getUserStatus() === 'General') {
      this._showUpgradeToAddIntegrationModal();
      return;
    }

    this.setState({loadingSlack: true});
    DatavaseApi.initializeSlackOAuth()
    .then(res => {
      this.setState({
        loadingSlack: false,
        slackConsentPageUrl: res.data.consent_page
      });
    })
    .catch(res => {
      console.error(res);
    });
  }

  _onSlackOAuthRevoke() {
    this.setState({loadingSlack: true});
    DatavaseApi.revokeSlackOAuth()
    .then(res => {
      this.setState({
        loadingSlack: false,
        oauthStatus: res.data,
      });
      this.props.sharedResourcesActions.setOAuthConnectionStatus(res.data);
    })
    .catch(res => {
      console.error(res);
    });
  }

  _integrationSettingComponents(serviceName, serviceLogo, onOauthInitialize, onOauthRevoke, consentPageUrl, hasConnected, isProcessing) {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const connectionStatusLabel = hasConnected ? lang.connectedLabel   : lang.notConfiguredLabel;
    const connectionButtonLabel = hasConnected ? lang.disconnectButton : lang.connectButton;
      const connectionButtonStyle = hasConnected ? "danger" : "primary";
    const onClick               = hasConnected ? onOauthRevoke : onOauthInitialize;

    return(
      <Row className="integration-service-row">
        <Col xs={3} lg={1}>
          <img alt={serviceName} className="integration-logo" src={serviceLogo}/>
        </Col>
        <Col xs={9} lg={11}>
          <span className="integration-service-name">{serviceName}: {connectionStatusLabel}</span>
          {
            isProcessing ?
              <div style={{display: 'inline-block'}}><BlockSpinner visible/></div>
            :
              consentPageUrl ?
                <a href={consentPageUrl}>
                  <Button bsStyle="success" bsSize="small">
                    {lang.clickAgainButton}
                  </Button>
                </a>
              :
                <Button onClick={onClick} bsStyle={connectionButtonStyle} bsSize="small">
                  {connectionButtonLabel}
                </Button>
          }
        </Col>
      </Row>
    );
  }

  render() {
    if(!this.state.hasLoaded) {
      return(<BlockSpinner visible={true}/>);
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const oauthStatus = this.state.oauthStatus;

    const chatworkIntegrationSetting = this._integrationSettingComponents(
      'Chatwork', chatwork_logo,
      this._onChatworkOAuthInitialize, this._onChatworkOAuthRevoke,
      this.state.chatworkConsentPageUrl, oauthStatus.chatwork,
      this.state.loadingChatWork
    );

    const slackIntegrationSetting = this._integrationSettingComponents(
      'Slack', slack_logo,
      this._onSlackOAuthInitialize, this._onSlackOAuthRevoke,
      this.state.slackConsentPageUrl, oauthStatus.slack,
      this.state.loadingSlack
    );

    return(
      <div>
        <UpgradeToAddIntegrationModal
          show={this.state.showUpgradeToAddIntegrationModal}
          onCancelClick={this._hideUpgradeToAddIntegrationModal}
        />
        <div id="account-settings-page-contents" style={{maxWidth: '1200px', margin: 'auto'}} className="clearfix">
          <Col sm={12} md={12} lg={12}>
            <Panel id="integration-panel">
              <Tabs id="integration-tabs">
                <Tab eventKey={1} title={lang.integrationTitle}>
                  <Panel>
                    <span>{lang.integrationText}</span>
                    {chatworkIntegrationSetting}
                    {slackIntegrationSetting}
                  </Panel>
                </Tab>
              </Tabs>
            </Panel>
            <AccountDetailsPanel />
            <UserAccountStatusPanel />
          </Col>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsPane);
