import React, {Component} from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as DatavaseApi from './datavase-api';
import * as stateCacheActions from '../actions/state-cache';
import UpgradeToProceedModalRevised from "./partials/upgrade-to-proceed-modal-revised";
import PersonReviewDetails from "./partials/person-review-details";
import {withRouter} from "react-router";

const SEARCH_VIEW = 'SEARCH_VIEW';
const ADVANCED_SEARCH = 'ADVANCED_SEARCH';

class PersonReviewDetailsPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false,
            componentToDisplay: SEARCH_VIEW,
            showUpgradeToProceedModal: false,
        };
        this._onAdvancedSearchClicked = this._onAdvancedSearchClicked.bind(this);
        this._onColumnViewClicked = this._onColumnViewClicked.bind(this);
        this._showUpgradeToProceedModal = this._showUpgradeToProceedModal.bind(this);
        this._hideUpgradeToProceedModal = this._hideUpgradeToProceedModal.bind(this);
    }

    _showUpgradeToProceedModal() {
        this.setState({showUpgradeToProceedModal: true});
    }

    _hideUpgradeToProceedModal() {
        this.setState({showUpgradeToProceedModal: false});
    }

    componentDidMount() {
        const cachedState = this.props.stateCache.investorsPaneState;
        if (cachedState) {
            this.setState({
                componentToDisplay: cachedState.componentToDisplay
            });
        }
        DatavaseApi.fetchPersonWithRelatedResources(this.props.match.params.id)
            .then(res => {
                this.setState({
                    hasLoaded: true,
                    id: res.data.person.id,
                })
            }).catch(res => {
            console.error(res);
        });
    }

    componentWillUnmount() {
        this.props.stateCacheActions.setInvestorsPaneState(this.state);
    }

    _onAdvancedSearchClicked() {
        let userStatus = DatavaseApi.getUserStatus();
        if (userStatus === 'Free') {
            this._showUpgradeToProceedModal();
            return;
        }

        this.setState({componentToDisplay: ADVANCED_SEARCH});
    }

    _onColumnViewClicked() {
        this.setState({componentToDisplay: SEARCH_VIEW});
    }

    render() {
        let viewToRender;
        if (this.state.componentToDisplay === SEARCH_VIEW && this.state.hasLoaded) {
            viewToRender = (
                <PersonReviewDetails id={this.state.id} review_id={this.props.match.params.review_id}/>
            );
        }

        return (
            <div>
                {viewToRender}
                <UpgradeToProceedModalRevised
                    show={this.state._showUpgradeToProceedModal}
                    onCancelClick={this._hideUpgradeToProceedModal}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        stateCache: state.stateCache
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stateCacheActions: bindActionCreators(stateCacheActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PersonReviewDetailsPane));
