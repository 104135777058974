import { combineReducers } from 'redux'
import auth from './reducers/auth'
import stateCache from './reducers/state-cache';
import sharedResources from './reducers/shared-resources';

const rootReducer = combineReducers({
  auth,
  stateCache,
  sharedResources,
});

export default rootReducer;
