// 記事パネル
import React, {Component} from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

import color from '../styles/colors.js';
import * as DatavaseApi from '../datavase-api';

import AccessRankingCard from '../molecules/access-ranking-card.js';

export default class AccessRankingPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rankings: {},
    };

    this._fetchRanking = this._fetchRanking.bind(this);
    this._renderCards = this._renderCards.bind(this);

    this._fetchRanking();
  }

  async _fetchRanking() {
    const mode = this.props.mode;
    const res = await DatavaseApi.getHotRank(mode);

    this.setState({
      rankings: res.data,
    });
  }

  _renderCards() {
    const results = [];
    for (let [i, rank] of Object.entries(this.state.rankings)) {
      results.push(
        <AccessRankingCard
            id={rank.slug}
            rank={i}
            title={rank.organization_name}
            image={rank.thumb_profile_image_url}
            review={rank.average_rating}
            num={rank.reviewCount}
            resource={rank.resource_type_name}
            key={rank.slug}
        />
      );
    }
    return results;
  }

  render() {
    return (
      <Wrapper>
        <PanelTitle>
          {this.props.panelTitle}
          <Br/>
          {this.props.panelTitleBottom}
        </PanelTitle>
        <Panel>
          {this.props.mode === 'investors'? <a href='https://lin.ee/Ooro7TF'><img src='/fill_idx_ranking.jpg' alt='Click here to see ranking'/></a>: this._renderCards()}
        </Panel>
      </Wrapper>
    );
  }
}


const Wrapper = styled.div`
`;

const PanelTitle = styled.p`
  margin-bottom: 20px;
  color: ${color.black87};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  background: #FFF;
  border-radius: 8px;
  padding: 20px 12px;
  ${media.lessThan("medium")`
    flex-direction: column;
    padding: 16px 8px;
    height: auto;
  `}
`;

const Br = styled.br`
  display: none;
  ${media.lessThan("medium")`
    display: block;
  `}
`;
