import React, { Component } from 'react';

import * as c3 from 'c3';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/contribution-stats-pie-chart.json';

export default class ContributionsStatsPieChart extends Component {
  componentDidMount() {
    if (this.props.contributor) {
      this._updateGraph(this.props.contributor);
    }
  }

  _pieChartProperties(contributor) {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return {
      bindto: `#${this.props.chartName}`,
      data: {
        columns: [
          [lang.praiseCount, contributor.praise],
          [lang.blameCount, contributor.soft_blame],
        ],
        type: 'pie'
      },
      size: {
        height: this.props.height,
        width: this.props.width,
      }
    };
  }

  _updateGraph(contributor) {
    const chartProperties = this._pieChartProperties(contributor);
    c3.generate(chartProperties);
  }

  render() {
    return (
      <div style={{ display: 'inline-block' }} id={this.props.chartName}></div>
    );
  }
}
