import React, { Component } from 'react';
import { Modal, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

import BlockSpinner from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/create-list-modal.json';

export default class CreateListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {
        list_name: '',
        description: '',
      },
      hasLoaded: true,
    };
    this._onChange = this._onChange.bind(this);
    this._onCancelClick = this._onCancelClick.bind(this);
    this._onCreateClick = this._onCreateClick.bind(this);
  }

  _onChange(e) {
    const newList = Object.assign(
      this.state.list,
      {[e.target.name]: e.target.value}
    );
    this.setState({list: newList});
  }

  _onCancelClick() {
    if(this.props.onCancelClick) { this.props.onCancelClick(); }
  }

  _onCreateClick() {
    if(this.props.onCreateClick) { this.props.onCreateClick(); }

    this.setState({hasLoaded: false});
    DatavaseApi.createList(this.state.list)
    .then(res => {
      if(this.props.onSuccess) { this.props.onSuccess(res); }
      this.setState({hasLoaded: true});
    })
    .catch(res => {
      if(this.props.onFailure) { this.props.onFailure(res); }
      this.setState({hasLoaded: true});
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.createNewListModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>{lang.listNameLabel}</ControlLabel>
            <FormControl
              name="list_name"
              placeholder={lang.listNamePlaceholder}
              onChange={this._onChange}
            />
            <ControlLabel>{lang.descriptionLabel}</ControlLabel>
            <FormControl
              name="description"
              placeholder={lang.descriptionPlaceholder}
              componentClass="textarea"
              onChange={this._onChange}
            />
          </FormGroup>
          <BlockSpinner visible={!this.state.hasLoaded}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{backgroundColor: '#fff'}}
            onClick={this._onCancelClick}
          >
            {lang.cancelButton}
          </Button>
          <Button
            bsStyle="primary"
            onClick={this._onCreateClick}
          >
            {lang.createButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
