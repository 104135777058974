import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/upgrade-to-add-more-resources-to-list-modal.json';

export default class UpgradeToAddMoreResourcesToListModal extends Component {
    constructor(props) {
        super(props);
        this._onCancelClick = this._onCancelClick.bind(this);
    }

  _onCancelClick() {
    if(this.props.onCancelClick) { this.props.onCancelClick(); }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.upgradeToAddMoreResourcesToListModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.upgradeToAddMoreResourcesToListModalBody}
        </Modal.Body>
        <Modal.Footer>
            <a href="/upgrade/" target="_blank" rel="noopener noreferrer">
                <Button bsStyle="primary">
                    {lang.upgradeButton}
                </Button>
            </a>
            <Button
                onClick={this._onCancelClick}
            >
                {lang.cancelButton}
            </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
