import React, {Component} from 'react'
import {Col, Image, Panel} from 'react-bootstrap'
import ReviewStarPanel from './review-star-panel'

import placeholder from '../../assets/datavase_logo_icon_grey.png';
import '../../css/abstract-panel.css';

export default class AbstractPanel extends Component {
  render() {
    const {
      abstractTitle,
      subHeading,
      abstractDescription,
      profileImage,
      averageReviews
    } = this.props;

    return (
      <Panel>
        <div id="abstract-panel-title-container">
          <Col
            xs={4} sm={2} md={2} lg={2}
            id="abstract-panel-picture-col"
          >
            <Image
              responsive
              src={profileImage || placeholder}
              alt={abstractTitle}
              id="abstract-panel-picture"
            />
          </Col>
          <Col xs={8} sm={10} md={10} lg={10}>
            <h1 id="abstract-panel-title">
              {abstractTitle}
            </h1>
            <div>
              {subHeading}
            </div>
            {
              this.props.loadingReviews || this.props.hideReviews ?
              null
              :
              <ReviewStarPanel
                rating={averageReviews}
              />
            }
          </Col>
        </div>
          <p id="abstract-description" style={{lineHeight: "1.6"}}>
          {abstractDescription}
        </p>
      </Panel>
    );
  }
}
