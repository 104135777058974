import React, { Component } from 'react';
import * as c3 from 'c3';
import * as d3 from 'd3';
import * as DatavaseApi from '../datavase-api';
import svgz from 'svg-z-order';
import '../../css/c3.css';

import { abbrPrice } from '../datavase-api';

function calcMinMaxDate(dates) {
  const f = new Date(Math.min.apply(null, dates.map(d => new Date(d))));
  const l = new Date(Math.max.apply(null, dates.map(d => new Date(d))));
  const elapsed = (l - f) / (1000 * 60 * 60 * 24);
  const minDate = new Date(f);
  const maxDate = new Date(l);
  minDate.setDate(minDate.getDate() - Math.floor(elapsed/10));
  maxDate.setDate(maxDate.getDate() + Math.floor(elapsed/10));
  return [minDate, maxDate];
}

function calcRaduisCoefficient(amounts, radiusMax=90) {
  let max = Math.max.apply(null, amounts);
  if (max === 0) { max = 1; }
  const coefficient = radiusMax / Math.cbrt(max);
  return coefficient;
}

function lerp(max=0.8, min=0.2, values) {
  const maxValue = Math.max.apply(null, values);
  const minValue = Math.min.apply(null, values);
  const valueRange = maxValue - minValue;
  const range = max - min;
  return range / valueRange;
}

function sortDataByDate(data) {
  const date = data.date.map((d, i) => {
    return { date: d, index: i };
  });
  date.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const sortedDate      = date.map(d => d.date);
  const sortedAmount    = date.map(d => data.amount[d.index]);
  const sortedCurrency  = date.map(d => data.currency[d.index]);
  const sortedFundingType = date.map(d => data.fundingType[d.index]);
  return {
    date:     sortedDate,
    amount:   sortedAmount,
    currency: sortedCurrency,
    fundingType: sortedFundingType
  };
}

function constructGraphData(fundingRounds, currencyCode) {
  const fundings = {
    date:        fundingRounds.map(fundingRound => fundingRound.date_of_investment),
    amount:      fundingRounds.map(fundingRound => {
      return DatavaseApi.convertCurrency(
        fundingRound.amount, fundingRound.currency_code, currencyCode
      );
    }),
    currency:    fundingRounds.map(fundingRound => fundingRound.currency_code),
    fundingType: fundingRounds.map(fundingRound => fundingRound.funding_type_name)
  };

  return fundings;
}

export default class FundingRoundsHistoryGraph extends Component {
  componentDidMount() {
    this._updateGraph();
  }

  componentDidUpdate() {
    this._updateGraph();
  }

  _scatterPlotProperties() {
    const displayedCurrency   = this.props.displayedCurrency || 'USD';
    const fundingRounds       = this.props.fundingRounds.sort(
      (a,b) => a.date_of_investment > b.date_of_investment
    );
    const fundingData         = constructGraphData(fundingRounds, displayedCurrency);
    const data                = sortDataByDate(fundingData);
    const [minDate, maxDate]  = calcMinMaxDate(data.date);
    const coefficient         = calcRaduisCoefficient(data.amount);

    return {
      bindto: `#${this.props.graphName}`,
      data: {
        xs: { amount: 'date' },
        columns: [
          ['date'].concat(data.date),
          ['amount'].concat(Array(data.date.length).fill(0))
        ],
        type: 'scatter'
      },
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          min: minDate,
          max: maxDate,
          tick: {
            culling: {
              max: 5
            },
            format: '%Y/%m/%d'
          }
        },
        y: {
          show: false,
          min: -10,
          max: 10
        }
      },
      grid: {
        y: {
          lines: [ {value: 0} ]
        },
      },
      point: {
        r: (d) => { return Math.cbrt(data.amount[d.index]) * coefficient; }
      },
      tooltip: {
        format: {
          title: () => 'Raised Amount',
          value: (value, ratio, id, i) => { return abbrPrice(fundingRounds[i].amount, fundingRounds[i].currency_code) }
        }
      },
      legend: { hide: true },
    };
  }

  _updateGraph() {
    const displayedCurrency = this.props.displayedCurrency || 'USD';
    const fundingRounds     = this.props.fundingRounds.sort((a,b) => a.date_of_investment > b.date_of_investment);
    const fundingData       = constructGraphData(fundingRounds, displayedCurrency);
    const data              = sortDataByDate(fundingData);
    const coefficient       = calcRaduisCoefficient(data.amount);
    const chart             = c3.generate(this._scatterPlotProperties());
    const series            = chart.internal.main.selectAll('.' + c3.chart.internal.fn.CLASS.circles)[0];
    const texts             = chart.internal.main.selectAll('.' + c3.chart.internal.fn.CLASS.chartTexts)
                                       .selectAll('.' + c3.chart.internal.fn.CLASS.chartText)[0]

    let fontSize = '11px';
    if(window.matchMedia('(max-width: 767px)').matches) {
      fontSize = '12px';
    }
    let color = '#555';
    if(window.matchMedia('(max-width: 767px)').matches) {
      color = '#333';
    }
    let fundingTypeFontSize = '11px';
    if(window.matchMedia('(max-width: 767px)').matches) {
      fundingTypeFontSize = '10px';
    }

    const points = d3.select(series[0]).selectAll('.' + c3.chart.internal.fn.CLASS.circle)[0];

    if(points) {
      points.forEach((point, i) => {
        d3.select(texts[0])
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', `-${Math.cbrt(data.amount[i]) * coefficient + 10}`)
          .attr('x', d3.select(point).attr('cx'))
          .attr('y', d3.select(point).attr('cy'))
          .style('font-size', fontSize)
          .style('fill', color)
          .text(abbrPrice(fundingRounds[i].amount, fundingRounds[i].currency_code, 'Undisclosed'))
      });
    }

    if(points) {
      points.forEach((point, i) => {
        d3.select(texts[0])
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', `${Math.cbrt(data.amount[i]) * coefficient + 10}`)
          .attr('x', d3.select(point).attr('cx'))
          .attr('y', d3.select(point).attr('cy'))
          .style('font-size', fundingTypeFontSize)
          .style('fill', color)
          .text(data.fundingType[i])
      });
    }

    if(points) {
      const coeff = lerp(0.85, 0.3, data.amount);
      points.forEach((point, i) => {
        d3.select(point).style('opacity', 0.3 + data.amount[i]*coeff)
      });
    }

    const gridLines = document.getElementsByClassName('c3-grid-lines');
    for(let i = 0; i < gridLines.length; i++){
      svgz.element(gridLines[i]).toBottom();
    }
  }

  render() {
    return(
      <div id={this.props.graphName}></div>
    );
  }
}
