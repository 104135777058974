import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Panel, Tab, Tabs } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router-dom';

import BlockSpinner from './partials/block-spinner';
import ListTable from './partials/list-table';
import ChronologicalFeedPanels from './partials/chronological-feed-panels';
import UpgradeToAddMoreResourcesToListModal from './partials/upgrade-to-add-more-resources-to-list-modal';
import NotificationChannelsConfigurationModal from './partials/notification-channels-configuration-modal';
import UpgradeToAddIntegrationModal from './partials/upgrade-to-add-integration-modal';

import placeholder from '../assets/datavase_logo_icon_grey.png';
import chatwork_logo from '../assets/chatwork_logo.png';
import slack_logo from '../assets/slack_logo.png';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/list-details-pane.json';
import '../css/list-details-pane.css';

function getSuggestionValue(suggestion) {
  const langCode = DatavaseApi.getLangCode();
  const type = suggestion.resource_type_name;
  let name;
  if (['companies', 'investors', 'schools'].includes(type)) {
    name = DatavaseApi.extractOrganizationName(suggestion, langCode);
  } else if (type === 'people') {
    name = DatavaseApi.extractAndFormatPersonName(suggestion, langCode);
  } else if (type === 'events') {
    name = DatavaseApi.extractEventName(suggestion, langCode);
  }

  return name;
}

function renderSuggestion(suggestion) {
  const langCode = DatavaseApi.getLangCode();
  const type = suggestion.resource_type_name;
  let name;
  if (['companies', 'investors', 'schools'].includes(type)) {
    name = DatavaseApi.extractOrganizationName(suggestion, langCode);
  } else if (type === 'people') {
    name = DatavaseApi.extractAndFormatPersonName(suggestion, langCode);
  } else if (type === 'events') {
    name = DatavaseApi.extractEventName(suggestion, langCode);
  }
  return (
    <span className="suggestion-to-add-to-list">
      <img
        alt={name}
        src={suggestion.thumb_profile_image_url || placeholder}
      />{name}
    </span>
  );
}

function renderSectionTitle(section) {
  return (
    <strong>{section.title}</strong>
  );
}

function getSectionSuggestions(section) {
  return section.entities;
}

const autosuggestTheme = {
  input: {
    width: '100%',
    height: '34px',
    padding: '6px 12px',
    marginBottom: '20px',
    fontSize: '14px',
    lineHeight: 1.42857143,
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
  }
};

const colors = [
  '#c0392b',
  '#d35400',
  '#f39c12',
  '#F1D53B',
  '#27ae60',
  '#16a085',
  '#2980b9',
  '#2c3e50',
  '#8e44ad',
];

class ListDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: '',
      suggestions: [],
      prefixSearchTimeoutId: null,
      isLoadingDigests: false,
      isSearching: false,
      isModifyingList: false,
      hasLoaded: false,
      showUpgradeToAddIntegrationModal: false,
      showNotificationSettingModal: false,
      showConfirmationModal: false,
      showRenameModal: false,
      showUpgradeToAddMoreResourcesToListModal: false
    };

    this._showUpgradeToAddMoreResourcesToListModal = this._showUpgradeToAddMoreResourcesToListModal.bind(this);
    this._showUpgradeToAddIntegrationModal = this._showUpgradeToAddIntegrationModal.bind(this);
    this._showNofiticationSettingModal = this._showNofiticationSettingModal.bind(this);
    this._showDeleteConfirmationModal = this._showDeleteConfirmationModal.bind(this);
    this._showRenameModal = this._showRenameModal.bind(this);

    this._hideUpgradeToAddMoreResourcesToListModal = this._hideUpgradeToAddMoreResourcesToListModal.bind(this);
    this._hideUpgradeToAddIntegrationModal = this._hideUpgradeToAddIntegrationModal.bind(this);
    this._hideNotificationSettingModal = this._hideNotificationSettingModal.bind(this);
    this._hideConfirmationModal = this._hideConfirmationModal.bind(this);
    this._hideRenameModal = this._hideRenameModal.bind(this);

    this._onAddNotificationSettingsClicked = this._onAddNotificationSettingsClicked.bind(this);
    this._deleteList = this._deleteList.bind(this);
    this._renameList = this._renameList.bind(this);
    this._loadListFullResources = this._loadListFullResources.bind(this);
    this._loadListChronologicalDigests = this._loadListChronologicalDigests.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    this._loadListFullResources(id);
    this._loadListChronologicalDigests(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this._getId();
    if (nextProps.match.params.id !== id) {
      this.setState({
        formValue: '',
        suggestions: [],
        hasLoaded: false,
        showUpgradeToAddIntegrationModal: false,
        showConfirmationModal: false,
        showRenameModal: false,
      });
      this._loadListFullResources(nextProps.match.params.id);
      this._loadListChronologicalDigests(nextProps.match.params.id);
    }
  }

  _loadSuggestions(val) {
    this.setState({ isSearching: true });
    DatavaseApi.crossModelSearch(val, 5)
      .then(res => {
        let entities = [];
        for (var key in res.data) {
          if (res.data[key].length !== 0) {
            entities.push({
              title: key.charAt(0).toUpperCase() + key.slice(1),
              entities: res.data[key],
            });
          }
        }
        this.setState({
          isLoading: true,
          suggestions: entities,
        });
      })
      .catch(res => {
        this.setState({ isLoading: false });
        console.error(res);
      });
  }

  _onSuggestionsFetchRequested({ value }) {
    if (this.state.prefixSearchTimeoutId !== null) {
      clearTimeout(this.state.prefixSearchTimeoutId);
    }
    const timeoutId = setTimeout(() => {
      this._loadSuggestions(value);
    }, 300);
    this.setState({ prefixSearchTimeoutId: timeoutId });
  }

  _onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  _onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    const resourceType = suggestion.resource_type_name;
    const id = suggestion.id;
    this._addResourceToList(resourceType, id);
  }

  _onChangeForm(e, { newValue }) {
    this.setState({ formValue: newValue });
  }

  _addResourceToList(resourceType, resourceId) {
    const resourcesNum = this.state.companyEntries.length +
      this.state.investorEntries.length +
      this.state.schoolEntries.length +
      this.state.personEntries.length +
      this.state.eventEntries.length;
    if (DatavaseApi.getUserStatus() === 'Free' && resourcesNum >= 3) {
      this._showUpgradeToAddMoreResourcesToListModal();
      return;
    }

    const params = {
      list: {
        resource_type: resourceType,
        ids: [resourceId]
      }
    };
    const id = this._getId();

    this.setState({ isModifyingList: true });
    DatavaseApi.addResourcesToList(id, params)
      .then(res => {
        this.setState({
          formValue: '',
          isModifyingList: false
        });
        this._loadListFullResources(id);
        this._loadListChronologicalDigests(id);
        return DatavaseApi.listLists({ perPage: 10000 });
      })
      .then(res => {
        this.props.sharedResourcesActions.setMylists(res.data);
      })
      .catch(res => {
        this.setState({ isModifyingList: false });
        console.error(res);
      });
  }

  _removeResourcesFromList(resourceType, resourceId) {
    const params = {
      list: {
        resource_type: resourceType,
        ids: [resourceId]
      }
    };
    const id = this._getId();

    this.setState({ isModifyingList: true });
    DatavaseApi.removeResourcesFromList(id, params)
      .then(res => {
        this.setState({
          isModifyingList: false
        });
        this._loadListFullResources(id);
        this._loadListChronologicalDigests(id);
        return DatavaseApi.listLists({ perPage: 10000 });
      })
      .then(res => {
        this.props.sharedResourcesActions.setMylists(res.data);
      })
      .catch(res => {
        this.setState({ isModifyingList: false });
        console.error(res);
      });
  }

  _loadListFullResources(id) {
    DatavaseApi.fetchListWithRelatedResources(id)
      .then((res) => {
        const resources = res.data;
        const companyEntries = this._organizationEntries(resources.organizations, 'companies');
        const investorEntries = this._organizationEntries(resources.organizations, 'investors');
        const schoolEntries = this._organizationEntries(resources.organizations, 'schools');
        const personEntries = this._personEntries(resources.people);
        const eventEntries = this._eventEntries(resources.events);
        this.setState({
          list: resources.list,
          companyEntries: companyEntries,
          investorEntries: investorEntries,
          schoolEntries: schoolEntries,
          personEntries: personEntries,
          eventEntries: eventEntries,
          hasLoaded: true,
        });
      }).catch((res) => {
        console.error(res);
      });
  }

  _loadListChronologicalDigests(id) {
    this.setState({
      isLoadingDigests: true,
      chronologicalFeed: [],
    });
    DatavaseApi.fetchListChronologicalFeed(id)
      .then(res => {
        this.setState({
          isLoadingDigests: false,
          chronologicalFeed: res.data
        });
      });
  }

  _showUpgradeToAddIntegrationModal() { this.setState({ showUpgradeToAddIntegrationModal: true }); }

  _hideUpgradeToAddIntegrationModal() { this.setState({ showUpgradeToAddIntegrationModal: false }); }

  _showUpgradeToAddMoreResourcesToListModal() { this.setState({ showUpgradeToAddMoreResourcesToListModal: true }); }

  _hideUpgradeToAddMoreResourcesToListModal() { this.setState({ showUpgradeToAddMoreResourcesToListModal: false }); }

  _showNofiticationSettingModal() { this.setState({ showNotificationSettingModal: true }); }

  _hideNotificationSettingModal() { this.setState({ showNotificationSettingModal: false }); }

  _showDeleteConfirmationModal() { this.setState({ showConfirmationModal: true }); }

  _hideConfirmationModal() { this.setState({ showConfirmationModal: false }); }

  _showRenameModal() { this.setState({ showRenameModal: true }); }

  _hideRenameModal() { this.setState({ showRenameModal: false }); }

  _onAddNotificationSettingsClicked() {
    if (DatavaseApi.getUserStatus() === 'Free') {
      this._showUpgradeToAddIntegrationModal();
    } else {
      this._showNofiticationSettingModal();
    }
  }

  _deleteList() {
    const id = this._getId();
    DatavaseApi.deleteList(id)
      .then(_ => {
        window.location.href = '/mylists';
      });
  }

  _renameList(newList) {
    const id = this._getId();
    DatavaseApi.updateList(id, newList)
      .then(res => {
        const newList = res.data;
        const newListId = newList.id;
        const newLists = this.props.sharedResources.lists.map(list => {
          if (list.id !== newListId) {
            return list;
          } else {
            list.list_name = newList.list_name;
            list.description = newList.description;
            return list;
          }
        });
        this.props.sharedResourcesActions.setMylists(newLists);
        this.setState({
          list: res.data,
          showRenameModal: false
        });
      })
      .catch(res => {
        console.error(res);
      })
  }

  _removeResourcesFromListButton(resourceType, id) {
    const onClick = () => {
      this._removeResourcesFromList(resourceType, id);
    };
    return (
      <Button className="remove-resource-from-list-button" onClick={onClick}>
        <i className="material-icons">
          remove_circle_outline</i>
      </Button>
    );
  }

  _organizationColumnNames() {
    const langCode = DatavaseApi.getLangCode();
    return [
      i18n[langCode].organizationLogoColumn,
      i18n[langCode].organizationNameColumn,
      'actionButton'
    ];
  }

  _organizationColumnWidth() {
    return ['50px', null, '20px'];
  }

  _organizationEntries(organizations, resourceType) {
    const langCode = DatavaseApi.getLangCode();
    const organizationColumnNames = this._organizationColumnNames();
    const entities = organizations.filter(org => org.resource_type_name === resourceType);

    return entities.map(org => {
      let name = org.organization_name;
      if (org.native_organization_name &&
        langCode === 'ja' &&
        org.native_language === 'ja') {
        name = org.native_organization_name;
      }
      let profileImage = org.thumb_profile_image_url || org.profile_image_url;
      let id = org.id;

      return {
        [organizationColumnNames[0]]: profileImage,
        [organizationColumnNames[1]]: name,
        'actionButton': this._removeResourcesFromListButton('organizations', id),
        'id': org.id
      }
    });
  }

  _personColumnNames() {
    const langCode = DatavaseApi.getLangCode();
    return [
      i18n[langCode].personImageColumn,
      i18n[langCode].personNameColumn,
      'actionButton'
    ];
  }

  _personColumnWidth() {
    return ['50px', null, '20px'];
  }

  _personEntries(people) {
    const langCode = DatavaseApi.getLangCode();
    const personColumnNames = this._personColumnNames();

    return people.map(person => {
      let name = DatavaseApi.extractAndFormatPersonName(person, langCode);
      let profileImage = person.thumb_profile_image_url || person.profile_image_url;
      let id = person.id;

      return {
        [personColumnNames[0]]: profileImage,
        [personColumnNames[1]]: name,
        'actionButton': this._removeResourcesFromListButton('people', id),
        'id': person.id
      };
    });
  }

  _eventColumnNames() {
    const langCode = DatavaseApi.getLangCode();
    return [
      i18n[langCode].eventImageColumn,
      i18n[langCode].eventNameColumn,
      'actionButton'
    ];
  }

  _eventColumnWidth() {
    return ['50px', null, '20px'];
  }

  _eventEntries(events) {
    const eventColumnNames = this._eventColumnNames();

    return events.map(event => {
      let name = event.event_name;
      let profileImage = event.thumb_profile_image_url || event.profile_image_url;
      let id = event.id;

      return {
        [eventColumnNames[0]]: profileImage,
        [eventColumnNames[1]]: name,
        'actionButton': this._removeResourcesFromListButton('events', id),
        'id': event.id
      };
    });
  }

  render() {
    if (!this.state.hasLoaded) {
      return (<BlockSpinner visible={true} />);
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const list = this.state.list;
    const organizationColumnNames = this._organizationColumnNames();
    const organizationColumnWidth = this._organizationColumnWidth();
    const personColumnNames = this._personColumnNames();
    const personColumnWidth = this._personColumnWidth();
    const eventColumnNames = this._eventColumnNames();
    const eventColumnWidth = this._eventColumnWidth();
    let inputProps = {
      placeholder: lang.searchFormPlaceholder,
      value: this.state.formValue,
      onChange: this._onChangeForm.bind(this),
    };

    return (
      <div id="details-page-contents">
        <UpgradeToAddIntegrationModal
          show={this.state.showUpgradeToAddIntegrationModal}
          onCancelClick={this._hideUpgradeToAddIntegrationModal}
        />
        <UpgradeToAddMoreResourcesToListModal
          show={this.state.showUpgradeToAddMoreResourcesToListModal}
          onCancelClick={this._hideUpgradeToAddMoreResourcesToListModal}
        />
        <NotificationChannelsConfigurationModal
          show={this.state.showNotificationSettingModal}
          listId={this._getId()}
          onDoneClicked={this._hideNotificationSettingModal}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          onCancelClicked={this._hideConfirmationModal}
          onDeleteClicked={this._deleteList}
        />
        <RenameModal
          originalList={list}
          show={this.state.showRenameModal}
          onCancelClicked={this._hideRenameModal}
          onRenameClicked={this._renameList}
        />
        <Tabs id="list-contents-tabs" className="jumbo-tabs">
          <Tab eventKey={2} title={list.list_name} className="clearfix">
            <Col sm={12} md={8}>
              <div id="quick-add-form">
                <Autosuggest
                  multiSection={true}
                  suggestions={this.state.suggestions}
                  onSuggestionsFetchRequested={this._onSuggestionsFetchRequested.bind(this)}
                  onSuggestionsClearRequested={this._onSuggestionsClearRequested.bind(this)}
                  getSuggestionValue={getSuggestionValue}
                  onSuggestionSelected={this._onSuggestionSelected.bind(this)}
                  renderSuggestion={renderSuggestion}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  inputProps={inputProps}
                  theme={autosuggestTheme}
                />
              </div>
              <ChronologicalFeedPanels
                chronologicalFeed={this.state.chronologicalFeed}
                color={colors[this.state.list.id % 9]}
                lastSeenAt={this.state.list.last_seen_at}
                isLoading={this.state.isLoadingDigests}
              />
            </Col>
            <Col sm={12} md={4}>
              <Button
                className="notification-logo-style"
                onClick={this._onAddNotificationSettingsClicked}
              >
                <img alt="chatwork_integration" src={chatwork_logo} width="100%" />
              </Button>
              <Button
                className="notification-logo-style"
                onClick={this._onAddNotificationSettingsClicked}
              >
                <img alt="slack_integration" src={slack_logo} width="100%" />
              </Button>
              {
                this.state.isModifyingList ?
                  <BlockSpinner visible={true} /> : null
              }
              {this.state.companyEntries.length !== 0 ?
                <Panel>
                  <Tabs id="companies-list-tabs">
                    <Tab eventKey={1} title={lang.companiesTabTitle}>
                      <Panel className="side-padded-panel">
                        <ListTable
                          responsive
                          hover
                          disableHeaders
                          entries={this.state.companyEntries}
                          columnNames={organizationColumnNames}
                          columnWidth={organizationColumnWidth}
                          imageColumnName={organizationColumnNames[0]}
                          imageWidth="30px"
                          linkColumnName={organizationColumnNames[1]}
                          resourceName={'companies'}
                        />
                      </Panel>
                    </Tab>
                  </Tabs>
                </Panel>
                : null}
              {this.state.investorEntries.length !== 0 ?
                <Panel>
                  <Tabs id="investors-list-tabs">
                    <Tab eventKey={1} title={lang.investorsTabTitle}>
                      <Panel className="side-padded-panel">
                        <ListTable
                          responsive
                          hover
                          disableHeaders
                          entries={this.state.investorEntries}
                          columnNames={organizationColumnNames}
                          columnWidth={organizationColumnWidth}
                          imageColumnName={organizationColumnNames[0]}
                          imageWidth="30px"
                          linkColumnName={organizationColumnNames[1]}
                          resourceName={'investors'}
                        />
                      </Panel>
                    </Tab>
                  </Tabs>
                </Panel>
                : null}
              {this.state.schoolEntries.length !== 0 ?
                <Panel>
                  <Tabs id="schools-list-tabs">
                    <Tab eventKey={1} title={lang.schoolsTabTitle}>
                      <Panel className="side-padded-panel">
                        <ListTable
                          responsive
                          hover
                          disableHeaders
                          entries={this.state.schoolEntries}
                          columnNames={organizationColumnNames}
                          columnWidth={organizationColumnWidth}
                          imageColumnName={organizationColumnNames[0]}
                          imageWidth="30px"
                          linkColumnName={organizationColumnNames[1]}
                          resourceName={'schools'}
                        />
                      </Panel>
                    </Tab>
                  </Tabs>
                </Panel>
                : null}
              {this.state.personEntries.length !== 0 ?
                <Panel>
                  <Tabs id="people-list-tabs">
                    <Tab eventKey={1} title={lang.peopleTabTitle}>
                      <Panel className="side-padded-panel">
                        <ListTable
                          responsive
                          hover
                          disableHeaders
                          entries={this.state.personEntries}
                          columnNames={personColumnNames}
                          columnWidth={personColumnWidth}
                          imageColumnName={personColumnNames[0]}
                          imageWidth="30px"
                          linkColumnName={personColumnNames[1]}
                          resourceName={'people'}
                        />
                      </Panel>
                    </Tab>
                  </Tabs>
                </Panel>
                : null}
              {this.state.eventEntries.length !== 0 ?
                <Panel>
                  <Tabs id="events-list-tabs">
                    <Tab eventKey={1} title={lang.eventsTabTitle}>
                      <Panel className="side-padded-panel">
                        <ListTable
                          responsive
                          hover
                          disableHeaders
                          entries={this.state.eventEntries}
                          columnNames={eventColumnNames}
                          columnWidth={eventColumnWidth}
                          imageColumnName={eventColumnNames[0]}
                          imageWidth="30px"
                          linkColumnName={eventColumnNames[1]}
                          resourceName={'events'}
                        />
                      </Panel>
                    </Tab>
                  </Tabs>
                </Panel>
                : null}
              <Panel header={lang.listSettingsPaneTitle}>
                <Button block bsStyle="default" onClick={this._onAddNotificationSettingsClicked}>
                  <i className="material-icons list-action-button-icon">
                    notifications
                  </i>{lang.notificationSetting}
                </Button>
                <Button block bsStyle="default" onClick={this._showRenameModal}>
                  <i className="material-icons list-action-button-icon">
                    create
                  </i>{lang.changeListName}
                </Button>
                <Button block bsStyle="danger" onClick={this._showDeleteConfirmationModal}>
                  <i className="material-icons list-action-button-icon">
                    delete
                  </i>{lang.deleteList}
                </Button>
              </Panel>
            </Col>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListDetailsPane));

class ConfirmationModal extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.confirmationModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.deleteConfirmationText}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancelClicked}>
            {lang.cancelButton}
          </Button>
          <Button bsStyle="danger" onClick={this.props.onDeleteClicked}>
            {lang.deleteButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class RenameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: true,
      list: {
        list_name: props.originalList.list_name,
        description: props.originalList.description
      },
    };
    this._onChange = this._onChange.bind(this);
    this._onRenameClicked = this._onRenameClicked.bind(this);
    this._renameButtonDisabled = this._renameButtonDisabled.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.show) {
      this.setState({ hasLoaded: true });
    }
  }

  _onChange(e) {
    const newList = Object.assign({}, this.state.list, {
      [e.target.name]: e.target.value,
    });
    this.setState({ list: newList });
  }

  _onRenameClicked() {
    this.setState({ hasLoaded: false });
    if (this.props.onRenameClicked) { this.props.onRenameClicked(this.state.list); }
  }

  _renameButtonDisabled() {
    const currentList = this.state.list;
    const originalList = this.props.originalList;
    if (currentList.list_name === originalList.list_name &&
      currentList.description === originalList.description) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const originalList = this.props.originalList;

    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.renameListModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>{lang.listNameLabel}</ControlLabel>
            <FormControl
              name="list_name"
              placeholder={lang.listNamePlaceholder}
              defaultValue={originalList.list_name}
              onChange={this._onChange}
            />
            <ControlLabel>{lang.descriptionLabel}</ControlLabel>
            <FormControl
              name="description"
              placeholder={lang.descriptionPlaceholder}
              defaultValue={originalList.description}
              componentClass="textarea"
              onChange={this._onChange}
            />
          </FormGroup>
          <BlockSpinner visible={!this.state.hasLoaded} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancelClicked}>
            {lang.cancelButton}
          </Button>
          <Button
            bsStyle="primary"
            disabled={this._renameButtonDisabled()}
            onClick={this._onRenameClicked}
          >
            {lang.renameButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
