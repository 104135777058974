import React, {Component} from 'react';

import * as DatavaseApi from './datavase-api';
import BlockSpinner from './partials/block-spinner';
import '../css/my-page-pane.css';
import placeholder from '../assets/datavase_logo_icon_grey.png';
import {Panel} from "react-bootstrap";
import ListReviewPanel from "./organisms/list-review-panel";
import i18n from "../i18n/investors-pane";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const RESOURCE_NAME = 'investors';
const COLUMN_WIDTH = ['20px', '50px', 'null', '150px'];

class MyPagePublicPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      sort_date: 'newer',
      sort_good: 'good_first',
      loadedPage: 0,
      getOrder: 'post',
      nextPageLoaded: false,
      prefixSearchTimeoutId: null,
      keyword: '',
      showUpgradeToAddIntegrationModal: false,
      countPost: 0,
      countLike: 0,
    };
    this._trackScrolling = this._trackScrolling.bind(this);
    this._trackLikeScrolling = this._trackLikeScrolling.bind(this);
  }
  static _columnNames() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return [
      '#',
      lang.logoColumnHeader,
      lang.investorNameColumnHaeder,
      lang.dateOfFoundationColumnHaeder
    ];
  }
  static _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _trackScrolling() {
    const wrappedElement = document.getElementById('root');
    if (MyPagePublicPane._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  };
  _trackLikeScrolling() {
    const wrappedElement = document.getElementById('root');
    if (MyPagePublicPane._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackLikeScrolling);
      this._loadNextPage("like");
    }
  };
  _loadNextPage(getOrder = 'post',isChange = false) {
    let loadPage;
    if(isChange) {
      loadPage = 0;
    } else {
      loadPage = this.state.loadedPage;
    }
    if(getOrder === 'post' || getOrder === 'init') {
      this.setState({nextPageLoaded: false});
      const pageToLoad = loadPage + 1;
      const keyword = this.state.keyword;
      this._fetchInvestors(getOrder, pageToLoad, keyword);
    } else if(getOrder === 'like') {
      this.setState({nextPageLoaded: false});
      const pageToLoad = loadPage + 1;
      const keyword = this.state.keyword;
      this._fetchInvestors("like", pageToLoad, keyword);
    }
  }
  _fetchInvestors(type, page, keyword) {
    const params = {keyword: keyword, page: page, perPage: 40, uid: this.props.match.params.id};
    if(type === 'post' || type === "init") {
      if(type === "init") {
        DatavaseApi.listUserLikes({keyword: keyword, page: 1, perPage: 1, uid: this.props.match.params.id})
            .then((res) => {
              this.setState({countLike: res.data.countLike})
            })
      }
      DatavaseApi.listPublicLatestInvestorReview(params)
          .then((res) => {
            let newEntries = this._constructEntriesList(res.data);
            this.setState({
              entries: this.state.entries.concat(newEntries),
              countPost: res.data.countPost,
              loadedPage: page,
              nextPageLoaded: true,
              keyword: '',
            });
            if (res.data.reviews.length !== 0) {
              document.addEventListener('scroll', this._trackScrolling);
            }
          }).catch((res) => {
        console.error(res);
        document.addEventListener('scroll', this._trackScrolling);
      });
    } else if(type === 'like') {
      DatavaseApi.listUserLikes(params)
          .then((res) => {
            let newEntries = this._constructEntriesList(res.data);
            this.setState({
              entries: this.state.entries.concat(newEntries),
              loadedPage: page,
              nextPageLoaded: true,
              keyword: '',
            });
            if (res.data.reviews.length !== 0) {
              document.addEventListener('scroll', this._trackLikeScrolling);
            }
          }).catch((res) => {
        console.error(res);
        document.addEventListener('scroll', this._trackLikeScrolling);
      });
    }
  }

  _constructEntriesList(investors) {
    const langCode = DatavaseApi.getLangCode();
    return investors.reviews.map((investor, i) => {
      const name = DatavaseApi.extractOrganizationName(investor, langCode);
      const profileImage = investor.thumb_profile_image_url || investor.profile_image_url;
      const screen_name = investor.name || investor.screen_name;
      const reviewerProfileImg = investor.reviewer_thumb_image || investor.reviewer_image;
      let orgName = '';
      if (investor.user_org_name == null || investor.user_org_name === '') {
        orgName = ''
      } else {
        orgName = ' (' + investor.user_org_name + ') '
      }
      const dateOfFoundation = DatavaseApi.prettifyDate(
          investor.date_of_foundation,
          investor.precision_of_date_of_foundation
      );
      const UpdatedAt = DatavaseApi.prettifyDate(
          investor.updated_at
      );
      const CreatedAt = DatavaseApi.prettifyDate(
          investor.created_at
      );
      if (investor.access_count === null) {
        investor.access_count = 0;
      }
      return {
        name: name,
        found: dateOfFoundation,
        profileImg: profileImage,
        bad_point: investor.bad_point,
        user_org_name: orgName,
        organization_name: investor.organization_name,
        good_point: investor.good_point,
        total_review: investor.total_review,
        slug: investor.slug,
        created_at: CreatedAt,
        updated_at: UpdatedAt,
        review_likes: investor.review_likes,
        reviewer_image: reviewerProfileImg,
        current_like_status: investors.likes[i].current_like_status,
        review_id: investor.review_id,
        screen_name: screen_name,
        rating: investor.rating,
        showSharePanel: false,
        showComment: false,
        comment: '',
        access_count: investor.access_count,
        anonymous: false,
        'id': investor.id,
        uid: investor.uid,
        anonimity: investor.anonymous,
        resource_type_name: investor.resource_type_name
      };
    });
  }
  componentDidMount() {
    DatavaseApi.fetchPublicContributor(this.props.match.params.id)
        .then(res => {
          this.setState({
            hasLoaded:   true,
            screenName:  res.data.screen_name,
            name:        res.data.name,
            contributor: res.data,
          });
        })
        .catch(res => {
          console.error(res);
        });
    this._loadNextPage("init");
  }
  clickChangeView(like_or_post) {
    this.setState({
      getOrder: like_or_post,
    });
    if(like_or_post === 'like') {
      this.setState({
        entries: [],
        loadedPage: 0,
      });
      this._loadNextPage("like",true);
      document.removeEventListener('scroll', this._trackScrolling);
    } else if(like_or_post === 'post') {
      this.setState({
        entries: [],
        loadedPage: 0,
      });
      this._loadNextPage("post",true);
      document.removeEventListener('scroll', this._trackLikeScrolling);
    }
  }

  render() {
    const columnNames = MyPagePublicPane._columnNames();
    if(!this.state.hasLoaded) {
      return(<BlockSpinner visible={true}/>);
    }
    return(
        <div className="my-page-pane">
          <div className="intro-part first">
            <div className="intro-panel">
              <img src={this.state.contributor.profile_image_url || placeholder} alt="profile"/>
              <div className="name-bio-area">
                <p className="name">{this.state.screenName || this.state.name}</p>
                <p>{this.state.contributor.introduction}&nbsp;</p>
              </div>
              <br className="clear"/>
            </div>
          </div>
          <div className="intro-part">
            <div className="intro-panel">
              <div className="intro-control">
                <div className="control-left">
                  <p onClick={()=>this.clickChangeView("post")} className={this.state.getOrder==='post'?"active":""}>投稿数<br/><span>{this.state.countPost}</span></p>
                  <p onClick={()=>this.clickChangeView("like")} className={this.state.getOrder==='like'?"active":""}>いいね<br/><span>{this.state.countLike}</span></p>
                </div>
                {parseInt(this.props.match.params.id) === DatavaseApi.getUserId()?
                <div className="control-right">
                  <Link to="/account_settings" onlyActiveOnIndex>アカウントを編集</Link>
                </div>:""}
                <br className="clear"/>
              </div>
            </div>
          </div>
          <div className="main-content">
            <div className="main-panel">
              <div>
                {this.state.getOrder === 'post' && this.state.entries.length !== 0?
                    <Panel className="review-panel-parent" id="review-panel-post-parent">
                      <ListReviewPanel
                          hover
                          striped
                          condensed
                          entries={this.state.entries}
                          columnNames={columnNames}
                          columnWidth={COLUMN_WIDTH}
                          linkColumnName={columnNames[2]}
                          imageColumnName={columnNames[1]}
                          resourceName={RESOURCE_NAME}
                      />
                    </Panel>
                    :this.state.getOrder === 'post' && this.state.entries.length === 0?
                        <Panel className="review-panel-parent" id="review-panel-post-parent">
                          <p style={{textAlign: "center"}}>今は何もありません。</p>
                        </Panel>:""}
                {this.state.getOrder === 'like' && this.state.entries.length !== 0?
                    <Panel className="review-panel-parent" id="review-panel-like-parent">
                      <ListReviewPanel
                          hover
                          striped
                          condensed
                          entries={this.state.entries}
                          columnNames={columnNames}
                          columnWidth={COLUMN_WIDTH}
                          linkColumnName={columnNames[2]}
                          imageColumnName={columnNames[1]}
                          resourceName={RESOURCE_NAME}
                      />
                    </Panel>
                    :this.state.getOrder === 'like' && this.state.entries.length === 0?
                        <Panel className="review-panel-parent" id="review-panel-like-parent">
                          <p style={{textAlign: "center"}}>今は何もありません。</p>
                        </Panel>:""}
                <p style={this.state.nextPageLoaded?{textAlign: "center",marginTop: "4em"}:{display:"none"}}>全て読み込みました。</p>
                <BlockSpinner visible={!this.state.nextPageLoaded}/>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(MyPagePublicPane);
