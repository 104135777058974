import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import placeholder from "../../assets/datavase_logo_icon_grey.png";
import '../../css/review-panel.css';
import color from "../styles/colors";
import styled from "styled-components";
import media from "styled-media-query";

import ReviewStarPanelOnListPanel from "../partials/review-star-panel-on-list-panel";
import {Button, Modal} from "react-bootstrap";
import * as DatavaseApi from "../datavase-api";
import ShareButtons from "../partials/share-buttons";
import i18n from "../../i18n/list-review-panel";
import reviewLock from "../../assets/review_lock.png";
import InputText from "../atoms/input-text.js"

export default class ListReviewPanel extends Component {

    _postOrganizationReviewLike(orgId, review_id, i) {
        let likeVal = 0;
        if (this.state.entries[i].current_like_status !== 1) {
            likeVal = 1;
        }
        DatavaseApi.postOrganizationReviewLike(orgId, {
            id: review_id,
            like: likeVal,
        }).then(res => {
            this._updateOrganizationReviewLikeStatus(res.data, likeVal, orgId, i);
        });
    }

    _renderCommentBox(i) {
        if (this.state.entries[i].showComment) {
            return (
                <CommentBox>
                    <p>コメントは該当する企業・投資家のページよりご確認いただけます。</p>
                    <div>
                        {/* <InputGroup>
                            <InputGroup.Addon>
                                <Checkbox
                                    onChange={() => this._toggleAnonymous(i)}
                                >
                                    Anonymous
                                </Checkbox>
                            </InputGroup.Addon>
                            <FormControl
                                componentClass="textarea"
                                type="text"
                                style={{resize: 'vertical'}}
                                onChange={(e) => this._editComment(i, e)}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={() => this._submitComment(i)}
                                >
                                    Submit
                                </Button>
                            </InputGroup.Button>
                        </InputGroup> */}

                        <ReplyForm>
                            <InputText placeholder="本文" onChange={(e) => this._editComment(i, e)} />
                            <SubmitWrap>
                                <CheckBoxWrap>
                                    <input
                                        style={{display: "none"}}
                                        onChange={() => this._toggleAnonymous(i)}
                                        type="checkbox"
                                        name="checkboxAnonymous"
                                        value="チェックを入れると匿名になります"
                                        id="checkboxAnonymous"
                                    />
                                    <CheckBox for="checkboxAnonymous">チェックを入れると匿名になります</CheckBox>
                                </CheckBoxWrap>
                                <Submit type="submit" value="回答する" onClick={(e) => this._submitComment(i, e)}/>
                            </SubmitWrap>
                        </ReplyForm>
                    </div>
                </CommentBox>
            );
        } else {
            return (<div />);
        }
    }

    _editComment(i, e) {
        let entries = this.state.entries;
        entries[i].comment = e.target.value;
        this.setState({
            entries
        });
    }

    _submitComment(i, e) {
        e.preventDefault();
        DatavaseApi.postOrganizationReviewComment(this.props.entries[i].id, {
            id: this.props.entries[i].review_id,
            anonymous: this.state.entries[i].anonymous,
            comment: this.state.entries[i].comment,
        }).then(_res => {
            window.location.reload();
            return (
                <Modal
                    keyboard
                    show={true}
                    onHide={() => window.location.reload()}
                >
                    <Modal.Header>
                        <Modal.Title>
                            投稿完了
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>投稿完了しました。OKでリロードします。</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={() => window.location.reload()}
                        >
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>)
        });
    }

    _updateOrganizationReviewLikeStatus(data, state_expected, orgId, i) {
        let entries = this.state.entries;
        entries[i].likes = data.like;
        entries[i].current_like_status = state_expected;
        if (state_expected === 0) {
            entries[i].review_likes = entries[i].review_likes - 1;
        } else if (state_expected === 1) {
            entries[i].review_likes = entries[i].review_likes + 1;
        }
        this.setState({ entries });
    }

    _toggleComments(i) {
        let entries = this.state.entries;
        entries[i].showComment = !this.state.entries[i].showComment;
        this.setState({ entries });
    }

    _toggleAnonymous(i) {
        let entries = this.state.entries;
        entries[i].anonymous = !this.state.entries[i].anonymous;
        this.setState({ entries });
    }

    ///////////////////////////////////////////////////////


    _toggleSharePanel(i) {
        let entries = this.state.entries;
        if (this.state.entries[i].showSharePanel === undefined) {
            entries[i].showSharePanel = false;
        }
        entries[i].showSharePanel = !this.state.entries[i].showSharePanel;
        this.setState({ entries: entries });
    }


    _renderSharePanel(i) {
        if (this.state.entries[i].showSharePanel) {
            var msg = '';
            if (this.state.entries[i].good_point.length !== 0) {
                msg += `良い点: ${this.state.entries[i].good_point}/`
            }
            if (this.state.entries[i].bad_point.length !== 0) {
                msg += `改善点: ${this.state.entries[i].bad_point}`
            }
            if (this.state.entries[i].total_review[i].length !== 0) {
                msg += `総合評価: ${this.state.entries[i].total_review}`
            }
            return (
                <div>
                    <ShareButtons
                        url={`https://api.datavase.io/${encodeURIComponent(`organizations/${this.state.entries[i].id}/organization_reviews/${this.state.entries[i].review_id}/share`)}`}
                        title={`${msg}`}
                    />
                </div>
            );
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            entries: nextProps.entries,
        });
    }

    componentDidMount() {
        this.setState({
            entries: this.props.entries,
        })
    }

    render() {
        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];
        let userStatus = DatavaseApi.getUserStatus();
        let dataCount = this.props.entries.length;
        let reviewData = this.props.entries;
        if (userStatus === 'Free' || userStatus === 'Guest') {
            reviewData = this.props.entries.slice(0, 10);
        }
        const unlockPage = Array(dataCount - reviewData.length).fill(undefined).map((_unlock, i) => (
            <div
                key={i}
                onClick={() => {
                    window.location.href = '/upgrade'
                }}
            >
                <p>
                    <img src={reviewLock} alt="review_lock" />
                </p>
                <p>
                    {lang.getAccess}
                </p>
            </div>
        ));
        let tableRows = reviewData.map((entry, i) => {
            return (
                <Panel key={`review-panel-${i}`}>
                    {entry.anonimity ?
                        <PanelTop id="panelTop">
                            <Thambnail alt="test-avatar" src={entry.reviewer_image || placeholder} />
                            <TopText>
                                {entry.screen_name || entry.name}{entry.user_org_name}• {entry.created_at}
                            </TopText>
                        </PanelTop>
                        :
                        <PanelTop id="panelTop" onClick={() => window.location.href = `/mypage/${entry.uid}/`}>
                            <Thambnail alt="test-avatar" src={entry.reviewer_image || placeholder} />
                            <TopText>
                                {entry.screen_name || entry.name}{entry.user_org_name}• {entry.created_at}
                            </TopText>
                        </PanelTop>
                    }
                    <PanelBottom>
                        <ReviewHead>
                            <Link to={`/${entry.resource_type_name}/${entry.slug || entry.id}/`}>
                                <ReviewThamb alt="test-avatar" src={entry.profileImg || placeholder} />
                            </Link>
                            <ReviewHeadAbout>
                                <CompanyName
                                    to={`/${entry.resource_type_name}/${entry.slug || entry.id}/`}
                                >
                                    {entry.name}
                                </CompanyName>
                                <ReviewStarPanelOnListPanel rating={entry.rating} />
                            </ReviewHeadAbout>
                        </ReviewHead>
                        <div>
                            <Content>{entry.total_review}</Content>

                            <AnswerAction>
                                {/* ハート */}
                                <span
                                    className="click"
                                    onClick={() => this._postOrganizationReviewLike(entry.id, entry.review_id, i)}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "18px",
                                        marginRight: "10px",
                                        lineHeight: "18px",
                                        textAlign: "center",
                                    }}
                                >
                                    <span
                                        className={entry.current_like_status === 1 ? "list-review-panel-like qbpane" : "list-review-panel-like-empty qbpane"}
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                            display: "block",
                                            marginRight: "4px",
                                            fontSize: "14px",
                                        }}
                                    >
                                    </span>
                                    <span
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                            display: "block",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {entry.length === 0 ? "N" : entry.review_likes}
                                    </span>
                                </span>
                                {/* リプライ */}
                                <span
                                    onClick={() => this._toggleComments(i)}
                                    className="click"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "18px",
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        textAlign: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "18px",
                                            height: "18px",
                                            display: "block",
                                            fontSize: "14px",
                                            lineHeight: "18px",
                                            textAlign: "center",
                                        }}
                                        className="material-icons qbpane"
                                    >
                                        question_answer
                                    </span>
                                </span>

                                {/* シェア */}
                                <span
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "18px",
                                        marginRight: "10px",
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        textAlign: "center",
                                    }}
                                    onClick={() => this._toggleSharePanel(i)}
                                    className="click"
                                >
                                    <span
                                        className="list-review-panel-share"
                                        style={{
                                            width: "14px",
                                            height: "14px",
                                            display: "block",
                                            fontSize: "14px",
                                            marginRight: "4px",
                                            lineHeight: "18px",
                                            textAlign: "center",
                                        }}
                                    >
                                    </span>
                                    <span
                                        style={{
                                            height: "18px",
                                            display: "block",
                                            fontSize: "14px",
                                            lineHeight: "18px",
                                            textAlign: "center",
                                        }}
                                    >
                                        シェア
                                    </span>
                                </span>
                            </AnswerAction>

                            {this.state === null || this.state.entries.length === 0 ? "" : this._renderSharePanel(i)}
                            {this.state === null || this.state.entries.length === 0 ? "" : this._renderCommentBox(i)}
                        </div>
                    </PanelBottom>
                </Panel>
            );
        });

        return (
            <div>
                {tableRows}
                {unlockPage}
            </div>
        );
    }
}


const Panel = styled.div`
    border-radius: 8px;
    overflow: hidden;
    background: #FFF;
    margin: 0 0 24px;
    border-radius: 8px;
`;

const PanelTop = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: ${color.primary};
    height: 70px;
    padding: 0 16px;
    ${media.lessThan("medium")`
        padding: 0 12px;
    `}
`;

const Thambnail = styled.img`
    width: 48px;
    border-radius: 6px;
    overflow: hidden;
`;

const TopText = styled.p`
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 16px;
    padding: 0;
`;

const PanelBottom = styled.div`
    padding: 16px 24px;
  ${media.lessThan("medium")`
    padding: 12px;
  `}
`;

const ReviewHead = styled.div`
    display: flex;
    margin: 0 0 16px;
`;

const ReviewThamb = styled.img`
    width: 42px;
    height: 42px;
    border-radius: 6px;
    border: solid 1px ${color.black12};
    overflow: hidden;
`;

const ReviewHeadAbout = styled.div`
    margin: 0 0 0 16px;
    width: calc(100% - 58px);
`;

const CompanyName = styled(Link)`
    display: block;
    color: ${color.black87};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
`;

const Content = styled.p`
    color: ${color.black87};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

const ReplyForm = styled.form`
  width: 100%;
  /* margin: 0 0 0 16px; */
`;


const CheckBoxWrap = styled.div`
  margin: 0 auto 0 0;
`;

const CheckBox = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0;
  padding: 0 0 0 28px;
  cursor: pointer;
  color: ${color.black54};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  ${media.lessThan("medium")`
    font-size: 12px;
  `}
  &:hover {
    &:after {
      border-color: ${color.accent};
    }
  }
  &:after {
    -webkit-transition: border-color 0.2s linear;
    transition: border-color 0.2s linear;
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    margin-top: -10px;
    width: 18px;
    height: 18px;
    border: 2px solid ${color.black12};
    border-radius: 6px;
    content: "";
  }
  &:before {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 50%;
    left: 12px;
    display: block;
    margin-top: -9px;
    width: 6px;
    height: 12px;
    border-right: 2px solid ${color.accent};
    border-bottom: 2px solid ${color.accent};
    content: "";
    opacity: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    input[type="checkbox"]:checked + & {
      opacity: 1;
    }
  }
`;

const SubmitWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0;
  padding: 8px 0 0;
`;

const Submit = styled.input`
  padding: 12px 24px;
  border-radius: 8px;
  background: ${color.accent};
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
`;

const CommentBox = styled.div`
    margin-top: 12px;
`;

const AnswerAction = styled.div`
  display: flex;
  margin: 16px 0 0;
`;
