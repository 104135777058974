import React, {Component} from 'react';
import {Col,Button} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BlockSpinner from './partials/block-spinner';
import OrganizationAbstractPanel from './partials/organization-abstract-panel';
import {withRouter} from 'react-router-dom';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/investor-details-pane.json'
import '../css/details-pane.css';
import styled from "styled-components";
import color from "./styles/colors";
import media from "styled-media-query";

class CompanyRecruitsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      ads: [],
      contributions: null,
      hasLoaded: false,
      id: null,
      restrict_tag_id: -1,
      recruits: [],
      condition: {
        working: null,
        stage: null,
        role: null,
      },
      formValue: '',
      suggestions: [],
      isLoading: false,
      nextPageLoaded: false //LoadedPageは今のところなしでOK(全件読込にする)
    };

    this._onTabSelect = this._onTabSelect.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchCompanyWithRelatedResources(id)
        .then(res => {
          this.setState({
            resources: res.data,
            hasLoaded: true,
            id: res.data.company.id,
          });
        }).catch((res) => {
      console.error(res);
    });
    this._loadNextPage();
  }

  _onTabSelect(key) {
    if (key === "contributions") {
      if (!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('Companys', id)
            .then(res => {
              this.setState({contributions: res.data});
            })
            .catch(res => {
              console.error(res);
            });
      }
    }
  }

  _trackScrolling() {
    const wrappedElement = document.getElementById('root');
    if (this._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  }

  _loadNextPage() {
    this.setState({
      nextPageLoaded: false
    });
    DatavaseApi.fetchRecruits().then((res) => {
      this.setState({
        recruits: this.state.recruits.concat(res.data)
      })
    }).finally(() => {
      this.setState({
        nextPageLoaded: true
      })
    })
  }

  static enc_ml(x,y){
    var t=x+y;
    var s="",moji="";
    for(var i=0;i<t.length;i++){
      moji=t.charCodeAt(i);
      s +=String.fromCharCode(moji+1);
    }
    return s;
  }

  _recruitApply(pointerUri) {
    window.location.href = pointerUri;
  }

  _setRestrictTagId(genre, code) {
    let rewriteCond = this.state.condition;
    if (rewriteCond[genre] === code) {
      rewriteCond[genre] = null;
    } else {
      rewriteCond[genre] = code;
    }
    this.setState(rewriteCond);
  }

  render() {
    if (!this.state.hasLoaded) {
      return (<BlockSpinner visible={true}/>);
    }
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const {resources, id} = this.state;
    const company = resources.company;
    let m1='mai';
    let m2='lto:';
    let em_shtml=m1+m2+CompanyRecruitsPane.enc_ml(String.fromCharCode(115,100,96,108,63,103,96,98),String.fromCharCode(106,105,111,109,45,98,110,108));
    return (
        <div id="details-page-contents">
          <Col sm={12} md={12} lg={12}>
            <OrganizationAbstractPanel
                organization={company}
                profileImage={company.profile_image_url}
                id={id}
            />
          </Col>
          <Col sm={12} md={8} lg={8} className="clearfix">
            <TagBtn eventKey="review"
                    onClick={() => window.location.href = `/companies/${this._getId()}/`}>{lang.review}</TagBtn>
            <TagBtn eventKey="details" onClick={() => window.location.href = `/companies/${this._getId()}/details/`}
            >{lang.detailTabTitle}</TagBtn>
            <TagBtn eventKey="recruits" onClick={() => window.location.href = `/companies/${this._getId()}/recruits/`}
                    active>{lang.recruitsTabTitle}</TagBtn>
          </Col>
          <div>
            <div style={{width: "calc(100% + 28px)",display: "flex", flexWrap: "wrap", justifyContent: "space-around", marginLeft: "-14px"}}>
              {this.state.nextPageLoaded ? this.state.recruits.map((recruit, i) => {
                if ((this.state.condition.stage === null || this.state.condition.stage === recruit.stage) &&
                    (this.state.condition.role === null || this.state.condition.role === recruit.role) &&
                    (this.state.condition.working === null || this.state.condition.working === recruit.working) &&
                    (this.state.id === recruit.organization_id)) {
                  return (
                      <Recruit>
                        <RecruitLeft>
                          <p><img src={recruit.image_uri || recruit.profile_image_url} alt="Organization."
                                  style={{width: "100%", height: "27.97vh", objectFit: "contain",margin: "auto", borderRadius: "10px", display: "block"}}/></p>
                        </RecruitLeft>
                        <RecruitRight>
                          <p style={{fontSize:"1.84em"}}>{recruit.title}</p>
                          <p>法人名: {recruit.organization_name}</p>
                          <p>エリア/ポジション: {recruit.region}</p>
                          <p>ポジション: {recruit.role}({recruit.working})</p>
                          <p>必要/推奨スキル: {recruit.requirements}</p>
                          <p>給与: {Number(recruit.min_salary).toLocaleString()}~{Number(recruit.salary).toLocaleString()}円</p>
                          <p>ストックオプション: {recruit.min_stock}~{recruit.stock}%</p>
                          <p>採用担当: {recruit.name}</p>
                          <Button bsStyle="primary" onClick={() => {
                            this._recruitApply(recruit.contact)
                          }} style={{float: "right"}}>Apply Now</Button>
                        </RecruitRight>
                        <br style={{clear: "both"}}/>
                      </Recruit>
                  )
                } else {
                  return "";
                }
              }) : <BlockSpinner visible={true}/>}
            </div>
            <p><a href={em_shtml}>求人掲載についてはこちらをクリックしてメールをお願いいたします。</a></p>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}
const TagBtn = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 28.33%;
  margin: 2.5%;
  border: solid 2px ${color.primary};
  border-radius: 8px;
  padding: 8px;
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 12px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  &:checked {
    color: #fff;
    background: ${color.primary};
  }
`;
/*
backgroundColor: "white",
                        border: "solid 1px black",
                        padding: "15px",
                        width: "calc(33.33% - 30px)",
                        marginBottom: "20px",
                        borderRadius: "10px",
                        cursor: "pointer"
 */
const Recruit = styled.div`
background-color: white;
padding: 15px;
width: calc(100% - 30px);
margin-bottom: 20px;
border-radius: 10px;
${media.lessThan("medium")`
    width: calc(100% - 30px);
  `}
`;
const RecruitLeft = styled.div`
float: left;
min-width: 50%;
max-width: 50%;
${media.lessThan("medium")`
    min-width: 100%;
    max-width: 100%;
`}
`;
const RecruitRight = styled.div`
float: right;
min-width: 50%;
max-width: 50%;
${media.lessThan("medium")`
    min-width: 100%;
    max-width: 100%;
`}
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyRecruitsPane));
