import React, { Component } from 'react';
import * as c3 from 'c3';

export default class NumberOfInvestmentsGraph extends Component {
  componentDidMount() {
    this._updateGraph();
  }

  componentDidUpdate() {
    //this._updateGraph();
  }

  _constructDataForGraph(investments) {
    let investmentsByMonth = {};
    investments.forEach(investment => {
      if(investment.precision_of_date_of_investment !== 'year') {
        const month = investment.date_of_investment.slice(0, 7);
        investmentsByMonth[month] = (investmentsByMonth[month] || 0) + 1;
      }
    });
    return investmentsByMonth;
  }

  _updateGraph() {
    const investmentsByMonth = this._constructDataForGraph(this.props.investments);
    const date    = Object.keys(investmentsByMonth).map(date => `${date}-01`);
    const numberOfInvestments = Object.values(investmentsByMonth);
    const numberOfHistories = date.length;
    let barWidthRatio = 0.4;
    if(numberOfHistories < 3) {
      barWidthRatio = 0.1;
    }else if(numberOfHistories < 8) {
      barWidthRatio = 0.3;
    }

    c3.generate({
      bindto: `#${this.props.graphName}`,
      data: {
        xs: {
          'Number of investments by month': 'date'
        },
        columns: [
          ['date'].concat(date),
          ['Number of investments by month'].concat(numberOfInvestments)
        ],
        types: {
          'Number of investments by month': 'bar'
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            culling: {
              max: 4,
            },
            format: '%Y/%m'
          }
        }
      },
      bar: {
        width: {
          ratio: barWidthRatio
        }
      }
    });
  }

  render() {
    return(
      <div id={this.props.graphName}></div>
    );
  }
}
