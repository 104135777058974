import React, { Component } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import styled from "styled-components";
import color from "../styles/colors.js";

import BlockSpinner from '../partials/block-spinner';
import ListReviewPanel from './list-review-panel';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/investors-pane.json';
import '../../css/table-pane.css';
import store from 'store';
const RESOURCE_NAME = 'investors';
const COLUMN_WIDTH = ['20px', '50px', 'null', '150px'];

export default class InvestorsSearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      sort_date: 'newer',
      sort_good: 'good_first',
      loadedPage: 0,
      nextPageLoaded: false,
      prefixSearchTimeoutId: null,
      keyword: '',
    };
    this._onSearchFormChange = this._onSearchFormChange.bind(this);
    this._trackScrolling = this._trackScrolling.bind(this);
  }

  _columnNames() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return [
      '#',
      lang.logoColumnHeader,
      lang.investorNameColumnHaeder,
      lang.dateOfFoundationColumnHaeder
    ];
  }

  // If the user comes back via the browerback and there's cached state, use the cache.
  // Otherwise, load resources from the server.
  componentDidMount() {
    this._loadNextPage();
  }

  _constructEntriesList(investors) {
    const langCode = DatavaseApi.getLangCode();
    const newEntries = investors.reviews.map((investor, i) => {
      const name = DatavaseApi.extractOrganizationName(investor, langCode);
      const profileImage = investor.thumb_profile_image_url || investor.profile_image_url;
      const screen_name = investor.name || investor.screen_name;
      const reviewerProfileImg = investor.reviewer_thumb_image || investor.reviewer_image;
      let orgName = '';
      if (investor.user_org_name == null || investor.user_org_name === '') {
        orgName = ''
      } else {
        orgName = ' (' + investor.user_org_name + ') '
      }
      const dateOfFoundation = DatavaseApi.prettifyDate(
        investor.date_of_foundation,
        investor.precision_of_date_of_foundation
      );
      const UpdatedAt = DatavaseApi.prettifyDate(
        investor.updated_at
      );
      const CreatedAt = DatavaseApi.prettifyDate(
        investor.created_at
      );
      if (investor.access_count === null) {
        investor.access_count = 0;
      }
      const entry = {
        name: name,
        found: dateOfFoundation,
        profileImg: profileImage,
        bad_point: investor.bad_point,
        user_org_name: orgName,
        organization_name: investor.organization_name,
        good_point: investor.good_point,
        total_review: investor.total_review,
        slug: investor.slug,
        created_at: CreatedAt,
        updated_at: UpdatedAt,
        review_likes: investor.review_likes,
        reviewer_image: reviewerProfileImg,
        current_like_status: investors.likes[i].current_like_status,
        review_id: investor.review_id,
        screen_name: screen_name,
        rating: investor.rating,
        showSharePanel: false,
        showComment: false,
        comment: '',
        access_count: investor.access_count,
        anonymous: false,
        anonimity: investor.anonymous,
        'id': investor.id,
        uid: investor.uid,
        resource_type_name: investor.resource_type_name
      };

      return entry;
    });

    return newEntries;
  }

  _loadNextPage() {
    this.setState({ nextPageLoaded: false });
    const pageToLoad = this.state.loadedPage + 1;
    const keyword = this.state.keyword;
    this._fetchInvestors(pageToLoad, keyword);
  }

  _fetchInvestors(page, keyword) {
    const params = { keyword: keyword, page: page, perPage: 40, sort_date: store.get("sort_date"), sort_good: store.get("sort_good") };
    DatavaseApi.listLatestInvestorReview(params)
      .then((res) => {
        let newEntries = this._constructEntriesList(res.data);
        this.setState({
          entries: this.state.entries.concat(newEntries),
          loadedPage: page,
          nextPageLoaded: true,
          keyword: '',
        });
        if (res.data.reviews.length !== 0) {
          document.addEventListener('scroll', this._trackScrolling);
        }
      }).catch((res) => {
        console.error(res);
        document.addEventListener('scroll', this._trackScrolling);
      });
  }

  _onSearchFormChange(e) {
    if (this.state.prefixSearchTimeoutId !== null) {
      clearTimeout(this.state.prefixSearchTimeoutId);
    }
    const keyword = e.target.value;
    this.setState({
      keyword: keyword,
      loadedPage: 0
    });
    const timeoutId = setTimeout(() => {
      this.setState({
        entries: [],
      });
      this._loadNextPage()
    }, 300);
    this.setState({ prefixSearchTimeoutId: timeoutId })
  }

  _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _trackScrolling() {
    const wrappedElement = document.getElementById('root');
    if (this._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  };

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const columnNames = this._columnNames();
    const navbar = (
      <div>
        <div>
          <NavWrapper>
            {/*titleを動的にする*/}
            <NavTitle
              eventKey={5}
              title={store.get("sort_date") !== "newer" && store.get("sort_date") != null ? lang.orderStar : lang.orderNew}
              id="investor-pane-order-dropdown"
            >
              <MenuItem
                eventKey={5.1}
                onClick={() => { store.set("sort_date", "newer"); window.location.reload() }}
              >
                {lang.orderNew}
              </MenuItem>
              <MenuItem
                eventKey={5.2}
                onClick={() => { store.set("sort_date", "review"); window.location.reload() }}
              >
                {lang.orderStar}
              </MenuItem>
            </NavTitle>
            {/*上下キャロット変更はどうやるか応相談。あとはStateで並べ替えを管理する。で、文字はそれに対照とする*/}
            <NavTitle
              eventKey={6}
              disabled={store.get("sort_date") !== "review"} title={store.get("sort_good") !== "good_first" && store.get("sort_good") != null ? lang.orderLow : lang.orderHigh}
              id="investor-pane-order-good-dropdown"
            >
              <MenuItem
                eventKey={6.1}
                onClick={() => { store.set("sort_good", "good_first"); window.location.reload() }}
              >{lang.orderHigh}</MenuItem>
              <MenuItem
                eventKey={6.2}
                onClick={() => { store.set("sort_good", "bad_first"); window.location.reload() }}
              >
                {lang.orderLow}
              </MenuItem>
            </NavTitle>
          </NavWrapper>
        </div>
      </div>
    );
    const list = (
      <div>
        <div>
          <ListReviewPanel
            hover
            striped
            condensed
            entries={this.state.entries}
            columnNames={columnNames}
            columnWidth={COLUMN_WIDTH}
            linkColumnName={columnNames[2]}
            imageColumnName={columnNames[1]}
            resourceName={RESOURCE_NAME}
          />
        </div>
        <BlockSpinner visible={!this.state.nextPageLoaded} />
        <p style={this.state.nextPageLoaded ? { textAlign: "center" } : { display: "none" }}>全て読み込みました。</p>
      </div>
    );
    return (
      <div>
        {navbar}
        {list}
      </div>
    );
  }
}


const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 8px;
`

const NavTitle = styled(NavDropdown)`
  color: ${color.black87};
  font-size: 1.4rem;
  font-weight: 400;
  padding: 12px 16px;
  border-radius: 8px;
  background: #FFF;
  margin-left: 8px;
`
