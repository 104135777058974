import React, { Component } from 'react';
import store from 'store';
import gtag from '../../utils/gtag';
import StudentSignupWindow from "../partials/student-signup-window";

export default class StudentSignupPage extends Component {
  componentDidMount() {
    gtag('event', 'conversion', {
      'send_to': 'AW-767387394/a4ffCMiu15MBEILO9e0C',
      'transaction_id': ''
    });
  }
  _get_lang() {
    return (new URL(window.location)).searchParams.get('lang') || store.get('lang');
  }

  render() {
    const lang = this._get_lang();
    return(
      <div style={{margin: '10px'}}>
        <h1 id="auth-page-heading" className="datavase">datavase.io</h1>
        <StudentSignupWindow lang={lang}/>
      </div>
    );
  }
}
