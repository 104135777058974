import React, { Component } from 'react';
import { Button, Panel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import store from 'store';

import BlockSpinner from './block-spinner';
import CreditCardForm from './credit-card-form';
import Footer from '../organisms/footer';
import animateScrollTo from 'animated-scroll-to';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/upgrade-plan-window.json';
import '../../css/auth-pane.css';
import '../../css/mdi.css';
import '../../css/style.css';

export default class UpgradePlanWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      planName: 'Pro',
      isLoading: true,
      isProcessing: false,
      loggedIn: true,
      user: {},
    };
    this._onSuccess = this._onSuccess.bind(this);
    this._onFailure = this._onFailure.bind(this);
    //ここにonClickPlanを入れないといけないが、Separateできないのでうまく対処する
    this._onSubmitClicked = this._onSubmitClicked.bind(this);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }

  componentDidMount() {
    DatavaseApi.isLoggedIn()
      .then((res) => {
        this.setState({
          user: res,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          loggedIn: false,
          isLoading: false,
        })
      });
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _onSubmitClicked() {
    this.setState({ isProcessing: true });
  }

  _onSuccess(cardToken) {
    const planName = this.state.planName;
    DatavaseApi.updateSubscription(planName, cardToken) //ここで決済のアプデをしているっぽいから、Therefore Change It
      .then(res => {
        window.location.href = '/action_done/upgrade';
      })
      .catch(err => {
        this.setState({
          isProcessing: false,
          errorMessage: err.response.data.errors
        });
      });
  }

  _onFailure(errorMessage) {
    this.setState({
      errorMessage: errorMessage,
      isProcessing: false
    });
  }
  _onClickPlan(plan) {
    if (!this.state.loggedIn) {
      const next = window.location.href;
      window.location.href = `/login?next=${next}&message_key=login_to_continue`
    }
    if (this.state.planName !== plan) {
      this.setState({
        planName: plan,
        errorMessage: [],
      }, function () {
        animateScrollTo(document.querySelector('.auth-panel'));
      }
      )
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const planName = this.state.planName;
    let creditCardForm;
    if (this.state.isLoading) {
      creditCardForm = (<BlockSpinner visible={true} />);
    }/*else if(!['Guest', 'Free'].includes(DatavaseApi.getUserStatus())) {
      creditCardForm = (
        <div>
          <span>{lang.alreadySubscribing}</span>
        </div>
      );
    }*/ else if (planName === 'Enterprise') {
      const formUrl1 = 'mai';
      const formUrl2 = 'lto:';

      function convertMoji2_shtml(t) {
        var s = "", moji = "";
        for (var i = 0; i < t.length; i++) {
          moji = t.charCodeAt(i);
          s += String.fromCharCode(moji + 1);
        }
        return s;
      }

      const formUrl3 = convertMoji2_shtml(String.fromCharCode(115, 100, 96, 108, 63, 103, 96, 98) + String.fromCharCode(106, 105, 111, 109, 45, 98, 110, 108));
      const concatformUrl = `${formUrl1}${formUrl2}${formUrl3}`;
      creditCardForm = (
        <a href={concatformUrl} target="_blank" rel="noopener noreferrer">
          <Button block bsStyle="primary" style={{ marginBottom: '10px' }}>
            {lang.talkToSalesNow}
          </Button>
        </a>
      );
    } else {
      creditCardForm = (
        <CreditCardForm
          onSubmitClicked={this._onSubmitClicked}
          onSuccess={this._onSuccess}
          onFailure={this._onFailure}
          isProcessing={this.state.isProcessing}
          submitButtonText={lang.checkoutButton}
        />
      );
    }

    let errorMessage = null;
    if (this.state.errorMessage) {
      errorMessage = (<span>{this.state.errorMessage}</span>);
    }

    return (
      <div className="auth-form">
        <div className="page upgrade">
          <section className="section section-lg text-center" style={{ padding: '10px 0' }}>
            <div className="shell shell-wide">
              <div className="range range-md range-50 range-xs-center">
                <div className="cell-sm-6 cell-lg-4">
                  <div className="pricing-box pricing-box-xl pricing-box-novi">
                    <div className="pricing-box-header">
                      <h4>{lang.generalPlan}</h4>
                    </div>
                    <div className="pricing-box-price">
                      <div className="heading-2"><sup>¥</sup>1500</div>
                    </div>
                    <a href="#ccForm" className="button button-sm button-secondary" id="general-upgrade" onClick={this._onClickPlan.bind(this, "General")}>{lang.upgradeButton}</a>
                    <div className="pricing-box-body">
                      <ul className="pricing-box-list">
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-newspaper">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reportPart}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-message">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reviewPart}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-view-list">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.createMylist}</span></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cell-sm-6 cell-lg-4">
                  <div className="pricing-box pricing-box-xl pricing-box-novi">
                    <div className="pricing-box-header">
                      <h4>{lang.proPlan}</h4>
                    </div>
                    <div className="pricing-box-price">
                      <div className="heading-2"><sup>¥</sup>10000</div>
                    </div>
                    <a href="#ccForm" className="button button-sm button-secondary" id="pro-upgrade" onClick={this._onClickPlan.bind(this, "Pro")}>{lang.upgradeButton}</a>
                    <div className="pricing-box-body">
                      <ul className="pricing-box-list">
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-newspaper">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reportAll}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-message">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reviewAll}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-view-list">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.createMylist}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-magnify">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.searchWithCondition}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-slack">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.otherServiceRelation}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-account-search">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.researchRequest}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-deskphone">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.supportDesk}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-verified">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.verifiedMark}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-calendar-multiple-check">&nbsp;</span>
                            </div>
                            <div className="unit-body"><span>{lang.invitationToClosedEvents}</span></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cell-sm-6 cell-lg-4">
                  <div className="pricing-box pricing-box-xl pricing-box-novi">
                    <div className="pricing-box-header">
                      <h4>{lang.enterprisePlan}</h4>
                    </div>
                    <div className="pricing-box-price">
                      <div className="heading-2">{lang.priceOnRequest}</div>
                    </div>
                    <Link className="button button-sm button-secondary" id="enterprise-button" onClick={this._onClickPlan.bind(this, "Enterprise")}>{lang.upgradeButton}</Link>
                    <div className="pricing-box-body">
                      <ul className="pricing-box-list">
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-newspaper">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reportAll}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-message">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.reviewAll}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-view-list">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.createMylist}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-magnify">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.searchWithCondition}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-slack">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.otherServiceRelation}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-account-search">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.researchRequest}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-deskphone">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.supportDeskVip}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-verified">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.verifiedMark}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-calendar-multiple-check">&nbsp;</span>
                            </div>
                            <div className="unit-body"><span>{lang.invitationToClosedEvents}</span></div>
                          </div>
                        </li>
                        <li>
                          <div className="unit unit-spacing-sm unit-horizontal unit-middle">
                            <div className="unit-left"><span
                              className="icon icon-md-big icon-primary mdi mdi-briefcase">&nbsp;</span></div>
                            <div className="unit-body"><span>{lang.cxoPeopleSupport}</span></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Panel className="auth-panel" header={lang.changeToEn + this.state.planName + lang.changeToJp}>
          <div id="ccForm" className="auth-panel-content">
            <div className="auth-form upgrade">
              {errorMessage}
              {creditCardForm}
            </div>
          </div>
        </Panel>
        <div className="upgrade-footer">
          <Footer />
        </div>
      </div>
    )
  }
}
