import React, { Component } from 'react';
import { InputGroup, FormGroup, FormControl, Button } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/news-summary-form.json';

export default class NewsSummaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true,
      newBulletPointText: '',
      bulletPoints: props.defaultBulletPoints || [],
      updateSummaryTimeoutId: null,
    };
    this._enterEditMode = this._enterEditMode.bind(this);
    this._leaveEditMode = this._leaveEditMode.bind(this);
    this._onNewBulletPointKeyPress = this._onNewBulletPointKeyPress.bind(this);
    this._onAddNewBulletPointClick = this._onAddNewBulletPointClick.bind(this);
    this._onNewBulletPointChange   = this._onNewBulletPointChange.bind(this);
    this._onBulletPointChange      = this._onBulletPointChange.bind(this);
  }

  _enterEditMode() {
    this.setState({isEditing: true});
  }

  _leaveEditMode() {
    this.setState({isEditing: false});
  }

  _onAddNewBulletPointClick() {
    const newBulletPointText = this.state.newBulletPointText;
    if(newBulletPointText.length === 0) {
      return;
    }

    this._addNewBulletPoints(newBulletPointText);
  }

  _onNewBulletPointKeyPress(e) {
    const newBulletPointText = this.state.newBulletPointText;
    if(e.key !== 'Enter' || newBulletPointText.length === 0) {
      return;
    }

    this._addNewBulletPoints(newBulletPointText);
  }

  _onNewBulletPointChange(e) {
    if(e.target.value.length <= 80) {
      this.setState({newBulletPointText: e.target.value});
    }
  }

  _onBulletPointChange(newText, i) {
    if(newText.length > 80) {
      return;
    }

    const newBulletPoints = [...this.state.bulletPoints];
    newBulletPoints[i] = newText;
    this.setState({bulletPoints: newBulletPoints});

    if(this.state.updateSummaryTimeoutId) {
      window.clearTimeout(this.state.updateSummaryTimeoutId);
    }
    const updateSummaryTimeoutId = window.setTimeout(() => {
      DatavaseApi.updateNewsArticleSummary(this.props.warehouseNewsArticleId, newBulletPoints);
    }, 200)
    this.setState({updateSummaryTimeoutId: updateSummaryTimeoutId});
  }

  _addNewBulletPoints(newBulletPointText) {
    const newBulletPoints = [...this.state.bulletPoints, newBulletPointText];
    this.setState({
      bulletPoints: newBulletPoints,
      newBulletPointText: ''
    });
    DatavaseApi.updateNewsArticleSummary(
      this.props.warehouseNewsArticleId, newBulletPoints
    );
  }

  _removeBulletPoint(i) {
    const newBulletPoints = [...this.state.bulletPoints];
    newBulletPoints.splice(i, 1);
    this.setState({bulletPoints: newBulletPoints});
    DatavaseApi.updateNewsArticleSummary(
      this.props.warehouseNewsArticleId, newBulletPoints
    );
  }

  _constructBulletPointForms(bulletPoints) {
    const bulletPointForms = bulletPoints.map((bulletPoint, i) => {
      const onChange      = (e) => { this._onBulletPointChange(e.target.value, i); }
      const onRemoveClick = (e) => { this._removeBulletPoint(i); }
      return(
        <InputGroup key={i} style={{marginBottom: '4px'}}>
          <FormControl
            onChange={onChange.bind(this)}
            value={bulletPoint}
          />
          <InputGroup.Button>
            <Button
              onClick={onRemoveClick.bind(this)}
              bsStyle="danger"
            >
              <i
                className="material-icons"
                style={{verticalAlign: 'middle', padding: '0', fontSize: '14px'}}
              >
                clear
              </i>
            </Button>
          </InputGroup.Button>
        </InputGroup>
      );
    });
    return bulletPointForms;
  }

  _constructBulletPointPreviews(bulletPoints) {
    const bulletPointPreviews = bulletPoints.map((bulletPoint, i) => {
      return(
        <div key={i}>
          <span>
            {bulletPoint}
          </span>
          <Button
            onClick={this._enterEditMode}
            style={{border: 'none', padding: '0', backgroundColor: 'transparent'}}
          >
            <i className="material-icons" style={{padding: '0', verticalAlign: 'middle', fontSize: '15px', color: '#ccc'}}>edit</i>
          </Button>
        </div>
      );
    });
    return bulletPointPreviews;
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const bulletPoints = this.state.bulletPoints;

    return(
      <div style={{marginTop: '10px', marginBottom: '10px'}}>
        {
          this.state.isEditing ?
          <div>
            <FormGroup>
              {this._constructBulletPointForms(bulletPoints)}
              <InputGroup>
                <FormControl 
                  value={this.state.newBulletPointText}
                  onChange={this._onNewBulletPointChange}
                  onKeyPress={this._onNewBulletPointKeyPress}
                  placeholder={lang.summaryPlaceholder}
                />
                <InputGroup.Button>
                  <Button
                    onClick={this._onAddNewBulletPointClick}
                  >
                    <i
                      className="material-icons"
                      style={{verticalAlign: 'middle', padding: '0', fontSize: '14px'}}
                    >
                      add
                    </i>
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
          </div>
          :
          <div>
            {this._constructBulletPointPreviews(bulletPoints)}
          </div>
        }
      </div>
    );
  }
}
