import React, { Component } from 'react';

import placeholder from '../../assets/datavase_logo_icon_grey.png';
import * as DatavaseApi from '../datavase-api';

import i18n from '../../i18n/datavase-resource-preview.json';
import '../../css/datavase-resource-preview.css';

export default class DatavaseResourcePreview extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const resource = this.props.resource;
    const name = resource.normalized_name ||
                 resource.organization_name ||
                 `${resource.first_name} ${resource.last_name}` ||
                 resource.event_name;
    const profileImage = resource.profile_image_url || placeholder;
    const href = `/${resource.resource_type_name}/${resource.slug || resource.id}/`;

    let lastFundingInfo;
    if(resource.last_funding_date) {
      let amount = DatavaseApi.abbrPrice(
        resource.last_funding_amount,
        resource.last_funding_currency_code,
        'Undisclosed'
      );
      let fundingDate = DatavaseApi.prettifyDate(
        resource.last_funding_date,
        resource.precision_of_last_funding_date
      );
      let fundingType = resource.last_funding_type_name;

      lastFundingInfo = (
        <div style={{fontSize: '16px', color: '#aaa'}}>
          <span>{lang.lastFundingLabel}: </span>
          <span style={{color: '#666', fontWeight: 'bold'}}>{amount} </span>
          <span>{fundingType} </span>
          <span>{fundingDate}</span>
        </div>
      );
    }

    return(
      <div className="datavase-resource-preview">
        <div>
          <a target="_blank" rel="noopener noreferrer" href={href}>
            <img alt={name} src={profileImage}/>
          </a>
          <div className="datavase-resource-preview-link">
            <a target="_blank" rel="noopener noreferrer" href={href}>
              <span>{name}</span>
            </a>
          </div>
          {lastFundingInfo}
          <div>
            <a href={href} target="_blank" rel="noopener noreferrer">{lang.seeFullProfile}</a>
          </div>
        </div>
      </div>
    );
  }
}
