import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import CreditCardForm   from './credit-card-form';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/update-payment-method-modal.json';

export default class UpdatePaymentMethodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      errorMessage: null,
    };
    this._onDoneClick     = this._onDoneClick.bind(this);
    this._onSubmitClicked = this._onSubmitClicked.bind(this);
    this._onSuccess       = this._onSuccess.bind(this);
    this._onFailure       = this._onFailure.bind(this);
  }

  _onDoneClick() {
    this.setState({errorMessage: null});
    if(this.props.onDoneClick) {this.props.onDoneClick();}
  }

  _onSubmitClicked() {
    this.setState({isProcessing: true});
  }

  _onSuccess(card_token) {
    DatavaseApi.updateDefaultPayment(card_token)
    .then(res => {
      //console.log(res);
      this.setState({
        errorMessage: null,
        isProcessing: false
      });
      if(this.props.onUpdatePaymentSuccess) {
        this.props.onUpdatePaymentSuccess(res.data);
      }
    })
    .catch(res => {
      this.setState({
        errorMessage: res.response.data.error,
        isProcessing: false,
      })
    });
  }

  _onFailure(errorMessage) {
    console.error(errorMessage);
    this.setState({
      errorMessage: errorMessage,
      isProcessing: false
    });
  }

  render() {
    const langCode    = DatavaseApi.getLangCode();
    const lang        = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.updatePaymentMethodModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage ? <span>{this.state.errorMessage}</span> : null}
          <CreditCardForm
            onSubmitClicked={this._onSubmitClicked}
            onSuccess={this._onSuccess}
            onFailure={this._onFailure}
            isProcessing={this.state.isProcessing}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onDoneClick}>
            {lang.doneButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
