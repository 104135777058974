import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/add-companies-filter-modal.json';

export default class AddCompaniesFilterModal extends Component {
  constructor(props) {
    super(props);
    this._onCancelClick = this._onCancelClick.bind(this);
    this._onAddClick    = this._onAddClick.bind(this);
  }

  _onCancelClick() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  _onAddClick(filterSubject, filterName, filterType) {
    if(this.props.onAddFilter) {
      this.props.onAddFilter(filterSubject, filterName, filterType);
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show} keyboard onHide={this._onCancelClick}>
        <Modal.Header>
          {lang.addFilterModalTitle}
        </Modal.Header>
        <Modal.Body>
          <div style={{marginBottom: '10px'}}>
          {lang.addFilterModalBody}
          </div>
          <Button onClick={() => {this._onAddClick("last_raised", lang.lastRaised, "price")}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.lastRaised}
          </Button>
          <Button onClick={() => {this._onAddClick("investment_stage", lang.investmentStage, "string")}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.investmentStage}
          </Button>
          <Button onClick={() => {this._onAddClick("num_investors", lang.numInvestors)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.numInvestors}
          </Button>
          <Button onClick={() => {this._onAddClick("num_portfolio_orgs", lang.numPortfolioOrgs)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.numPortfolioOrgs}
          </Button>
          <Button onClick={() => {this._onAddClick("num_fundings", lang.numFundings)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.numFundings}
          </Button>
          <Button onClick={() => {this._onAddClick("num_investments", lang.numInvestments)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.numInvestments}
          </Button>
          <Button onClick={() => {this._onAddClick("review_count", lang.numRatings)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.numRatings}
          </Button>
          <Button onClick={() => {this._onAddClick("review_average", lang.reviewAverage)}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.reviewAverage}
          </Button>
          <Button onClick={() => {this._onAddClick("invest_style", lang.investStyle, "invest_style")}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.investStyle}
          </Button>
          <Button onClick={() => {this._onAddClick("support_frequency", lang.supportFrequency, "support_frequency")}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.supportFrequency}
          </Button>
          <Button onClick={() => {this._onAddClick("recommend_characteristic", lang.recommendCharacteristic, "recommend_characteristic")}} style={{textAlign: 'left'}} bsStyle="primary" block>
            {lang.recommendCharacteristic}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onCancelClick}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
