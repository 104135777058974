import React, { Component } from 'react'
import { MatchMediaHOC } from 'react-match-media';
import { Navbar, NavItem } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as authActions from '../../actions/auth';
import datavase_logo    from '../../assets/datavase_logo.png';

// import '../../css/top-navbar.css';

class TopNavbarMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false
    };
  }

  componentDidMount() {
    this.props.onMobileNavbarMounted();
  }

  componentWillUnmount() {
    this.props.onMobileNavbarUnmounted();
  }

  _setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }

  _closeNav() {
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <div id="top-navbar">
        <Navbar onToggle={this.props.onSidebarVisibilityToggled}
                expanded={this.state.navExpanded}>
          <Navbar.Header>
            <Navbar.Brand>
              <NavItem href="/">
                <img src={datavase_logo} alt="datavase.io"/>datavase.io
              </NavItem>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch){
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default MatchMediaHOC(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNavbarMobile), '(max-width: 767px)');

