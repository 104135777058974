import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter,faFacebook,faSlack } from '@fortawesome/free-brands-svg-icons'
import styled from "styled-components";
import color from "../styles/colors.js";


export default class ShareButtons extends Component {
  render() {
    const { url, title } = this.props;
    //TWは121までOK
    return(
      <BtnWrapper id="share-buttons">
          <BtnTwitter
            href={`http://twitter.com/share?url=${url}&text=${title.substr(0,100)}${title.length>100?'…':''}+-+Datavase&related=hikaru_hackjpn,datavase_io&via=datavase_io&hashtags=datavase_io`}
            target="_new"
          >
            <Icon icon={faTwitter} />
          </BtnTwitter>
          <BtnFacebook
            href={`https://www.facebook.com/sharer.php?u=${url}`}
            target="_new"
          >
            <Icon icon={faFacebook} />
          </BtnFacebook>
          <BtnSlack
            className="slack-button-widget"
            data-source="http://slackbutton.herokuapp.com/embed"
            href="http://slackbutton.herokuapp.com/post/new"
            data-url={`${url}`}
          >
            <Icon icon={faSlack} />
          </BtnSlack>
        <script src="http://slackbutton.herokuapp.com/widget.js" type="text/javascript"/>
      </BtnWrapper>
    );
  }
}

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 16px 0 0;
`

const BtnTwitter = styled.a`
  background: ${color.sns.twitter};
  transition: .4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 8px;
  &:hover {
    color: #FFF;
    opacity: 0.8;
    transition: 0.4s;
  }
`

const BtnFacebook = styled.a`
  background: ${color.sns.facebook};
  transition: .4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 8px;
  &:hover {
    color: #FFF;
    opacity: 0.8;
    transition: 0.4s;
  }
`

const BtnSlack = styled.a`
  background: ${color.sns.slack};
  transition: .4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 8px;
  &:hover {
    color: #FFF;
    opacity: 0.8;
    transition: 0.4s;
  }
`

const Icon = styled(FontAwesomeIcon)`
  color: #FFF;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
`
