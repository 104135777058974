import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import { getLangCode } from '../datavase-api';
import i18n from '../../i18n/video-panel.json';

export default class VideoPanel extends Component {
  render() {
    const langCode = getLangCode();
    const lang     = i18n[langCode];

    return(
      <Panel>
        <Tabs id="video-tabs">
          <Tab eventKey={1} title={lang.videoPanelTitle}>
            <Panel>
              <div style={{position: 'relative', height: '0', padding: '30px 0 56.25%', overflow: 'hidden'}}>
                <iframe
                  style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                  src={this.props.url}
                  title={this.props.url}
                  frameBorder="0"
                  allowFullScreen
                >
                </iframe>
              </div>
            </Panel>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}
