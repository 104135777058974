import React, { Component } from 'react';
import { Panel, Col, Tabs, Tab } from 'react-bootstrap';

import { getLangCode } from '../datavase-api';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

import i18n from '../../i18n/exits-panel.json';
import '../../css/exits-panel.css';

export default class ExitsPanel extends Component {
  render() {
    const langCode = getLangCode();
    const lang = i18n[langCode];
    const exits = this.props.exits;

    const ipoList = exits.ipos.map((ipo, i) => {
      const desc = `${lang.ipoDesc} ${ipo.stock_symbol}`;
      return(
        <ExitRowView
          key={i}
          organization={ipo.organization}
          description={desc}
        />
      );
    });

    const acquisitionList = exits.acquisitions.map((acquisition, i) => {
      const desc = `${lang.acquisitionDesc} ${acquisition.acquiring_organization.organization_name}`;
      return(
        <ExitRowView
          key={i}
          organization={acquisition.acquired_organization}
          description={desc}
        />
      );
    });

    const exitsList = ipoList.concat(acquisitionList);
    const firstHalf = exitsList.filter((exit, i) => i%2 === 0);
    const lastHalf  = exitsList.filter((exit, i) => i%2 === 1);
    return(
      <Panel>
        <Tabs id="exits-tabs">
          <Tab eventKey={1} title={lang.title}>
            <Col lg={6} xs={12}>
              {firstHalf}
            </Col>
            <Col lg={6} xs={12}>
              {lastHalf}
            </Col>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}

class ExitRowView extends Component {
  render() {
    const organization  = this.props.organization;
    const description   = this.props.description;
    const profileImage  = organization.profile_image_url || placeholder;
    const href          = `/${organization.resource_type_name}/${organization.slug || organization.id}/`;

    return(
      <div className="exit-row-view">
        <Col xs={4} sm={4} md={4} lg={4}>
          <img
            className="exit-row-image"
            src={profileImage}
            alt={organization.organization_name}
          />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8}>
          <a href={href}>{organization.organization_name}</a>
          <span className="exit-row-desc">{description}</span>
        </Col>
      </div>
    );
  };
}
