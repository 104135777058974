import React, { Component }   from 'react';
import { Button }             from 'react-bootstrap';

import BlockSpinner            from './block-spinner';
import ColumnView              from './column-view';
import * as DatavaseApi        from '../datavase-api';
import i18n                    from '../../i18n/draggable-columns-view.json';

const addColumnButtonOuterStyle = {
  display: 'inline-block',
  margin: '0 6px',
  width: '340px',
  whiteSpace: 'normal',
  verticalAlign: 'top'
};

const addColumnButtonStyle = {
  display: 'inline-block',
  width: "280px",
  margin: '0 4px 8px 16px',
  padding: '8px',
  fontSize: '16px',
};

export default class DraggableColumnsView extends Component {
  render(){
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const columnViews = this.props.columns.map((column, i) => {
      const onDeleteButtonClick   = () => { this.props.onDeleteButtonClick(column.id, i); };
      const onSettingsButtonClick = () => { this.props.onSettingsButtonClick(column.id, i); };

      return(
        <ColumnView
          key={i}
          color={column.color}
          title={column.title}
          entriesView={column.entriesView}
          titleWidth={column.titleWidth}
          columnWidth={column.columnWidth}
          isLoading={!column.hasLoaded}
          onSettingsButtonClick={onSettingsButtonClick.bind(this)}
          onDeleteButtonClick={onDeleteButtonClick.bind(this)}
          configurable={column.configurable}
        />
      );
    });

    return (
      <div id="draggable-columns-view" style={{overflowX: 'scroll', whiteSpace: 'nowrap', padding: '10px 10px'}}>
        {
          this.props.hasLoaded ?
          <div>
            {columnViews}
            <div style={addColumnButtonOuterStyle}>
              <Button
                onClick={this.props.onAddColumnClick}
                bsStyle="primary"
                style={addColumnButtonStyle}
              >
                <i className="material-icons" style={{verticalAlign: 'middle'}}>
                  add
                </i>
                {lang.addColumnButton}
              </Button>
            </div>
          </div> :
          <BlockSpinner visible/>
        }
      </div>
    );
  }
}
