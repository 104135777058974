import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as DatavaseApi from './datavase-api';
import * as stateCacheActions from '../actions/state-cache';
import CompaniesColumnsViewDetails from "./partials/companies-columns-view-details";
import { withRouter } from 'react-router-dom';

const COLUMN_VIEW = 'COLUMN_VIEW';
const ADVANCED_SEARCH = 'ADVANCED_SEARCH';

class CompaniesPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentToDisplay: COLUMN_VIEW,
            showUpgradeToProceedModal: false,
        };
        this._onAdvancedSearchClicked = this._onAdvancedSearchClicked.bind(this);
        this._onColumnViewClicked = this._onColumnViewClicked.bind(this);
        this._showUpgradeToProceedModal = this._showUpgradeToProceedModal.bind(this);
        this._hideUpgradeToProceedModal = this._hideUpgradeToProceedModal.bind(this);
    }

    _showUpgradeToProceedModal() {
        this.setState({showUpgradeToProceedModal: true});
    }

    _hideUpgradeToProceedModal() {
        this.setState({showUpgradeToProceedModal: false});
    }

    componentDidMount() {
        const cachedState = this.props.stateCache.companiesPaneState;
        if (cachedState) {
            this.setState({
                componentToDisplay: cachedState.componentToDisplay
            });
        }
    }

    componentWillUnmount() {
        this.props.stateCacheActions.setCompaniesPaneState(this.state);
    }

    _onAdvancedSearchClicked() {
        let userStatus = DatavaseApi.getUserStatus();
        if (userStatus === 'Free') {
            this._showUpgradeToProceedModal();
            return;
        }

        this.setState({componentToDisplay: ADVANCED_SEARCH});
    }

    _onColumnViewClicked() {
        this.setState({componentToDisplay: COLUMN_VIEW});
    }

    render() {
        let viewToRender;
        viewToRender = (
            <CompaniesColumnsViewDetails id={this.props.match.params.id} review_id={this.props.match.params.review_id}/>
        );

        return (
            <div>
                {viewToRender}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        stateCache: state.stateCache
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stateCacheActions: bindActionCreators(stateCacheActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompaniesPane));
