import React, { Component } from 'react';

import { getLangCode, prettifyDate } from '../datavase-api';
import { Panel, Col, Row } from 'react-bootstrap';

import i18n from '../../i18n/person-data-grid-panel.json';

export default class PersonDataGridPanel extends Component {
  render () {
    const langCode = getLangCode();
    const lang = i18n[langCode];
    let cellHeadings = [
      [lang.dateOfBirth, lang.exits, lang.latestEducation]
    ];

    const person          = this.props.person;
    const exits           = this.props.exits;
    const date            = prettifyDate(person.date_of_birth, person.precision_of_date_of_birth);
    const education       = person.education || '-';
    //const age             = calculateAge(person.date_of_birth);
    //const birth_location  = prettifyLocation(person.city_of_birth, person.state_or_prefecture_of_birth, person.country_of_birth);

    let numberOfExits = '-';
    if(exits) {
      numberOfExits = exits.ipos.length + exits.acquisitions.length;
      if(numberOfExits === 0) { numberOfExits = '-'; }
    }

    let cellValues = [
      [date, numberOfExits, education]
    ];
    return (
      <DataGridPanel
        dataGridHeading={lang.gridTitle}
        dataGridCellHeadings={cellHeadings}
        dataGridCellValues={cellValues}
      />
    );
  }
}

class DataGridPanel extends Component {
  render () {
    return (
      <Panel id="data-grid-panel">
        <h2 id="data-grid-heading">{this.props.dataGridHeading}</h2>
        <Col className="data-grid-column" id="data-grid-column-1" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][0]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][0]}</span>
          </Row>
        </Col>
        <Col className="data-grid-column" id="data-grid-column-2" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][1]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][1]}</span>
          </Row>
        </Col>
        <Col className="data-grid-column" id="data-grid-column-3" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][2]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][2]}</span>
          </Row>
        </Col>
      </Panel>
    );
  }
}
