import axios from 'axios';
import store from 'store';
import * as ENV from '../constants/environment';
import placeholder from '../assets/datavase_logo_icon_grey.png';

///////////////////////////////////////////////////////////////////////////////
// Utility
///////////////////////////////////////////////////////////////////////////////
export function generateCreditCardToken(card, onSuccess, onFailure) {
  window.Omise.setPublicKey(ENV.OMISE_PUBLIC_KEY);
  window.Omise.createToken('card', card, (statusCode, response) => {
    if (statusCode === 200) {
      onSuccess(response.id);
    } else {
      onFailure(response.code, response.message);
    }
  });
}

export function resolveCdnFilePath(path) {
  if (!path) { return placeholder; }
  if (path[0] === '/') {
    path = path.slice(1);
  }
  return `${ENV.CDN_ENDPOINT}/${path}`;
}

export function getAppEndpoint() {
  return ENV.WEB_APP_ENDPOINT;
}

export function getLangCode() {
  return store.get('langCode') || "ja";
}

export function prettifyDatetime(rawDatetime) {
  if (!rawDatetime) { return '-'; }
  const datetime = new Date(rawDatetime);
  const year = datetime.getUTCFullYear();
  const month = datetime.getMonth() + 1;
  const date = datetime.getDate();
  let hours = datetime.getHours();
  if (hours < 10) { hours = `0${hours}`; }
  let minutes = datetime.getMinutes();
  if (minutes < 10) { minutes = `0${minutes}`; }
  let seconds = datetime.getSeconds();
  if (seconds < 10) { seconds = `0${seconds}`; }
  return `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;
}


export function extractHostname(url) {
  if (!url) return '';
  let hostname;
  if (url.indexOf("://") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  return hostname;
}

export function timeSince(datetime, langCode) {
  let seconds = Math.floor((new Date() - new Date(datetime)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    let yearsAgo = langCode === 'ja' ? '年前' : ' years ago';
    return interval + yearsAgo;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    let monthsAgo = langCode === 'ja' ? 'ヶ月前' : ' months ago';
    return interval + monthsAgo;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    let daysAgo = langCode === 'ja' ? '日前' : ' days ago';
    return interval + daysAgo;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    let hoursAgo = langCode === 'ja' ? '時間前' : ' hours ago';
    return interval + hoursAgo;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    let minutesAgo = langCode === 'ja' ? '分前' : ' minutes ago';
    return interval + minutesAgo;
  }
  let secondsAgo = langCode === 'ja' ? '秒前' : ' seconds ago';
  return Math.floor(seconds) + secondsAgo;
}

export function prettifyLocation(city, state, country) {
  let location = '';
  if (city) {
    location = `${location}, ${city}`;
  }
  if (state) {
    location = `${location}, ${state}`;
  }
  if (country) {
    location = `${location}, ${country}`;
  }
  location = location.slice(2);
  if (location.length === 0) {
    location = '-';
  }
  return location;
}

export function prettifyPrice(price, currencyCode, defaultValue = '-') {
  if (!price || !currencyCode) {
    return defaultValue;
  }

  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currencyCode}`;
}

export function abbrPrice(price, currencyCode, defaultValue = '-') {
  if (!price || !currencyCode) {
    return defaultValue;
  }
  price = parseFloat(price);

  const prefix = ['', 'k', 'M', 'B', 'T'];
  let cnt = 0;
  while ((price / 1000) >= 1) {
    price /= 1000;
    cnt += 1;
  }

  const currencySymbol = convertCurrencyCodeToSymbol(currencyCode);
  return `${currencySymbol}${price.toFixed(1)}${prefix[Math.min(3, cnt)]}`;
}

export function prettifyDate(rawDate, precision = 'date') {
  if (!rawDate) { return '-'; }
  const splitDate = rawDate.split('-');
  const year = parseInt(splitDate[0], 10);
  const month = parseInt(splitDate[1], 10);
  const date = parseInt(splitDate[2], 10);

  if (precision === 'date') {
    return `${year}/${month}/${date}`;
  } else if (precision === 'month') {
    return `${year}/${month}`;
  } else if (precision === 'year') {
    return `${year}`;
  }
}

export function getPrecisionAwareDateStr(dateStr, precision = 'date') {
  if (!dateStr) return null;

  const prettifiedDate = prettifyDate(dateStr, precision);
  return prettifiedDate.replace(/\//g, '-');
}


export function convertUTCTimestampToDate(seconds) {
  if (!seconds) { return '-'; }

  let t = new Date(1970, 0, 1);
  t.setSeconds(seconds);
  return `${t.getUTCFullYear()}/${t.getMonth() + 1}/${t.getDate()}`;
}

export function convertCurrencyCodeToSymbol(currencyCode) {
  switch (currencyCode) {
    case 'USD': {
      return '$';
    }
    case 'JPY': {
      return '¥';
    }
    case 'EUR': {
      return '€';
    }
    case 'GBP': {
      return '£';
    }
    case 'CNY': {
      return 'CN¥';
    }
    default: {
      return '';
    }
  }
}

// Currently supports only USD and JPY for target currency.
export function convertCurrency(amount, sourceCurrency, targetCurrency) {
  if (targetCurrency === 'USD') {
    if (sourceCurrency === 'JPY') {
      return amount * 0.0093;
    } else if (sourceCurrency === 'CHY') {
      return amount * 0.15;
    } else if (sourceCurrency === 'EUR') {
      return amount * 1.25;
    } else if (sourceCurrency === 'GBP') {
      return amount * 1.4;
    } else if (sourceCurrency === 'USD') {
      return amount;
    }
  } else if (targetCurrency === 'JPY') {
    if (sourceCurrency === 'USD') {
      return amount * 107;
    } else if (sourceCurrency === 'CHY') {
      return amount * 17;
    } else if (sourceCurrency === 'EUR') {
      return amount * 132;
    } else if (sourceCurrency === 'GBP') {
      return amount * 153;
    } else if (sourceCurrency === 'JPY') {
      return amount;
    }
  }
}

export function extractAndFormatPersonName(person, langCode = 'en') {
  if (!person) { return null; }
  if (!person.first_name || !person.last_name) { return null; }
  const nativeLang = person.native_language;
  const nativeFirst = person.native_first_name;
  const nativeLast = person.native_last_name;

  if (langCode === 'ja' && nativeLang === 'ja' && nativeFirst && nativeLast) {
    return `${nativeLast} ${nativeFirst}`;
  } else {
    return `${person.first_name} ${person.last_name}`;
  }
}

export function extractOrganizationName(organization, langCode = 'en') {
  if (!organization || !organization.organization_name) { return null; }
  const nativeLang = organization.native_language;
  const nativeName = organization.native_organization_name;

  if (langCode === 'ja' && nativeLang === 'ja' && nativeName) {
    return nativeName;
  } else {
    return organization.organization_name;
  }
}

export function extractEventName(event, langCode = 'en') {
  if (!event || !event.event_name) { return null; }
  const nativeLang = event.native_language;
  const nativeName = event.native_event_name;

  if (langCode === 'ja' && nativeLang === 'ja' && nativeName) {
    return nativeName;
  } else {
    return event.event_name;
  }
}

export function extractDescription(entity, langCode = 'en') {
  if (!entity) { return '-'; }
  const desc_en = entity.description_en;
  const desc_ja = entity.description_ja;

  if (langCode === 'ja' && desc_ja && desc_ja.length !== 0) {
    return desc_ja;
  } else if (desc_en && desc_en.length !== 0) {
    return desc_en;
  } else {
    return '-';
  }
}

export function calculateAge(dateOfBirth) {
  if (!dateOfBirth) { return '-'; }
  const dob = new Date(dateOfBirth);
  const today = new Date();
  const diff = new Date(today - dob);
  return Math.abs(diff.getUTCFullYear() - 1970);
}

export function formatURL(url) {
  if (!url) { return null; }
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  } else {
    return `http://${url}`;
  }
}

export function isLoggedIn() {
  return new Promise((resolve, reject) => {
    const accessToken = store.get('access-token');
    const uid = store.get('uid');
    const client = store.get('client');
    if (!accessToken || !uid || !client) {
      return reject("Not logged in");
    }

    axios.get(`${ENV.API_ENDPOINT}/auth/validate_token`)
      .then(res => {
        return resolve(res.data.data);
      })
      .catch(res => {
        return reject("Not logged in");
      })
  });
}

export function getUserId() {
  return parseInt(store.get('user-id'), 10);
}

export function getUserStatus() {
  return store.get('user-status-type');
}

export function getUserTrialStatus() {
  return store.get('trial');
}

export function getUserEmail() {
  return store.get('uid');
}

export function getUserEmailDomain() {
  const email = store.get('uid');
  if (!email) {
    return null;
  }

  return email.split('@')[1];
}

export function isUserStatusFree() {
  const status = store.get('user-status-type');
  if (!status || status === 'Free') {
    return true;
  } else {
    return false;
  }
}

export function isUserAccessibleToArticle(article) {
  const status = store.get('user-status-type');
  const condition = (article.is_visible_to_free && ['Free', 'Guest'].includes(status)) ||
    (article.is_visible_to_pro && status === 'Pro') ||
    (article.is_visible_to_student && status === 'Student') ||
    (article.is_visible_to_enterprise && status === 'Enterprise');
  return condition;
}

export function articleAccessiblePlansList(article) {
  let plans = [];
  if (article.is_visible_to_free) {
    plans.push('Free');
    plans.push('Guest');
  }
  if (article.is_visible_to_pro) {
    plans.push('Pro');
  }
  if (article.is_visible_to_student) {
    plans.push('Student');
  }
  if (article.is_visible_to_enterprise) {
    plans.push('Enterprise');
  }
  return plans;
}

function constructQueryString(params) {
  let queryString = '';
  if (params.fieldsList) {
    queryString += `fields=${params.fieldsList.join(',')}&`;
  }
  if (params.keyword) {
    queryString += `keyword=${encodeURIComponent(params.keyword)}&`;
  }
  if (params.tagName) {
    queryString += `tag_name=${params.tagName}&`;
  }
  if (params.page) {
    queryString += `page=${params.page}&`;
  }
  if (params.perPage) {
    queryString += `per_page=${params.perPage}&`;
  }
  if (params.sort) {
    queryString += `sort=${params.sort}&`;
  }
  if (params.sort_date) {
    queryString += `sort_date=${params.sort_date}&`;
  }
  if (params.sort_good) {
    queryString += `sort_good=${params.sort_good}&`;
  }
  if (params.uid) {
    queryString += `uid=${params.uid}&`;
  }
  if (params.onlyPartial) {
    queryString += `onlyPartial=${params.onlyPartial}&`;
  }
  if (params.tags) {
    queryString += params.tags.map(tag => `tags[]=${encodeURIComponent(tag)}&`);
  }
  return queryString.slice(0, queryString.length - 1);
}

///////////////////////////////////////////////////////////////////////////////
// Authentication
///////////////////////////////////////////////////////////////////////////////
export function signup(params) {
  return axios.post(`${ENV.API_ENDPOINT}/auth`, params);
}

export function login(user) {
  return axios.post(`${ENV.API_ENDPOINT}/auth/sign_in`, user);
}

export function logout() {
  return axios.delete(`${ENV.API_ENDPOINT}/auth/sign_out`);
}

export function forgetTokens() {
  store.remove('uid');
  store.remove('access-token');
  store.remove('client');
  store.remove('user-id');
  store.remove('user-status-type');
}

export function deleteAccount(email) {
  const uid = store.get('uid');
  if (email === uid) {
    return axios.delete(`${ENV.API_ENDPOINT}/auth`);
  }
}

export function fetchContributor() {
  return axios.get(`${ENV.API_ENDPOINT}/contributor`);
}
export function fetchPublicContributor(uid) {
  return axios.get(`${ENV.API_ENDPOINT}/public_contributor?uid=${uid}`);
}

export function validateScreenName(screenName) {
  const encodedScreenName = encodeURIComponent(screenName);
  return axios.get(`${ENV.API_ENDPOINT}/contributor/validate_screen_name?screen_name=${encodedScreenName}`);
}

export function updateContributorScreenName(screenName) {
  const params = {
    contributor: {
      screen_name: screenName,
    }
  };
  return axios.put(`${ENV.API_ENDPOINT}/contributor`, params);
}
export function updateContributorIntroduction(introduction) {
  const params = {
    contributor: {
      introduction: introduction,
    }
  };
  return axios.put(`${ENV.API_ENDPOINT}/contributor/introduction`, params);
}

export function updateContributorProfileImage(newImage) {
  let imageParams = new FormData();
  imageParams.append('image', newImage);
  return axios.post(`${ENV.API_ENDPOINT}/contributor/image`, imageParams);
}
export function fetchQuestionBoards(params, id) {
  //${id}
  return axios.get(`${ENV.API_ENDPOINT}/question_boards.json?organization_id=${params.organization_id}`, params);
}
export function fetchQuestionBoardDetails(id) {
  //${id}
  return axios.get(`${ENV.API_ENDPOINT}/question_boards/${id}`);
}
export function fetchQuestionBoardsTimeline(params) {
  //${id}
  return axios.get(`${ENV.API_ENDPOINT}/question_boards_timeline`, params);
}
export function createQuestionBoard(params) {
  return axios.post(`${ENV.API_ENDPOINT}/question_boards`, params)
}
export function addAnswer(question_board_id, params) {
  return axios.put(`${ENV.API_ENDPOINT}/question_boards/${question_board_id}`, params)
}
export function postVote(question_board_id, params) {
  return axios.put(`${ENV.API_ENDPOINT}/question_boards/${question_board_id}/vote`, params);
}
export function deleteTopic(question_board_id) {
  return axios.delete(`${ENV.API_ENDPOINT}/question_boards/${question_board_id}`);
}

export function getNotification() {
  return axios.get(`${ENV.API_ENDPOINT}/notifications`);
}
///////////////////////////////////////////////////////////////////////////////
// OAuth
///////////////////////////////////////////////////////////////////////////////
export function initializeSlackOAuth() {
  return axios.post(`${ENV.API_ENDPOINT}/oauth/slack`);
}

export function revokeSlackOAuth() {
  return axios.delete(`${ENV.API_ENDPOINT}/oauth/slack`);
}

export function initializeChatWorkOAuth() {
  return axios.post(`${ENV.API_ENDPOINT}/oauth/chatwork`);
}

export function revokeChatWorkOAuth() {
  return axios.delete(`${ENV.API_ENDPOINT}/oauth/chatwork`);
}

export function fetchOAuthConnectionStatus() {
  return axios.get(`${ENV.API_ENDPOINT}/oauth/status`);
}

export function listChatworkChannels() {
  return axios.get(`${ENV.API_ENDPOINT}/notification_setting/chatwork_channels`);
}

export function listSlackChannels() {
  return axios.get(`${ENV.API_ENDPOINT}/notification_setting/slack_channels`);
}

///////////////////////////////////////////////////////////////////////////////
// Dashboard
///////////////////////////////////////////////////////////////////////////////
export function fetchDashboardResources() {
  return axios.get(`${ENV.API_ENDPOINT}/dashboard`);
}

///////////////////////////////////////////////////////////////////////////////
// Cross Model
///////////////////////////////////////////////////////////////////////////////
export function crossModelSearch(keyword, limit) {
  const queryString = `keyword=${encodeURIComponent(keyword)}&limit=${limit}`;
  return axios.get(`${ENV.API_ENDPOINT}/cross_model?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// DetailsPageResources
///////////////////////////////////////////////////////////////////////////////
export function fetchCompanyWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/companies/${id}/full_resources`);
}

export function fetchInvestorWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/investors/${id}/full_resources`);
}

export function fetchSchoolWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/schools/${id}/full_resources`);
}

export function fetchProductWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/products/${id}/full_resources`);
}

export function fetchPersonWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/people/${id}/full_resources`);
}

export function fetchEventWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/events/${id}/full_resources`);
}

export function fetchListWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/lists/${id}/details`);
}

export function fetchDatavaseArticleWithRelatedResources(id) {
  return axios.get(`${ENV.API_ENDPOINT}/datavase_articles/${id}/full_resources`);
}

export function fetchListChronologicalFeed(id) {
  return axios.get(`${ENV.API_ENDPOINT}/lists/${id}/chronological_feed`);
}

///////////////////////////////////////////////////////////////////////////////
// Organization
///////////////////////////////////////////////////////////////////////////////
export function searchOrganizations(query = { filter: {}, page: 1, per_page: 40 }) {
  return axios.post(`${ENV.API_ENDPOINT}/organizations/search`, { query: query });
}

export function listOrganizations(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/organizations?${queryString}`);
}

export function listCompanies(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/companies?${queryString}`);
}

export function listInvestors(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/investors?${queryString}`);
}
export function listLatestInvestorReview(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/latest_investor_reviews?${queryString}`);
}

export function listLatestInvestorDetailReview(org_id, id, params) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/organizations/${org_id}/organization_reviews/${id}?${queryString}`);
}

export function listLatestPersonDetailReview(person_id, id, params) {
    const queryString = constructQueryString(params);
    return axios.get(`${ENV.API_ENDPOINT}/people/${person_id}/person_reviews/${id}?${queryString}`);
}
export function listPublicLatestInvestorReview(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/public_latest_investor_reviews?${queryString}`);
}
export function listUserLikes(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/latest_user_likes?${queryString}`);
}
export function listLatestCompaniesReview(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/latest_company_reviews?${queryString}`);
}

export function listLatestCompaniesDetailReview(org_id, id, params) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/organizations/${org_id}/organization_reviews/${id}?${queryString}`);
}
export function listSchools(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/schools?${queryString}`);
}

export function listInvestables(keyword, limit) {
  const queryString = `keyword=${encodeURIComponent(keyword)}&limit=${limit}`;
  return axios.get(`${ENV.API_ENDPOINT}/investables?${queryString}`);
}

export function listOrganizationsRecently(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/organizations/recently_updated?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// Investment
///////////////////////////////////////////////////////////////////////////////

export function listInvestments(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/investments?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// Product
///////////////////////////////////////////////////////////////////////////////
export function listProducts(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/products?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// Person
///////////////////////////////////////////////////////////////////////////////
export function listPeople(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/people?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// Event
///////////////////////////////////////////////////////////////////////////////
export function listEvents(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/events?${queryString}`);
}

///////////////////////////////////////////////////////////////////////////////
// DatavaseArticle
///////////////////////////////////////////////////////////////////////////////
export function listDatavaseArticles(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/datavase_articles?${queryString}`);
}

export function listDatavaseArticleTags() {
  return axios.get(`${ENV.API_ENDPOINT}/datavase_articles/tags`);
}

///////////////////////////////////////////////////////////////////////////////
// List
///////////////////////////////////////////////////////////////////////////////
export function listLists(params = {}) {
  const queryString = constructQueryString(params);
  return axios.get(`${ENV.API_ENDPOINT}/lists?${queryString}`);
}
export function createList(params = {}) {
  return axios.post(`${ENV.API_ENDPOINT}/lists`, params);
}
export function updateList(id, params = {}) {
  return axios.put(`${ENV.API_ENDPOINT}/lists/${id}`, params);
}
export function deleteList(id) {
  return axios.delete(`${ENV.API_ENDPOINT}/lists/${id}`);
}
export function addResourcesToList(id, params = {}) {
  return axios.post(`${ENV.API_ENDPOINT}/lists/${id}/resources`, params);
}
export function removeResourcesFromList(id, params = {}) {
  return axios.delete(`${ENV.API_ENDPOINT}/lists/${id}/resources`, { data: params });
}
export function listNotificationChannelsByList(listId) {
  return axios.get(`${ENV.API_ENDPOINT}/lists/${listId}/notification_channels`);
}
export function addNotificationChannelToList(listId, channelId, channelName, authorizerName) {
  const params = {
    notification_channel: {
      channel_id: channelId,
      channel_name: channelName,
      authorizer_name: authorizerName
    }
  };
  return axios.post(`${ENV.API_ENDPOINT}/lists/${listId}/notification_channels`, params);
}
export function removeNotificationChannel(id) {
  return axios.delete(`${ENV.API_ENDPOINT}/notification_channels/${id}`);
}

///////////////////////////////////////////////////////////////////////////////
// Subscription
///////////////////////////////////////////////////////////////////////////////
export function fetchSubscription() {
  return axios.get(`${ENV.API_ENDPOINT}/subscription`);
}
export function updateSubscription(planName, cardToken = null) {
  const params = {
    plan_name: planName,
    card_token: cardToken
  };
  return axios.post(`${ENV.API_ENDPOINT}/subscription/update`, params);
}
export function startSubscription(plan_name) {
  return axios.post(`${ENV.API_ENDPOINT}/subscription/start`);
}
export function cancelSubscription() {
  return axios.post(`${ENV.API_ENDPOINT}/subscription/cancel`);
}
export function resumeSubscription(cardToken = null) {
  const params = {
    card_token: cardToken
  };
  return axios.post(`${ENV.API_ENDPOINT}/subscription/resume`, params);
}

///////////////////////////////////////////////////////////////////////////////
// Payment
///////////////////////////////////////////////////////////////////////////////
export function fetchDefaultPayment() {
  return axios.get(`${ENV.API_ENDPOINT}/payment`);
}
export function updateDefaultPayment(card_token) {
  const params = { card_token: card_token };
  return axios.put(`${ENV.API_ENDPOINT}/payment`, params);
}

///////////////////////////////////////////////////////////////////////////////
// ResearchRequest
///////////////////////////////////////////////////////////////////////////////
export function fetchResearchRequests() {
  return axios.get(`${ENV.API_ENDPOINT}/research_requests`);
}
export function requestResearch(resourceType, id) {
  return axios.post(
    `${ENV.API_ENDPOINT}/${resourceType}/${id}/research_requests`
  );
}

///////////////////////////////////////////////////////////////////////////////
// ColumnsViewConfiguration
///////////////////////////////////////////////////////////////////////////////
export function listColumnsViewConfigurations(columnsViewType) {
  return axios.get(`${ENV.API_ENDPOINT}/columns_view_configurations?columns_view_type=${columnsViewType}`);
}
export function createColumnsViewConfiguration(params) {
  return axios.post(`${ENV.API_ENDPOINT}/columns_view_configurations`, params);
}
export function updateColumnsViewConfiguration(id, params) {
  return axios.put(`${ENV.API_ENDPOINT}/columns_view_configurations/${id}`, params);
}
export function deleteColumnsViewConfiguration(id) {
  return axios.delete(`${ENV.API_ENDPOINT}/columns_view_configurations/${id}`);
}

///////////////////////////////////////////////////////////////////////////////
// NewsArticles
///////////////////////////////////////////////////////////////////////////////
export function listLatestNewsArticles(params = {}) {
  let queryString = '';
  if (params['articleType']) {
    queryString += `article_type=${params['articleType']}&`;
  }
  if (params['language']) {
    queryString += `language=${params['language'].join()}&`;
  }
  return axios.get(`${ENV.API_ENDPOINT}/news_articles/latest?${queryString}`);
}

export function fetchNewsArticleWithRelatedResources(warehouse_id) {
  return axios.get(`${ENV.API_ENDPOINT}/news_articles/${warehouse_id}`);
}

export function updateNewsArticleSummary(warehouse_id, bulletPoints) {
  const params = {
    news_article: {
      summary_in_bullet_points: bulletPoints
    }
  };
  return axios.post(
    `${ENV.API_ENDPOINT}/news_articles/${warehouse_id}/news_summary`, params
  );
}

///////////////////////////////////////////////////////////////////////////////
// Create/Update/Delete API
///////////////////////////////////////////////////////////////////////////////
export function createInvestment(params) {
  return axios.post(`${ENV.API_ENDPOINT}/investments`, params);
}

export function editInvestment(id, params) {
  return axios.put(`${ENV.API_ENDPOINT}/investments/${id}`, params);
}

///////////////////////////////////////////////////////////////////////////////
// Contributions
///////////////////////////////////////////////////////////////////////////////
export function listContributionsByResource(resourceType, id) {
  let type = 'organizations';
  if (resourceType === 'people') {
    type = 'people';
  } else if (resourceType === 'events') {
    type = 'events';
  }
  return axios.get(`${ENV.API_ENDPOINT}/${type}/${id}/contributions`);
}

export function fetchDetailedContributionSnapshot(id) {
  return axios.get(`${ENV.API_ENDPOINT}/contributions/${id}/detailed_snapshot`);
}

export function fetchInformationRemovalRequests() {
  return axios.get(`${ENV.API_ENDPOINT}/information_removal_requests`);
}

export function requestInformationRemoval(id) {
  return axios.post(`${ENV.API_ENDPOINT}/contributions/${id}/removal_requests`);
}

export function cancelRequestInformationRemoval(id) {
  return axios.delete(`${ENV.API_ENDPOINT}/contributions/${id}/removal_requests`);
}

export function deleteCreatedInformation(id) {
  return axios.delete(`${ENV.API_ENDPOINT}/contributions/${id}/information`);
}

export function praiseContribution(id) {
  const params = {
    evaluation: {
      evaluation_type_name: 'praise'
    }
  };
  return axios.post(`${ENV.API_ENDPOINT}/contributions/${id}/evaluations`, params);
}

export function blameContribution(id) {
  const params = {
    evaluation: {
      evaluation_type_name: 'soft_blame'
    }
  };
  return axios.post(`${ENV.API_ENDPOINT}/contributions/${id}/evaluations`, params);
}

///////////////////////////////////////////////////////////////////////////////
// Reviews
///////////////////////////////////////////////////////////////////////////////
export function fetchOrganizationReviews(organization_id) {
  return axios.get(`${ENV.API_ENDPOINT}/organizations/${organization_id}/organization_reviews.json`);
}

export function fetchOrganizationAverageReviews(organization_id) {
  return axios.get(`${ENV.API_ENDPOINT}/organizations/${organization_id}/organization_reviews/average_rating`);
}

export function postOrganizationReviewLike(organization_id, params) {
  return axios.post(`${ENV.API_ENDPOINT}/organizations/${organization_id}/organization_reviews/like`, params);
}

export function postOrganizationReviewComment(organization_id, params) {
  return axios.post(`${ENV.API_ENDPOINT}/organizations/${organization_id}/organization_reviews/comments`, params);
}

export function postOrganizationReviews(id, params) {
  return axios.post(`${ENV.API_ENDPOINT}/organizations/${id}/organization_reviews.json`, params);
}

export function fetchPersonReviews(person_id) {
  return axios.get(`${ENV.API_ENDPOINT}/people/${person_id}/person_reviews.json`);
}

export function fetchPersonAverageReviews(person_id) {
  return axios.get(`${ENV.API_ENDPOINT}/people/${person_id}/person_reviews/average_rating`);
}

export function postPersonReviews(person_id, params) {
  return axios.post(`${ENV.API_ENDPOINT}/people/${person_id}/person_reviews.json`, params);
}

///////////////////////////////////////////////////////////////////////////////
// Hot Rank
///////////////////////////////////////////////////////////////////////////////

export function getHotRank(mode) {
  return axios.get(`${ENV.API_ENDPOINT}/hot_rank/${mode}`);
}

///////////////////////////////////////////////////////////////////////////////
// Ads
///////////////////////////////////////////////////////////////////////////////

export function fetchAds(id) {
  return axios.get(`${ENV.API_ENDPOINT}/ads/${id}`);
}

///////////////////////////////////////////////////////////////////////////////
// Datavase.hero(Recruitment page)
///////////////////////////////////////////////////////////////////////////////

export function fetchRecruits() {
  //Recruitment list
  return axios.get(`${ENV.API_ENDPOINT}/recruit/`);
}

export function fetchRecruitDetails(id) {
  //Recruitment details
  return axios.get(`${ENV.API_ENDPOINT}/recruit/${id}`);
}

export function postRecruitment(params) {
  //Even user can post recruitment details
  return axios.post(`${ENV.API_ENDPOINT}/recruit/`, params);
}
