import React, { Component } from 'react'
import '../../css/terms-and-privacy-page.css'

import TopNavbar            from '../organisms/top-navbar';
import TopNavbarMobile      from '../organisms/top-navbar-mobile';
import Footer               from '../organisms/footer';

export default class SpecifiedCommercialTransactionLawPage extends Component {
  render() {
    return(
      <div id="terms-and-privacy-page">
        <TopNavbar/>
        <TopNavbarMobile/>
        <div id="terms-and-privacy-contents">
          <div id="terms-and-privacy-contents-container">
            <h1>特定商取引法に基づく表記</h1>
            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                販売事業主名
              </span>
              <span style={{fontWeight: 'bold'}}>
                hackjpn株式会社
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                運営責任者名
              </span>
              <span style={{fontWeight: 'bold'}}>
                戸村光
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                問い合わせ窓口(メールアドレス)
              </span>
              <span style={{fontWeight: 'bold'}}>
                team@hackjpn.com
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                問い合わせ窓口(電話番号)
              </span>
              <span style={{fontWeight: 'bold'}}>
                070-4286-7094
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                本社所在地
              </span>
              <span style={{fontWeight: 'bold'}}>
                大阪府八尾市黒谷町5-166
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                商品代金以外の必要料金
              </span>
              <span style={{fontWeight: 'bold'}}>
                銀行振込の場合、振込手数料をご負担いただきます。
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                お支払い方法
              </span>
              <span style={{fontWeight: 'bold'}}>
                銀行振込またはクレジットカード
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                代金の支払い時期
              </span>
              <span style={{fontWeight: 'bold'}}>
                銀行振込は初回申し込み後、10日以内にお振り込みいただきます。着金が確認できてから5日以内にサービス提供の開始となり、二回目以降はサービス開始日を起点として毎月同じ日にお振り込みいただきます。クレジットカードは初回申し込みと同時に課金が発生し、即時サービス提供開始となります。二回目以降はサービス開始日を起点として毎月同じ日に課金が発生いたします。
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                商品の引渡し時期
              </span>
              <span style={{fontWeight: 'bold'}}>
                銀行振込の場合、着金確認後5日以内にサービスの提供を開始いたします。クレジットカードの場合即時のサービス提供開始となります。
              </span>
            </div>

            <hr style={{borderColor: '#ccc'}}/>

            <div>
              <span style={{display: 'inline-block', width: '240px'}}>
                返品・交換不良品・解約について
              </span>
              <span style={{fontWeight: 'bold'}}>
                サービスの特性上、お申し込み後の返金には対応いたしかねます。解約につきましてはアカウント設定ページよりいつでも手続きが可能です。解約手続きをいただいた月の末日までサービスを提供し、翌月以降のサービス提供および請求を停止いたします。
              </span>
            </div>
          </div>
        </div>
        <Footer page/>
      </div>
    );
  }
}
