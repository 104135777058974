import React, { Component } from 'react';
import { Button }    from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Footer                           from '../organisms/footer';
import add_news_column_ja               from '../../assets/add_news_column_ja.gif';
import add_entry_to_list_ja             from '../../assets/add_entry_to_list_ja.gif';
import create_list_ja                   from '../../assets/create_list_ja.gif';
import add_slack_notification_ja        from '../../assets/add_slack_notification_ja.gif';
import slack_notification_configured_ja from '../../assets/slack_notification_configured_ja.png';
import exclusive_articles               from '../../assets/exclusive_articles.png';
import filter_search_ja                 from '../../assets/filter_search_ja.gif';
import add_information_ja               from '../../assets/add_information_ja.gif';
import evaluate_contribution_ja         from '../../assets/evaluate_contribution_ja.png';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/features-page.json';

import '../../css/features-page.css';

export default class FeaturesPage extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <div style={{textAlign: 'center', margin: '10px', paddingBottom: '20px'}}>
          <h1 id="auth-page-heading" className="datavase">datavase.io</h1>
          <div className="features-page-introduction">
            <span>
              {lang.featuresIntroduction}
            </span>
          </div>
          <VerticalTimeline animate={false}>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff'}}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>announcement</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.newsCurationFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.newsCurationFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.newsCurationFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={add_news_column_ja} />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff' }}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>list_alt</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.mylistFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.mylistFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.mylistFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={add_entry_to_list_ja} />
              <span className="vertical-timeline-element-paragraph">{lang.mylistFeatureParagraph2}</span>
              <img alt="" className="vertical-timeline-element-image" src={create_list_ja} />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff' }}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>notification_important</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.listNotificationFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.listNotificationFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.listNotificationFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={add_slack_notification_ja} />
              <span className="vertical-timeline-element-paragraph">{lang.listNotificationFeatureParagraph2}</span>
              <img alt="" className="vertical-timeline-element-image" src={slack_notification_configured_ja} />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff' }}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>library_books</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.exclusiveArticleFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.exclusiveArticleFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.exclusiveArticleFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={exclusive_articles} />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff' }}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>find_replace</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.filterSearchFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.filterSearchFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.filterSearchFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={filter_search_ja} />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#3779B3', color: '#fff' }}
              icon={<i className="material-icons" style={{lineHeight: '60px', fontSize: '30px'}}>edit</i>}
            >
              <h3 className="vertical-timeline-element-title">{lang.addInfoFeatureHeader}</h3>
              <h5 className="vertical-timeline-element-subtitle">{lang.addInfoFeatureSubheader}</h5>
              <span className="vertical-timeline-element-paragraph">{lang.addInfoFeatureParagraph}</span>
              <img alt="" className="vertical-timeline-element-image" src={add_information_ja} />
              <span className="vertical-timeline-element-paragraph">{lang.addInfoFeatureParagraph2}</span>
              <img alt="" className="vertical-timeline-element-image" src={evaluate_contribution_ja} />
            </VerticalTimelineElement>
          </VerticalTimeline>
          <LinkContainer to="/upgrade/">
            <Button bsStyle="primary">
              {lang.upgradeNowButton}
            </Button>
          </LinkContainer>
        </div>
        <Footer page/>
      </div>
    );
  }
}
