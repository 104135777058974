import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MetaTags from 'react-meta-tags';
import {withRouter} from 'react-router-dom';

import BlockSpinner from './partials/block-spinner';
import OrganizationAbstractPanel from './partials/organization-abstract-panel';
import OrganizationOfficersPanel from './partials/organization-officers-panel';
import ContactSocialPanel from './partials/contact-social-panel';
import IpoPanel from './partials/ipo-panel';
import ExitsPanel from './partials/exits-panel';
import NewsArticlesByResourcePanel from './partials/news-articles-by-resource-panel';
import CompanyDataGridPanel from './partials/company-data-grid-panel';
import CompanyGraphsPanel from './partials/company-graphs-panel';
import CompanyHistoryDetailsPanel from './partials/company-history-details-panel';
import VideoPanel from './partials/video-panel';
import TwitterTimelinePanel from './partials/twitter-timeline-panel';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/company-details-pane.json'
import '../css/details-pane.css';
import UpgradeToProceedModalRevised from "./partials/upgrade-to-proceed-modal-revised";
import UpgradeToProceedModalRevisedMobile from "./partials/upgrade-to-proceed-modal-revised-mobile";
import styled from "styled-components";
import color from "./styles/colors";

class CompanyDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      contributions: null,
      hasLoaded: false,
      id: null,
    };

    this._onTabSelect = this._onTabSelect.bind(this);

  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchCompanyWithRelatedResources(id)
      .then((res) => {
        this.setState({
          resources: res.data,
          hasLoaded: true,
          id: res.data.company.id,
        });
      }).catch((res) => {
        console.error(res);
      });
  }

  _onTabSelect(key) {
    if (key === "contributions") {
      if (!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('companies', id)
          .then(res => {
            this.setState({ contributions: res.data });
          })
          .catch(res => {
            console.error(res);
          });
      }
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return (<BlockSpinner visible={true} />);
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const { resources, id } = this.state;
    const company = resources.company;
    const newsArticles = resources.news_articles;
    const socialMediaList = resources.social_media_list;
    const fundingRounds = resources.funding_rounds;
    const investments = resources.investments;
    const acquisitions = resources.acquisitions;
    const estimatedRevenueHistories = resources.estimated_revenue_histories;
    const relationships = resources.relationships;
    const directors = resources.directors;
    const ipo = resources.ipo;
    const exits = resources.exits;
    const totalEmployeesHistories = resources.total_employees_histories;
    const estimatedTotalEmployeesHistories = resources.estimated_total_employees_histories;
    const hasExits = (exits.ipo && exits.ipo.length !== 0) ||
      (exits.acquisitions && exits.acquisitions.length !== 0);
    const twitterId = socialMediaList.twitter_id;
    const researchRequested = (this.props.sharedResources.researchRequests || []).find(req =>
      req.research_requestable_id === parseInt(id, 10) &&
      req.research_requestable_type === 'Organization'
    );

    return (
      <div id="details-page-contents">
        <Col sm={12} md={12} lg={12}>
          <OrganizationAbstractPanel
            organization={company}
            profileImage={company.profile_image_url}
            hideReviews
          />
          <MetaTags>
            <title>{DatavaseApi.extractOrganizationName(company, langCode)}</title>
            <meta name="description" content={DatavaseApi.extractDescription(company, langCode)} />
            <meta property="og:title" content={DatavaseApi.extractOrganizationName(company, langCode)} />
            <meta property="og:image" content={company.profile_image_url} />
          </MetaTags>
          <UpgradeToProceedModalRevised
              show={resources.needs_lock}
              onCancelClick={false}
              completelyHide={true}
          />
          <UpgradeToProceedModalRevisedMobile
              show={resources.needs_lock}
              onCancelClick={false}
              completelyHide={true}
          />
        </Col>
        <div className="clearfix">
          <Col sm={12} md={8} lg={8} className="clearfix">
            <TagBtn eventKey="review"
                    onClick={() => window.location.href = `/companies/${this._getId()}/`}>{lang.review}</TagBtn>
            <TagBtn eventKey="details" onClick={() => window.location.href = `/companies/${this._getId()}/details/`}
                    active={true}>{lang.detailTabTitle}</TagBtn>
            <TagBtn eventKey="recruits"
                    onClick={() => window.location.href = `/companies/${this._getId()}/recruits/`}>{lang.recruitsTabTitle}</TagBtn>
          </Col>
          <Col sm={12} md={8} lg={8}>
            {
              newsArticles.length !== 0 ?
                  <NewsArticlesByResourcePanel
                      newsArticles={newsArticles}
                  />
                  : null
            }
            <CompanyDataGridPanel
              company={company}
              fundingRounds={fundingRounds}
              lastTotalEmployeesHistory={totalEmployeesHistories[0]}
              directors={directors}
              ipo={ipo}
            />
            {
              ipo ? <IpoPanel ipo={ipo} /> : null
            }
            <CompanyGraphsPanel
              researchRequested={researchRequested}
              company={company}
              fundingRounds={fundingRounds}
              totalEmployeesHistories={totalEmployeesHistories}
              estimatedTotalEmployeesHistories={estimatedTotalEmployeesHistories}
              estimatedRevenueHistories={estimatedRevenueHistories}
            />
            {
              hasExits ? <ExitsPanel exits={exits} /> : null
            }
            <CompanyHistoryDetailsPanel
              researchRequested={researchRequested}
              company={company}
              fundingRounds={fundingRounds}
              investments={investments}
              acquisitions={acquisitions}
              totalEmployeesHistories={totalEmployeesHistories}
            />
            {
              company.video_url ? <VideoPanel url={company.video_url} /> : null
            }
            {
              twitterId ? <TwitterTimelinePanel screenName={twitterId} /> : null
            }
          </Col>
          <Col sm={12} md={4} lg={4}>
            <ContactSocialPanel
              resource={company}
              resourceType={'companies'}
              resourceId={id}
              websiteUrl={company.url}
              socialMediaList={socialMediaList}
              url={window.location.href}
              title={company.organization_name}
            />
            <OrganizationOfficersPanel
              researchRequested={researchRequested}
              organization={company}
              relationships={relationships}
            />
          </Col>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

const TagBtn = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 28.33%;
  margin: 2.5%;
  border: solid 2px ${color.primary};
  border-radius: 8px;
  padding: 8px;
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 12px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  &:checked {
    color: #fff;
    background: ${color.primary};
  }
`;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyDetailsPane));
