import React, { Component } from 'react'
import '../../css/terms-and-privacy-page.css'

import TopNavbar            from '../organisms/top-navbar';
import TopNavbarMobile      from '../organisms/top-navbar-mobile';
import Footer               from '../organisms/footer';

export default class TermsAndPrivacyPage extends Component {
  render() {
    return(
      <div id="terms-and-privacy-page">
        <TopNavbar/>
        <TopNavbarMobile/>
        <div id="terms-and-privacy-contents">
          <div id="terms-and-privacy-contents-container">
            <h1>利用規約</h1>
            <p>
              hackjpn株式会社（以下、「当社」といいます。）は、当社が企画運営するウェブサイト「datavase.io」（以下、「本サイト」といいます。）を通じて提供される一切のサービス（以下、「本サービス」といいます。）をユーザーへ提供するにあたり、datavase.io利用規約を以下のとおり定めます。
            </p>
            <p>
              第１条（定義） 1. 本サービスとは当社が提供するベンチャー企業のファイナンス情報及び求人案件についての情報提供サービスおよび、有料職業紹介事業者への紹介、及び当社が提携する有料職業紹介事業者が提供する有料職業紹介業に関わるサービス、それらに関連する全てのサービスを意味します。 2. ユーザーとは本サービスの利用者を意味します。 3. 本契約とは当社とユーザー間に成立する規約に基づく利用契約を意味します。
            </p>
            <p>
              第２条（適用） 1. 本規約は、ベンチャー企業のファイナンス情報及び求人案件についての情報提供、応募方法の提供等、本サービスの利用条件を定めるものであり、本サービスの全てのユーザーに適用されるものとします。 2. 当社は、ユーザーの承諾を得ることなく、本規約を随時変更することができます。 3. 利用者は、同利用規約の内容をすべて承諾したものとします。
            </p>
            <p>
              第３条（本サービスの提供） 当社は、以下の中から利用者に適切なものを当社の判断で提供するものとします。(0)ベンチャー企業のファイナンス情報(1)電話やe-mail、面談による就職相談の実施 (2)個別担当者による就職活動支援 (3)求人情報の提供 (4)当社事業提携先より就職コンサルジュの紹介 (5)紹介企業への推薦および選考結果の通知 (6)その他利用者の就職活動に有益と当社が判断する一切のサービス
            </p>
            <p>
              第４条（連絡について） 1. 当社がユーザーに対して行う、本サービスについての一切の通知は、ユーザーが登録したメールアドレス宛てのメールその他当社が適切と判断する方法により行います。また、ユーザーは、本サービスへの登録により、メールマガジンの配信登録が行われ、配信が開始されることをあらかじめ承諾するものとします。 2. 本サイト上またはユーザーが登録したメールアドレス宛ての電子メールによる通知の効力は、当社が当該通知を発信した時点をもって発生するものとし、それ以外の通知方法を用いる場合、その通知の効力は、当該通知が各ユーザーに到達するために合理的に必要な期間が経過した時点で発生するものとします。 3. またユーザーは当社提携先である第三者有料職業紹介事業者より直接連絡を受ける場合があります。
            </p>
            <p>
              第５条（禁止事項） 1．利用者は、以下の行為をしてはならないものとします。 (1) 虚偽または不正確な情報の提供 (2) 他人の名誉、心情や信用を傷つける行為 (3) 当社のサービス運営を妨害する行為 (4) 法令または公序良俗に違反する行為 (5) 特定の企業、団体、地域、個人に対しての誹謗・中傷・苦情・差別発言を行なう等の不適切な行為 (6) 当社サービスを得て知り得た求人企業に対し、当社を通さず直接連絡を取り、採用選考を受けるまたは入社する行為 (7) その他、本規約の定めに違反する行為
            </p>
            <p>
              第６条（情報の利用） 当社は、前条に定めるほか、本サービスの利用履歴等の情報を、会員登録前後を問わず閲覧・利用することがあるものとし、ユーザーはこれを予め承諾するものとします。また、当社は、ユーザー・求人企業間のメッセージ等の送受信履歴及び通話情報を、会員登録前後を問わず、閲覧・利用することができるものとします。当社は、本条に基づき当社が行った行為によりユーザーに生じた一切の損害等について何ら責任を負いません。
            </p>
            <p>
              第７条（協議解決） 当社およびユーザーは、本規約に定めのない事項または本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上、速やかに解決を図るものとします。
            </p>
            <p>
              第８条（免責） (1)本サービスの利用（サービスに伴う第三者への情報提供行為等を含む）、又は利用できないことから生じる一切の損害（精神的苦痛、求職活動の中断、又はその他の金銭的損失を含む一切の不利益）について、当社は故意又は重過失がない限り一切の責任を負わないこととします。 (2)当社は、本サービスを通じて提供するサービスがユーザーに与える損害につき、一切責任を負合わないこととします。 (3)当社は、本サービスにおけるデータが消去・変更されないことを保証できません。必要なデータはユーザーが自己の責任において保存することとして下さい。 (4)当社は、検討基準や判断理由、年収査定金額の算出方法等、当社及び求人企業の機密に属する事項についての質問等への回答、その他対応等は一切行いません。
            </p>
            <p>
              第９条（準拠法および管轄裁判所） 本サービスに関する一切については日本法に準拠して解釈されるものとし、本規約に関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
          </div>
        </div>
        <Footer page/>
      </div>
    );
  }
}
