import React, { Component } from 'react';
import { Panel, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

import '../../css/people-list-panel.css';

export default class PeopleListPanel extends Component {
  render() {
    const peopleList = this.props.peopleList.map((person, i) => {
      const profileImage  = person.profileImage || placeholder;
      const name          = person.name;
      const position      = person.position;
      const personPageUrl = `/people/${person.slug || person.id}/`;

      return(
        <li key={i} className="people-list-person">
          <Col xs={4} sm={4} md={4} lg={4}>
            <img
              src={profileImage}
              alt={name}
              className="people-list-person-picture"
            />
          </Col>
          <Col xs={8} sm={8} md={8} lg={8}>
            <span className="people-list-person-name">
              <Link to={personPageUrl}>
                {name}
              </Link>
            </span>
            <span className="people-list-person-position">
              {position}
            </span>
          </Col>
        </li>
      );
    });

    return(
      <Panel id="people-list-panel">
        <h2 id="people-list-heading">
          {this.props.peopleListHeading}
        </h2>
        <hr/>
        <ul id="people-list">
          {peopleList}
        </ul>
      </Panel>
    );
  }
}
