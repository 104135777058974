import React, { Component } from 'react';
import { Modal, FormControl, FormGroup, Panel, Tabs, Tab, Button, Label } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import BlockSpinner     from './block-spinner';
import placeholder      from '../../assets/datavase_logo_icon_grey.png';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/account-details-panel.json';
import '../../css/account-details-panel.css';

export default class AccountDetailsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      showConfirmAccountDeleteModal: false,
      updatingScreenName: false,
      updatingProfileImage: false,
      editingIntroduction: false,
      introduction: '',
      updatingIntroduction: false,
      editingScreenName: false,
      showScreenNameEditFeedbackMessage: false,
      screenName: '',
      screenNameValidationState: null,
    };
    this._onScreenNameChange            = this._onScreenNameChange.bind(this);
    this._onLogoutButtonClicked         = this._onLogoutButtonClicked.bind(this);
    this._showConfirmAccountDeleteModal = this._showConfirmAccountDeleteModal.bind(this);
    this._hideConfirmAccountDeleteModal = this._hideConfirmAccountDeleteModal.bind(this);
    this._onScreenNameEditClick         = this._onScreenNameEditClick.bind(this);
    this._onScreenNameSaveClick         = this._onScreenNameSaveClick.bind(this);
    this._onScreenNameCancelClick       = this._onScreenNameCancelClick.bind(this);
    this._onIntroductionEditClick         = this._onIntroductionEditClick.bind(this);
    this._onIntroductionChange         = this._onIntroductionChange.bind(this);
    this._onIntroductionSaveClick         = this._onIntroductionSaveClick.bind(this);
    this._onProfileImageChange          = this._onProfileImageChange.bind(this);
    this._onProfileImageSaveClick       = this._onProfileImageSaveClick.bind(this);
    this._onProfileImageCancelClick     = this._onProfileImageCancelClick.bind(this);
  }

  componentDidMount() {
    DatavaseApi.fetchContributor()
        .then(res => {
          this.setState({
            hasLoaded:   true,
            screenName:  res.data.screen_name,
            contributor: res.data,
            newImage:    null
          });
        })
        .catch(res => {
          console.error(res);
        });
  }

  _showConfirmAccountDeleteModal() {
    this.setState({showConfirmAccountDeleteModal: true});
  }

  _hideConfirmAccountDeleteModal() {
    this.setState({showConfirmAccountDeleteModal: false});
  }

  _onLogoutButtonClicked() {
    DatavaseApi.logout();
    window.location.href = '/login';
  }

  _onScreenNameEditClick() {
    this.setState({editingScreenName: true});
  }
  _onIntroductionEditClick() {
    this.setState({editingIntroduction: !this.state.editingIntroduction});
  }
  _onIntroductionChange(e) {
    this.setState({introductionValidationState: null});
    const screenName = e.target.value;

    this.setState({introduction: screenName});
    DatavaseApi.validateScreenName(screenName)
        .then(res => {
          const validationState = res.data.validity ? "success" : "error";
          this.setState({introductionValidationState: validationState});
        })
        .catch(res => {
          console.error(res);
        });
  }

  _onIntroductionSaveClick() {
    this.setState({updatingIntroduction: true});

    DatavaseApi.updateContributorIntroduction(this.state.introduction)
        .then(res => {
          window.location.reload();
        })
        .catch(res => {
          console.error(res);
          this.setState({
            updatingScreenName: false,
            editingScreenName:   false,
            screenNameEditSuccess: false,
            showScreenNameEditFeedbackMessage: true,
          });

          setTimeout(() => {
            this.setState({showScreenNameEditFeedbackMessage: false});
          }, 2000);
        });
  }

  _onScreenNameSaveClick() {
    this.setState({updatingScreenName: true});

    DatavaseApi.updateContributorScreenName(this.state.screenName)
        .then(res => {
          this.setState({
            contributor:         res.data,
            updatingScreenName: false,
            editingScreenName:   false,
            screenNameEditSuccess: true,
            showScreenNameEditFeedbackMessage: true,
          });

          setTimeout(() => {
            this.setState({showScreenNameEditFeedbackMessage: false});
          }, 2000);
        })
        .catch(res => {
          console.error(res);
          this.setState({
            updatingScreenName: false,
            editingScreenName:   false,
            screenNameEditSuccess: false,
            showScreenNameEditFeedbackMessage: true,
          });

          setTimeout(() => {
            this.setState({showScreenNameEditFeedbackMessage: false});
          }, 2000);
        });
  }

  _onScreenNameCancelClick() {
    this.setState({editingScreenName: false});
  }

  _onScreenNameChange(e) {
    this.setState({screenNameValidationState: null});
    const screenName = e.target.value;

    this.setState({screenName: screenName});
    DatavaseApi.validateScreenName(screenName)
        .then(res => {
          const validationState = res.data.validity ? "success" : "error";
          this.setState({screenNameValidationState: validationState});
        })
        .catch(res => {
          console.error(res);
        });
  }

  _onProfileImageSaveClick() {
    this.setState({updatingProfileImage: true});
    DatavaseApi.updateContributorProfileImage(this.state.newImage)
        .then(res => {
          this.setState({
            contributor: res.data,
            newImage: null,
            updatingProfileImage: false
          })
        })
        .catch(res => {
          console.error(res);
          this.setState({
            updatingProfileImage: false
          });
        });
  }

  _onProfileImageCancelClick() {
    this.setState({newImage: null});
  }

  _onProfileImageChange(e) {
    this.setState({newImage: e.target.files[0]});
  }
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const email    = DatavaseApi.getUserEmail();
    const contributor = this.state.contributor || {};
    const {
      introductionValidationState,
      screenNameValidationState,
      showScreenNameEditFeedbackMessage,
      screenNameEditSuccess
    } = this.state;

    const screenNameEditFeedBackMessageClassName = showScreenNameEditFeedbackMessage ?
        "screenname-saved-message screenname-saved-message-shown" :
        "screenname-saved-message screenname-saved-message-hidden";
    const screenNameEditFeedBackMessageBsStyle   = screenNameEditSuccess ? "success" : "danger";
    const screenNameEditFeedBackMessageString    = screenNameEditSuccess ? lang.screenNameSavedMessage : lang.screenNameFailedToSaveMessage;

    let profileImagePreview;
    if(this.state.newImage) {
      profileImagePreview = URL.createObjectURL(this.state.newImage);
    }else{
      profileImagePreview = contributor.profile_image_url || placeholder;
    }

    return(
        <div>
          <ConfirmAccountDeleteModal
              onCancelClick={this._hideConfirmAccountDeleteModal}
              show={this.state.showConfirmAccountDeleteModal}
          />
          <Panel id="account-details-panel">
            <Tabs id="account-details-tabs">
              <Tab eventKey={1} title={lang.accountDetailsPanelTitle}>
                { this.state.hasLoaded ?
                    <Panel>
                      <div>
                  <span className="account-details-label">
                    {lang.screenName}
                  </span>
                        {
                          this.state.editingScreenName ?
                              <div style={{display: 'inline-block'}}>
                                <FormGroup
                                    className="account-details-screenname-form"
                                    validationState={screenNameValidationState}
                                >
                                  <FormControl
                                      defaultValue={contributor.screen_name}
                                      onChange={this._onScreenNameChange}
                                  />
                                  <FormControl.Feedback/>
                                </FormGroup>
                                {
                                  this.state.updatingScreenName ?
                                      <div style={{display: 'inline-block'}}>
                                        <BlockSpinner visible/>
                                      </div>
                                      :
                                      <div style={{display: 'inline-block'}}>
                                        <Button
                                            bsStyle="primary"
                                            onClick={this._onScreenNameSaveClick}
                                            className="account-details-save-button"
                                            disabled={screenNameValidationState === 'error'}
                                        >
                                          {lang.saveScreenName}
                                        </Button>
                                        <Button
                                            bsStyle="danger"
                                            onClick={this._onScreenNameCancelClick}
                                            className="account-details-save-button"
                                        >
                                          {lang.cancelEditing}
                                        </Button>
                                      </div>
                                }
                              </div>
                              :
                              <div style={{display: 'inline-block'}}>
                      <span className="account-details-screenname">
                        {contributor.screen_name}
                      </span>
                                <Button
                                    onClick={this._onScreenNameEditClick}
                                    className="account-details-edit-button"
                                >
                                  <i className="material-icons">edit</i>
                                </Button>
                                <Label
                                    bsStyle={screenNameEditFeedBackMessageBsStyle}
                                    className={screenNameEditFeedBackMessageClassName}
                                >
                                  {screenNameEditFeedBackMessageString}
                                </Label>
                              </div>
                        }
                      </div>
                      <hr/>
                      <div>
                  <span className="account-details-label">
                    自己紹介文
                  </span>
                        {
                          this.state.updatingIntroduction ?
                              <div>
                                <BlockSpinner visible/>
                              </div>
                              :
                              !this.state.editingIntroduction ?
                                  <div style={{display: 'inline'}}>
                                                          <span className="account-details-screenname">
                        {contributor.introduction}
                      </span>
                                    <Button
                                        onClick={this._onIntroductionEditClick}
                                        className="account-details-edit-button"
                                        style={{display: 'inline'}}
                                    >
                                      <i className="material-icons">edit</i>
                                    </Button>
                                  </div>:
                                  <div style={{width:'100%',display: 'inline-block'}}>
                                    <FormGroup
                                        style={{width: "100%"}}
                                        className="account-details-screenname-form"
                                        validationState={introductionValidationState}
                                    >
                                      <FormControl
                                          style={{height: '6em'}}
                                          componentClass="textarea"
                                          defaultValue={contributor.introduction}
                                          onChange={this._onIntroductionChange}
                                      />
                                      <FormControl.Feedback/>
                                    </FormGroup>
                                    <Button
                                        bsStyle="primary"
                                        onClick={this._onIntroductionSaveClick}
                                        className="account-details-save-button"
                                        disabled={introductionValidationState === 'error'}
                                    >
                                      {lang.saveScreenName}
                                    </Button>
                                    <Button
                                        bsStyle="danger"
                                        onClick={this._onIntroductionEditClick}
                                        className="account-details-save-button"
                                    >
                                      {lang.cancelEditing}
                                    </Button>
                                  </div>
                        }
                      </div>
                      <hr/>
                      <div>
                  <span className="account-details-label">
                    {lang.profileImage}
                  </span>
                        <div style={{display: 'inline-block'}}>
                          <img
                              alt={contributor.screenName}
                              src={profileImagePreview}
                              width="60px"
                          />
                          <Button
                              style={{padding: '0', marginLeft: '10px'}}
                              className="account-details-edit-button"
                          >
                            <label style={{margin: '0'}}>
                              新しい画像をアップロード
                              <FormControl
                                  type="file"
                                  onChange={this._onProfileImageChange}
                                  style={{display: 'none'}}
                              />
                            </label>
                          </Button>
                          {
                            this.state.newImage ?
                                this.state.updatingProfileImage ?
                                    <div style={{display: 'inline-block'}}>
                                      <BlockSpinner visible/>
                                    </div>
                                    :
                                    <div style={{display: 'inline-block'}}>
                                      <Button
                                          bsStyle="primary"
                                          onClick={this._onProfileImageSaveClick}
                                          className="account-details-save-button"
                                          disabled={screenNameValidationState === 'error'}
                                      >
                                        {lang.saveScreenName}
                                      </Button>
                                      <Button
                                          bsStyle="danger"
                                          onClick={this._onProfileImageCancelClick}
                                          className="account-details-save-button"
                                      >
                                        {lang.cancelEditing}
                                      </Button>
                                    </div>
                                :
                                null
                          }
                        </div>
                      </div>
                      <hr/>
                      <div>
                  <span className="account-details-label">
                    {lang.email}
                  </span>
                        <span className="account-details-email">
                    {email}
                  </span>
                        <Button
                            bsStyle="danger"
                            onClick={this._onLogoutButtonClicked}
                        >
                          {lang.logoutButton}
                        </Button>
                      </div>
                      <hr/>
                      <div>
                  <span className="account-details-label">
                    {lang.password}
                  </span>
                        <Link to="/password_reset_request">
                          {lang.resetPassword}
                        </Link>
                      </div>
                      <hr/>
                      <div>
                  <span className="account-details-label">
                    {lang.deleteAccount}
                  </span>
                        <Link onClick={this._showConfirmAccountDeleteModal}>
                          {lang.accountDeleteButton}
                        </Link>
                      </div>
                    </Panel>
                    : <BlockSpinner visible/> }
              </Tab>
            </Tabs>
          </Panel>
        </div>
    );
  }
}

class ConfirmAccountDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: '',
      isProcessing: false,
    };
    this._onChange              = this._onChange.bind(this);
    this._deleteButtonDisabled  = this._deleteButtonDisabled.bind(this);
    this._deleteAccount         = this._deleteAccount.bind(this);
  }

  _onChange(e) {
    this.setState({confirmation: e.target.value});
  }

  _deleteButtonDisabled() {
    const email = DatavaseApi.getUserEmail();

    if(this.state.confirmation === email) {
      return false;
    }else{
      return true;
    }
  }

  _deleteAccount() {
    this.setState({isProcessing: true});
    DatavaseApi.deleteAccount(this.state.confirmation)
        .then(res => {
          DatavaseApi.forgetTokens();
          window.location.href = '/';
        })
        .catch(err => {
          console.error(err.response);
          this.setState({isProcessing: false});
        });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const deleteButtonDisabled = this._deleteButtonDisabled();

    return(
        <Modal show={this.props.show}>
          <Modal.Header>
            <Modal.Title>
              {lang.confirmAccountDeleteModalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lang.accountDeletionInstruction}
            <div style={{display: 'inline-block', marginTop: '10px', width: '100%'}}>
              <FormControl
                  placeholder={lang.confirmationFormPlaceholder}
                  onChange={this._onChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              this.state.isProcessing ? <BlockSpinner visible/> :
                  <div>
                    <Button
                        bsStyle="danger"
                        disabled={deleteButtonDisabled}
                        onClick={this._deleteAccount}
                    >
                      {lang.deleteButton}
                    </Button>
                    <Button onClick={this.props.onCancelClick}>
                      {lang.cancelButton}
                    </Button>
                  </div>
            }
          </Modal.Footer>
        </Modal>
    );
  }
}
