import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BlockSpinner from './partials/block-spinner';
import ListTable from './partials/list-table';
import * as DatavaseApi from './datavase-api';
import * as stateCacheActions from '../actions/state-cache';

import '../css/table-pane.css';

const FIELDS_LIST = ['id', 'product_name', 'description_en', 'date_of_release'];
const RESOURCE_NAME = 'products';
const COLUMN_NAMES = ['ID', 'Product Name', 'Description', 'Date of Release'];
const LINK_COLUMN_NAME = 'Product Name';

class ProductsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      loadedPage: 0,
      nextPageLoaded: false,
    }
  }

  // If the user comes back via the browerback and there's cached state, use the cache.
  // Otherwise, load resources from the server.
  componentDidMount() {
    let cachedState = this.props.stateCache.productsPaneState;
    if(window.location.action === 'POP' && cachedState != null){
      this.setState({
        entries: cachedState.entries,
        loadedPage: cachedState.loadedPage,
        nextPageLoaded: true,
      });
    }else{
      DatavaseApi.listProducts({fieldsList: FIELDS_LIST, page: 1})
      .then((res) => {
        let newEntries = this._constructEntriesList(res.data);
        this.setState({
          entries: newEntries,
          loadedPage: 1,
          nextPageLoaded: true,
        });
      }).catch((res) => {
        console.error(res);
      });
    }
    document.addEventListener('scroll', this._trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._trackScrolling);
    // cache the state in case the user wants to come back via the browserback.
    this.props.stateCacheActions.setProductsPaneState(this.state);
  }

  _constructEntriesList(products) {
    let newEntries = [];
    products.forEach((product) => {
      let entry = {
        'ID': product.id,
        'Product Name': product.product_name,
        'Description': product.description_en,
        'Date of Release': product.date_of_foundation,
        'id': product.id
      };
      newEntries.push(entry)
    });
    return newEntries;
  }

  _loadNextPage() {
    this.setState({nextPageLoaded: false});
    DatavaseApi.listProducts({fieldsList: FIELDS_LIST, page: this.state.loadedPage+1})
    .then((res) => {
      let newEntries = this._constructEntriesList(res.data);
      this.setState({
        entries: this.state.entries.concat(newEntries),
        loadedPage: this.state.loadedPage+1,
        nextPageLoaded: true,
      });
      if(res.data.length !== 0){
        document.addEventListener('scroll', this._trackScrolling);
      }
    }).catch((res) => {
      console.error(res);
      document.addEventListener('scroll', this._trackScrolling);
    });
  }

  _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  };

  render(){
    return (
      <div>
        <Panel>
          <ListTable
            entries={this.state.entries}
            columnNames={COLUMN_NAMES}
            linkColumnName={LINK_COLUMN_NAME}
            resourceName={RESOURCE_NAME}
          />
        </Panel>
        <BlockSpinner visible={!this.state.nextPageLoaded}/>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    stateCache: state.stateCache,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    stateCacheActions: bindActionCreators(stateCacheActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsPane);

