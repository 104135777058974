import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import AddNewInvestmentForm from './add-new-investment-form';
import * as DatavaseApi     from '../datavase-api';
import i18n from '../../i18n/contribute-modal.json';

export default class ContributeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contributeFormType: null,
    };
    this._backButton              = this._backButton.bind(this);
    this._onCancelClick           = this._onCancelClick.bind(this);
    this._onContributeButtonClick = this._onContributeButtonClick.bind(this);
    this._companyContributeFormSelectorButtons = this._companyContributeFormSelectorButtons.bind(this);
    this._personContributeFormSelectorButtons  = this._personContributeFormSelectorButtons.bind(this);
  }

  _onCancelClick() {
    this.setState({contributeFormType: null});
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  _onContributeButtonClick(contributeFormType) {
    this.setState({contributeFormType: contributeFormType});
  }

  _backButton() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Button
        onClick={() => { this.setState({contributeFormType: null}); }}
        style={{border: 'none', padding: '0', color: '#3779B3'}}
      >
        <i className="material-icons" style={{margin: '0', verticalAlign: 'middle'}}>
          chevron_left
        </i>{lang.backButton}
      </Button>
    );
  }

  _contributeForm() {
    const langCode           = DatavaseApi.getLangCode();
    const lang               = i18n[langCode];
    const contributeFormType = this.state.contributeFormType;
    const subject            = this.props.subject;
    const resourceType       = this.props.resourceType;

    let contributeFormSelectorButtons;
    if(resourceType === 'companies') {
      contributeFormSelectorButtons = this._companyContributeFormSelectorButtons();
    }else if(resourceType === 'investors') {
      contributeFormSelectorButtons = this._investorContributeFormSelectorButtons();
    }else if(resourceType === 'people') {
      contributeFormSelectorButtons = this._personContributeFormSelectorButtons();
    }

    if(!contributeFormType) {
      return(
        <div>
          <div style={{marginBottom: '10px'}}>
            {lang.chooseContributeType}
          </div>
          {contributeFormSelectorButtons}
        </div>
      );
    }else if(contributeFormType === 'funding') {
      return(
        <div>
          <div style={{marginBottom: '10px'}}>
            {this._backButton()}
          </div>
          <AddNewInvestmentForm
            investeeFormLocked
            defaultInvesteeOrganization={subject}
          />
        </div>
      );
    }else if(contributeFormType === 'investment' && ['companies', 'investors'].includes(resourceType)) {
      return(
        <div>
          <div style={{marginBottom: '10px'}}>
            {this._backButton()}
          </div>
          <AddNewInvestmentForm
            defaultInvestingOrganizations={[subject]}
          />
        </div>
      );
    }else if(contributeFormType === 'investment' && resourceType === 'people') {
      return(
        <div>
          <div style={{marginBottom: '10px'}}>
            {this._backButton()}
          </div>
          <AddNewInvestmentForm
            defaultInvestingPeople={[subject]}
          />
        </div>
      );
    }
  }

  _personContributeFormSelectorButtons() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('investment')}}
          style={{textAlign: 'left'}}
        >
          {lang.investmentInformation}
        </Button>
      </div>
    );
  }

  _investorContributeFormSelectorButtons() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('funding')}}
          style={{textAlign: 'left'}}
        >
          {lang.fundingInformation}
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('investment')}}
          style={{textAlign: 'left'}}
        >
          {lang.investmentInformation}
        </Button>
      </div>
    );
  }

  _companyContributeFormSelectorButtons() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('funding')}}
          style={{textAlign: 'left'}}
        >
          {lang.fundingInformation}
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('investment')}}
          style={{textAlign: 'left'}}
        >
          {lang.investmentInformation}
        </Button>
      {/*<Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('basic')}}
          style={{textAlign: 'left'}}
        >
          この企業の基本情報(本社所在地や創業年月日など)
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('social accounts')}}
          style={{textAlign: 'left'}}
        >
          この企業のSNSアカウント
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('total employees history')}}
          style={{textAlign: 'left'}}
        >
          総従業員数の推移
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('revenue history')}}
          style={{textAlign: 'left'}}
        >
          推定収益の推移
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('timeline history')}}
          style={{textAlign: 'left'}}
        >
          沿革・経歴
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('acquisition')}}
          style={{textAlign: 'left'}}
        >
          買収・M&A情報
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('sell-off')}}
          style={{textAlign: 'left'}}
        >
          売却情報
        </Button>
        <Button
          block
          bsStyle="primary"
          onClick={() => {this._onContributeButtonClick('ipo')}}
          style={{textAlign: 'left'}}
        >
          IPO情報
        </Button>*/}
      </div>
    );
  }


  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const form = this._contributeForm();

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          {lang.contributeModalTitle}
        </Modal.Header>
        <Modal.Body>
          {form}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onCancelClick}>
            {lang.cancelButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
