import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, ControlLabel, Form, FormControl, FormGroup, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import BlockSpinner from './block-spinner';
import ListTable from './list-table';
import chatwork_logo from '../../assets/chatwork_logo.png';
import slack_logo from '../../assets/slack_logo.png';
import * as DatavaseApi from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n from '../../i18n/notification-channels-configuration-modal.json';

class NotificationChannelsConfigurationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedNotificationChannels: [],
      chatworkChannelsHasLoaded: false,
      chatworkChannels:          [],
      slackChannelsHasLoaded:    false,
      slackChannels:             [],
      selectedChatworkChannel:   null,
    };
    this._onChatworkChannelSelected   = this._onChatworkChannelSelected.bind(this);
    this._onAddChatworkChannelClicked = this._onAddChatworkChannelClicked.bind(this);
    this._onSlackChannelSelected      = this._onSlackChannelSelected.bind(this);
    this._onAddSlackChannelClicked    = this._onAddSlackChannelClicked.bind(this);
  }

  componentDidMount() {
    this._fetchNotificationChannels();
  }

    UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.sharedResources.oauthStatus.chatwork &&
       this.state.chatworkChannelsHasLoaded === false) {
      this._fetchChatworkChannels();
    }

    if(nextProps.sharedResources.oauthStatus.slack &&
       this.state.slackChannelsHasLoaded === false) {
      this._fetchSlackChannels();
    }
  }

  _fetchNotificationChannels() {
    const listId = this.props.listId;
    DatavaseApi.listNotificationChannelsByList(listId)
    .then(res => {
      this.setState({addedNotificationChannels: res.data});
    })
    .catch(res => {
      console.error(res);
    });
  }

  _notificationChannelsTableColumnNames() {
    return ['Authorizer', 'Channel Name', 'Action'];
  }

  _notificationChannelsTableColumnWidth() {
    return ['50px', null, '50px'];
  }

  _notificationChannelsTableEntries(channels) {
    const columnNames = this._notificationChannelsTableColumnNames();
    return channels.map((channel, i) => {
      let onRemoveClicked = () => {
        this._removeNotificationChannel(channel.id);
      };

      let actionButton = (
        <Button onClick={onRemoveClicked.bind(this)} style={{border: 'none'}}>
          <i className="material-icons remove-from-list-icon">
            remove_circle
          </i>
        </Button>
      );

      let authorizerIcon;
      if(channel.authorizer_name === 'ChatWork') {
        authorizerIcon = (
          <img alt="ChatWork" src={chatwork_logo} width="40px"/>
        );
      }else if(channel.authorizer_name === 'Slack') {
        authorizerIcon = (
          <img alt="Slack" src={slack_logo} width="40px"/>
        );
      }

      return {
        [columnNames[0]]: authorizerIcon,
        [columnNames[1]]: channel.channel_name,
        [columnNames[2]]: actionButton,
        'id': channel.id
      };
    });
  }

  _fetchChatworkChannels() {
    this.setState({chatworkChannelsHasLoaded: false});
    DatavaseApi.listChatworkChannels()
    .then(res => {
      const filteredChatworkChannels = this._filterChatworkChannels(res.data);
      this.setState({
        chatworkChannels: filteredChatworkChannels,
        selectedChatworkChannel: filteredChatworkChannels[0],
        chatworkChannelsHasLoaded: true
      });
    })
    .catch(res => {
      console.error(res);
      this.setState({chatworkChannelsHasLoaded: true});
    });
  }

  _fetchSlackChannels() {
    this.setState({slackChannelsHasLoaded: false});
    DatavaseApi.listSlackChannels()
    .then(res => {
      this.setState({
        slackChannels: res.data,
        selectedSlackChannel: res.data[0],
        slackChannelsHasLoaded: true
      });
    })
    .catch(err => {
      console.error(err);
      this.setState({slackChannelsHasLoaded: true});
    })
  }

  _addNotificationChannelToList(channelId, channelName, authorizerName) {
    const listId = this.props.listId;
    DatavaseApi.addNotificationChannelToList(listId, channelId, channelName, authorizerName)
    .then(res => {
      this.setState({selectedChatworkChannel: null});
      this._fetchNotificationChannels();
    })
    .catch(res => {
      console.error(res);
    });
  }

  _removeNotificationChannel(id) {
    DatavaseApi.removeNotificationChannel(id)
    .then(res => {
      this._fetchNotificationChannels();
    })
    .catch(res => {
      console.error(res);
    });
  }

  _chatworkOptions() {
    const chatworkChannels = this.state.chatworkChannels;
    return chatworkChannels.map((channel, i) => {
      return(
        <option key={i} value={i}>
          {channel.name}
        </option>
      );
    });
  }

  _slackOptions() {
    const slackChannels = this.state.slackChannels;
    return slackChannels.map((channel, i) => {
      return(
        <option key={i} value={i}>
          {channel.name}
        </option>
      );
    });
  }

  _onChatworkChannelSelected(e) {
    const channel = this.state.chatworkChannels[e.target.value];
    this.setState({
      selectedChatworkChannel: channel
    });
  }

  _onSlackChannelSelected(e) {
    const channel = this.state.slackChannels[e.target.value];
    this.setState({
      selectedSlackChannel: channel
    });
  }

  _onAddChatworkChannelClicked(e) {
    const channel = this.state.selectedChatworkChannel;
    if(!channel) { return; }
    const channelId = channel['room_id'];
    const channelName = channel['name'];

    this._addNotificationChannelToList(channelId, channelName, 'ChatWork');
  }

  _onAddSlackChannelClicked(e) {
    const channel = this.state.selectedSlackChannel;
    if(!channel) { return; }
    const channelId = channel['id'];
    const channelName = channel['name'];

    this._addNotificationChannelToList(channelId, channelName, 'Slack');
  }

  _filterChatworkChannels(channels) {
    const addedNotificationChannelsIds = this.state.addedNotificationChannels.map(c => parseInt(c.channel_id, 10));
    return channels.filter(channel => {
      return channel.type !== 'direct' && !addedNotificationChannelsIds.includes(channel.room_id)
    });
  }

  _chatworkConnectionStatusMessage() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    let chatworkConnectionStatusMessage;
    if(this.props.sharedResources.oauthStatus.chatwork === true) {
      chatworkConnectionStatusMessage = (
        <span>{lang.integrationConfiguredMessage}</span>
      );
    }else{
      chatworkConnectionStatusMessage = (
        <span>
          {lang.chatworkIntegrationNotConfiguredMessage}
          <Link to='/account_settings'>{lang.oauthConfigurationPageLinkText}</Link>
        </span>
      );
    }
    return chatworkConnectionStatusMessage;
  }

  _slackConnectionStatusMessage() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    let slackConnectionStatusMessage;
    if(this.props.sharedResources.oauthStatus.slack === true) {
      slackConnectionStatusMessage = (
        <span>{lang.integrationConfiguredMessage}</span>
      );
    }else{
      slackConnectionStatusMessage = (
        <span>
          {lang.slackIntegrationNotConfiguredMessage}
          <Link to='/account_settings'>{lang.oauthConfigurationPageLinkText}</Link>
        </span>
      );
    }
    return slackConnectionStatusMessage;
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const entries = this._notificationChannelsTableEntries(this.state.addedNotificationChannels);
    const chatworkConnectionStatus        = this.props.sharedResources.oauthStatus.chatwork;
    const chatworkConnectionStatusMessage = this._chatworkConnectionStatusMessage();
    const slackConnectionStatus           = this.props.sharedResources.oauthStatus.slack;
    const slackConnectionStatusMessage    = this._slackConnectionStatusMessage();
    const columnNames = this._notificationChannelsTableColumnNames();
    const columnWidth = this._notificationChannelsTableColumnWidth();

    let chatworkChannelsSelector;
    if(!chatworkConnectionStatus) {
      chatworkChannelsSelector = null;
    }else if(!this.state.chatworkChannelsHasLoaded){
      chatworkChannelsSelector = (
        <BlockSpinner visible={true}/>
      );
    }else{
      chatworkChannelsSelector = (
        <Form inline>
          <FormControl
            componentClass="select"
            placeholder="Select channels"
            disabled={!chatworkConnectionStatus}
            onChange={this._onChatworkChannelSelected}
          >
            {this._chatworkOptions()}
          </FormControl>
          <Button
            onClick={this._onAddChatworkChannelClicked}
            bsStyle="primary"
          >
            追加する
          </Button>
        </Form>
      );
    }

    let slackChannelsSelector;
    if(!slackConnectionStatus) {
      slackChannelsSelector = null;
    }else if(!this.state.slackChannelsHasLoaded){
      slackChannelsSelector = (
        <BlockSpinner visible={true}/>
      );
    }else{
      slackChannelsSelector = (
        <Form inline>
          <FormControl
            componentClass="select"
            placeholder="Select channels"
            disabled={!slackConnectionStatus}
            onChange={this._onSlackChannelSelected}
          >
            {this._slackOptions()}
          </FormControl>
          <Button
            onClick={this._onAddSlackChannelClicked}
            bsStyle="primary"
          >
            追加する
          </Button>
        </Form>
      );
    }

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.notificationSettingModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form inline>
              <ControlLabel>
                <img alt="chatwork" src={chatwork_logo} width="30px"/>Chatwork
              </ControlLabel>
              {chatworkConnectionStatusMessage}
            </Form>
            {chatworkChannelsSelector}
          </FormGroup>
          <FormGroup>
            <Form inline>
              <ControlLabel>
                <img alt="slack" src={slack_logo} width="30px"/>Slack
              </ControlLabel>
              {slackConnectionStatusMessage}
            </Form>
            {slackChannelsSelector}
          </FormGroup>
          <ListTable
            condensed
            disableHeaders
            entries={entries}
            columnNames={columnNames}
            columnWidth={columnWidth}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onDoneClicked}>
            {lang.settingDoneButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationChannelsConfigurationModal);

