import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styled from "styled-components";
import media from "styled-media-query";

import BlockSpinner from '../partials/block-spinner';
import * as stateCacheActions from '../../actions/state-cache';
import * as DatavaseApi from '../datavase-api';
import color from '../styles/colors.js';

import SidePanel from '../organisms/side-panel.js';
import ArticlePreviewPanel from '../organisms/article-preview-panel';

class DatavaseArticlesPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      tags: [],
      tagsSelected: [],
      loadedPage: 0,
      nextPageLoaded: false
    };
    this._trackScrolling = this._trackScrolling.bind(this);
  }

  componentDidMount() {
    let cachedState = this.props.stateCache.datavaseArticlesPaneState;
    if (window.location.action === 'POP' && cachedState != null) {
      this.setState({
        articles: cachedState.articles,
        tags: cachedState.tags,
        tagsSelected: cachedState.tagsSelected,
        loadedPage: cachedState.loadedPage,
        nextPageLoaded: true,
      });
    } else {
      this._fetchTags();
      this._loadNextPage();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._trackScrolling);
    // cache the state in case the user wants to come back via the browserback.
    this.props.stateCacheActions.setDatavaseArticlesPaneState(this.state);
  }

  _loadNextPage() {
    this.setState({ nextPageLoaded: false });
    const pageToLoad = this.state.loadedPage + 1;
    this._fetchArticles(pageToLoad);
  }

  _fetchTags() {
    DatavaseApi.listDatavaseArticleTags().then((res) => {
      this.setState({
        tags: res.data,
      });
    })
  }

  _handleTagsChange(e, key) {
      let tagSelected = [];
    if (e.target.checked) {
        tagSelected[0] = key;
    } else {
        tagSelected = null;
    }

    this.setState({
      articles: [],
      loadedPage: 0,
      nextPageLoaded: false,
        tagsSelected: tagSelected,
    }, () => {
      this._fetchArticles(1);
    });
  }

  _handleTagsSet(keys) {
    //console.log(keys);
    this.setState({
      tagsSelected: keys,
      articles: [],
      loadedPage: 0,
      nextPageLoaded: false,
    }, () => {
      this._fetchArticles(1);
    });
  }

  _fetchArticles(page) {
    const params = { tags: this.state.tagsSelected, sort: '-published_at', page: page, perPage: 5 };
    DatavaseApi.listDatavaseArticles(params)
      .then((res) => {
        this.setState({
          articles: this.state.articles.concat(res.data),
          loadedPage: page,
          nextPageLoaded: true,
        });
        if (res.data.length !== 0) {
          document.addEventListener('scroll', this._trackScrolling);
        }
      }).catch((res) => {
        console.error(res);
        document.addEventListener('scroll', this._trackScrolling);
      });
  }

  _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _trackScrolling() {
    const wrappedElement = document.getElementById('root');
    if (this._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  };

  _articlePreviewPanels(articles) {
    return articles.map((article, i) => {
      if (article.primary_images.length > 0) {
        article.image = article.primary_images[0].image;
      }
      return (
        <ArticlePreviewPanel
          article={article}
          tagsHandler={(tags) => this._handleTagsSet(tags)}
          key={i}
        />
      );
    });
  }

  render() {
    const articlePreviewPanels = this._articlePreviewPanels(this.state.articles);
    return (
      <Wrapper>
        <MainColumn>
          <TagWrapper>
            {this.state.tags.map(tag =>
                <div key={tag} style={{marginBottom: "0.5em"}}>
                    <input style={{display: "none"}} type="checkbox" name="tags" value={tag}
                           checked={this.state.tagsSelected === tag} onChange={(e) => this._handleTagsChange(e, tag)}
                           id={tag}/>
                    <TagBtn htmlFor={tag} active={this.state.tagsSelected === tag}> {tag} </TagBtn>
              </div>
            )}
          </TagWrapper>
          <ArticleArea>
            <CardWrapper>
              {articlePreviewPanels}
            </CardWrapper>
            <BlockSpinner visible={!this.state.nextPageLoaded} />
          </ArticleArea>
        </MainColumn>
        <SideColumn>
          <SidePanel mode="articles" />
        </SideColumn>
      </Wrapper>
    );
  }
}
function mapStateToProps(state) {
  return {
    stateCache: state.stateCache,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    stateCacheActions: bindActionCreators(stateCacheActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatavaseArticlesPane);


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

const MainColumn = styled.div`
  width: 65%;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const SideColumn = styled.div`
  width: 30%;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;


const TagWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  max-width: 60%;
  margin: 0 auto 24px;
  ${media.lessThan("medium")`
    max-width: 100%;
  `}
`;

const TagBtn = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: solid 2px ${color.primary};
  border-radius: 8px;
  padding: 8px;
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 12px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  &:checked {
    color: #fff;
    background: ${color.primary};
  }
`;

const ArticleArea = styled.div`
  ${media.lessThan("medium")`
    margin-bottom: 48px;
  `}
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 2.4rem;
  width: 100%;
  ${media.lessThan("medium")`
    grid-template-columns: repeat(1, 1fr);
  `}
`;
