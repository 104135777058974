import React, { Component } from 'react';
import { Button, FormControl, ControlLabel, FormGroup } from 'react-bootstrap';

import BlockSpinner from './block-spinner';
import PriceForm from './price-form';
import DateForm from './date-form';
import AutosuggestForm from './autosuggest-form';
import placeholder from '../../assets/datavase_logo_icon.png';
import * as DatavaseApi from '../datavase-api';

import i18n from '../../i18n/investment-form';
import '../../css/investment-form.css';

function getSuggestionValue(suggestion) {
  return suggestion.organization_name ||
    `${suggestion.first_name} ${suggestion.last_name}`;
}

function renderSuggestion(suggestion) {
  const name = suggestion.organization_name ||
    `${suggestion.first_name} ${suggestion.last_name}`;
  return (
    <span
      style={{
        display: 'inline-block',
        borderTop: '1px solid #ddd',
        width: '100%',
        padding: '4px',
      }}
    >
      <img
        src={suggestion.thumb_profile_image_url || placeholder}
        alt={name}
        style={{ width: '30px', marginRight: '5px' }}
      />
      {name}
    </span>
  );
}

function preview(suggestion) {
  const name = suggestion.organization_name ||
    `${suggestion.first_name} ${suggestion.last_name}`;
  return (
    <Button style={{ margin: '2px 2px' }}>
      <img
        src={suggestion.thumb_profile_image_url || placeholder}
        alt={name}
        style={{ width: '20px', marginRight: '5px' }}
      />
      {name}
      <i className="material-icons" style={{ marginLeft: '5px', verticalAlign: 'middle', fontSize: '15px' }}>clear</i>
    </Button>
  );
}

const previewOuterStyle = {
  display: 'inline-block',
  margin: '0px'
};

export default class InvestmentForm extends Component {
  constructor(props) {
    super(props);

    const precisionAwareDateStr = DatavaseApi.getPrecisionAwareDateStr(
      props.defaultDateOfFunding, props.defaultPrecisionOfDateOfFunding
    );

    this.state = {
      investment: {
        amount: props.defaultAmount,
        currency_code: props.defaultCurrency || 'USD',
        date_of_investment: precisionAwareDateStr,
        funding_type_name: props.defaultFundingType,
        investing_organizations_ids: (props.defaultInvestingOrganizations || []).map(org => org.id),
        investing_people_ids: (props.defaultInvestingPeople || []).map(person => person.id),
        recipient_organization_id: (props.defaultInvesteeOrganization || {}).id,
        source_urls: (props.defaultSourceUrls || []),
      },
      sourceUrl: '',
    };

    this._onChange = this._onChange.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._onSourceUrlChange = this._onSourceUrlChange.bind(this);
    this._onSourceUrlKeyPress = this._onSourceUrlKeyPress.bind(this);
    this._onInvesteeFormChange = this._onInvesteeFormChange.bind(this);
    this._onInvestorsFormChange = this._onInvestorsFormChange.bind(this);
    this._onAmountChanged = this._onAmountChanged.bind(this);
    this._onCurrencyChanged = this._onCurrencyChanged.bind(this);
    this._onSubmitClick = this._onSubmitClick.bind(this);
    this._onEnteredUrlClick = this._onEnteredUrlClick.bind(this);
  }

  _onInvestmentChange(investment) {
    if (this.props.onChange) {
      this.props.onChange(investment);
    }
  }

  _onChange(e) {
    const newInvestment = Object.assign({}, this.state.investment, {
      [e.target.name]: e.target.value,
    });
    this.setState({
      investment: newInvestment
    });
    this._onInvestmentChange(newInvestment);
  }

  _onSourceUrlChange(e) {
    this.setState({
      sourceUrl: e.target.value
    });
  }

  _onDateChange(dateStr) {
    const newInvestment = Object.assign({}, this.state.investment, {
      date_of_investment: dateStr
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _onSourceUrlKeyPress(e) {
    if (e.key === 'Enter') {
      const url = this.state.sourceUrl;
      let newUrls = this.state.investment.source_urls.slice();
      if (!newUrls.includes(url)) {
        newUrls = newUrls.concat([url]);
        const newInvestment = Object.assign({}, this.state.investment, {
          source_urls: newUrls
        });

        this.setState({ investment: newInvestment });
        this._onInvestmentChange(newInvestment);
      }
    }
  }

  _onInvesteeFormChange(selections) {
    const investee = selections.length === 0 ? {} : selections[0];
    const newInvestment = Object.assign({}, this.state.investment, {
      recipient_organization_id: investee.id,
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _onInvestorsFormChange(selections) {
    let organizationIdList = [];
    let personIdList = [];

    selections.forEach((selection, i) => {
      if (['companies', 'investors', 'schools'].includes(selection.resource_type_name)) {
        organizationIdList.push(selection.id);
      } else if (selection.resource_type_name === 'people') {
        personIdList.push(selection.id);
      }
    });

    const newInvestment = Object.assign({}, this.state.investment, {
      investing_organizations_ids: organizationIdList,
      investing_people_ids: personIdList
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _onAmountChanged(amount) {
    const newInvestment = Object.assign({}, this.state.investment, {
      amount: amount
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _onCurrencyChanged(currency) {
    const newInvestment = Object.assign({}, this.state.investment, {
      currency_code: currency
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _onSubmitClick() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.investment);
    }
  }

  _onEnteredUrlClick(index) {
    let newUrls = this.state.investment.source_urls.slice();
    newUrls.splice(index, 1);
    let newInvestment = Object.assign({}, this.state.investment, {
      source_urls: newUrls
    });
    this.setState({ investment: newInvestment });
    this._onInvestmentChange(newInvestment);
  }

  _errorMessages() {
    const errors = this.props.errors || {};
    let messagesToDisplay = [];
    for (const key in errors) {
      if (key) {
        let messages = errors[key];
        messages.forEach((message, i) => {
          messagesToDisplay.push(
            <span
              style={{ display: 'inline-block', width: '100%', color: 'orange', fontWeight: 'bold' }}
              key={`${key}-${i}`}
            >
              {key}: {message}
            </span>
          );
        });
      }
    }
    return messagesToDisplay;
  }

  _isCreateButtonClickable() {
    const investment = this.state.investment;
    if (
      investment.date_of_investment &&
      investment.recipient_organization_id &&
      investment.funding_type_name
    ) {
      if (this._hasChanged()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  _hasChanged() {
    const newInvestment = this.state.investment;

    const {
      defaultCurrency,
      defaultFundingType,
    } = this.props;

    const defaultAmount = parseFloat(this.props.defaultAmount, 10);
    const defaultInvesteeOrgId = (this.props.defaultInvesteeOrganization || {}).id;
    const defaultInvestingOrgIds = (this.props.defaultInvestingOrganizations || []).map(org => org.id);
    const defaultInvestingPeopleIds = (this.props.defaultInvestingPeople || []).map(person => person.id);
    const defaultSourceUrls = this.props.defaultSource || [];
    const defaultPrecisionAwareDate = DatavaseApi.getPrecisionAwareDateStr(
      this.props.defaultDateOfFunding, this.props.defaultPrecisionOfDateOfFunding
    );

    if (defaultInvesteeOrgId !== newInvestment.recipient_organization_id) {
      //console.log(defaultInvesteeOrgId, newInvestment.recipient_organization_id);
      return true;
    }

    const stringifiedDefaultInvestingOrgIds = JSON.stringify(defaultInvestingOrgIds);
    const stringifiedNewInvestingOrgIds = JSON.stringify(newInvestment.investing_organizations_ids);
    if (stringifiedDefaultInvestingOrgIds !== stringifiedNewInvestingOrgIds) {
      return true;
    }

    const stringifiedDefaultInvestingPeopleIds = JSON.stringify(defaultInvestingPeopleIds);
    const stringifiedNewInvestingPeopleIds = JSON.stringify(newInvestment.investing_people_ids);
    if (stringifiedDefaultInvestingPeopleIds !== stringifiedNewInvestingPeopleIds) {
      return true;
    }

    if (defaultCurrency !== newInvestment.currency_code) {
      return true;
    }

    if (defaultAmount !== parseFloat(newInvestment.amount, 10)) {
      return true;
    }

    if (defaultFundingType !== newInvestment.funding_type_name) {
      return true;
    }

    if (defaultPrecisionAwareDate !== newInvestment.date_of_investment) {
      return true;
    }

    if (JSON.stringify(defaultSourceUrls) !== JSON.stringify(newInvestment.source_urls)) {
      return true;
    }

    return false;
  }

  _constructSourceUrlsList() {
    const urls = this.state.investment.source_urls;
    return urls.map((url, i) => {
      return (
        <Button className="entered-source-url" key={i} onClick={() => { this._onEnteredUrlClick(i) }}>
          <i className="material-icons" style={{ verticalAlign: 'middle', padding: '0', marginRight: '4px' }}>
            close
          </i>
          {`${url.slice(0, 80)}${url.length > 80 ? '...' : ''}`}
        </Button>
      );
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const createButtonClickable = this._isCreateButtonClickable();
    const sourceUrls = this._constructSourceUrlsList();
    const investeeOrg = this.props.defaultInvesteeOrganization;
    const investee = investeeOrg ? [investeeOrg] : [];
    const investingOrganizations = this.props.defaultInvestingOrganizations || [];
    const investingPeople = this.props.defaultInvestingPeople || [];
    const investors = [...investingOrganizations, ...investingPeople];
    const defaultAmount = this.props.defaultAmount;
    const defaultCurrency = this.props.defaultCurrency;
    const defaultFundingType = this.props.defaultFundingType;
    const defaultDateOfFunding = this.props.defaultDateOfFunding;
    const defaultPrecisionOfDateOfFunding = this.props.defaultPrecisionOfDateOfFunding;

    return (
      <div>
        {this._errorMessages()}
        <FormGroup>
          <ControlLabel>{lang.investeeOrganizationLabel}</ControlLabel>
          <AutosuggestForm
            locked={this.props.investeeFormLocked}
            selectSingle
            defaultSelections={investee}
            resourceTypeToSearch="organizations"
            placeholder={lang.investeeOrganizationPlaceholder}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            preview={preview}
            previewOuterStyle={previewOuterStyle}
            onChange={this._onInvesteeFormChange}
          />
        </FormGroup>
        <FormGroup>
          <PriceForm
            label={lang.amountLabel}
            placeholder={lang.amountPlaceholder}
            onAmountChanged={this._onAmountChanged}
            onCurrencyChanged={this._onCurrencyChanged}
            defaultAmount={defaultAmount}
            defaultCurrency={defaultCurrency}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>{lang.fundingTypeLabel}</ControlLabel>
          <FormControl
            componentClass="select"
            name="funding_type_name"
            defaultValue={defaultFundingType || ""}
            onChange={this._onChange}
          >
            <option value="" disabled>{lang.fundingTypePlaceholder}</option>
            <option value="VC Fundraising">{lang.vcFundraising}</option>
            <option value="Angel">{lang.angel}</option>
            <option value="Preseed">{lang.preseed}</option>
            <option value="Seed">{lang.seed}</option>
            <option value="Series A">{lang.seriesA}</option>
            <option value="Series B">{lang.seriesB}</option>
            <option value="Series C">{lang.seriesC}</option>
            <option value="Series D">{lang.seriesD}</option>
            <option value="Series E">{lang.seriesE}</option>
            <option value="Series F">{lang.seriesF}</option>
            <option value="Series G">{lang.seriesG}</option>
            <option value="Series H">{lang.seriesH}</option>
            <option value="Debt Financing">{lang.debtFinancing}</option>
            <option value="Convertible Note">{lang.convertibleNote}</option>
            <option value="Private Equity">{lang.privateEquity}</option>
            <option value="Grant">{lang.grant}</option>
            <option value="Post-IPO Equity">{lang.postIpoEquity}</option>
            <option value="Post-IPO Debt">{lang.postIpoDebt}</option>
          </FormControl>
        </FormGroup>
        <FormGroup>
          <DateForm
            label={lang.dateLabel}
            defaultDate={defaultDateOfFunding}
            defaultPrecision={defaultPrecisionOfDateOfFunding}
            onChange={this._onDateChange}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>{lang.investorsLabel}</ControlLabel>
          <AutosuggestForm
            defaultSelections={investors}
            resourceTypeToSearch="investables"
            placeholder={lang.investorsPlaceholder}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            preview={preview}
            previewOuterStyle={previewOuterStyle}
            onChange={this._onInvestorsFormChange}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>{lang.sourceUrlsLabel}</ControlLabel>
          <div className="source-urls-form">
            {sourceUrls}
            <FormControl
              onChange={this._onSourceUrlChange}
              onKeyPress={this._onSourceUrlKeyPress}
              placeholder={lang.sourceUrlsPlaceholder}
            />
          </div>
        </FormGroup>
        {
          this.props.isProcessing ?
            <BlockSpinner visible />
            :
            <Button
              disabled={!createButtonClickable}
              onClick={this._onSubmitClick}
              bsStyle="primary"
              block
            >
              {this.props.submitButtonText}
            </Button>
        }
      </div>
    );
  }
}

