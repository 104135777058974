import React, { Component } from 'react';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import EvaluateButtons            from './evaluate-buttons';
import ContributorTooltipContents from './contributor-tooltip-contents';
import ContributionDetailsModal   from './contribution-details-modal';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/fundraisings-history-table.json';
import '../../css/history-table.css';

export default class FundraisingsHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContributionDetailsModal: false,
      contribution: null,
      selectedFunding: {},
    };
    this._showContributionDetailsModal = this._showContributionDetailsModal.bind(this);
    this._hideContributionDetailsModal = this._hideContributionDetailsModal.bind(this);
    this._onSeeDetailsClick            = this._onSeeDetailsClick.bind(this);
  }

  _showContributionDetailsModal() {
    this.setState({showContributionDetailsModal: true});
  }

  _hideContributionDetailsModal() {
    this.setState({showContributionDetailsModal: false});
  }

  _onSeeDetailsClick(contribution) {
    const selectedFunding = this.props.fundraisings.find(funding => funding.id === contribution.information_id);
    this.setState({
      contribution: contribution,
      selectedFunding: selectedFunding
    });
    this._showContributionDetailsModal();
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    let fundraisings = this.props.fundraisings.slice();
    fundraisings.sort((a,b) => new Date(b.date_of_investment) - new Date(a.date_of_investment));
    let fundraisingRows = fundraisings.map((funding, i) => {
      const amount = DatavaseApi.prettifyPrice(
        funding.amount, funding.currency_code, 'Undisclosed'
      );
      const date = DatavaseApi.prettifyDate(
        funding.date_of_investment, funding.precision_of_date_of_investment
      );

      const investors     = [...funding.investing_organizations, ...funding.investing_people];

      const investorsList = investors.map((investor, i) => {
        const image = investor.thumb_profile_image_url || placeholder;
        const name  = investor.normalized_name;
        const link  = `/${investor.resource_type_name}/${investor.slug || investor.id}/`;
        return(
          <div key={i}>
            <img src={image} alt={name} className="history-table-image"/>
            <a href={link}>{name}</a>
          </div>
        );
      });

      const contribution = funding.most_recent_contribution;
      const evaluations  = contribution.evaluations;
      const userid       = DatavaseApi.getUserId();
      const currentUsersEvaluation = (evaluations.find(e => e.user_id === userid) || {}).evaluation_type_name;
      const contributorTooltip  = (
        <Tooltip id="contributor-tooltip">
          <ContributorTooltipContents
            contributor={contribution.contributor}
          />
        </Tooltip>
      );

      const seeDetailsButton = (
        <Button
          onClick={() => {this._onSeeDetailsClick(contribution)}}
          style={{padding: '0', border: 'none', background: 'transparent'}}
        >
          <i className="material-icons" style={{verticalAlign: 'middle'}}>more_horiz</i>
        </Button>
      );

      return (
        <OverlayTrigger key={i} overlay={contributorTooltip}>
          <tr key={i}>
            <td>
              <EvaluateButtons
                contribution={contribution}
                defaultEvaluation={currentUsersEvaluation}
              />
            </td>
            <td>{date}</td>
            <td>{amount}</td>
            <td>{investorsList}</td>
            <td>{seeDetailsButton}</td>
          </tr>
        </OverlayTrigger>
      );
    });

    return(
      <div>
        <ContributionDetailsModal
          show={this.state.showContributionDetailsModal}
          information={this.state.selectedFunding}
          contribution={this.state.contribution}
          onCancel={this._hideContributionDetailsModal}
        />
        <Table striped responsive hover id="fundraisings-table">
          <thead>
            <tr>
              <th>{lang.reputationHeader}</th>
              <th>{lang.dateOfFundingHeader}</th>
              <th>{lang.amountHeader}</th>
              <th>{lang.investorsHeader}</th>
              <th>{lang.seeDetailsHeader}</th>
            </tr>
          </thead>
          <tbody>
            {fundraisingRows}
          </tbody>
        </Table>
      </div>
    );
  }
}


