const colors = {
  black87: "rgba(0, 0, 0, 0.87)",
  black70: "rgba(0, 0, 0, 0.70)",
  black54: "rgba(0, 0, 0, 0.54)",
  black26: "rgba(0, 0, 0, 0.26)",
  black12: "rgba(0, 0, 0, 0.12)",
  black04: "rgba(0, 0, 0, 0.04)",
  white100: "#fff",
  white87: "rgba(255, 255, 255, 0.87)",
  white70: "rgba(255, 255, 255, 0.70)",
  white30: "rgba(255, 255, 255, 0.30)",
  white12: "rgba(255, 255, 255, 0.12)",
  white8: "rgba(255, 255, 255, 0.08)",
  white4: "rgba(255, 255, 255, 0.04)",
  primary: "#252747", // メインの紫
  hover: "#101240", // メインカラーがホバーした時の色
  accent: "#66D7B1", // アクセントカラーの緑
  default: "##CEE9E7", // 画像などが設定されていない時の背景色
  placeholder: "#01052E", // 検索窓の背景色
  sns: {
    facebook: "#3a579d",
    line: "#4ecd00",
    twitter: "#1da1f2",
    hatena: "#008fde",
    slack: "#4A154B",
  }
};

export default colors;