import React, {Component} from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Panel, Radio} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import store from 'store';

import BlockSpinner from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/signup-window.json';
import '../../css/auth-pane.css';

export default class SignupWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSigningUp: false,
      hasSignedUp: false,
      messages: [],
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        organization_name: '',
        name: '',
        phone_number: '',
        job_title: '',
        business_type: '',
        reason: '',
        /*送信フォーム入れる*/
      }
    };
    this._signup = this._signup.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _languageChangeLink() {
    const langCode = DatavaseApi.getLangCode();
    if(langCode === 'ja'){
      return(<Link onClick={this._onClickEnglish}>English</Link>);
    }else{
      return(<Link onClick={this._onClickJapanese}>日本語</Link>);
    }
  }

  _signup() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    this.setState({isSigningUp: true});

    const params = {user: this.state.user};
    DatavaseApi.signup(params)
    .then(res => {
      this.setState({
        isSigningUp: false,
        hasSignedUp: true,
        messages: [lang.signupCompletedMessage]
      });
      window.location.href="/action_done/signup";
    })
    .catch(res => {
      let errorMessages = [];
      if(res.response.data.errors) {
        errorMessages = res.response.data.errors.full_messages;
      }
      this.setState({
        isSigningUp: false,
        messages: errorMessages,
      });
    });
  }

  _onFormChange(e) {
    const newUser = Object.assign({}, this.state.user, {
      [e.target.name]: e.target.value,
    });
    this.setState({ user: newUser });
  }

  _constructErrorsList() {
    const errorRows = this.state.messages.map((message, i) => {
      return (<li key={i} style={{color: '#666', fontWeight: 'bold'}}>{message}</li>);
    });

    return( <ul>{errorRows}</ul> );
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return (
      <Panel className="auth-panel" header={lang.signupWindowTitle}>
        {this._constructErrorsList()}
        { this.state.hasSignedUp ? null :
        <div className="auth-panel-content">
          <div className="social-auth">
            <h5>{lang.signupMessage}</h5>
          </div>
          <div className="auth-form">
            <p>{lang.intro1}<br/>{lang.intro2}<br/>{lang.intro3}</p>
            <FormGroup>
              <ControlLabel>{lang.organizationNameLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="text"
                placeholder={lang.organizationNamePlaceholder}
                name="organization_name"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.nameLabel} (姓と名は半角スペースで区切ってください)</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="text"
                placeholder={lang.namePlaceholder}
                name="name"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.emailLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="email"
                placeholder={lang.emailPlaceholder}
                name="email"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.phoneNumberLabel}</ControlLabel>
              <FormControl
                  className="top-navbar-form"
                  type="text"
                  placeholder={lang.phoneNumberPlaceholder}
                  name="phone_number"
                  onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.passwordLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="password"
                placeholder={lang.passwordPlaceholder}
                name="password"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.passwordConfirmationLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="password"
                placeholder={lang.passwordConfirmationPlaceholder}
                name="password_confirmation"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.jobTitle}</ControlLabel>
              <FormControl
                  className="top-navbar-form"
                  type="text"
                  placeholder={lang.jobTitlePlaceholder}
                  name="job_title"
                  onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.orgType}</ControlLabel>
              <div key={`default-radio`} style={{textAlign: "left", paddingLeft: "35%"}}>
                <Radio
                    id={`default-radio-1`}
                    name="business_type"
                    value="company"
                    onChange={this._onFormChange}
                >{lang.company}</Radio>
                <Radio
                    id={`default-radio-2`}
                    name="business_type"
                    value="startup"
                    onChange={this._onFormChange}
                >{lang.startup}</Radio>
                <Radio
                    id={`default-radio-3`}
                    name="business_type"
                    value="investor"
                    onChange={this._onFormChange}
                >{lang.investors}</Radio>
                <Radio
                    id={`default-radio-4`}
                    name="business_type"
                    value="school"
                    onChange={this._onFormChange}
                >{lang.school}</Radio>
                <Radio
                    id={`default-radio-5`}
                    name="business_type"
                    value="proprietary"
                    onChange={this._onFormChange}
                >{lang.proprietary}</Radio>
                <Radio
                    id={`default-radio-6`}
                    name="business_type"
                    value="others"
                    onChange={this._onFormChange}
                >{lang.others}</Radio>
              </div>
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.useCase}</ControlLabel>
              <div key={`default-radio`} style={{textAlign: "left", paddingLeft: "35%"}}>
                <Radio
                    id={`default-radio-1`}
                    name="reason"
                    value="refer"
                    onChange={this._onFormChange}
                >{lang.newBusiness}</Radio>
                <Radio
                    id={`default-radio-2`}
                    name="reason"
                    value="research"
                    onChange={this._onFormChange}
                >{lang.researchCompetitor}</Radio>
                <Radio
                    id={`default-radio-3`}
                    name="reason"
                    value="review"
                    onChange={this._onFormChange}
                >{lang.reviewFeature}</Radio>
                <Radio
                    id={`default-radio-4`}
                    name="reason"
                    value="sourcing"
                    onChange={this._onFormChange}
                >{lang.investorSourcing}</Radio>
                <Radio
                    id={`default-radio-5`}
                    name="reason"
                    value="market"
                    onChange={this._onFormChange}
                >{lang.countryMktng}</Radio>
                <Radio
                    id={`default-radio-6`}
                    name="reason"
                    value="others"
                    onChange={this._onFormChange}
                >{lang.others}</Radio>
              </div>
            </FormGroup>
            {
              this.state.isSigningUp ?
              <BlockSpinner visible={true}/>
              :
              <Button id="signupWindowSingupButton" bsStyle="primary" className="auth-confirm-button" onClick={this._signup}>{lang.signupButton}</Button>
            }
            <Link className="auth-link" to="/login">{lang.loginLinkMessage}</Link>
            {this._languageChangeLink()}
          </div>
          <span className="signup-agreement">
            {lang.signupAgreementMessage}
          </span>
        </div>
        }
      </Panel>
    )
  }
}

