import React, { Component } from 'react';

import InvestmentForm              from './investment-form';
import ThankyouForContributingView from './thankyou-for-contributing-view';
import * as DatavaseApi            from '../datavase-api';
import i18n                        from '../../i18n/edit-investment-form.json';

export default class EditInvestmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      hasDone: false,
    };
    this._onSubmit = this._onSubmit.bind(this);
  }

  _onSubmit(investment) {
    this._editInvestment(investment);
  }

  _editInvestment(investment) {
    this.setState({isProcessing: true});
    DatavaseApi.editInvestment(this.props.id, {investment: investment})
    .then(res => {
      this.setState({
        isProcessing: true,
        hasDone: true,
      });
      //console.log(res);
    })
    .catch(res => {
      console.error(res);
    });
  }

  render() {
    if(this.state.hasDone) {
      return(
        <div>
          <ThankyouForContributingView/>
        </div>
      );
    }

    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const {
      investeeFormLocked,
      defaultInvesteeOrganization,
      defaultInvestingOrganizations,
      defaultInvestingPeople,
      defaultCurrency,
      defaultAmount,
      defaultFundingType,
      defaultDateOfFunding,
      defaultPrecisionOfDateOfFunding,
      defaultSourceUrls
    } = this.props;

    return(
      <div>
        <InvestmentForm
          onSubmit={this._onSubmit}
          isProcessing={this.state.isProcessing}
          investeeFormLocked={investeeFormLocked}
          defaultInvesteeOrganization={defaultInvesteeOrganization}
          defaultInvestingOrganizations={defaultInvestingOrganizations}
          defaultInvestingPeople={defaultInvestingPeople}
          defaultAmount={defaultAmount}
          defaultCurrency={defaultCurrency}
          defaultFundingType={defaultFundingType}
          defaultDateOfFunding={defaultDateOfFunding}
          defaultPrecisionOfDateOfFunding={defaultPrecisionOfDateOfFunding}
          defaultSourceUrls={defaultSourceUrls}
          submitButtonText={lang.submitButton}
        />
      </div>
    );
  }
}
