import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import * as DatavaseApi                  from '../datavase-api';
import i18n                              from '../../i18n/remove-column-modal.json';

export default class RemoveColumnModal extends Component {
  constructor(props) {
    super(props);
    this._onCancelClick = this._onCancelClick.bind(this);
    this._onDeleteClick = this._onDeleteClick.bind(this);
  }

  _onCancelClick() {
    if(this.props.onCancelClick) { this.props.onCancelClick(); }
  }

  _onDeleteClick() {
    if(this.props.onDeleteClick) { this.props.onDeleteClick(); }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.deleteColumnModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.deleteColumnModalBody}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{backgroundColor: '#fff'}}
            onClick={this._onCancelClick}
          >
            {lang.cancelButton}
          </Button>
          <Button
            bsStyle="danger"
            onClick={this._onDeleteClick}
          >
            {lang.deleteColumnButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
