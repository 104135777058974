import React, { Component } from 'react';
import * as c3 from 'c3';
import '../../css/c3.css';

export default class TotalEmployeesHistoryGraph extends Component {
  componentDidMount() {
    this._updateGraph();
  }

  componentDidUpdate() {
    this._updateGraph();
  }

  _updateGraph() {
    const totalEmployeesHistories          = this.props.totalEmployeesHistories;
    const estimatedTotalEmployeesHistories = this.props.estimatedTotalEmployeesHistories;
    const data = {
      date:                      totalEmployeesHistories.map(history => history.date),
      total_employees:           totalEmployeesHistories.map(history => history.total_number_of_employees),
      estimated_date:            estimatedTotalEmployeesHistories.map(history => history.date),
      estimated_total_employees: estimatedTotalEmployeesHistories.map(history => history.estimated_total_number_of_employees)
    };

    c3.generate({
      bindto: `#${this.props.graphName}`,
      data: {
        xs: {
          'Total Number of Employees': 'date',
          'Estimated Total Number of Employees': 'estimated_date'
        },
        columns: [
          ['date'].concat(data.date),
          ['estimated_date'].concat(data.estimated_date),
          ['Total Number of Employees'].concat(data.total_employees),
          ['Estimated Total Number of Employees'].concat(data.estimated_total_employees)
        ],
        types: {
          'Total Number of Employees': 'area',
          'Estimated Total Number of Employees': 'area-spline',
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            culling: {
              max: 5,
            },
            format: '%Y/%m/%d'
          }
        }
      }
    });
  }
  render() {
    return(
      <div id={this.props.graphName}></div>
    );
  }
}
