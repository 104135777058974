// @flow
import React from "react";
import styled from "styled-components";
import color from "../styles/colors";
import media from "styled-media-query";

export default function Tag(
  {
    text,
    padding,
    margin,
    active,
    name,
    onClick,
    radius,
  }) {
  return (
    <div>
      <TagBtn onClick={onClick} name={name} margin={margin} active={active} padding={padding} radius={radius}>{text}</TagBtn>
    </div>
  );
};


const TagBtn = styled.p`
  margin: ${props => props.margin || '0'};
  border: solid 2px ${color.primary};
  border-radius: ${props => props.radius || '8px'};
  padding: ${props => props.padding || '8px'};
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 10px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  ${media.lessThan("medium")`
    padding: 8px 3px;
  `}
`;
