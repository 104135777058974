import React, {Component} from 'react';
import {Button} from 'react-bootstrap';

import BlockSpinner from './block-spinner';
import ShareButtons from './share-buttons';
import DatavaseResourcePreview from './datavase-resource-preview';
import NewsSummaryForm from './news-summary-form';
import NewsArticleSummaryView from './news-article-summary-view';

import * as DatavaseApi from '../datavase-api';
import jp_techcrunch_logo from '../../assets/jp_techcrunch_logo.png';
import us_techcrunch_logo from '../../assets/us_techcrunch_logo.png';
import thebridge_logo from '../../assets/thebridge_logo.png';
import hackletter_logo from '../../assets/hackletter_logo.png';
import i18n from '../../i18n/news-article-preview.json';

export default class NewsArticlePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded: false,
      currentArticle: {},
      currentArticleWarehouseId: props.warehouseNewsArticleId,
      relatedResourcePreviews: [],
    };

    if(props.warehouseNewsArticleId) {
      this._fetchNewsArticleDetails(props.warehouseNewsArticleId);
    }
  }

    UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.warehouseNewsArticleId && newProps.warehouseNewsArticleId !== this.state.currentArticleWarehouseId) {
      this.setState({
        currentArticleWarehouseId: newProps.warehouseNewsArticleId
      });
      this._fetchNewsArticleDetails(newProps.warehouseNewsArticleId);
    }
  }

  _constructResourcePreviews(organizations, people, events) {
    let resources = organizations.concat(people).concat(events);
    return resources.map((resource, i) => {
      return(
        <div key={i}>
          <hr/>
          <DatavaseResourcePreview
            key={i}
            resource={resource}
          />
        </div>
      );
    });
  }

  _fetchNewsArticleDetails(warehouse_id) {
    this.setState({hasLoaded: false});
    DatavaseApi.fetchNewsArticleWithRelatedResources(warehouse_id)
    .then(res => {
      let previews = this._constructResourcePreviews(
        res.data.organizations,
        res.data.people,
        res.data.events
      );

      this.setState({
        hasLoaded: true,
        currentArticle: res.data,
        relatedResourcePreviews: previews
      });
    })
    .catch(err => {
      console.error(err);
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const currentArticle = this.state.currentArticle;

    let hostname = DatavaseApi.extractHostname(
      currentArticle.url
    );
    let image;
    if(hostname === 'jp.techcrunch.com') {
      image = jp_techcrunch_logo;
    }else if(hostname === 'techcrunch.com') {
      image = us_techcrunch_logo;
    }else if(hostname === 'thebridge.jp') {
      image = thebridge_logo;
    }else if(hostname === 'hackletter.com') {
      image = hackletter_logo;
    }
    let sitename;
    if(hostname === 'jp.techcrunch.com') {
      sitename = "TechCrunch Japan";
    }else if(hostname === 'techcrunch.com') {
      sitename = "TechCrunch";
    }else if(hostname === 'thebridge.jp') {
      sitename = "THE BRIDGE";
    }else if(hostname === 'hackletter.com') {
      sitename = "hackletter";
    }
    const time  = DatavaseApi.timeSince(
      currentArticle.published_at, langCode
    );
    const appEndpoint = DatavaseApi.getAppEndpoint();
    const userEmailDomain = DatavaseApi.getUserEmailDomain();

    if(!this.state.hasLoaded) {
      return(
        <div>
          <BlockSpinner visible/>
        </div>
      );
    }

    return(
      <div>
        <div style={{margin: '20px', textAlign: 'center'}}>
          <div style={{textAlign: 'left'}}>
            <a
              href={currentArticle.url}
              rel="noopener noreferrer"
              target="_blank"
              style={{fontWeight: 'bold', color: '#666', fontSize: '23px'}}
            >
              {currentArticle.title}
            </a>
            <div>
              <img alt={currentArticle.title} src={image} style={{width: '25px', marginRight: '8px'}}/>
              <span style={{lineHeight: '25px', color: '#aaa'}}>{sitename}</span>
              <span style={{lineHeight: '25px', color: '#aaa'}}> - {time}</span>
            </div>
          </div>
          {
            userEmailDomain === 'hackjpn.com' ?
            <NewsSummaryForm
              warehouseNewsArticleId={currentArticle.warehouse_news_article_id}
              defaultBulletPoints={currentArticle.summary_in_bullet_points}
            />
            :
            <NewsArticleSummaryView
              bulletPoints={currentArticle.summary_in_bullet_points}
            />
          }
          <div>
            <a href={currentArticle.url} target="_blank" rel="noopener noreferrer">
              <Button bsStyle="primary">
                {lang.continueReadingButton}
              </Button>
            </a>
            <div style={{marginTop: '10px'}}>
              <span>
                {
                  this.state.relatedResourcePreviews.length !== 0 ?
                  lang.shareNewsWithDataButton :
                  lang.shareOnlyNewsButton
                }
              </span>
              <div style={{marginBottom: '10px'}}>
                <ShareButtons
                  url={`${appEndpoint}/news/${currentArticle.warehouse_news_article_id}`}
                  title={currentArticle.title}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{margin: '20px'}}>
          {
            this.state.relatedResourcePreviews.length !== 0 ?
            <span style={{color: '#aaa'}}>{lang.relatedDataLabel}</span> : null
          }
          {this.state.relatedResourcePreviews}
        </div>
      </div>
    );
  }
}
