import React, { Component } from 'react';
import { Form, FormControl, ControlLabel } from 'react-bootstrap';

function getDefaultValues(price) {
  if(!price) return {};

  price = parseFloat(price);
  const multipliers = [1, 1000, 1000000, 1000000000];
  let cnt = 0;
  while((price / 1000) >= 1) {
    price /= 1000;
    cnt += 1;
  }

  return {
    amountMultiplier: multipliers[Math.min(3, cnt)],
    baseAmount: price.toFixed(1)
  }
}

export default class PriceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseAmount: 0,
      amountMultiplier: 1,
      currency: "USD",
    }
    this._onChangeMultiplier = this._onChangeMultiplier.bind(this);
    this._onChangeBaseAmount = this._onChangeBaseAmount.bind(this);
    this._onChangeCurrency = this._onChangeCurrency.bind(this);

    if(props.defaultAmount) {
      const defaultValues = getDefaultValues(props.defaultAmount);
      this.state = Object.assign({}, this.state, {
        baseAmount: defaultValues.baseAmount,
        amountMultiplier: defaultValues.amountMultiplier
      });
    }
    if(props.defaultCurrency) {
      this.state = Object.assign({}, this.state, {
        currency: props.defaultCurrency
      });
    }
  }

  _onChangeMultiplier(e) {
    const amountMultiplier = e.target.value;
    let baseAmount, newAmount;
    if(this.state.baseAmount) {
      baseAmount = this.state.baseAmount;
      newAmount  = baseAmount * amountMultiplier;
    }else{
      newAmount = null;
    }
    this.setState({amountMultiplier: amountMultiplier});
    this.props.onAmountChanged(newAmount);
  }

  _onChangeBaseAmount(e) {
    const amountMultiplier = this.state.amountMultiplier;
    let baseAmount, newAmount;
    if(e.target.value) {
      baseAmount = parseFloat(e.target.value);
      newAmount  = baseAmount * amountMultiplier;
    }else{
      baseAmount = null;
      newAmount  = null;
    }
    this.setState({baseAmount: baseAmount});
    this.props.onAmountChanged(newAmount);
  }

  _onChangeCurrency(e) {
    const currency = e.target.value;
    this.setState({currency: currency});
    this.props.onCurrencyChanged(currency);
  }

  render(){
    const defaultValues           = getDefaultValues(this.props.defaultAmount);
    const defaultBaseAmount       = defaultValues.baseAmount;
    const defaultAmountMultiplier = defaultValues.amountMultiplier;
    const defaultCurrency         = this.props.defaultCurrency;

    return(
      <div>
        <ControlLabel>{this.props.label}</ControlLabel>
        <Form inline>
          <FormControl
            type="text"
            placeholder={this.props.placeholder}
            name="baseAmount"
            defaultValue={defaultBaseAmount}
            onChange={this._onChangeBaseAmount}
          />
          <FormControl
            componentClass="select"
            placeholder="-"
            name="amountMultiplier"
            defaultValue={defaultAmountMultiplier}
            onChange={this._onChangeMultiplier}
          >
            <option value={1}>-</option>
            <option value={1000}>k</option>
            <option value={1000000}>M</option>
            <option value={1000000000}>B</option>
          </FormControl>
          <FormControl
            componentClass="select"
            placeholder="Select currency"
            name="currency"
            defaultValue={defaultCurrency}
            onChange={this._onChangeCurrency}
          >
            <option value="USD">USD</option>
            <option value="JPY">JPY</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="CNY">CNY</option>
          </FormControl>
        </Form>
      </div>
    );
  }
}
