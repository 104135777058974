// 記事パネル
import React, { Component } from 'react';
// import { Panel, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import articleEyeCatching from '../../assets/datavase_article_eyecatching.png';
import color from '../styles/colors.js';

export default class DashboardArticlePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      panelTitle: '',
    }
  }
  _handleTag(tag) {
    const tagsHandler = this.props.tagsHandler;
    tagsHandler([tag]);
  }

  _tagsView() {
    const tags = this.props.article.tags;
    return tags.map(tag => {
      return (
        <div key={tag}>
          {tag ?
            <Tag>
              {tag}
            </Tag>
            :
            null
          }
        </div>
      )
    });
  }

  render() {
    const article = this.props.article;
    const id = article.id;
    const title = article.title;

    const imageSource = article.image || articleEyeCatching;
    const writer_name = article.author.writer_name || 'Datavase Editors';


    return (
      <List to={`/articles/${id}`}>
        <Thambnail>
          <ThambImg alt={title} src={imageSource} />
        </Thambnail>
        <Contents>
          <Title>{title}</Title>
          <Meta>
            <Wrapper>
              <MetaTxt>{writer_name}</MetaTxt>
              {this._tagsView()}
            </Wrapper>
            {/* <MetaTxt>{datetime}</MetaTxt> */}
          </Meta>
        </Contents>
      </List>
    );
  }
}


const List = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: #fff;
  &:hover {
    background: ${color.black04};
  }
`

const Thambnail = styled.p`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin: 0 12px 0 0;
  overflow: hidden;
`

const ThambImg = styled.img`
  width: 100%;
  height: 100%;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 68px);
  height: 100%;
  margin: 0;
`

const Title = styled.h1`
  margin: 0 0 4px;
  color: ${color.black87};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
`

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14px;
  width: 100%;
  margin: 0;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`

const MetaTxt = styled.p`
  margin: 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`

const Tag = styled.div`
  background: ${color.accent};
  padding: 0 4px;
  margin: 0 0 0 4px;
  border-radius: 3px;
  color: ${color.white100};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`
