import React, {Component} from 'react';
import {Button, Panel, Tab, Tabs} from 'react-bootstrap';

import BlockSpinner from './block-spinner';
import UpdatePaymentMethodModal from './update-payment-method-modal';
import UpdatePaymentAndResumeSubscriptionModal from './update-payment-and-resume-subscription-modal';
import ConfirmSubscriptionCancelModal from './confirm-subscription-cancel-modal';
import ConfirmSubscriptionResumeModal from './confirm-subscription-resume-modal';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/user-account-status-panel.json';

export default class UserAccountStatusPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        hasLoaded: false,
        showUpdatePaymentMethodModal: false,
      showUpdatePaymentAndResumeSubscriptionModal: false,
        showConfirmSubscriptionCancelModal: false,
        showConfirmSubscriptionResumeModal: false,
        subscription: {},
        plan: {},
        defaultPayment: {}
    };
    this._showConfirmSubscriptionResumeModal = this._showConfirmSubscriptionResumeModal.bind(this);
    this._hideConfirmSubscriptionResumeModal = this._hideConfirmSubscriptionResumeModal.bind(this);
    this._showConfirmSubscriptionCancelModal = this._showConfirmSubscriptionCancelModal.bind(this);
    this._hideConfirmSubscriptionCancelModal = this._hideConfirmSubscriptionCancelModal.bind(this);
    this._showUpdatePaymentAndResumeSubscriptionModal = this._showUpdatePaymentAndResumeSubscriptionModal.bind(this);
    this._hideUpdatePaymentAndResumeSubscriptionModal = this._hideUpdatePaymentAndResumeSubscriptionModal.bind(this);
    this._showUpdatePaymentMethodModal = this._showUpdatePaymentMethodModal.bind(this);
    this._hideUpdatePaymentMethodModal = this._hideUpdatePaymentMethodModal.bind(this);
    this._onCancelSubscriptionSuccess = this._onCancelSubscriptionSuccess.bind(this);
    this._onUpdateDefaultPaymentSuccess = this._onUpdateDefaultPaymentSuccess.bind(this);
  }

  componentDidMount() {
    this._fetchPaymentsAndSubscription();
  }

  _showConfirmSubscriptionResumeModal() {
      this.setState({showConfirmSubscriptionResumeModal: true});
  }

  _hideConfirmSubscriptionResumeModal() {
      this.setState({showConfirmSubscriptionResumeModal: false});
  }

  _showConfirmSubscriptionCancelModal() {
      this.setState({showConfirmSubscriptionCancelModal: true});
  }

  _hideConfirmSubscriptionCancelModal() {
      this.setState({showConfirmSubscriptionCancelModal: false});
  }

  _showUpdatePaymentAndResumeSubscriptionModal() {
      this.setState({showUpdatePaymentAndResumeSubscriptionModal: true});
  }

  _hideUpdatePaymentAndResumeSubscriptionModal() {
      this.setState({showUpdatePaymentAndResumeSubscriptionModal: false});
  }

  _showUpdatePaymentMethodModal() {
    this.setState({showUpdatePaymentMethodModal: true});
  }

  _hideUpdatePaymentMethodModal() {
    this.setState({showUpdatePaymentMethodModal: false});
  }

  _onResumeSubscriptionSuccess() {
    window.location.reload();
  }

  _onCancelSubscriptionSuccess() {
    window.location.reload();
  }

  _onUpdateDefaultPaymentSuccess(newPayment) {
    this.setState({
      showUpdatePaymentMethodModal: false,
      defaultPayment: newPayment
    });
  }

  _fetchPaymentsAndSubscription() {
    const userStatusType = DatavaseApi.getUserStatus();
      if (!['Pro', 'Student', 'General', 'Startup'].includes(userStatusType)) {
      this.setState({hasLoaded: true});
      return;
    }

    DatavaseApi.fetchSubscription().then(res => {
        const subscription = res.data;
      this.setState({
          subscription: subscription,
          hasLoaded: true,
      });
    }).catch(res => {
      console.error(res);
      this.setState({hasLoaded: true});
    });

    DatavaseApi.fetchDefaultPayment().then(res => {
      const defaultPayment = res.data;
      this.setState({
        defaultPayment: defaultPayment,
      });
    }).catch(res => {
      console.error(res);
      this.setState({hasLoaded: true});
    });
  }

  render() {
      const langCode = DatavaseApi.getLangCode();
      const lang = i18n[langCode];
      const userStatusType = DatavaseApi.getUserStatus();
      const trial = DatavaseApi.getUserTrialStatus();
      const subscription = this.state.subscription || {};
      const defaultPayment = this.state.defaultPayment;

      let currentPlan = userStatusType;
      if (['Pro', 'Student', 'General', 'Startup'].includes(userStatusType)) {
          if (subscription.status === 'active') {
        currentPlan = `${userStatusType} (${lang.subscriptionIsEnabled})`;
          } else if (subscription.status === 'deleted') {
        currentPlan = `${userStatusType} (${lang.subscriptionIsDisabled})`;
          } else if (subscription.status === 'suspended') {
        currentPlan = `${userStatusType} (${lang.subscriptionIsDisabled})`;
      }
    }

    if (trial && (new Date(trial).to_i > new Date())) {
      currentPlan += lang.inTrial;
    }

    let expirationDate = '-';
      if (['Pro', 'Student', 'General', 'Startup'].includes(userStatusType)) {
      expirationDate = DatavaseApi.prettifyDate(subscription.current_period_end);
    }

      let nextBillingDate = '-';
      if (subscription.status === 'active') {
      nextBillingDate = DatavaseApi.prettifyDate(subscription.next_billing_date);
      } else if (subscription.status === 'deleted') {
      nextBillingDate = `${lang.subscriptionIsDisabled}`;
      } else if (subscription.status === 'suspended') {
      nextBillingDate = `${lang.subscriptionIsSuspended}`;
    }

    let nextBilledAmount = '-';
      if (subscription.status === 'deleted') {
      nextBilledAmount = `${lang.subscriptionIsDisabled}`;
      } else if (subscription.status === 'suspended') {
      nextBilledAmount = `${lang.subscriptionIsSuspended}`;
      } else if (subscription.status === 'active' && subscription.billed_amount) {
      nextBilledAmount = `¥${subscription.billed_amount}`;
    }

    let planActionButton = null;
      if (userStatusType === 'Enterprise') {
          planActionButton = null;
      } else if (['Pro', 'General', 'Student', 'Startup'].includes(userStatusType)) {
          if (subscription.status === 'active') {
              planActionButton = (
                  <span>
                                        <a href="/upgrade/" target="_blank">
                    <Button
                        bsSize="small"
                        bsStyle="primary"
                    >
                      プランの変更
                    </Button>
                  </a> &nbsp;
                      <Button
                          bsSize="small"
                          bsStyle="danger"
                          onClick={this._showConfirmSubscriptionCancelModal}
                      >
                        {lang.cancelSubscriptionButton}
                    </Button>
                    </span>
        );
          } else if (subscription.status === 'deleted') {
        planActionButton = (
            <Button
                bsSize="small"
                bsStyle="primary"
                onClick={this._showConfirmSubscriptionResumeModal}
            >
                {lang.resumeSubscriptionButton}
            </Button>
        );
          } else if (subscription.status === 'suspended') {
        planActionButton = (
            <Button
                bsSize="small"
                bsStyle="primary"
                onClick={this._showConfirmSubscriptionResumeModal}
            >
                {lang.resumeSubscriptionButton}
            </Button>
        );
      }
    }

      return (
          <div>
              <ConfirmSubscriptionResumeModal
                  show={this.state.showConfirmSubscriptionResumeModal}
                  onCancelClick={this._hideConfirmSubscriptionResumeModal}
                  onResumeSubscriptionSuccess={this._onResumeSubscriptionSuccess}
              />
              <ConfirmSubscriptionCancelModal
                  show={this.state.showConfirmSubscriptionCancelModal}
                  onCancelClick={this._hideConfirmSubscriptionCancelModal}
                  onCancelSubscriptionSuccess={this._onCancelSubscriptionSuccess}
              />
              <UpdatePaymentAndResumeSubscriptionModal
                  show={this.state.showUpdatePaymentAndResumeSubscriptionModal}
                  onDoneClick={this._hideUpdatePaymentAndResumeSubscriptionModal}
                  onResumeSubscriptionSuccess={this._onResumeSubscriptionSuccess}
              />
              <UpdatePaymentMethodModal
                  show={this.state.showUpdatePaymentMethodModal}
                  onDoneClick={this._hideUpdatePaymentMethodModal}
                  onUpdatePaymentSuccess={this._onUpdateDefaultPaymentSuccess}
              />
              <Panel id="account-information-panel">
                  <Tabs id="account-information-tabs">
                      <Tab eventKey={1} title={lang.userAccountStatusPanelTitle}>
                          {
                              this.state.hasLoaded ?
                                  <Panel>
                                      <div>
                    <span style={{display: 'inline-block', width: '140px'}}>
                      {lang.planName}
                    </span>
                                          <span style={{fontWeight: 'bold', marginRight: '20px'}}>
                      {currentPlan}
                    </span>
                                          {planActionButton}
                                      </div>

                                      {['Pro', 'Student', 'General', 'Startup'].includes(userStatusType) ?
                                          <div>
                                              <hr/>
                                              <span style={{display: 'inline-block', width: '140px'}}>
                      {lang.expiresOn}
                    </span>
                                              <span style={{fontWeight: 'bold'}}>
                      {expirationDate}
                    </span>
                                          </div>
                                          : null}

                                      {['Pro', 'Student', 'General', 'Startup'].includes(userStatusType) ?
                                          <div>
                                              <hr/>
                                              <span style={{display: 'inline-block', width: '140px'}}>
                      {lang.billedAmount}
                    </span>
                                              <span style={{fontWeight: 'bold'}}>
                      {nextBilledAmount}
                    </span>
                                          </div>
                                          : null}

                                      {['Pro', 'Student', 'General', 'Startup'].includes(userStatusType) ?
                                          <div>
                                              <hr/>
                                              <span style={{display: 'inline-block', width: '140px'}}>
                      {lang.nextBillingDate}
                    </span>
                                              <span style={{fontWeight: 'bold'}}>
                      {nextBillingDate}
                    </span>
                                          </div>
                                          : null}

                                      {(['Pro', 'Student', 'General', 'Startup'].includes(userStatusType) && defaultPayment) ?
                                          <div>
                                              <hr/>
                                              <span style={{display: 'inline-block', width: '140px'}}>
                      {lang.defaultPayment}
                    </span>
                                              <span style={{fontWeight: 'bold', marginRight: '20px'}}>
                      <i className="material-icons" style={{verticalAlign: 'middle'}}>credit_card</i>
                      <span style={{marginRight: '20px'}}>
                        {defaultPayment.brand} **** **** **** {defaultPayment.last4}
                      </span>
                      <span style={{fontWeight: 'normal'}}>{lang.expiration}: </span>
                      <span>
                        {defaultPayment.exp_month}/{defaultPayment.exp_year}
                      </span>
                    </span>
                                              <Button
                                                  bsSize="small"
                                                  bsStyle="primary"
                                                  onClick={this._showUpdatePaymentMethodModal}
                                              >
                                                  {lang.editPayment}
                                              </Button>
                                          </div>
                                          : null}
                                  </Panel>
                                  :
                                  <BlockSpinner visible/>
                          }
                      </Tab>
                  </Tabs>
              </Panel>
          </div>
    );
  }
}
