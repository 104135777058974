import React, { Component } from 'react';
import AbstractPanel from './abstract-panel';

import * as DatavaseApi     from '../datavase-api';

export default class OrganizationAbstractPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      loadingReviews: true,
    };
  }

  componentDidMount() {
    const id = this.props.id;
    DatavaseApi.fetchOrganizationAverageReviews(id)
    .then(res => {
      this.setState({
        loadingReviews: false,
        averageReviews: res.data.average_rating,
      });
    }).catch((res) => {
      console.error(res);
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();

    const organization = this.props.organization;
    const profileImage = this.props.profileImage;
    const averageReviews = this.state.averageReviews || 0.0;
    const name         = DatavaseApi.extractOrganizationName(organization, langCode);
    const desc         = DatavaseApi.extractDescription(organization, langCode);

    const location       = DatavaseApi.prettifyLocation(
                          organization.based_city,
                          organization.based_state_prefecture,
                          organization.based_country
                        );

    return (
      <AbstractPanel
        profileImage={profileImage}
        abstractTitle={name}
        subHeading={location}
        abstractDescription={desc}
        averageReviews={averageReviews}
        loadingReviews={this.state.loadingReviews}
        hideReviews={this.props.hideReviews || false}
      />
    );
  }
}
