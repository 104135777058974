import React, { Component } from 'react'
import { Button, FormControl } from 'react-bootstrap'

import * as DatavaseApi         from '../datavase-api';
import i18n                     from '../../i18n/price-filter-configuration.json';
import '../../css/filter-configuration.css';

export default class PriceFilterConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseAmount: null,
      multiplier: 1,
      currency: 'USD',
      comparator: "$gt",
    };
    this._onBaseAmountChange      = this._onBaseAmountChange.bind(this);
    this._onComparatorChange      = this._onComparatorChange.bind(this);
    this._onPriceMultiplierChange = this._onPriceMultiplierChange.bind(this);
    this._onCurrencyChange        = this._onCurrencyChange.bind(this);
    this._onRemoveClick           = this._onRemoveClick.bind(this);
  }

  _constructFilter(baseAmount, multiplier, currency, comparator) {
    let subject    = this.props.subject;
    let filter = {
      "$and": [
          {
            [subject]: {
              [comparator]: baseAmount * multiplier
            },
          },
          {
            [`${subject}_currency`]: {
              "$eq": currency
            }
          }
        ]
    };

    return filter;
  }

  _onBaseAmountChange(e) {
    let baseAmount = e.target.value;
    let multiplier = this.state.multiplier;
    let currency   = this.state.currency;
    let comparator = this.state.comparator;
    this.setState({baseAmount: baseAmount});

    let filter = this._constructFilter(baseAmount, multiplier, currency, comparator);

    this.props.onChange(this.props.index, filter);
  }

  _onComparatorChange(e) {
    let baseAmount = this.state.baseAmount;
    let multiplier = this.state.multiplier;
    let currency   = this.state.currency;
    let comparator = e.target.value;
    this.setState({comparator: comparator});

    let filter = this._constructFilter(baseAmount, multiplier, currency, comparator);

    this.props.onChange(this.props.index, filter);
  }

  _onPriceMultiplierChange(e) {
    let baseAmount = this.state.baseAmount;
    let multiplier = e.target.value;
    let currency   = this.state.currency;
    let comparator = this.state.comparator;
    this.setState({multiplier: multiplier});

    let filter = this._constructFilter(baseAmount, multiplier, currency, comparator);

    this.props.onChange(this.props.index, filter);
  }

  _onCurrencyChange(e) {
    let baseAmount = this.state.baseAmount;
    let multiplier = this.state.multiplier;
    let currency   = e.target.value;
    let comparator = this.state.comparator;
    this.setState({currency: currency});

    let filter = this._constructFilter(baseAmount, multiplier, currency, comparator);

    this.props.onChange(this.props.index, filter);
  }

  _onRemoveClick() {
    if(this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <Button style={{border: 'none', backgroundColor: 'transparent', borderRadius: '12px', padding: '0'}} onClick={this._onRemoveClick}>
          <i className="material-icons" style={{verticalAlign: 'middle', color: '#D75553'}}>
            remove_circle
          </i>
        </Button>
        <span style={{display: 'inline-block', backgroundColor: '#3996D7', borderRadius: '3px', fontWeight: 'bold', color: '#fff', padding: '7px 10px', margin: '5px 5px', minWidth: '300px'}}>
          {this.props.filterName}
        </span>
        {
          langCode === 'ja' ?
          <div style={{display: 'inline-block'}}>
            <div style={{display: 'inline-block', width: '100px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-value-form"
                placeholder="(例) 5"
                onChange={this._onBaseAmountChange}
              />
            </div>
            <div style={{display: 'inline-block', width: '100px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-comparator-form"
                componentClass="select"
                onChange={this._onPriceMultiplierChange}
              >
                <option value="0">{lang.one}</option>
                <option value="1000">{lang.kilo}</option>
                <option value="1000000">{lang.million}</option>
                <option value="1000000000">{lang.billion}</option>
              </FormControl>
            </div>
            <div style={{display: 'inline-block', width: '150px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-comparator-form"
                componentClass="select"
                onChange={this._onCurrencyChange}
              >
                <option value="USD">{lang.usd}</option>
                <option value="JPY">{lang.jpy}</option>
                <option value="EUR">{lang.eur}</option>
                <option value="GBP">{lang.gbp}</option>
                <option value="CNY">{lang.cny}</option>
              </FormControl>
            </div>
          </div>
            :
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-comparator-form"
              componentClass="select"
              onChange={this._onComparatorChange}
            >
              <option value="$gt">{lang.gt}</option>
              <option value="$gte">{lang.gte}</option>
              <option value="$lt">{lang.lt}</option>
              <option value="$lte">{lang.lte}</option>
              <option value="$eq">{lang.eq}</option>
              <option value="$ne">{lang.ne}</option>
            </FormControl>
          </div>
        }
        {
          langCode === 'ja' ?
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-comparator-form"
              componentClass="select"
              onChange={this._onComparatorChange}
            >
              <option value="$gt">{lang.gt}</option>
              <option value="$gte">{lang.gte}</option>
              <option value="$lt">{lang.lt}</option>
              <option value="$lte">{lang.lte}</option>
              <option value="$eq">{lang.eq}</option>
              <option value="$ne">{lang.ne}</option>
            </FormControl>
          </div>
          :
          <div style={{display: 'inline-block'}}>
            <div style={{display: 'inline-block', width: '100px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-value-form"
                placeholder="e.g. 5"
                onChange={this._onBaseAmountChange}
              />
            </div>
            <div style={{display: 'inline-block', width: '100px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-comparator-form"
                componentClass="select"
                onChange={this._onPriceMultiplierChange}
              >
                <option value="0">{lang.one}</option>
                <option value="1000">{lang.kilo}</option>
                <option value="1000000">{lang.million}</option>
                <option value="1000000000">{lang.billion}</option>
              </FormControl>
            </div>
            <div style={{display: 'inline-block', width: '150px', margin: '5px 5px'}}>
              <FormControl
                style={{borderRadius: '3px'}}
                className="filter-configuration-comparator-form"
                componentClass="select"
                onChange={this._onCurrencyChange}
              >
                <option value="USD">{lang.usd}</option>
                <option value="JPY">{lang.jpy}</option>
                <option value="EUR">{lang.eur}</option>
                <option value="GBP">{lang.gbp}</option>
                <option value="CNY">{lang.cny}</option>
              </FormControl>
            </div>
          </div>
        }
      </div>
    );
  }
}
