import React, {Component} from 'react';
import {Button, Checkbox, ControlLabel, FormControl, FormGroup, Panel, Radio} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import store from 'store';

import BlockSpinner from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/signup-window.json';
import '../../css/auth-pane.css';

export default class StudentSignupWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSigningUp: false,
            hasSignedUp: false,
            messages: [],
            user: {
                //
                "mode": "student",
                "want_use": [],
                "organization_name": "",
                "job_title": "",
                "request_promotion": "",
                //
                "last_graduate": "",
                //
                "graduate_year": "",
                "name": "",
                //
                "birthday": "",
                "email": "",
                "phone_number": "",
                //
                "introduction_student": "",
                //
                "interest_companies": "",
                //
                "interest_zone": [],
                //
                "interest_hiring_style": [],
                //
                "interest_area": [],
                //
                "visa_status": "",
                "password": "",
                "password_confirmation": "",
                /*送信フォーム入れる。Checkbox=Set Checked Flag and Then Send with These Flag... It's not good, but it has only one method that I think*/
            }
        };
        this._signup = this._signup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onCheckBoxGroupChange = this._onCheckBoxGroupChange.bind(this);
        this._onClickJapanese = this._onClickJapanese.bind(this);
        this._onClickEnglish = this._onClickEnglish.bind(this);
    }

    _onClickJapanese() {
        store.set('langCode', 'ja');
        window.location.reload();
    }

    _onClickEnglish() {
        store.set('langCode', 'en');
        window.location.reload();
    }

    _languageChangeLink() {
        const langCode = DatavaseApi.getLangCode();
        if (langCode === 'ja') {
            return (<Link onClick={this._onClickEnglish}>English</Link>);
        } else {
            return (<Link onClick={this._onClickJapanese}>日本語</Link>);
        }
    }

    _signup() {
        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];
        this.setState({isSigningUp: true});

        const params = {user: this.state.user};
        DatavaseApi.signup(params)
            .then(res => {
                this.setState({
                    isSigningUp: false,
                    hasSignedUp: true,
                    messages: [lang.signupCompletedMessage]
                });
                window.location.href = "/action_done/signup";
            })
            .catch(res => {
                let errorMessages = [];
                if (res.response.data.errors) {
                    errorMessages = res.response.data.errors.full_messages;
                }
                this.setState({
                    isSigningUp: false,
                    messages: errorMessages,
                });
            });
    }

    _onCheckBoxChange(e) {
        this.setState({[e.target.name]: e.target.value === 'on'});
    }

    _onCheckBoxGroupChange(e) {
        const arr = this.state.user[e.target.name];
        const key = e.target.value;

        if (arr.indexOf(key) === -1) {
            arr.push(key);
        } else {
            arr.splice(arr.indexOf(key), 1);
        }

        this.setState({
            [e.target.name]: arr,
        });
    }

    _onFormChange(e) {
        const newUser = Object.assign({}, this.state.user, {
            [e.target.name]: e.target.value,
        });
        this.setState({user: newUser});
    }

    _constructErrorsList() {
        const errorRows = this.state.messages.map((message, i) => {
            return (<li key={i} style={{color: '#666', fontWeight: 'bold'}}>{message}</li>);
        });

        return (<ul>{errorRows}</ul>);
    }

    render() {
        const langCode = DatavaseApi.getLangCode();
        const lang = i18n[langCode];
        return (
            <Panel className="auth-panel signup" header={lang.signupWindowTitle}>
                {this._constructErrorsList()}
                {this.state.hasSignedUp ? null :
                    <div className="auth-panel-content">
                        <div className="social-auth">
                            <h5>{lang.signupMessage}</h5>
                        </div>
                        <div className="auth-form">
                            <p>{lang.intro1}<br/>{lang.intro2}<br/>{lang.intro3}</p>
                            <FormGroup>
                                <ControlLabel>ご利用希望サービス</ControlLabel>
                                <Checkbox
                                    id={`want-use-1`}
                                    name="want_use"
                                    value="academy"
                                    onChange={this._onCheckBoxGroupChange}
                                >HunterCity（一流人材養成アカデミー）</Checkbox>
                                <Checkbox
                                    id={`want-use-2`}
                                    name="want_use"
                                    value="recruitment"
                                    onChange={this._onCheckBoxGroupChange}
                                >HunterBook(一流人材向け求人サービス)</Checkbox>
                                <Checkbox
                                    id={`want-use-3`}
                                    name="want_use"
                                    value="intern"
                                    onChange={this._onCheckBoxGroupChange}
                                >シリバレシップ（シリコンバレーインターン）</Checkbox>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>組織名(現在在籍している大学・企業をお答えください)</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="text"
                                    placeholder={lang.organizationNamePlaceholder}
                                    name="organization_name"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>専攻又は所属部署(大学生の場合は専攻を社会人の場合は所属部署をお答えください)</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="text"
                                    placeholder={lang.jobTitlePlaceholder}
                                    name="job_title"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>招待コード(任意)</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="text"
                                    placeholder="XXXXXX"
                                    name="request_promotion"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>最終学歴</ControlLabel>
                                <div key={`default-Checkbox`} style={{textAlign: "left", paddingLeft: "35%"}}>
                                    <Radio
                                        id={`default-Checkbox-1`}
                                        name="last_graduate"
                                        value="bachelor"
                                        onChange={this._onFormChange}
                                    >Bachelor</Radio>
                                    <Radio
                                        id={`default-Checkbox-2`}
                                        name="last_graduate"
                                        value="master"
                                        onChange={this._onFormChange}
                                    >Master</Radio>
                                    <Radio
                                        id={`default-Checkbox-3`}
                                        name="last_graduate"
                                        value="doctor"
                                        onChange={this._onFormChange}
                                    >Doctor</Radio>
                                    <Radio
                                        id={`default-Checkbox-4`}
                                        name="last_graduate"
                                        value="others"
                                        onChange={this._onFormChange}
                                    >Others</Radio>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>卒業年数</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="number"
                                    placeholder="1999"
                                    name="graduate_year"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{lang.nameLabel} (姓と名は半角スペースで区切ってください)</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="text"
                                    placeholder={lang.namePlaceholder}
                                    name="name"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>生年月日*</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="date"
                                    placeholder="tomura@example.com"
                                    name="birthday"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Eメール*</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="email"
                                    placeholder={lang.emailPlaceholder}
                                    name="email"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{lang.passwordLabel}</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="password"
                                    placeholder={lang.passwordPlaceholder}
                                    name="password"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{lang.passwordConfirmationLabel}</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="password"
                                    placeholder={lang.passwordConfirmationPlaceholder}
                                    name="password_confirmation"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>電話番号*</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="text"
                                    placeholder={lang.phoneNumberPlaceholder}
                                    name="phone_number"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>自己紹介</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    componentClass="textarea"
                                    type="textarea"
                                    placeholder="#01自分の紹介　#02どんな企業で働きたいか"
                                    name="introduction_student"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>気になる企業</ControlLabel>
                                <FormControl
                                    className="top-navbar-form"
                                    type="textarea"
                                    placeholder="気になる企業"
                                    name="interest_companies"
                                    onChange={this._onFormChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>興味のある領域</ControlLabel>
                                <div key={`interest-zone`} style={{textAlign: "left", paddingLeft: "35%"}}>
                                    <Checkbox
                                        id={`interest-zone-1`}
                                        name="interest_zone"
                                        value="saas"
                                        onChange={this._onCheckBoxGroupChange}
                                    >SaaS</Checkbox>
                                    <Checkbox
                                        id={`interest-zone2`}
                                        name="interest_zone"
                                        value="healthcare"
                                        onChange={this._onCheckBoxGroupChange}
                                    >HealthCare</Checkbox>
                                    <Checkbox
                                        id={`interest-zone3`}
                                        name="interest_zone"
                                        value="beauty"
                                        onChange={this._onCheckBoxGroupChange}
                                    >Beauty</Checkbox>
                                    <Checkbox
                                        id={`interest-zone4`}
                                        name="interest_zone"
                                        value="iot"
                                        onChange={this._onCheckBoxGroupChange}
                                    >IoT</Checkbox>
                                    <Checkbox
                                        id={`interest-zone5`}
                                        name="interest_zone"
                                        value="ai"
                                        onChange={this._onCheckBoxGroupChange}
                                    >AI</Checkbox>
                                    <Checkbox
                                        id={`interest-zone6`}
                                        name="interest_zone"
                                        value="mobility"
                                        onChange={this._onCheckBoxGroupChange}
                                    >Mobility</Checkbox>
                                    <Checkbox
                                        id={`interest-zone7`}
                                        name="interest_zone"
                                        value="btob"
                                        onChange={this._onCheckBoxGroupChange}
                                    >BtoB</Checkbox>
                                    <Checkbox
                                        id={`interest-zone8`}
                                        name="interest_zone"
                                        value="ctoc"
                                        onChange={this._onCheckBoxGroupChange}
                                    >CtoC</Checkbox>
                                    <Checkbox
                                        id={`interest-zone9`}
                                        name="interest_zone"
                                        value="entertainment"
                                        onChange={this._onCheckBoxGroupChange}
                                    >Entertainment</Checkbox>
                                    <Checkbox
                                        id={`interest-zone10`}
                                        name="interest_zone"
                                        value="fintech"
                                        onChange={this._onCheckBoxGroupChange}
                                    >FinTech</Checkbox>
                                    <Checkbox
                                        id={`interest-zone11`}
                                        name="interest_zone"
                                        value="others"
                                        onChange={this._onCheckBoxGroupChange}
                                    >その他</Checkbox>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>興味のある雇用形態（任意）</ControlLabel>
                                <div key={`interest-hiring-style`} style={{textAlign: "left", paddingLeft: "35%"}}>
                                    <Checkbox
                                        id={`interest-hiring-style1`}
                                        name="interest_hiring_style"
                                        value="full_time"
                                        onChange={this._onCheckBoxGroupChange}
                                    >フルタイム</Checkbox>
                                    <Checkbox
                                        id={`interest-hiring-style2`}
                                        name="interest_hiring_style"
                                        value="part_time"
                                        onChange={this._onCheckBoxGroupChange}
                                    >パートタイム</Checkbox>
                                    <Checkbox
                                        id={`interest-hiring-style3`}
                                        name="interest_hiring_style"
                                        value="intern"
                                        onChange={this._onCheckBoxGroupChange}
                                    >インターンシップ</Checkbox>
                                    <Checkbox
                                        id={`interest-hiring-style4`}
                                        name="interest_hiring_style"
                                        value="others"
                                        onChange={this._onCheckBoxGroupChange}
                                    >その他</Checkbox>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>興味のある職種（任意）</ControlLabel>
                                <div key={`interest_area`} style={{textAlign: "left", paddingLeft: "35%"}}>
                                    <Checkbox
                                        id={`interest-area1`}
                                        name="interest_area"
                                        value="engineer"
                                        onChange={this._onCheckBoxGroupChange}
                                    >エンジニア</Checkbox>
                                    <Checkbox
                                        id={`interest-area2`}
                                        name="interest_area"
                                        value="marketing"
                                        onChange={this._onCheckBoxGroupChange}
                                    >マーケティング</Checkbox>
                                    <Checkbox
                                        id={`interest-area3`}
                                        name="interest_area"
                                        value="cxo"
                                        onChange={this._onCheckBoxGroupChange}
                                    >CXO人材</Checkbox>
                                    <Checkbox
                                        id={`interest-area4`}
                                        name="interest_area"
                                        value="designer"
                                        onChange={this._onCheckBoxGroupChange}
                                    >デザイナー</Checkbox>
                                    <Checkbox
                                        id={`interest-area5`}
                                        name="interest_area"
                                        value="hr"
                                        onChange={this._onCheckBoxGroupChange}
                                    >HR</Checkbox>
                                    <Checkbox
                                        id={`interest-area6`}
                                        name="interest_area"
                                        value="pr"
                                        onChange={this._onCheckBoxGroupChange}
                                    >PR</Checkbox>
                                    <Checkbox
                                        id={`interest-area7`}
                                        name="interest_area"
                                        value="general"
                                        onChange={this._onCheckBoxGroupChange}
                                    >総務</Checkbox>
                                    <Checkbox
                                        id={`interest-area8`}
                                        name="interest_area"
                                        value="others"
                                        onChange={this._onCheckBoxGroupChange}
                                    >その他</Checkbox>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>ビザステータス(米国求職者の場合)</ControlLabel>
                                <div key={`visa-status`} style={{textAlign: "left", paddingLeft: "35%"}}>
                                    <Radio
                                        id={`visa-status1`}
                                        name="visa_status"
                                        value="F1_OPT"
                                        onChange={this._onFormChange}
                                    >F1(OPT希望)</Radio>
                                    <Radio
                                        id={`visa-status2`}
                                        name="visa_status"
                                        value="F1_CPT"
                                        onChange={this._onFormChange}
                                    >F1(CPT希望)</Radio>
                                    <Radio
                                        id={`visa-status3`}
                                        name="visa_status"
                                        value="H1B"
                                        onChange={this._onFormChange}
                                    >H1B</Radio>
                                    <Radio
                                        id={`visa-status4`}
                                        name="visa_status"
                                        value="J1"
                                        onChange={this._onFormChange}
                                    >J1</Radio>
                                    <Radio
                                        id={`visa-status5`}
                                        name="visa_status"
                                        value="before_apply"
                                        onChange={this._onFormChange}
                                    >ビザ申請前</Radio>
                                </div>
                            </FormGroup>
                            {
                                this.state.isSigningUp ?
                                    <BlockSpinner visible={true}/>
                                    :
                                    <Button id="signupWindowSingupButton" bsStyle="primary"
                                            className="auth-confirm-button"
                                            onClick={this._signup}>{lang.signupButton}</Button>
                            }
                            <Link className="auth-link" to="/login">{lang.loginLinkMessage}</Link>
                            {this._languageChangeLink()}
                        </div>
                        <span className="signup-agreement">
            {lang.signupAgreementMessage}
          </span>
                    </div>
                }
            </Panel>
        )
    }
}

