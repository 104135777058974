import React, { Component } from 'react'
import { Button, Panel, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import store from 'store';

import BlockSpinner     from './block-spinner';
import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/login-window.json';
import '../../css/auth-pane.css';

export default class LoginWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggingIn: false,
      messages: [],
      user: {
        email: '',
        password: '',
      }
    }
    this._login = this._login.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickJapanese = this._onClickJapanese.bind(this);
    this._onClickEnglish = this._onClickEnglish.bind(this);
  }

  _onClickJapanese() {
    store.set('langCode', 'ja');
    window.location.reload();
  }

  _onClickEnglish() {
    store.set('langCode', 'en');
    window.location.reload();
  }

  _languageChangeLink() {
    const langCode = DatavaseApi.getLangCode();
    if(langCode === 'ja'){
      return(<Link onClick={this._onClickEnglish}>English</Link>);
    }else{
      return(<Link onClick={this._onClickJapanese}>日本語</Link>);
    }
  }

  _login() {
    this.setState({isLoggingIn: true});
    DatavaseApi.login(this.state.user)
    .then(res => {
      //console.log(res);
      store.set('access-token', res.headers['access-token']);
      store.set('uid',          res.headers['uid']);
      store.set('client',       res.headers['client']);
      store.set('trial',        res.data.data['trial_expires_on'])
      window.location.href = this.props.next || '/';
    })
    .catch(res => {
      let errorMessages = [];
      if(res.response.data.errors) {
        errorMessages = res.response.data.errors;
      }
      this.setState({
        isLoggingIn: false,
        messages: errorMessages,
      });
    });
  }

  _onFormChange(e) {
    const newUser = Object.assign({}, this.state.user, {
      [e.target.name]: e.target.value
    });
    this.setState({ user: newUser });
  }

  _constructErrorsList() {
    let errorRows = this.state.messages.map((message, i) => {
      return(<li key={i} style={{color: '#666', fontWeight: 'bold'}}>{message}</li>);
    });

    return( <ul>{errorRows}</ul> );
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return (
      <Panel className="auth-panel" header={lang.loginWindowTitle}>
        {this._constructErrorsList()}
        <div className="auth-panel-content">
          <div className="social-auth">
            <h5>{lang.loginMessage}</h5>
          </div>
          <div className="auth-form">
            <FormGroup>
              <ControlLabel>{lang.emailLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="text"
                placeholder={lang.emailPlaceholder}
                name="email"
                onChange={this._onFormChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{lang.passwordLabel}</ControlLabel>
              <FormControl
                className="top-navbar-form"
                type="password"
                placeholder={lang.passwordPlaceholder}
                name="password"
                onChange={this._onFormChange}
              />
            </FormGroup>
            { this.state.isLoggingIn ? <BlockSpinner visible={true}/> :
            <Button bsStyle="primary" className="auth-confirm-button" onClick={this._login}>{lang.loginButton}</Button> }
            <Link className="auth-link" to="/signup">{lang.signupLinkMessage}</Link>
            <Link className="auth-link" to="/password_reset_request">{lang.passwordResetLinkMessage}</Link>
            <Link className="auth-link" to="/resend_confirmation_request">{lang.resendConfirmationRequestMessage}</Link>
            {this._languageChangeLink()}
          </div>
        </div>
      </Panel>
    )
  }
}
