import React, { Component } from 'react';

export default class NewsArticleSummaryView extends Component {
  render() {
    const bulletPoints = this.props.bulletPoints || [];
    const summaryInBulletPoints = bulletPoints.map((bulletPoint, i) => {
      return(
        <li key={i} style={{margin: '8px 30px', textAlign: 'left', listStyleType: 'square'}}>
          {bulletPoint}
        </li>
      );
    });

    return(
      <ul style={{margin: '10px 0'}}>
        {summaryInBulletPoints}
      </ul>
    );
  }
}
