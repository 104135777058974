import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import '../css/review-panel.css';
import * as DatavaseApi from './datavase-api';
import { Button, Checkbox, FormControl, InputGroup, Panel } from "react-bootstrap";
import BlockSpinner from "./partials/block-spinner";
import ShareButtons from "./partials/share-buttons";
import store from "store";

class QuestionBoardsPane extends Component {
  //コメントを回答にしてPOSTにする、初期取得は一覧をとって(それにトピックが入ってるから)、それからクリックされたら詳細を取る感じでいいかな
  //新規投稿…いいねは後日実装としても、うん
  constructor(props) {
    super(props);
    this.state = {
      topics: {},
      replies: {},
      current_like_status: [],
      likes: [],
      mePics: '',
      anonymous: false,
      loading: true,
      isLoggedIn: true,
      showNewQuestion: false,
      title: '',
      body: '',
      tag: 99,
      restrict_tag_id: -1
    };
    this._editTitle = this._editTitle.bind(this);
    this._editBody = this._editBody.bind(this);
    this._createThread = this._createThread.bind(this);
  }

  componentDidMount() {
    DatavaseApi.fetchQuestionBoardDetails(this.props.match.params.id).then(res => {
      this.setState({
        topics: res.data,
        loading: false,
      });
    }).catch((res) => {
      console.error(res);
    });
    if (store.get('access-token') === undefined ||
      store.get('uid') === undefined ||
      store.get('client') === undefined) {
      let mePic = Math.floor(Math.random() * (20 - 1) + 1);
      this.setState({
        isLoggedIn: false,
        mePics: `/avatars/avatar_${mePic}.png`
      });
    } else {
      DatavaseApi.fetchContributor().then(res => {
        this.setState({
          mePics: res.data.thumb_profile_image_url
        });
      }).catch((res) => {
        console.error(res);
      });
    }
  }

  _createThread(e) {
    DatavaseApi.createQuestionBoard({
      organization_id: null,
      title: this.state.title,
      body: this.state.body,
      tag_id: this.state.tag, //Tmp
      anonymous: this.state.anonymous,
    }).then(_res => window.location.reload());
  }

  _editTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  _editBody(e) {
    this.setState({
      body: e.target.value,
    });
  }

  _editAnswerBody(i, e) {
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    topics[i].answer.body = e.target.value;
    this.setState({ topics })
  }

  _editAnswerAnonymity(i) {
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    if (topics[i].answer.anonymous === undefined) {
      topics[i].answer.anonymous = true;
    } else {
      topics[i].answer.anonymous = !topics[i].answer.anonymous;
    }
    this.setState({ topics })
  }

  _setRestrictTagId(id) {
    this.setState({
      restrict_tag_id: id
    })
  }

  _postVote(id, flag, orig_i) {
    if (!this.state.isLoggedIn) {
      DatavaseApi.fetchContributor().then(res => {
        //console.log(res);
      }).catch((res) => {
        console.error(res)
      })
    } else {
      DatavaseApi.postVote(id, { rating: flag }).then(res => {
        let likes = this.state.current_like_status;
        likes[id] = flag;
        let like_count = this.state.topics;
        if (flag === null) {
          like_count[orig_i].likes--;
        } else {
          like_count[orig_i].likes++;
        }
        this.setState({
          current_like_status: likes,
          topics: like_count,
        });
      }).catch((res) => {
        console.error(res);
      });
    }
  }
  _toggleAnswerPanel(i) {
    if (!this.state.isLoggedIn) {
      DatavaseApi.fetchContributor().then(res => {
        //console.log(res);
      }).catch((res) => {
        console.error(res)
      })
    } else {
      let entries = this.state.topics;
      if (this.state.topics[i].showAnswerPanel === undefined) {
        entries[i].showAnswerPanel = false;
      }
      entries[i].showAnswerPanel = !this.state.topics[i].showAnswerPanel;
      this.setState({ topics: entries });
    }
  }
  _toggleSharePanel(i) {
    let entries = this.state.topics;
    if (this.state.topics[i].showSharePanel === undefined) {
      entries[i].showSharePanel = false;
    }
    entries[i].showSharePanel = !this.state.topics[i].showSharePanel;
    this.setState({ topics: entries });
  }
  _renderSharePanel(i) {
    if (this.state.topics[i].showSharePanel) {
      return (
        <div style={{ marginTop: '30px', paddingBottom: '20px' }}>
          <ShareButtons
            url={`https://datavase.io/qa_boards/${this.state.topics[i].id}`}
            title={`${this.state.topics[i].title}`}
          />
        </div>
      );
    }
  }
  _renderAnswerPanel(orig_i, topic, replies, i) {
    if (this.state.topics[orig_i].showAnswerPanel) {
      return (
        <div style={{ marginTop: '25px' }}>
          {replies[i] !== undefined ? replies[i].map((reply, i) => {
            return (<div
              key={`question-board-topic-${reply.id}`}
              style={{ paddingLeft: '1em', borderLeft: "solid 3px gray", marginLeft: '1em' }}>
              <p style={{ fontSize: '1.1em' }}>{reply.title}</p>
              <img style={{ width: '35px', borderRadius: '20px' }}
                src={reply.thumb_profile_image_url || reply.profile_image_url}
                alt="profileImg" />
              <span
                style={reply.user_id === topic.user_id ? { fontWeight: "bold" } : {}}>{reply.name || reply.screen_name}&nbsp;</span>
              <span style={{ color: "#E3E3E3" }}>{DatavaseApi.prettifyDatetime(reply.created_at)}</span>
              <p style={{ marginTop: '10px', fontSize: '1em' }}>{reply.body}</p>
              <div className="action-button">
                <span className="click"
                  onClick={() => this._postVote(reply.id, reply.current_like_status === true ? null : true, reply.orig_i_number)}>
                  <span
                    className={reply.current_like_status === true ? "list-review-panel-like qbpane" : "list-review-panel-like-empty qbpane"}
                    style={{ verticalAlign: 'middle' }}>&nbsp;　　</span>
                  <span>{reply.likes}</span>
                </span>
              </div>
            </div>)
          }) : "回答がありません。"}
          <div style={{ margin: '20px 0' }}>
            <p>回答・アドバイスする</p>
            <InputGroup className="mb-3">
              <FormControl componentClass="textarea"
                type="text"
                placeholder="本文"
                style={{ resize: 'vertical' }}
                onChange={(e) => this._editAnswerBody(topic.orig_i_number, e)} />
              <div>
                <Checkbox name="anonymous"
                  onClick={() => this._editAnswerAnonymity(topic.orig_i_number)}
                  inline> チェックを入れると匿名になります </Checkbox>
              </div>
              <InputGroup.Button>
                <Button bsStyle="primary"
                  onClick={() => this._postAnswer(topic.orig_i_number)}> Post </Button>
              </InputGroup.Button>
            </InputGroup>
          </div>
        </div>
      );
    }
  }

  _postAnswer(i) {
    let topics = this.state.topics;
    if (topics[i].answer === undefined) {
      topics[i].answer = {}
    }
    if (topics[i].answer.anonymous === undefined) {
      topics[i].answer.anonymous = false;
    }
    if (topics[i].answer.title === undefined) {
      topics[i].answer.title = "";
    }
    if (topics[i].answer.body === undefined) {
      topics[i].answer.body = "";
    }
    topics[i].answer.organization_id = topics[i].organization_id;
    topics[i].answer.tag_id = topics[i].tag;
    DatavaseApi.addAnswer(topics[i].id, topics[i].answer).then(_res => window.location.reload());
  }

  _clickNewQuestion() {
    this.setState({
      showNewQuestion: !this.state.showNewQuestion
    })
  }


  render() {
    if (this.state.loading) {
      return (
        <div>
          <Panel style={{ marginLeft: '12.5%', width: '75%' }} className="panel-qa">
            <BlockSpinner visible />
          </Panel>
        </div>
      );
    }
    let replies = [];
    let topics = [];
    // eslint-disable-next-line
    this.state.topics.map((topic, i) => {
      if (topic.question_board_id !== null) {
        if (replies[65536 - topic.question_board_id] === undefined) {
          replies[65536 - topic.question_board_id] = []
        }
        if (replies[65536 - topic.question_board_id].answer_count === undefined) {
          replies[65536 - topic.question_board_id].answer_count = 1;
        } else {
          replies[65536 - topic.question_board_id].answer_count++;
        }
        replies[65536 - topic.question_board_id][65536 - topic.id] = topic;
        replies[65536 - topic.question_board_id][65536 - topic.id].current_like_status = this.state.current_like_status[topic.id] === undefined ? topic.current_like_status : this.state.current_like_status[topic.id];
        replies[65536 - topic.question_board_id][65536 - topic.id].orig_i_number = i;
      } else if (this.state.restrict_tag_id === -1 || this.state.restrict_tag_id === topic.tag) {
        if (topics[65536 - topic.id] === undefined) {
          topics[65536 - topic.id] = []
        }
        topics[65536 - topic.id] = topic;
        topics[65536 - topic.id].current_like_status = this.state.current_like_status[topic.id] === undefined ? topic.current_like_status : this.state.current_like_status[topic.id];
        topics[65536 - topic.id].orig_i_number = i;
      }
    });
    return (
      <div>
        <div>
          {topics.map((topic, i) => {
            /* Todo: 無限スクロール(120件に絞る)、3件以上コメントは隠す? シェアページでページ番号を割り振るようにする*/
            //Extremely temporary table. Might migrate to the DB
            let tag = 'その他';
            if (topic.tag === 1) {
              tag = "ファイナンス"
            } else if (topic.tag === 2) {
              tag = "事業選定" //Deprecated
            } else if (topic.tag === 3) {
              tag = "バックオフィス"
            } else if (topic.tag === 4) {
              tag = "人材"
            } else if (topic.tag === 5) {
              tag = "企業調査"
            } else if (topic.tag === 6) {
              tag = "市場調査"
            } else if (topic.tag === 7) {
              tag = "マーケティング"
            } else if (topic.tag === 8) {
              tag = "セールス"
            } else if (topic.tag === 9) {
              tag = "HR"
            } else if (topic.tag === 10) {
              tag = "PR"
            }

            return (
              <Panel style={{ marginLeft: '12.5%', width: '75%' }} className="panel-qa"
                key={`question-board-topic-${topic.id}`}>
                <p style={{ fontSize: '1.75em', marginBottom: '15px' }}><Link
                  to={`/qa_boards/${topic.id}`} onlyActiveOnIndex>{topic.title}</Link></p>
                <img style={{ width: '35px', borderRadius: '20px' }}
                  src={topic.thumb_profile_image_url || topic.profile_image_url} alt="profileImg" />
                <span style={{
                  fontWeight: 'bold',
                  fontSize: '1.2em',
                  marginLeft: '1em'
                }}>{topic.name || topic.screen_name}&nbsp;</span>
                <span style={{ color: "#E3E3E3" }}>{DatavaseApi.prettifyDatetime(topic.created_at)}&nbsp;</span>
                {topic.org_name !== null ?
                  <span className="btn btn-default"
                    style={{
                      fontSize: '.7em',
                      backgroundColor: '#4A2F4A',
                      borderRadius: "5px",
                      marginLeft: '10px'
                    }}><Link style={{ color: '#FFF' }}
                      to={`/${topic.resource_type_name}/${topic.slug || topic.organization_id}/`}>{topic.org_name}</Link></span> : null
                }
                <span className="btn btn-primary"
                  style={{ fontSize: '.7em', borderRadius: "5px", marginLeft: '10px' }}
                  onClick={() => this._setRestrictTagId(topic.tag)}>{tag}</span>
                <p style={{ marginTop: '15px', fontSize: '1.25em' }}>{topic.body}</p>
                <hr />
                <div className="action-button">
                  <span style={{ verticalAlign: 'middle', marginRight: '1cm' }} className="click" onClick={() => this._postVote(topic.id, topic.current_like_status === true ? null : true, topic.orig_i_number)}>
                    <span
                      className={topic.current_like_status === true ? "list-review-panel-like qbpane" : "list-review-panel-like-empty qbpane"}
                    >&nbsp;　　</span>
                    <span style={{ display: 'inline-block', marginTop: '-9px' }}>{topic.likes}</span>
                  </span>
                  <span style={{ marginRight: '1cm' }} onClick={() => this._toggleAnswerPanel(topic.orig_i_number)} className="click">
                    <span>&nbsp;</span>
                    <span style={{ verticalAlign: 'middle', marginTop: "4px", display: "inline-block" }} className="material-icons qbpane"
                    >question_answer</span>
                    <span style={{ verticalAlign: 'middle', marginTop: "1px", display: "inline-block" }}>&nbsp;{replies[i] === undefined ? 0 : replies[i].answer_count}</span></span>
                  <span style={{ verticalAlign: 'middle' }} onClick={() => this._toggleSharePanel(topic.orig_i_number)} className="click">
                    <span
                      className="list-review-panel-share"
                    >  &nbsp;&nbsp;　</span></span>
                  {this._renderSharePanel(topic.orig_i_number)}
                  {this._renderAnswerPanel(topic.orig_i_number, topic, replies, i)}
                </div>
              </Panel>
            )
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(QuestionBoardsPane);
