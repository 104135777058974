import * as StateCache from '../constants/state-cache';

const initialState = {
  companiesPaneState: null,
  investorsPaneState: null,
  schoolsPaneState: null,
  peoplePaneState: null,
  productsPaneState: null,
  eventsPaneState: null,
  datavaseArticlesPaneState: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case StateCache.SET_COMPANIES_PANE_STATE: {
      return Object.assign({}, state, {
        companiesPaneState: action.state,
      });
    }
    case StateCache.SET_INVESTORS_PANE_STATE: {
      return Object.assign({}, state, {
        investorsPaneState: action.state,
      });
    }
    case StateCache.SET_SCHOOLS_PANE_STATE: {
      return Object.assign({}, state, {
        schoolsPaneState: action.state,
      });
    }
    case StateCache.SET_PEOPLE_PANE_STATE: {
      return Object.assign({}, state, {
        peoplePaneState: action.state,
      });
    }
    case StateCache.SET_PRODUCTS_PANE_STATE: {
      return Object.assign({}, state, {
        productsPaneState: action.state,
      });
    }
    case StateCache.SET_EVENTS_PANE_STATE: {
      return Object.assign({}, state, {
        eventsPaneState: action.state,
      });
    }
    case StateCache.SET_DATAVASE_ARTICLES_PANE_STATE: {
      return Object.assign({}, state, {
        datavaseArticlesPaneState: action.state,
      });
    }
    default: {
      return state;
    }
  }
}
