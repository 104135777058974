import * as StateCache from '../constants/state-cache';

export function setCompaniesPaneState(state) {
  return {
    type: StateCache.SET_COMPANIES_PANE_STATE,
    state: state,
  }
}
export function setInvestorsPaneState(state) {
  return {
    type: StateCache.SET_INVESTORS_PANE_STATE,
    state: state,
  }
}
export function setSchoolsPaneState(state) {
  return {
    type: StateCache.SET_SCHOOLS_PANE_STATE,
    state: state,
  }
}
export function setPeoplePaneState(state) {
  return {
    type: StateCache.SET_PEOPLE_PANE_STATE,
    state: state,
  }
}
export function setProductsPaneState(state) {
  return {
    type: StateCache.SET_PRODUCTS_PANE_STATE,
    state: state,
  }
}
export function setEventsPaneState(state) {
  return {
    type: StateCache.SET_EVENTS_PANE_STATE,
    state: state,
  }
}
export function setDatavaseArticlesPaneState(state) {
  return {
    type: StateCache.SET_DATAVASE_ARTICLES_PANE_STATE,
    state: state,
  }
}
