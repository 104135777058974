import React, { Component } from 'react';
import { Panel, ListGroup, ListGroupItem, Button }    from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from "styled-components";

import LoginWindow      from '../partials/login-window';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/login-page.json';
import * as sharedResourcesActions from '../../actions/shared-resources';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const showMessage = (new URL(window.location)).searchParams.get('message_key') !== null;
    this.state = {
      showMessage: showMessage,
    }
    this._onCancelClick = this._onCancelClick.bind(this);
    this._renderModal = this._renderModal.bind(this);
    this._onClickOverlay = this._onClickOverlay.bind(this);
  }

  _onCancelClick() {
    this.setState({showMessage: false});
  }

  _onClickOverlay(e) {
    if (e.target.id === 'modal-bg') {
      this._onCancelClick();
    }
  }

  _getNextPageHref() {
    return (new URL(window.location)).searchParams.get('next');
  }

  _getMessage(messageKey) {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    switch(messageKey) {
      case 'login_to_continue': {
        return lang.loginToContinue
      }
      case 'invitation_token_invalid': {
        return lang.invitationTokenInvalid;
      }
      case 'invitation_token_expired': {
        return lang.invitationTokenExpired;
      }
      case 'invitation_processed': {
        return lang.invitationProcessed;
      }
      case 'invitation_failed': {
        return lang.invitationFailed;
      }
      default: {
        return null;
      }
    }
  }

  _renderModal() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const messageKey = (new URL(window.location)).searchParams.get('message_key');
    const message  = this._getMessage(messageKey);
    if (this.state.showMessage) {
      return (
        <Overlay id="modal-bg" onClick={this._onClickOverlay}>
          <Panel>
            <ListGroup>
              <ListGroupItem>
                {lang.messageModalTitle}
              </ListGroupItem>
              <ListGroupItem>
                { message }
              </ListGroupItem>
              <ListGroupItem>
                <Button onClick={this._onCancelClick}>
                  OK
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Panel>
        </Overlay>
      );
    } else {
      return (<div></div>);
    }
  }

  render() {
    const next = this._getNextPageHref();

    return(
      <div>
        { this._renderModal() }
        <div style={{margin: '10px'}}>
          <h1 id="auth-page-heading" className="datavase">datavase.io</h1>
          <LoginWindow next={next}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
