import React, { Component } from 'react';
import { Panel, Col, Row } from 'react-bootstrap';
import '../../css/data-grid-panel.css';

export default class DataGridPanel extends Component {
  render () {
    return (
      <Panel id="data-grid-panel">
        <h2 id="data-grid-heading">{this.props.dataGridHeading}</h2>
        <Col className="data-grid-column" id="data-grid-column-1" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-1 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][0]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][0]}</span>
          </Row>
          <Row className="data-grid-row-2 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[1][0]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[1][0]}</span>
          </Row>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[2][0]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[2][0]}</span>
          </Row>
        </Col>
        <Col className="data-grid-column" id="data-grid-column-2" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-1 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][1]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][1]}</span>
          </Row>
          <Row className="data-grid-row-2 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[1][1]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[1][1]}</span>
          </Row>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[2][1]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[2][1]}</span>
          </Row>
        </Col>
        <Col className="data-grid-column" id="data-grid-column-3" sm={12} md={4} lg={4}>
          <Row className="data-grid-row-1 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[0][2]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[0][2]}</span>
          </Row>
          <Row className="data-grid-row-2 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[1][2]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[1][2]}</span>
          </Row>
          <Row className="data-grid-row-3 data-grid-row">
            <span className="data-grid-cell-heading">{this.props.dataGridCellHeadings[2][2]}</span>
            <span className="data-grid-cell-value">{this.props.dataGridCellValues[2][2]}</span>
          </Row>
        </Col>
      </Panel>
    );
  }
}
