import React, { Component } from 'react';
import * as c3 from 'c3';
import * as DatavaseApi from '../datavase-api';
import '../../css/c3.css';

export default class RevenueHistoryGraph extends Component {
  componentDidMount() {
    this._updateGraph();
  }

  componentDidUpdate() {
    this._updateGraph();
  }

  _updateGraph() {
    const displayedCurrency         = this.props.displayedCurrency || 'USD';
    const estimatedRevenueHistories = this.props.estimatedRevenueHistories.sort(
      (a,b) => a.date > b.date
    );

    const graphData = {
      date: estimatedRevenueHistories.map(history => history.date),
      estimated_revenues: estimatedRevenueHistories.map(history => {
        return DatavaseApi.convertCurrency(
          history.estimated_revenue, history.currency_code, displayedCurrency
        );
      })
    };

    const numberOfHistories = graphData.date.length;
    let barWidthRatio = 0.4;
    if(numberOfHistories < 3) {
      barWidthRatio = 0.1;
    }else if(numberOfHistories < 8) {
      barWidthRatio = 0.3;
    }

    c3.generate({
      bindto: `#${this.props.graphName}`,
      data: {
        xs: {
          'Estimated Revenue': 'date'
        },
        columns: [
          ['date'].concat(graphData.date),
          ['Estimated Revenue'].concat(graphData.estimated_revenues)
        ],
        types: {
          'Estimated Revenue': 'bar',
        },
        colors: {
          'Estimated Revenue': '#FAE5D5',
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            culling: {
              max: 5,
            },
            format: '%Y/%m/%d'
          }
        },
        y: {
          tick: {
            format: (d) => DatavaseApi.abbrPrice(d, displayedCurrency)
          }
        }
      },
      tooltip: {
        format: {
          value: (value, ratio, id, index) => {
            const history = estimatedRevenueHistories[index];
            return DatavaseApi.abbrPrice(
              history.estimated_revenue, history.currency_code
            );
          }
        }
      },
      bar: {
        width: {
          ratio: barWidthRatio
        }
      }
    });
  }
  render() {
    return(
      <div id={this.props.graphName}></div>
    );
  }
}
