import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import ResearchRequestPanel       from './research-request-panel';
import FundingRoundsHistoryGraph  from './funding-rounds-history-graph';
import TotalEmployeesHistoryGraph from './total-employees-history-graph';
import RevenueHistoryGraph        from './revenue-history-graph';
import { getLangCode }            from '../datavase-api';
import i18n                       from '../../i18n/company-graph-panel.json';

export default class CompanyGraphsPanel extends Component {
  _fundings() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const company       = this.props.company;
    const fundingRounds = this.props.fundingRounds;

    if(fundingRounds.length !== 0) {
      return(
        <Panel id="company-graphs">
          <Tabs id="details-graphs-tabs">
            <Tab eventKey={1} title={lang.fundingRoundsHistoryGraphTitle}>
              <Panel className="side-padded-panel">
                <FundingRoundsHistoryGraph
                  displayedCurrency="JPY"
                  graphName="a"
                  fundingRounds={fundingRounds}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(company.might_have_fundings){
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.fundingRoundsHistoryGraphTitle}
          resourceType={'organizations'}
          id={company.id}
          graph
        />
      );
    }else{
      return null;
    }
  }

  _employees() {
    const langCode                         = getLangCode();
    const lang                             = i18n[langCode];
    const company                          = this.props.company;
    const totalEmployeesHistories          = this.props.totalEmployeesHistories;
    const estimatedTotalEmployeesHistories = this.props.estimatedTotalEmployeesHistories;

    if(totalEmployeesHistories.length+estimatedTotalEmployeesHistories.length !== 0) {
      return(
        <Panel id="company-graphs">
          <Tabs id="details-graphs-tabs">
            <Tab eventKey={3} title={lang.totalEmployeesGraphTitle}>
              <Panel className="side-padded-panel">
                <TotalEmployeesHistoryGraph
                  graphName="c"
                  totalEmployeesHistories={totalEmployeesHistories}
                  estimatedTotalEmployeesHistories={estimatedTotalEmployeesHistories}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else{
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.totalEmployeesGraphTitle}
          resourceType={'organizations'}
          id={company.id}
          graph
        />
      );
    }
  }

  _revenues() {
    const langCode                  = getLangCode();
    const lang                      = i18n[langCode];
    const company                   = this.props.company;
    const estimatedRevenueHistories = this.props.estimatedRevenueHistories;

    if(estimatedRevenueHistories.length !== 0) {
      return(
        <Panel id="company-graphs">
          <Tabs id="details-graphs-tabs">
            <Tab eventKey={2} title={lang.revenueGraphTitle}>
              <Panel className="side-padded-panel">
                <RevenueHistoryGraph
                  graphName="b"
                  displayedCurrency="USD"
                  estimatedRevenueHistories={estimatedRevenueHistories}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else{
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.revenueGraphTitle}
          resourceType={'organizations'}
          id={company.id}
          graph
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this._fundings()}
        {this._revenues()}
        {this._employees()}
      </div>
    );
  }
}

