import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {prettifyDatetime} from '../datavase-api';
import questionEyeCatching from '../../assets/datavase_article_eyecatching.png';
import color from '../styles/colors.js';

export default class DashboardQuestionPanel extends Component {
  constructor(props) {
      super(props);
    this.state = {
      panelTitle: '',
    }
  }

  render() {
    const question = this.props.question;

    const abstract = question.body.substring(0, 60) + "..." || '';
    const imageSource = question.thumb_profile_image_url || questionEyeCatching;
    const datetime = prettifyDatetime(question.created_at);
    const writer_name = question.screen_name || 'Datavase Editors';


    return (
        <List onClick={() => window.location.href = `/qa_boards/${question.id}`}>
        <Thambnail>
          <ThambImg alt={abstract} src={imageSource} />
        </Thambnail>
        <Contents>
          <Title>{abstract}</Title>
          <Meta>
            <Wrapper>
              <MetaTxt>{writer_name}</MetaTxt>
            </Wrapper>
            <MetaTxt>{datetime}</MetaTxt>
          </Meta>
        </Contents>
      </List>
    );
  }
}


const List = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: #fff;
  &:hover {
    background: ${color.black04};
  }
`;

const Thambnail = styled.p`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin: 0 12px 0 0;
  overflow: hidden;
`;

const ThambImg = styled.img`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 68px);
  height: 100%;
  margin: 0;
`;

const Title = styled.h1`
  margin: 0 0 4px;
  color: ${color.black87};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14px;
  width: 100%;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

const MetaTxt = styled.p`
  margin: 0;
  color: ${color.black54};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;
