import React, { Component } from 'react';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import EvaluateButtons            from './evaluate-buttons';
import ContributorTooltipContents from './contributor-tooltip-contents';
import ContributionDetailsModal   from './contribution-details-modal';
import placeholder from '../../assets/datavase_logo_icon_grey.png';

import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/investments-history-table.json';
import '../../css/history-table.css';

export default class InvestmentsHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContributionDetailsModal: false,
      contribution: null,
      selectedInvestment: {},
    };
    this._showContributionDetailsModal = this._showContributionDetailsModal.bind(this);
    this._hideContributionDetailsModal = this._hideContributionDetailsModal.bind(this);
    this._onSeeDetailsClick            = this._onSeeDetailsClick.bind(this);
  }

  _showContributionDetailsModal() {
    this.setState({showContributionDetailsModal: true});
  }

  _hideContributionDetailsModal() {
    this.setState({showContributionDetailsModal: false});
  }

  _onSeeDetailsClick(contribution) {
    const selectedInvestment = this.props.investments.find(investment => investment.id === contribution.information_id);
    this.setState({
      contribution: contribution,
      selectedInvestment: selectedInvestment
    });
    this._showContributionDetailsModal();
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    let investments = this.props.investments.slice();
    investments.sort((a,b) => new Date(b.date_of_investment) - new Date(a.date_of_investment));
    let investmentRows = investments.map((investment, i) => {
      const investee = investment.recipient_organization;
      const image = investee.thumb_profile_image_url || placeholder
      const name  = investee.organization_name;
      const link  = `/${investee.resource_type_name}/${investee.slug || investee.id}/`;
      const amount = DatavaseApi.prettifyPrice(
        investment.amount, investment.currency_code, 'Undisclosed'
      );
      const date   = DatavaseApi.prettifyDate(
        investment.date_of_investment, investment.precision_of_date_of_investment
      );

      const contribution = investment.most_recent_contribution;
      const evaluations  = contribution.evaluations;
      const userid       = DatavaseApi.getUserId();
      const currentUsersEvaluation = (evaluations.find(e => e.user_id === userid) || {}).evaluation_type_name;
      const contributorTooltip  = (
        <Tooltip id="contributor-tooltip">
          <ContributorTooltipContents
            contributor={contribution.contributor}
          />
        </Tooltip>
      );

      const seeDetailsButton = (
        <Button
          onClick={() => {this._onSeeDetailsClick(contribution)}}
          style={{padding: '0', border: 'none', background: 'transparent'}}
        >
          <i className="material-icons" style={{verticalAlign: 'middle'}}>more_horiz</i>
        </Button>
      );

      return (
        <OverlayTrigger key={i} overlay={contributorTooltip}>
          <tr>
            <td>
              <EvaluateButtons
                contribution={contribution}
                defaultEvaluation={currentUsersEvaluation}
              />
            </td>
            <td>{date}</td>
            <td>{amount}</td>
            <td>{investment.funding_type_name}</td>
            <td>
              <div key={i}>
                <img
                  src={image}
                  alt={name}
                  className="history-table-image"
                />
                <a href={link}>{name}</a>
              </div>
            </td>
            <td>{seeDetailsButton}</td>
          </tr>
        </OverlayTrigger>
      );
    });

    return(
      <div>
        <ContributionDetailsModal
          show={this.state.showContributionDetailsModal}
          contribution={this.state.contribution}
          information={this.state.selectedInvestment}
          onCancel={this._hideContributionDetailsModal}
        />
        <Table striped responsive hover id="funding-founds-table">
          <thead>
            <tr>
              <th>{lang.reputationHeader}</th>
              <th>{lang.dateOfInvestmentHeader}</th>
              <th>{lang.amountHeader}</th>
              <th>{lang.investmentStageHeader}</th>
              <th>{lang.investeeHeader}</th>
              <th>{lang.seeDetailsHeader}</th>
            </tr>
          </thead>
          <tbody>
            {investmentRows}
          </tbody>
        </Table>
      </div>
    );
  }
}
