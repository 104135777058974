import {MatchMediaHOC} from "react-match-media";
import React, {Component} from 'react'
import * as DatavaseApi from "../datavase-api";

class AdsSidebarMobile extends Component {
    constructor(props) {
        super(props);
        this.state={
            ads: [],
            loaded: false,
        }
    }
    componentDidMount() {
        DatavaseApi.fetchAds("__show_dashboard__").then(res => {
            this.setState({
                ads: res.data,
                loaded: true,
            })
        });
    }

    render() {
        let ads = null;
        if(this.state.loaded) {
            ads = this.state.ads.map((ad, _i) => {
                return (<a href={ad.goto_uri}><img src={ad.image_uri} alt={"Advertisement" + _i} style={{marginBottom: "40px"}}/></a>)
            });
        }
        return (<div className="ads">{ads}</div>);
    }
}

export default MatchMediaHOC((AdsSidebarMobile), '(max-width: 767px)');