import React, {Component} from 'react';
import {Button, Panel} from 'react-bootstrap';
import CreatePeopleReviewModal from './create-people-review-modal';
import ReviewStarPanel from './review-star-panel';
import BlockSpinner from './block-spinner';

import i18n from '../../i18n/review-lists-panel.json';
import * as DatavaseApi from '../datavase-api';

import '../../css/organization-review-lists-panel.css';
import placeholder from "../../assets/datavase_logo_icon_grey.png";
import {Link} from "react-router-dom";

export default class PersonReviewListsPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      isReviewModalOpen: false,
      loading: true,
      reviews: []
    };

    this._openReviewModal = this._openReviewModal.bind(this);
    this._closeReviewModal = this._closeReviewModal.bind(this);
  }

  componentDidMount() {
    const id = this.props.id;
    DatavaseApi.fetchPersonReviews(id)
    .then(res => {
      this.setState({
        reviews: res.data.person_reviews,
      });
    }).catch((res) => {
    });
      DatavaseApi.fetchPersonWithRelatedResources(id).then(res => {
          this.setState({
              investor: res.data,
              loading: false,
          });
      }).catch((res) => {
    });
  }

  _openReviewModal(){
    this.setState({isReviewModalOpen: true});
  }

  _closeReviewModal(){
    this.setState({isReviewModalOpen: false});
  }

  render() {
    if(this.state.loading) {
      return(
        <Panel>
          <BlockSpinner visiable/>
        </Panel>
      );
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
      const reviews = this.state.reviews.map((review, i) => <Review review={review} investor={this.state.investor}
                                                                    key={i}/>);

    return(
      <Panel style={{padding: "0"}}>
          <CreatePeopleReviewModal
              id={this.props.id}
              show={this.state.isReviewModalOpen}
              onCancel={this._closeReviewModal}
              person
          />
          {
              this.state.reviews.length === 0 ?
                  <span className="no-review">
              <b>{lang.noReview}</b>
          </span> : null
        }
        <Button
          bsStyle="success"
          className="modal-button"
          onClick={this._openReviewModal}
        >
          口コミを投稿する
        </Button>
        {reviews}
      </Panel>
    );
  }
}

class Review extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    const rating = this.props.review.rating;
    const good_point = this.props.review.good_point;
    const bad_point = this.props.review.bad_point;
    const total_review = this.props.review.total_review;
    return(
      <Panel>
          <img src={this.props.review.reviewer_image == null ? placeholder : this.props.review.reviewer_image}
               style={{width: '50px', height: '50px', margin: '0 10px 13px 0'}} alt="reviewer_image"/><br/>
          Author: {DatavaseApi.extractAndFormatPersonName(this.props.investor, langCode) ?? this.props.review.screen_name ?? this.props.review.name}
          {this.props.investor.person.name != null ? '投資元: ' + this.props.investor.person.name : ''}
          <ReviewStarPanel
              rating={rating}
          />
          <div
              style={(good_point === null || good_point === undefined) && (bad_point === null || bad_point === undefined) ? {display: "none"} : {lineHeight: "1.25em"}}>
              <div className='tab' style={{"margin-top": "5px", "lineHeight": "1.25em"}}>
                  <b>{lang.goodPoint}</b>
              </div>
              <div className='comment tab' style={{"lineHeight": "1.25em"}}>
                  {good_point}
              </div>
              <div className='tab' style={{"margin-top": "2px", "lineHeight": "1.25em"}}>
                  <b>{lang.badPoint}</b>
              </div>
              <div className='comment' style={{"lineHeight": "1.25em"}}>
                  {bad_point}
                  <Link to={`review/${this.props.review.id}`}>この口コミの詳細ページへ</Link>
              </div>
        </div>
          <div style={(total_review===null || total_review===undefined)?{display: "none"}:{}}>
            <div className='tab' style={{"margin-top":"2px"}}>
                <b>{lang.totalReview}</b>
            </div>
            <div className='comment'>
                {total_review}
            </div>
        </div>
      </Panel>
    );
  }
}
