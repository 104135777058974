import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as DatavaseApi            from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n                        from '../../i18n/evaluate-buttons.json';

class EvaluateButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEvaluation: null,
      reputationOffset: 0,
    };

    this._onPraiseClick = this._onPraiseClick.bind(this);
    this._onBlameClick  = this._onBlameClick.bind(this);
  };

  _onPraiseClick() {
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    const currentEvaluation = this.state.currentEvaluation;
    const defaultEvaluation = this.props.defaultEvaluation;

    if((!currentEvaluation && defaultEvaluation === 'praise') ||
       (currentEvaluation === 'praise')){
      return;
    }

    let reputationOffset = 1;
    if(currentEvaluation === 'soft_blame' && defaultEvaluation === 'praise') {
      reputationOffset = 0;
    }else if((!currentEvaluation && defaultEvaluation === 'soft_blame') ||
             (currentEvaluation === 'soft_blame' && defaultEvaluation === 'soft_blame')){
      reputationOffset = 2;
    }

    this.setState({
      reputationOffset: reputationOffset,
      currentEvaluation: 'praise'
    });
    DatavaseApi.praiseContribution(this.props.contribution.id)
    .then(res => {
      console.log('Your evaluation has been recorded!');
    })
    .catch(res => {
      console.error(res);
    });
  }

  _onBlameClick() {
    if(DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    const currentEvaluation = this.state.currentEvaluation;
    const defaultEvaluation = this.props.defaultEvaluation;

    if((!currentEvaluation && defaultEvaluation === 'soft_blame') ||
       (currentEvaluation === 'soft_blame')){
      return;
    }

    let reputationOffset = -1;
    if(currentEvaluation === 'praise' && defaultEvaluation === 'soft_blame') {
      reputationOffset = 0;
    }else if((!currentEvaluation && defaultEvaluation === 'praise') ||
             (currentEvaluation === 'praise' && defaultEvaluation === 'praise')){
      reputationOffset = -2;
    }

    this.setState({
      reputationOffset: reputationOffset,
      currentEvaluation: 'soft_blame'
    });
    DatavaseApi.blameContribution(this.props.contribution.id)
    .then(res => {
      console.log('Your evaluation has been recorded!');
    })
    .catch(res => {
      console.error(res);
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const upvoteTooltip   = <Tooltip id="upvote">{lang.upvoteTooltip}</Tooltip>;
    const downvoteTooltip = <Tooltip id="downvote">{lang.downvoteTooltip}</Tooltip>;
    const contribution = this.props.contribution;
    const reputation = contribution.praise - contribution.soft_blame + this.state.reputationOffset;

    const currentEvaluation = this.state.currentEvaluation;
    const defaultEvaluation = this.props.defaultEvaluation;
    const upvotePressed   = (!currentEvaluation && defaultEvaluation === 'praise') || (currentEvaluation === 'praise');
    const downvotePressed = (!currentEvaluation && defaultEvaluation === 'soft_blame') || (currentEvaluation === 'soft_blame');

    return(
      <div>
        <div>
          <OverlayTrigger overlay={upvoteTooltip}>
            <Button onClick={this._onPraiseClick} style={{padding: '0', border: 'none', backgroundColor: 'transparent'}}>
              <i className="material-icons" style={{verticalAlign: 'middle', fontWeight: upvotePressed ? 'bold' : 'normal', color: upvotePressed ? '#5EB663' : '#666'}}>expand_less</i>
            </Button>
          </OverlayTrigger>
        </div>
        <div style={{display: 'inline-block', width: '24px', textAlign: 'center'}}>
          {reputation}
        </div>
        <div>
          <OverlayTrigger overlay={downvoteTooltip}>
            <Button onClick={this._onBlameClick} style={{padding: '0', border: 'none', backgroundColor: 'transparent'}}>
              <i className="material-icons" style={{verticalAlign: 'middle', fontWeight: downvotePressed ? 'bold' : 'normal', color: downvotePressed ? '#D75553' : '#666'}}>expand_more</i>
            </Button>
          </OverlayTrigger>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch)
  };
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluateButtons);
