import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import BlockSpinner from './block-spinner';
import i18n         from '../../i18n/column-view.json';
import '../../css/column-view.css';

const columnViewStyle = {
  display: 'inline-block',
  margin: '0 6px',
  whiteSpace: 'normal'
};

const categoryNameDivStyle = {
  display: 'inline-block',
  margin: '0px 4px 8px 4px',
  padding: '8px',
  backgroundColor: '#fff',
  fontSize: '16px',
  fontWeight: 'bold'
};

const deleteColumnButtonStyle = {
  border: 'none',
  backgroundColor: 'transparent',
  padding: '0',
};

const deleteColumnButtonIconStyle = {
  verticalAlign: 'middle',
  fontSize: '21px'
};


export default class ColumnView extends Component {
  constructor(props) {
    super(props);
    this._onDeleteButtonClick   = this._onDeleteButtonClick.bind(this);
    this._onSettingsButtonClick = this._onSettingsButtonClick.bind(this);
    this._onTitleClick          = this._onTitleClick.bind(this);
  }

  _onSettingsButtonClick() {
    if(this.props.onSettingsButtonClick) { this.props.onSettingsButtonClick(); }
  }

  _onDeleteButtonClick() {
    if(this.props.onDeleteButtonClick) { this.props.onDeleteButtonClick(); }
  }

  _onTitleClick() {
    if(this.props.onTitleClick) { this.props.onTitleClick(); }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return(
      <div style={columnViewStyle}>
        <div>
          <div
            onClick={this._onTitleClick}
            style={Object.assign({}, categoryNameDivStyle, {color: this.props.color, width: this.props.titleWidth || '332px'})}
          >
            {this.props.title}
            <Dropdown id="dropdown" style={{float: 'right'}}>
              <Dropdown.Toggle
                style={deleteColumnButtonStyle}
                noCaret
              >
                <i className="material-icons" style={deleteColumnButtonIconStyle}>more_horiz</i>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{borderRadius: '0'}}>
                <MenuItem
                  onClick={this._onSettingsButtonClick}
                >
                  {lang.settings}
                </MenuItem>
                <MenuItem
                  onClick={this._onDeleteButtonClick}
                >
                  {lang.delete}
                </MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div
          className="column-view"
          style={{height: this.props.columnHeight || '800px', width: this.props.columnWidth || '340px'}}
        >
          <div className="column-view-inner">
          {
            this.props.isLoading ?
            <BlockSpinner visible/> :
            this.props.entriesView
          }
          </div>
        </div>
      </div>
    );
  }
}
