import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import MetaTags from 'react-meta-tags';
import {withRouter} from 'react-router-dom';

import BlockSpinner from './partials/block-spinner';
import OrganizationAbstractPanel from './partials/organization-abstract-panel';
import OrganizationOfficersPanel from './partials/organization-officers-panel';
import ContactSocialPanel from './partials/contact-social-panel';
import ExitsPanel from './partials/exits-panel';
import NewsArticlesByResourcePanel from './partials/news-articles-by-resource-panel';
import InvestorDataGridPanel from './partials/investor-data-grid-panel';
import InvestorGraphsPanel from './partials/investor-graphs-panel';
import InvestorHistoryDetailsPanel from './partials/investor-history-details-panel';
import VideoPanel from './partials/video-panel';
import TwitterTimelinePanel from './partials/twitter-timeline-panel';
import UpgradeToProceedModalRevised from './partials/upgrade-to-proceed-modal-revised';
import UpgradeToProceedModalRevisedMobile from './partials/upgrade-to-proceed-modal-revised-mobile';

import * as DatavaseApi from './datavase-api';
import * as sharedResourcesActions from '../actions/shared-resources';
import i18n from '../i18n/investor-details-pane.json'
import '../css/details-pane.css';
import styled from "styled-components";
import color from "./styles/colors";

class InvestorDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: {},
      contributions: null,
      hasLoaded: false,
      id: null,
    };

    this._onTabSelect = this._onTabSelect.bind(this);
  }

  _getId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    const id = this._getId();
    DatavaseApi.fetchInvestorWithRelatedResources(id)
    .then(res => {
      this.setState({
        resources: res.data,
        hasLoaded: true,
        id: res.data.investor.id,
      });
    }).catch((res) => {
      console.error(res);
    })
  }

  _onTabSelect(key) {
    if(key === "contributions") {
      if(!this.state.contributions) {
        const id = this._getId();
        DatavaseApi.listContributionsByResource('investors', id)
        .then(res => {
          this.setState({contributions: res.data});
        })
        .catch(res => {
          console.error(res);
        });
      }
    }
  }

  render () {
    if(!this.state.hasLoaded) {
      return(<BlockSpinner visible={true}/>);
    }

    const langCode  = DatavaseApi.getLangCode();
    const lang      = i18n[langCode];

    const {resources, id} = this.state;
    const investor                         = resources.investor;
    const newsArticles                     = resources.news_articles;

    const researchRequested = this.props.sharedResources.researchRequests.find(req =>
      req.research_requestable_id === parseInt(id, 10) &&
      req.research_requestable_type === 'Organization'
    );
    return (
      <div id="details-page-contents">
        <MetaTags>
          <title>{DatavaseApi.extractOrganizationName(investor, langCode)}</title>
          <meta name="description" content={DatavaseApi.extractDescription(investor, langCode)} />
          <meta property="og:title" content={DatavaseApi.extractOrganizationName(investor, langCode)} />
          <meta property="og:image" content={investor.profile_image_url} />
        </MetaTags>
        <Col sm={12} md={12} lg={12}>
          <OrganizationAbstractPanel
            organization={investor}
            profileImage={investor.profile_image_url}
            id={id}
          />
        </Col>
        <div className="clearfix">
          <Col sm={12} md={8} lg={8} className="clearfix">
            <TagBtn eventKey="review"
                    onClick={() => window.location.href = `/investors/${this._getId()}/`}>{lang.review}</TagBtn>
            <TagBtn eventKey="details" onClick={() => window.location.href = `/investors/${this._getId()}/details/`}
                    active={true}>{lang.detailTabTitle}</TagBtn>
            <TagBtn eventKey="recruits" onClick={() => window.location.href = `/investors/${this._getId()}/recruits/`}
            >{lang.recruitsTabTitle}</TagBtn>
          </Col>
          <UpgradeToProceedModalRevised
              show={resources.needs_lock}
              onCancelClick={false}
              completelyHide={true}
          />
          <UpgradeToProceedModalRevisedMobile
              show={resources.needs_lock}
              onCancelClick={false}
              completelyHide={true}
          />
          <Col sm={12} md={8} lg={8}>
            {
              newsArticles.length !== 0 ?
              <NewsArticlesByResourcePanel
                newsArticles={newsArticles}
              />
              : null
            }
            <InvestorDataGridPanel
              investor={investor}
              directors={resources.directors}
              fundraisings={resources.fundraisings}
              exits={resources.exits}
              investments={resources.investments}
            />
            {
              resources.exits.ipos.length !== 0 ||
              resources.exits.acquisitions.length !== 0 ?
              <ExitsPanel exits={resources.exits} />
              : null
            }
            <InvestorGraphsPanel
              researchRequested={researchRequested}
              investor={investor}
              fundraisings={resources.fundraisings}
              investments={resources.investments}
            />
            <InvestorHistoryDetailsPanel
              researchRequested={researchRequested}
              investor={investor}
              investments={resources.investments}
              fundraisings={resources.fundraisings}
            />
            {
              investor.video_url ?
              <VideoPanel url={investor.video_url}/>
              : null
            }
            {
              resources.social_media_list.twitter_id ?
              <TwitterTimelinePanel
                lang={lang}
                screenName={resources.social_media_list.twitter_id}
              />
              : null
            }
          </Col>
          <Col sm={12} md={4} lg={4}>
            <ContactSocialPanel
              resource={investor}
              resourceType={'investors'}
              resourceId={this.state.id}
              websiteUrl={investor.url}
              socialMediaList={resources.social_media_list}
              url={window.location.href}
              title={investor.organization_name}
            />
            <OrganizationOfficersPanel
              researchRequested={researchRequested}
              organization={investor}
              relationships={resources.relationships}
            />
          </Col>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvestorDetailsPane));

const TagBtn = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 28.33%;
  margin: 2.5%;
  border: solid 2px ${color.primary};
  border-radius: 8px;
  padding: 8px;
  color: ${props => (props.active ? color.white100 : color.primary)};
  font-size: 12px;
  font-weight: 400;
  background: ${props => (props.active ? color.primary : `none`)};
  cursor: pointer;
  &:hover {
    color: #FFF;
    background: ${color.primary};
  }
  &:checked {
    color: #fff;
    background: ${color.primary};
  }
`;
