import React, { Component } from 'react'
import { Button, FormControl } from 'react-bootstrap'

import * as DatavaseApi         from '../datavase-api';
import i18n                     from '../../i18n/numerical-filter-configuration.json';
import '../../css/filter-configuration.css';

export default class NumericalFilterConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      comparator: "$gt",
    };
    this._onValueChange = this._onValueChange.bind(this);
    this._onComparatorChange = this._onComparatorChange.bind(this);
    this._onRemoveClick = this._onRemoveClick.bind(this);
  }

  _onValueChange(e) {
    let value = e.target.value;
    this.setState({value: value});
    let filter = { [this.props.subject]: {[this.state.comparator]: value} };
    this.props.onChange(this.props.index, filter);
  }

  _onComparatorChange(e) {
    let value = e.target.value;
    this.setState({comparator: value});
    let filter = { [this.props.subject]: {[value]: this.state.value} };
    this.props.onChange(this.props.index, filter);
  }

  _onRemoveClick() {
    if(this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <Button style={{border: 'none', backgroundColor: 'transparent', borderRadius: '12px', padding: '0'}} onClick={this._onRemoveClick}>
          <i className="material-icons" style={{verticalAlign: 'middle', color: '#D75553'}}>
            remove_circle
          </i>
        </Button>
        <span style={{display: 'inline-block', backgroundColor: '#3996D7', borderRadius: '3px', fontWeight: 'bold', color: '#fff', padding: '7px 10px', margin: '5px 5px', minWidth: '300px'}}>
          {this.props.filterName}
        </span>
        {
          langCode === 'ja' ?
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-value-form"
              placeholder="(例) 10"
              onChange={this._onValueChange}
            />
          </div>
            :
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-comparator-form"
              componentClass="select"
              onChange={this._onComparatorChange}
            >
              <option value="$gt">{lang.gt}</option>
              <option value="$gte">{lang.gte}</option>
              <option value="$lt">{lang.lt}</option>
              <option value="$lte">{lang.lte}</option>
              <option value="$eq">{lang.eq}</option>
              <option value="$ne">{lang.ne}</option>
            </FormControl>
          </div>
        }
        {
          langCode === 'ja' ?
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-comparator-form"
              componentClass="select"
              onChange={this._onComparatorChange}
            >
              <option value="$gt">{lang.gt}</option>
              <option value="$gte">{lang.gte}</option>
              <option value="$lt">{lang.lt}</option>
              <option value="$lte">{lang.lte}</option>
              <option value="$eq">{lang.eq}</option>
              <option value="$ne">{lang.ne}</option>
            </FormControl>
          </div>
          :
          <div style={{display: 'inline-block', width: '200px', margin: '5px 5px'}}>
            <FormControl
              style={{borderRadius: '3px'}}
              className="filter-configuration-value-form"
              placeholder="e.g. 10"
              onChange={this._onValueChange}
            />
          </div>
        }
      </div>
    );
  }
}
