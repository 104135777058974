import React from "react";
import styled from "styled-components";
import color from "../styles/colors";

export default function InputTitle({
  placeholder,
                                     onChange,
  type,
  name,
}) {
  return (
      <Input onChange={onChange} type={type || 'text'} name={name} placeholder={placeholder}/>
  );
};

const Input = styled.input`
  width: 100%;
  background: ${color.black04};
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px 0;
  color: ${color.black87};
  font-size: 14px;
  font-weight: bold;
  &:placeholder {
    color: ${color.black54};
  }
`;
