import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {MatchMediaHOC} from "react-match-media";
import * as DatavaseApi from "../datavase-api";

class UpgradeToProceedModalRevisedMobile extends Component {
    constructor(props) {
        super(props);
        this._onCancelClick = this._onCancelClick.bind(this);
    }

    _onCancelClick() {
        if(this.props.onCancelClick) { this.props.onCancelClick(); }
    }
    static enc_ml(x,y){
        var t=x+y;
        var s="",moji="";
        for(var i=0;i<t.length;i++){
            moji=t.charCodeAt(i);
            s +=String.fromCharCode(moji+1);
        }
        return s;
    }

    render() {
        //Temporarily using mapping function to reduce the short-term cost
        let m1='mai';
        let m2='lto:';
        let em_shtml=m1+m2+UpgradeToProceedModalRevisedMobile.enc_ml(String.fromCharCode(115,100,96,108,63,103,96,98),String.fromCharCode(106,105,111,109,45,98,110,108));
        if(this.props.completelyHide && document.querySelector("div > div.modal-backdrop.fade.in") !== null) {
            document.querySelector("div > div.modal-backdrop.fade.in").setAttribute("style","opacity: 0.95;")
        }
        let userStatus = DatavaseApi.getUserStatus();
        if (userStatus === 'Free' || userStatus === 'Guest') {
            return(
                <Modal dialogClassName="modal-full" keyboard show={this.props.show} onHide={this._onCancelClick}>
                    <Modal.Body id="ugpm">
                        <img src="/upgrade-info-mb.jpg" alt="UpgradeInfo" useMap="#image-map-mb" id="ImageMB"/>
                        <map id="image-map-mb" name="image-map-mb">
                            <area target="_blank" alt="Proceed to Upgrade Page" title="Proceed to Upgrade Page"
                                  href="/upgrade/" coords="600,1776,156,1702" shape="rect"/>
                            <area target="_blank" alt="Talk to Sales Team" title="Talk to Sales Team" href={em_shtml}
                                  coords="160,1811,532,1850" shape="rect"/>
                        </map>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href="/upgrade/" target="_blank" rel="noopener noreferrer">
                            <Button bsStyle="primary">
                                アップグレードする
                            </Button>
                        </a>
                        <a href={em_shtml} target="_blank" rel="noopener noreferrer">
                            <Button bsStyle="success">
                                セールスチームに相談する
                            </Button>
                        </a>
                        <a href="/" rel="noopener noreferrer">
                            <Button bsStyle="default">
                                ホームに戻る
                            </Button>
                        </a>
                    </Modal.Footer>
                </Modal>
            );} else {
            return(
                <div/>
            )
        }
        //Will Add Mobile Version
    }
}
export default MatchMediaHOC((UpgradeToProceedModalRevisedMobile), '(max-width: 767px)');
