import React, {Component} from 'react';
import {Button, Checkbox, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/news-articles-column-configuration-modal.json';

const articleTypes = [
  {
    articleType: 'Funding Round',
    articleTypeJa: '資金調達・投資',
    articleTypeEn: 'Funding Round',
  },
  {
    articleType: 'Product Release',
    articleTypeJa: 'リリース・アップデート',
    articleTypeEn: 'Product Release',
  },
  {
    articleType: 'Acquisition',
    articleTypeJa: '買収・M&A',
    articleTypeEn: 'Acquisition',
  },
  {
    articleType: 'IPO',
    articleTypeJa: 'IPO',
    articleTypeEn: 'IPO',
  },
  {
    articleType: 'ICO',
    articleTypeJa: 'ICO',
    articleTypeEn: 'ICO',
  },
  {
    articleType: 'Event',
    articleTypeJa: 'イベント・カンファレンス',
    articleTypeEn: 'Event/Convention',
  },
  {
    articleType: 'Politics',
    articleTypeJa: '政治・政策',
    articleTypeEn: 'Politics/Policy',
  },
  {
    articleType: 'Accident/Misconduct',
    articleTypeJa: '事故・不祥事',
    articleTypeEn: 'Accident/Misconduct',
  },
  {
    articleType: 'Closure/Discontinuation',
    articleTypeJa: 'サービス終了・廃業',
    articleTypeEn: 'Closure/Discontinuation',
  },
  {
    articleType: 'Other',
    articleTypeJa: 'その他',
    articleTypeEn: 'Misc.',
  },
];

const colors = [
  '#c0392b',
  '#d35400',
  '#f39c12',
  '#F1D53B',
  '#27ae60',
  '#16a085',
  '#2980b9',
  '#2c3e50',
  '#8e44ad',
];

export default class NewsArticlesColumnConfigurationModal extends Component {
  constructor(props) {
    super(props);
    let color       = this.props.color       || colors[0];
    let articleType = this.props.articleType || articleTypes[0].articleType;
    let language    = this.props.language    || ['en', 'ja'];

    this.state = {
      color:                    color,
      language:                 language,
      selectedArticleTypeIndex: articleTypes.findIndex(c => c.articleType === articleType),
    };
    this._onCancelClick = this._onCancelClick.bind(this);
    this._onCreateClick = this._onCreateClick.bind(this);
    this._onChange      = this._onChange.bind(this);
    this._onLanguageCheckboxChange = this._onLanguageCheckboxChange.bind(this);
  }

    UNSAFE_componentWillReceiveProps(newProps) {
    let color       = newProps.color       || colors[0];
    let articleType = newProps.articleType || articleTypes[0].articleType;
    let language    = newProps.language    || ['en', 'ja'];

    this.setState({
      color:                    color,
      language:                 language,
      selectedArticleTypeIndex: articleTypes.findIndex(c => c.articleType === articleType)
    });
  }

  _onLanguageCheckboxChange(e) {
    const language = this.state.language.slice();
    if(e.target.checked && !language.includes(e.target.name)) {
      language.push(e.target.name);
    }else if(!e.target.checked){
      let index = language.indexOf(e.target.name);
      language.splice(index, 1);
    }

    this.setState({language: language});
  }

  _onChange(e) {
    const articleType = e.target.value;
    this.setState({
      selectedArticleTypeIndex: articleTypes.findIndex(c => c.articleType === articleType),
    });
  }

  _onCancelClick() {
    if(this.props.onCancelClick) { this.props.onCancelClick(); }
  }

  _onCreateClick() {
    const langCode    = DatavaseApi.getLangCode();
    const index       = this.state.selectedArticleTypeIndex;
    const color       = this.state.color;
    const language    = this.state.language;
    const articleType = articleTypes[index].articleType;
    const title       = langCode === 'ja' ? articleTypes[index].articleTypeJa : articleTypes[index].articleTypeEn;

    const query = {
      news_article_type: articleType,
      language: language
    };
    if(this.props.onSubmitClick) { this.props.onSubmitClick(query, color, title); }
  }

  _constructOptions() {
    const langCode = DatavaseApi.getLangCode();
    const options = articleTypes.map((articleType, i) => {
      const articleTypeToDisplay = langCode === 'ja' ? articleType.articleTypeJa: articleType.articleTypeEn;
      return (
        <option key={i} value={articleType.articleType}>{articleTypeToDisplay}</option>
      )
    });
    return options;
  }

  _colorPalatte() {
    return colors.map((color, i) => {
        let onClick = () => {
            this.setState({color: color})
        };
      return(
        <Button
          style={{display: 'inline-block', width: '11%', height: '30px', backgroundColor: color, border: 'none'}}
          key={i}
          onClick={onClick.bind(this)}
        />
      );
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    const options = this._constructOptions();

    return(
      <Modal keyboard show={this.props.show} onHide={this._onCancelClick}>
        <Modal.Header>
          <Modal.Title>{this.props.titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>{lang.filterLabel}</ControlLabel>
            <FormControl
              componentClass="select"
              onChange={this._onChange}
              defaultValue={this.props.articleType || articleTypes[0].articleType}
            >
              {options}
            </FormControl>
            <div>
              <ControlLabel>{lang.languageLabel}</ControlLabel>
              <div>
                <Checkbox
                  defaultChecked={this.state.language.includes('en')} name="en" onChange={this._onLanguageCheckboxChange} inline>
                  {lang.english}
                </Checkbox>
                <Checkbox
                  defaultChecked={this.state.language.includes('ja')} name="ja" onChange={this._onLanguageCheckboxChange} inline>
                  {lang.japanese}
                </Checkbox>
              </div>
            </div>
            <div>
              <ControlLabel>{lang.colorLabel}</ControlLabel>
              <span
                style={{display: 'inline-block', marginLeft: '10px', marginTop: '4px', width: '40px', height: '16px', verticalAlign: 'middle', backgroundColor: this.state.color}}
              />
              <div>
                {this._colorPalatte()}
              </div>
            </div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{backgroundColor: '#fff'}}
            onClick={this._onCancelClick}
          >
            {this.props.cancelButtonText}
          </Button>
          <Button
            bsStyle="primary"
            onClick={this._onCreateClick}
          >
            {this.props.submitButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
