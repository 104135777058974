import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import * as DatavaseApi from '../datavase-api';
import i18n from '../../i18n/thankyou-for-contributing-view.json';

export default class ThankyouForContributingView extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div style={{textAlign: 'center'}}>
        <div style={{padding: '20px 10px'}}>
          <h1 style={{fontSize: '20px', fontWeight: 'bold'}}>{lang.thankyouHeader}</h1>
        </div>
        <div style={{padding: '0px 20px 40px 20px', color: '#aaa'}}>
          <div style={{marginBottom: '10px'}}>
            <span>{lang.thankyouBody}</span>
          </div>
          <Button
            bsStyle="primary"
            onClick={() => {window.location.reload();}}
          >
            <i className="material-icons" style={{verticalAlign: 'middle'}}>refresh</i>
            {lang.refreshButton}
          </Button>
        </div>
      </div>
    );
  }
}
