import React from "react";
import styled from "styled-components";
import color from "../styles/colors";
import media from "styled-media-query";

export default function InputText({
  placeholder,
                                      onChange,
  text,
  name,
  width,
  height,
}) {
  return (
      <TextArea onChange={onChange} name={name} placeholder={placeholder} width={width || `190px`}
                height={height || `120px`}>{text}</TextArea>
  );
};

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: ${props => props.height};
  background: ${color.black04};
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  color: ${color.black87};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  ${media.lessThan("medium")`
    padding: 8px;
    font-size: 12px;
    line-height: 14px;
  `}
  &:placeholder {
    color: ${color.black54};
  }
`;
