import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import placeholder      from '../../assets/datavase_logo_icon_grey.png';

export default class ListTable extends Component {
  render() {
    const columnNames = this.props.columnNames;
    const ths = columnNames.map((key) => {
      return(
        <th key={key}>
          {key}
        </th>
      );
    });

    let tableRows = this.props.entries.map(entry => {
      return (
        <TableRow
          entry={entry}
          columnNames={this.props.columnNames}
          columnWidth={this.props.columnWidth}
          linkColumnName={this.props.linkColumnName}
          imageColumnName={this.props.imageColumnName}
          imageWidth={this.props.imageWidth}
          resourceName={this.props.resourceName}
          id={entry.id}
          slug={entry.slug}
          key={entry.id}
        />
      );
    });

    return(
      <Table
        striped={this.props.striped}
        responsive={this.props.responsive || true}
        bordered={this.props.bordered}
        hover={this.props.hover}
        condensed={this.props.condensed}
        className="search-result-table"
      >
      { this.props.disableHeaders ? null :
        <thead>
          <tr>
            {ths}
          </tr>
        </thead> }
        <tbody>
          {tableRows}
        </tbody>
      </Table>
    );
  }
}

class TableRow extends Component {
  render() {
    const columnWidth = this.props.columnWidth;
    const tds = this.props.columnNames.map((columnName, i) => {
      let content = this.props.entry[columnName];

      if(columnName === this.props.linkColumnName){
        const path = `/${this.props.resourceName}/${this.props.slug || this.props.id}`;
        content = (
          <Link to={path} style={{fontWeight: 'bold'}} target="_blank" rel="noopener noreferrer">
            {this.props.entry[columnName]}
          </Link>
        );
      }else if(columnName === this.props.imageColumnName) {
        content = (
          <img
            alt="logo/profile"
            src={this.props.entry[columnName] || placeholder}
            style={
              {width: `${this.props.imageWidth || '28px'}`, border: 'solid 1px #ddd'}
            }
          />
        );
      }

      let iteratorKey = `${this.props.id}/${columnName}`;
      return (
        <td
          key={iteratorKey}
          style={
            {width: columnWidth[i], verticalAlign: 'middle'}
          }
        >
          {content}
        </td>
      );
    });

    return(
      <tr>
        {tds}
      </tr>
    );
  }
}

