import * as SharedResources from '../constants/shared-resources';

export function setUrl(url) {
  return {
    type: SharedResources.SET_URL,
    url: url
  };
}

export function setMylists(lists) {
  return {
    type: SharedResources.SET_MYLISTS,
    lists: lists
  };
}

export function setInformationRemovalRequests(informationRemovalRequests) {
  return {
    type: SharedResources.SET_INFORMATION_REMOVAL_REQUESTS,
    informationRemovalRequests: informationRemovalRequests
  };
}

export function setResearchRequests(researchRequests) {
  return {
    type: SharedResources.SET_RESEARCH_REQUESTS,
    researchRequests: researchRequests
  };
}

export function setOAuthConnectionStatus(status) {
  return {
    type: SharedResources.SET_OAUTH_CONNECTION_STATUS,
    status: status
  };
}

export function addRawErrorMessage(errorMessage, redirectPath) {
  return {
    type: SharedResources.ADD_RAW_ERROR_MESSAGE,
    error: {
      message: errorMessage,
      redirectPath: redirectPath
    }
  };
}

export function addBadRequestErrorMessage() {
  return {
    type: SharedResources.ADD_BAD_REQUEST_ERROR_MESSAGE,
    error: {
      message: '400'
    }
  };
}

export function addUnauthorizedErrorMessage() {
  return {
    type: SharedResources.ADD_UNAUTHORIZED_ERROR_MESSAGE,
    error: {
      message: '401'
    }
  };
}

export function addForbiddenErrorMessage() {
  return {
    type: SharedResources.ADD_FORBIDDEN_ERROR_MESSAGE,
    error: {
      message: '403'
    }
  };
}

export function addServerErrorMessage() {
  return {
    type: SharedResources.ADD_SERVER_ERROR_MESSAGE,
    error: {
      message: '500'
    }
  };
}

export function flushErrorMessage() {
  return {
    type: SharedResources.FLUSH_ERROR_MESSAGE
  };
}
