import React, { Component } from 'react';

import * as DatavaseApi                     from '../datavase-api';
import NewsArticlePreviewModal              from './news-article-preview-modal';
import NewsArticlesColumnConfigurationModal from './news-articles-column-configuration-modal';
import RemoveColumnModal                    from './remove-column-modal';
import DraggableColumnsView                 from './draggable-columns-view';
import UpgradeToProceedModal                from './upgrade-to-proceed-modal';

import jp_techcrunch_logo                   from '../../assets/jp_techcrunch_logo.png';
import us_techcrunch_logo                   from '../../assets/us_techcrunch_logo.png';
import thebridge_logo                       from '../../assets/thebridge_logo.png';
import hackletter_logo                      from '../../assets/hackletter_logo.png';
import placeholder                          from '../../assets/datavase_logo_icon_grey.png';
import i18n                                 from '../../i18n/news-articles-columns-view.json';

function extractHostname(url) {
  let hostname;
  if (url.indexOf("://") > -1) {
    hostname = url.split('/')[2];
  }else{
    hostname = url.split('/')[0];
  }
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  return hostname;
}

function timeSince(datetime, langCode) {
  let seconds  = Math.floor((new Date() - new Date(datetime)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    let yearsAgo = langCode === 'ja' ? '年前' : ' years ago';
    return interval + yearsAgo;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    let monthsAgo = langCode === 'ja' ? 'ヶ月前' : ' months ago';
    return interval + monthsAgo;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    let daysAgo = langCode === 'ja' ? '日前' : ' days ago';
    return interval + daysAgo;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    let hoursAgo = langCode === 'ja' ? '時間前' : ' hours ago';
    return interval + hoursAgo;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    let minutesAgo = langCode === 'ja' ? '分前' : ' minutes ago';
    return interval + minutesAgo;
  }
  let secondsAgo = langCode === 'ja' ? '秒前' : ' seconds ago';
  return Math.floor(seconds) + secondsAgo;
}

function constructEntriesView(articles, color, onClick) {
  const langCode = DatavaseApi.getLangCode();
  const entriesView = articles.map((article, i) => {
    let hostname = extractHostname(article.url);
    let image;
    if(hostname === 'jp.techcrunch.com') {
      image = jp_techcrunch_logo;
    }else if(hostname === 'techcrunch.com') {
      image = us_techcrunch_logo;
    }else if(hostname === 'thebridge.jp') {
      image= thebridge_logo;
    }else if(hostname === 'hackletter.com') {
      image = hackletter_logo;
    }
    let sitename;
    if(hostname === 'jp.techcrunch.com') {
      sitename = "TechCrunch Japan";
    }else if(hostname === 'techcrunch.com') {
      sitename = "TechCrunch";
    }else if(hostname === 'thebridge.jp') {
      sitename = "THE BRIDGE";
    }else if(hostname === 'hackletter.com') {
      sitename = "hackletter";
    }

    const title = article.title;
    const id    = article.id;
    const time  = timeSince(article.published_at, langCode);

    return(
      <div onClick={() => {onClick(article);}} key={id}>
        <div className="column-view-entry-view" style={{padding: '8px'}}>
          <div style={{display: 'inline-block', verticalAlign: 'top'}}>
            <span style={{display: 'inline-block', backgroundColor: color, color: '#fff', width: '30px', textAlign: 'center', fontWeight: 'bold', lineHeight: '30px', marginRight: '10px'}}>
              {i+1}
            </span>
            <img
              src={image || placeholder}
              alt={hostname}
              className="column-view-entry-view-img"
            />
            <span style={{lineHeight: '30px', color: '#aaa'}}>{time} - </span>
            <span style={{lineHeight: '30px', color: '#aaa'}}>{sitename}</span>
          </div>
          <div style={{display: 'inline-block'}}>
            <span className="column-view-entry-view-title">
              {title}
            </span>
          </div>
        </div>
      </div>
    );
  });
  return entriesView;
}

export default class NewsArticlesColumnsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      hasColumnConfigurationsLoaded: false,
      showAddColumnModal: false,
      showUpdateColumnModal: false,
      showRemoveColumnModal: false,
      showUpgradeToProceedModal: false,
      showNewsArticlePreviewModal: false,
      selectedNewsArticle: null,
      columnIdToDelete: null,
      columnIndexToDelete: null,
      columnIdToUpdate: null,
      columnIndexToUpdate: null,
      colorOfColumnToUpdate: null,
      queryOfColumnToUpdate: null,
    }
    this._showAddColumnModal          = this._showAddColumnModal.bind(this);
    this._hideAddColumnModal          = this._hideAddColumnModal.bind(this);
    this._showUpdateColumnModal       = this._showUpdateColumnModal.bind(this);
    this._hideUpdateColumnModal       = this._hideUpdateColumnModal.bind(this);
    this._showRemoveColumnModal       = this._showRemoveColumnModal.bind(this);
    this._hideRemoveColumnModal       = this._hideRemoveColumnModal.bind(this);
    this._showUpgradeToProceedModal   = this._showUpgradeToProceedModal.bind(this);
    this._hideUpgradeToProceedModal   = this._hideUpgradeToProceedModal.bind(this);
    this._showNewsArticlePreviewModal = this._showNewsArticlePreviewModal.bind(this);
    this._hideNewsArticlePreviewModal = this._hideNewsArticlePreviewModal.bind(this);
    this._onAddColumnButtonClick      = this._onAddColumnButtonClick.bind(this);
    this._onDeleteButtonClick         = this._onDeleteButtonClick.bind(this);
    this._onSettingsButtonClick       = this._onSettingsButtonClick.bind(this);
    this._onEntryClick                = this._onEntryClick.bind(this);
    this._addColumn                   = this._addColumn.bind(this);
    this._deleteColumn                = this._deleteColumn.bind(this);
    this._updateColumn                = this._updateColumn.bind(this);
  }

  componentDidMount() {
    this._loadColumnsToDisplay();
  }

  _showAddColumnModal() {
    this.setState({showAddColumnModal: true});
  }

  _hideAddColumnModal() {
    this.setState({showAddColumnModal: false});
  }

  _showUpdateColumnModal() {
    this.setState({showUpdateColumnModal: true});
  }

  _hideUpdateColumnModal() {
    this.setState({showUpdateColumnModal: false});
  }

  _showRemoveColumnModal() {
    this.setState({showRemoveColumnModal: true});
  }

  _hideRemoveColumnModal() {
    this.setState({showRemoveColumnModal: false});
  }

  _showUpgradeToProceedModal() {
    this.setState({showUpgradeToProceedModal: true});
  }

  _hideUpgradeToProceedModal() {
    this.setState({showUpgradeToProceedModal: false});
  }

  _showNewsArticlePreviewModal() {
    this.setState({showNewsArticlePreviewModal: true});
  }

  _hideNewsArticlePreviewModal() {
    this.setState({showNewsArticlePreviewModal: false});
  }

  _onAddColumnButtonClick() {
    if(DatavaseApi.getUserStatus() === 'Free' && this.state.columns.length >= 4) {
      this._showUpgradeToProceedModal();
      return;
    }

    this._showAddColumnModal();
  }

  _onDeleteButtonClick(id, index) {
    if(DatavaseApi.getUserStatus() === 'Free' && index < 3) {
      this._showUpgradeToProceedModal();
      return;
    }

    this.setState({
      columnIdToDelete:    id,
      columnIndexToDelete: index,
    });
    this._showRemoveColumnModal();
  }

  _onSettingsButtonClick(id, index) {
    if(DatavaseApi.getUserStatus() === 'Free' && index < 3) {
      this._showUpgradeToProceedModal();
      return;
    }

    this.setState({
      columnIdToUpdate:      id,
      columnIndexToUpdate:   index,
      colorOfColumnToUpdate: this.state.columns[index].color,
      languageOfColumnToUpdate: this.state.columns[index].language,
      articleTypeOfColumnToUpdate: this.state.columns[index].articleType
    });

    this._showUpdateColumnModal();
  }

  _onEntryClick(article) {
    this.setState({
      selectedNewsArticle: article,
    });
    this._showNewsArticlePreviewModal();
  }

  _deleteColumn() {
    const index = this.state.columnIndexToDelete;
    const id    = this.state.columnIdToDelete;
    const newColumns = this.state.columns;
    newColumns.splice(index, 1);
    this.setState({columns: newColumns});
    this._hideRemoveColumnModal();

    DatavaseApi.deleteColumnsViewConfiguration(id)
    .then(res => {
      //console.log(res.data);
    })
    .catch(err => {
      console.error(err.response);
    });
  }

  _updateColumn(query, color, title) {
    //console.log(query)
    const index          = this.state.columnIndexToUpdate;
    const updatedColumn  = Object.assign({}, this.state.columns[index], {
      title: title,
      color: color,
      articleType: query.news_article_type,
      language:    query.language,
      hasLoaded: false,
    });

    const columns = this.state.columns.slice();
    columns[index] = updatedColumn;
    this.setState({columns: columns});

    const params = {
      color_code: color,
      title:      title,
      query:      query,
    };
    DatavaseApi.updateColumnsViewConfiguration(this.state.columnIdToUpdate, params)
    .then(res => {
      this._fetchNewsArticlesForSingleColumn(updatedColumn, index);
      this._hideUpdateColumnModal();
    })
    .catch(err => {
      console.error(err);
    })
  }

  _addColumn(query, color, title) {
    const newColumn = {
      color: color,
      title: title,
      articleType: query.news_article_type,
      language: query.language,
      entriesView: [],
      hasLoaded: false,
      titleWidth:   '392px',
      columnWidth:  '400px',
      id: null,
    }

    //console.log(newColumn)

    const newColumns     = this.state.columns.concat([newColumn]);
    const newColumnIndex = this.state.columns.length;
    this.setState({columns: newColumns});
    this._fetchNewsArticlesForSingleColumn(newColumn, newColumnIndex);
    this._hideAddColumnModal();

    const params = {
      title:             title,
      color_code:        color,
      query:             query,
      columns_view_type: 'NewsArticlesColumnsView'
    };
    DatavaseApi.createColumnsViewConfiguration(params)
    .then(res => {
      const newColumns = this.state.columns;
      newColumns[newColumns.length-1].id = res.data.id;

      this.setState({columns: newColumns});
    })
    .catch(err => {
      console.error(err.response);
    });
  }

  async _loadColumnsToDisplay() {
    await this._loadColumnsConfigurations();
    this._fetchNewsArticlesForEachColumn();
  }

  async _loadColumnsConfigurations() {
    this.setState({ hasColumnConfigurationsLoaded: false });
    const langCode = DatavaseApi.getLangCode();
    let presetColumnsForFreeUsers = [];
    if(DatavaseApi.getUserStatus() === 'Free') {
      presetColumnsForFreeUsers = [
        {
          color:       '#c0392b',
          title:       langCode === 'ja' ? '資金調達・投資' : 'Funding Round',
          articleType: 'Funding Round',
          language:    [langCode],
          entries:     [],
          hasLoaded:   false,
          titleWidth:  '392px',
          columnWidth: '400px',
        },
        {
          color:       '#27ae60',
          title:       langCode === 'ja' ? 'リリース・アップデート' : 'Product Release',
          articleType: 'Product Release',
          language:    [langCode],
          entries:     [],
          hasLoaded:   false,
          titleWidth:  '392px',
          columnWidth: '400px',
        },
        {
          color:       '#8e44ad',
          title:       langCode === 'ja' ? '買収・M&A' : 'Acquisition',
          articleType: 'Acquisition',
          language:    [langCode],
          entries:     [],
          hasLoaded:   false,
          titleWidth:  '392px',
          columnWidth: '400px',
        }
      ];
    }

    await DatavaseApi.listColumnsViewConfigurations('NewsArticlesColumnsView')
    .then(res => {
      const savedColumns = res.data.map(column => {
        return {
          id: column.id,
          color:        column.color_code,
          title:        column.title,
          articleType:  column.query.news_article_type,
          language:     column.query.language,
          entriesView:  [],
          hasLoaded:    false,
          titleWidth:   '392px',
          columnWidth:  '400px',
          configurable: true,
        };
      });

      this.setState({
        hasColumnConfigurationsLoaded: true,
        columns: presetColumnsForFreeUsers.concat(savedColumns),
      });
    })
    .catch(err => {
      console.error(err);
    })
  }

  _fetchNewsArticlesForEachColumn() {
    this.state.columns.forEach((column, i) => {
      this._fetchNewsArticlesForSingleColumn(column, i);
    });
  }

  _fetchNewsArticlesForSingleColumn(column, i) {
    DatavaseApi.listLatestNewsArticles({articleType: column.articleType, language: column.language})
    .then(res => {
      const entriesView         = constructEntriesView(res.data, column.color, this._onEntryClick);
      const newColumns          = this.state.columns.slice();
      newColumns[i].entriesView = entriesView;
      newColumns[i].hasLoaded   = true;
      this.setState({columns: newColumns});
    })
    .catch(err => {
      console.error(err);
    });
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <div>
        <NewsArticlesColumnConfigurationModal
          show={this.state.showAddColumnModal}
          onCancelClick={this._hideAddColumnModal}
          onSubmitClick={this._addColumn}
          titleText={lang.addColumnModalTitle}
          bodyText={lang.addColumnModalBody}
          submitButtonText={lang.addColumnButton}
          cancelButtonText={lang.cancelButton}
        />
        <NewsArticlesColumnConfigurationModal
          show={this.state.showUpdateColumnModal}
          onCancelClick={this._hideUpdateColumnModal}
          onSubmitClick={this._updateColumn}
          color={this.state.colorOfColumnToUpdate}
          language={this.state.languageOfColumnToUpdate}
          articleType={this.state.articleTypeOfColumnToUpdate}
          titleText={lang.updateColumnModalTitle}
          bodyText={lang.updateColumnModalBody}
          submitButtonText={lang.updateColumnButton}
          cancelButtonText={lang.cancelButton}
        />
        <RemoveColumnModal
          show={this.state.showRemoveColumnModal}
          onCancelClick={this._hideRemoveColumnModal}
          onDeleteClick={this._deleteColumn}
        />
        <UpgradeToProceedModal
          show={this.state.showUpgradeToProceedModal}
          onCancelClick={this._hideUpgradeToProceedModal}
          title={lang.upgradeToAddMoreColumnsModalTitle}
          body={lang.upgradeToAddMoreColumnsModalBody}
          upgradeButton={lang.upgradeToAddMoreColumnsModalButton}
          cancelButton={lang.cancelButton}
        />
        <NewsArticlePreviewModal
          show={this.state.showNewsArticlePreviewModal}
          onHide={this._hideNewsArticlePreviewModal}
          article={this.state.selectedNewsArticle}
        />
        <DraggableColumnsView
          onDeleteButtonClick={this._onDeleteButtonClick}
          onSettingsButtonClick={this._onSettingsButtonClick}
          onAddColumnClick={this._onAddColumnButtonClick}
          hasLoaded={this.state.hasColumnConfigurationsLoaded}
          columns={this.state.columns}
        />
      </div>
    );
  }
}

