import React, { Component } from 'react'
import { Panel, FormGroup, FormControl } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BlockSpinner from './partials/block-spinner';
import ListTable from './partials/list-table';
import * as DatavaseApi from './datavase-api';
import * as stateCacheActions from '../actions/state-cache';
import i18n from '../i18n/events-pane.json';
import '../css/table-pane.css';

const RESOURCE_NAME = 'events';
const COLUMN_WIDTH = ['20px', '50px', '200px', null, '150px'];

class EventsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      loadedPage: 0,
      nextPageLoaded: false,
      prefixSearchTimeoutId: null,
      keyword: '',
    }
    this._onSearchFormChange = this._onSearchFormChange.bind(this);
  }

  _columnNames() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    return [
      '#',
      lang.logoColumnHeader,
      lang.eventNameColumnHeader,
      lang.descriptionColumnHeader,
      lang.startsOnColumnHeader
    ];
  }

  // If the user comes back via the browerback and there's cached state, use the cache.
  // Otherwise, load resources from the server.
  componentDidMount() {
    let cachedState = this.props.stateCache.eventsPaneState;
    if(window.location.action === 'POP' && cachedState != null){
      this.setState({
        entries: cachedState.entries,
        loadedPage: cachedState.loadedPage,
        nextPageLoaded: true,
      });
    }else{
      this._loadNextPage();
    }
    document.addEventListener('scroll', this._trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._trackScrolling);
    // cache the state in case the user wants to come back via the browserback.
    this.props.stateCacheActions.setEventsPaneState(this.state);
  }

  _constructEntriesList(events) {
    const langCode = DatavaseApi.getLangCode();
    const columnNames = this._columnNames();
    const newEntries = events.map((event, i) => {
      const name = DatavaseApi.extractEventName(event, langCode);
      const desc = DatavaseApi.extractDescription(event, langCode).substring(0, 210);
      const profileImage = event.thumb_profile_image_url || event.profile_image_url;
      const startsOn = DatavaseApi.prettifyDate(event.starts_on);

      return {
        [columnNames[0]]: this.state.loadedPage * 40 + i + 1,
        [columnNames[1]]: profileImage,
        [columnNames[2]]: name,
        [columnNames[3]]: desc,
        [columnNames[4]]: startsOn,
        slug: event.slug,
        'id': event.id
      };
    });

    return newEntries;
  }

  _loadNextPage() {
    this.setState({nextPageLoaded: false});
    const pageToLoad = this.state.loadedPage + 1;
    const keyword = this.state.keyword;
    this._fetchEvents(pageToLoad, keyword);
  }

  _fetchEvents(page, keyword) {
    const params = {keyword: keyword, page: page, perPage: 40};
    DatavaseApi.listEvents(params)
    .then((res) => {
      let newEntries = this._constructEntriesList(res.data);
      this.setState({
        entries: this.state.entries.concat(newEntries),
        loadedPage: page,
        nextPageLoaded: true,
      });
      if(res.data.length !== 0){
        document.addEventListener('scroll', this._trackScrolling);
      }
    }).catch((res) => {
      console.error(res);
      document.addEventListener('scroll', this._trackScrolling);
    });
  }

  _onSearchFormChange(e) {
    if(this.state.prefixSearchTimeoutId !== null) {
      clearTimeout(this.state.prefixSearchTimeoutId);
    }
    const keyword = e.target.value;
    this.setState({
      keyword: keyword,
      loadedPage: 0
    });
    const timeoutId = setTimeout(() => {
      this.setState({
        entries: [],
      });
      this._loadNextPage()
    }, 300);
    this.setState({prefixSearchTimeoutId: timeoutId})
  }

  _isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight + 200;
  }

  _trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this._isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this._trackScrolling);
      this._loadNextPage();
    }
  };

  render(){
    const columnNames = this._columnNames();
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return (
      <div>
        <FormGroup>
          <FormControl
            type="text"
            placeholder={lang.searchFormPlaceholder}
            onChange={this._onSearchFormChange}
          />
        </FormGroup>
        <Panel>
          <ListTable
            hover
            striped
            condensed
            entries={this.state.entries}
            columnNames={columnNames}
            columnWidth={COLUMN_WIDTH}
            linkColumnName={columnNames[2]}
            imageColumnName={columnNames[1]}
            resourceName={RESOURCE_NAME}
          />
        </Panel>
        <BlockSpinner visible={!this.state.nextPageLoaded}/>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    stateCache: state.stateCache,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    stateCacheActions: bindActionCreators(stateCacheActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsPane);

