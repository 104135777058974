export const SET_URL                          = 'SET_URL';
export const SET_MYLISTS                      = 'SET_MYLISTS';
export const SET_INFORMATION_REMOVAL_REQUESTS = 'SET_INFORMATION_REMOVAL_REQUESTS';
export const SET_RESEARCH_REQUESTS            = 'SET_RESEARCH_REQUESTS';
export const SET_OAUTH_CONNECTION_STATUS      = 'SET_OAUTH_CONNECTION_STATUS';
export const ADD_RAW_ERROR_MESSAGE            = 'ADD_RAW_ERROR_MESSAGE';
export const ADD_BAD_REQUEST_ERROR_MESSAGE    = 'ADD_BAD_REQUEST_ERROR_MESSAGE';
export const ADD_UNAUTHORIZED_ERROR_MESSAGE   = 'ADD_UNAUTHORIZED_ERROR_MESSAGE';
export const ADD_FORBIDDEN_ERROR_MESSAGE      = 'ADD_FORBIDDEN_ERROR_MESSAGE';
export const ADD_SERVER_ERROR_MESSAGE         = 'ADD_SERVER_ERROR_MESSAGE';
export const FLUSH_ERROR_MESSAGE              = 'FLUSH_ERROR_MESSAGE';
