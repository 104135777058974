import React, { Component } from 'react';
import * as DatavaseApi from '../datavase-api';
import * as c3 from 'c3';
import '../../css/c3.css';

export default class FundraisingsHistoryGraph extends Component {
  componentDidMount() {
    this._updateGraph();
  }

  componentDidUpdate() {
    this._updateGraph();
  }

  _updateGraph() {
    const displayedCurrency = this.props.displayedCurrency || 'USD';
    const fundraisings      = this.props.fundraisings.sort(
      (a,b) => a.date_of_investment > b.date_of_investment
    );

    const data = {
      date:         fundraisings.map(f => f.date_of_investment),
      raisedAmount: fundraisings.map(f => {
        return DatavaseApi.convertCurrency(
          f.amount, f.currency_code, displayedCurrency
        );
      })
    }

    c3.generate({
      bindto: `#${this.props.graphName}`,
      data: {
        xs: {
          'Raised Amount': 'date1',
        },
        columns: [
          ['date1'].concat(data.date),
          ['Raised Amount'].concat(data.raisedAmount),
        ],
        types: {
          'Raised Amount': 'area',
        },
        colors: {
          'Raised Amount': '#4478AF',
        },
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            culling: {
              max: 4,
            },
            format: '%Y/%m/%d'
          }
        },
        y: {
          tick: {
            format: (d) => DatavaseApi.abbrPrice(d, displayedCurrency)
          }
        }
      },
      tooltip: {
        format: {
          value: (value, ratio, id, index) => {
            const fundraising = fundraisings[index];
            return DatavaseApi.abbrPrice(
              fundraising.amount, fundraising.currency_code
            );
          }
        }
      },
    });
  }
  render() {
    return(
      <div id={this.props.graphName}></div>
    );
  }
}
