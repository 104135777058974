import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import BlockSpinner     from './block-spinner';
import CreditCardForm   from './credit-card-form';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/update-payment-and-resume-subscription-modal.json';
import '../../css/update-payment-and-resume-subscription-modal.css';

export default class UpdatePaymentAndResumeSubscriptionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      errorMessage: null,
    };
    this._onResumeWithoutUpdatingCardClicked = this._onResumeWithoutUpdatingCardClicked.bind(this);
    this._onDoneClick     = this._onDoneClick.bind(this);
    this._onSubmitClicked = this._onSubmitClicked.bind(this);
    this._onSuccess       = this._onSuccess.bind(this);
    this._onFailure       = this._onFailure.bind(this);
  }

  _onDoneClick() {
    this.setState({errorMessage: null});
    if(this.props.onDoneClick) {this.props.onDoneClick();}
  }

  _onSubmitClicked() {
    this.setState({isProcessing: true});
  }

  _onResumeWithoutUpdatingCardClicked() {
    this.setState({isProcessing: true});

    DatavaseApi.resumeSubscription()
    .then(res => {
      if(this.props.onResumeSubscriptionSuccess) {
        this.props.onResumeSubscriptionSuccess(res.data);
      }
    })
    .catch(err => {
      this.setState({isProcessing: false});
      console.error(err);
    });
  }

  _onSuccess(card_token) {
    DatavaseApi.resumeSubscription(card_token)
    .then(res => {
      //console.log(res);
      this.setState({
        errorMessage: null
      });
      if(this.props.onResumeSubscriptionSuccess) {
        this.props.onResumeSubscriptionSuccess(res.data);
      }
    })
    .catch(res => {
      this.setState({
        errorMessage: res.response.data.error,
        isProcessing: false,
      })
    });
  }

  _onFailure(errorMessage) {
    console.error(errorMessage);
    this.setState({
      errorMessage: errorMessage,
      isProcessing: false
    });
  }

  render() {
    const langCode    = DatavaseApi.getLangCode();
    const lang        = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.updatePaymentAndResumeSubscriptionModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.instruction}
          <hr/>
          {this.state.errorMessage ? <span>{this.state.errorMessage}</span> : null}
          <CreditCardForm
            onSubmitClicked={this._onSubmitClicked}
            onSuccess={this._onSuccess}
            onFailure={this._onFailure}
            isProcessing={this.state.isProcessing}
            submitButtonText={lang.updateCardAndResume}
          />
          <p className="or-line">{lang.or}</p>
          <div style={{textAlign: 'center', marginBottom: '20px'}}>
            {
              this.state.isProcessing ?
              <BlockSpinner visible={true}/> :
              <Button
                block
                bsStyle="primary"
                onClick={this._onResumeWithoutUpdatingCardClicked}
              >
                {lang.resumeWithoutUpdatingCard}
              </Button>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._onDoneClick}>
            {lang.doneButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
