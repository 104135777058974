import React, { Component } from 'react';
import { Panel, Tabs, Tab, Table } from 'react-bootstrap';

import i18n from '../../i18n/timeline-events-panel.json';
import * as DatavaseApi from '../datavase-api';

export default class TimelineEventsPanel extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const eventRows = this.props.timelineEvents.map((event, i) => {
      return(
        <EventRow
          event={event}
          key={i}
        />
      );
    });

    return(
      <Panel>
        <Tabs id="timeline-events-tabs">
          <Tab eventKey={1} title={lang.timelineEventsTitle}>
            <Table responsive>
              <tbody>
                {eventRows}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}

class EventRow extends Component {
  render() {
    const langCode  = DatavaseApi.getLangCode();
    const event     = this.props.event;
    const date      = DatavaseApi.prettifyDate(event.date, event.precision_of_date);
    const desc      = DatavaseApi.extractDescription(event, langCode);
    let link = null;
    if(event.source_url) {
      let source_url = event.source_url;
      if (!source_url.includes('://')) { source_url = 'http://' + source_url }
      link = (<a target="_blank" rel="noopener noreferrer" href={source_url}><i className="material-icons" style={{fontSize: '11px'}}>open_in_new</i></a>)
    }

    return (
      <tr>
        <td style={{border: 'none'}}>{date}</td>
        <td style={{border: 'none'}}>{desc}{link}</td>
      </tr>
    );
  }
}
