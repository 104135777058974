import React, { Component } from 'react';
import { Panel, Tabs, Tab, Button, Modal, Label } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BlockSpinner                from './block-spinner';
import * as DatavaseApi            from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n                        from '../../i18n/research-request-panel.json';
import dummyGraph                  from '../../assets/dummy_graph.png';
import dummyTable                  from '../../assets/dummy_datatable.png';
import dummyRelationships          from '../../assets/dummy_relationships.png';
import dummyFundingGraph           from '../../assets/dummy_funding_graph.png';
import dummyRevenueGraph           from '../../assets/dummy_revenue_graph.png';
import '../../css/research-request-panel.css';

class ResearchRequestPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      showUpgradeToRequestResearchModal: false,
      isProcessing: false,
    };

    this._showConfirmationModal = this._showConfirmationModal.bind(this);
    this._hideConfirmationModal = this._hideConfirmationModal.bind(this);
    this._showUpgradeToRequestResearchModal = this._showUpgradeToRequestResearchModal.bind(this);
    this._hideUpgradeToRequestResearchModal = this._hideUpgradeToRequestResearchModal.bind(this);
    this._onResearchRequestButtonClicked = this._onResearchRequestButtonClicked.bind(this);
    this._submitResearchRequest = this._submitResearchRequest.bind(this);
  }

  _showUpgradeToRequestResearchModal() {
    this.setState({showUpgradeToRequestResearchModal: true});
  }

  _hideUpgradeToRequestResearchModal() {
    this.setState({showUpgradeToRequestResearchModal: false});
  }

  _showConfirmationModal() {
    this.setState({showConfirmationModal: true});
  }

  _hideConfirmationModal() {
    this.setState({showConfirmationModal: false});
  }

  _onResearchRequestButtonClicked() {
    const status = DatavaseApi.getUserStatus();
    if(['Free', 'Guest'].includes(status)) {
      this._showUpgradeToRequestResearchModal();
    }else{
      this._showConfirmationModal();
    }
  }

  _submitResearchRequest() {
    this.setState({isProcessing: true});
    const resourceType = this.props.resourceType;
    const id           = this.props.id;
    DatavaseApi.requestResearch(resourceType, id)
    .then(res => {
      this.setState({
        showConfirmationModal: false,
        isProcessing: false
      });
      this.props.sharedResourcesActions.setResearchRequests(res.data);
    })
    .catch(err => {
      this.setState({isProcessing: false});
      console.error(err.response);
    });
  }

  render() {
    let backgroundImage = dummyGraph;
    if(this.props.datatable) {
      backgroundImage = dummyTable;
    }else if(this.props.graph) {
      backgroundImage = dummyGraph;
    }else if(this.props.fundingGraph) {
      backgroundImage = dummyFundingGraph;
    }else if(this.props.revenueGraph) {
      backgroundImage = dummyRevenueGraph;
    }else if(this.props.relationships) {
      backgroundImage = dummyRelationships;
    }
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];
    let requestButton = (
      <Button
        bsStyle="primary"
        onClick={this._onResearchRequestButtonClicked}
      >
        {lang.researchRequestButton}
      </Button>
    );
    if(this.props.researchRequested) {
      requestButton = (
        <Label bsStyle="success">
          {lang.hasRequestedLabel}
        </Label>
      );
    }

    return(
      <div>
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          isProcessing={this.state.isProcessing}
          onCancelClicked={this._hideConfirmationModal}
          onsubmitClicked={this._submitResearchRequest}
        />
        <UpgradeToRequestResearchModal
          show={this.state.showUpgradeToRequestResearchModal}
          onCancelClicked={this._hideUpgradeToRequestResearchModal}
        />
        <Panel className="research-request-panel">
          <Tabs id="research-request-tabs">
            <Tab eventKey={1} title={this.props.title}>
              <Panel className="side-padded-panel">
                <div
                  className="research-request-panel-div"
                  style={{backgroundImage: `url(${backgroundImage})`}}
                >
                  {requestButton}
                </div>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchRequestPanel);

class ConfirmationModal extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.confirmationModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.requestConfirmationText}
        </Modal.Body>
        <Modal.Footer>
          {this.props.isProcessing ?
          <BlockSpinner visible={true}/> :
          <div>
            <Button bsStyle="primary" onClick={this.props.onsubmitClicked}>
              {lang.submitRequestButton}
            </Button>
            <Button onClick={this.props.onCancelClicked}>
              {lang.cancelButton}
            </Button>
          </div>}
        </Modal.Footer>
      </Modal>
    );
  }
}

class UpgradeToRequestResearchModal extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    return(
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{lang.upgradeToRequestResearchModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.upgradeToRequestResearchModalBody}
        </Modal.Body>
        <Modal.Footer>
          <a href="/upgrade/" target="_blank">
            <Button
              bsStyle="primary"
            >
              {lang.upgradeButton}
            </Button>
          </a>
          <Button
            onClick={this.props.onCancelClicked}
          >
      {lang.cancelButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
