import React, { Component } from 'react';
import PeopleListPanel from './people-list-panel';

import ResearchRequestPanel       from './research-request-panel';
import i18n from '../../i18n/organization-officers-panel.json';
import { getLangCode, extractAndFormatPersonName } from '../datavase-api';

export default class OrganizationOfficersPanel extends Component {
  render() {
    const langCode = getLangCode();
    const lang = i18n[langCode];

    const officersRelationships = this.props.relationships.sort((a, b) => {
      let scoreA = 0;
      scoreA += a.is_director ? 100 : 0;
      scoreA += a.is_founder  ? 10  : 0;
      scoreA += a.is_officer  ? 1   : 0;
      let scoreB = 0;
      scoreB += b.is_director ? 100 : 0;
      scoreB += b.is_founder  ? 10  : 0;
      scoreB += b.is_officer  ? 1   : 0;
      return scoreB - scoreA;
    });

    const peopleList = officersRelationships.map(relationship => {
      const person = relationship.person;
      return {
        id:           person.id,
        name:         extractAndFormatPersonName(person, langCode),
        profileImage: person.profile_image_url,
        slug:         person.slug,
        position:     relationship.position,
      };
    });

    if(this.props.relationships.length !== 0) {
      return(
        <PeopleListPanel
          peopleListHeading={lang.peopleListTitle}
          peopleList={peopleList}
        />
      );
    }else{
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.peopleListTitle}
          resourceType={'organizations'}
          id={this.props.organization.id}
          relationships
        />
      );
    }
  }
}

