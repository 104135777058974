import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import * as DatavaseApi from '../datavase-api';
import '../../css/autosuggest-form.css';

const autosuggestTheme = {
  input: {
    display:          'block',
    width:            '100%',
    height:           '34px',
    padding:          '6px 12px',
    fontSize:         '14px',
    lineHeight:       '1.42857143',
    color:            '#666',
    backgroundColor:  '#fff',
    backgroundImage:  'none',
    border:           'none',
    boxShadow:        'none',
    outline:          'none',
  }
};

const outerStyle = {
  display:         'block',
  width:           '100%',
  padding:         '2px',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  border:          '1px solid #ccc',
  borderRadius:    '0px',
  boxShadow:       'inset 0 1px 1px rgba(0,0,0,.075)',
};

export default class AutosuggestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue:    '',
      selections:   this.props.defaultSelections || [],
      suggestions:  [],
      isLoading:    false,
    };

    this._onChange                    = this._onChange.bind(this);
    this._loadSuggestions             = this._loadSuggestions.bind(this);
    this._onSuggestionFetchRequested  = this._onSuggestionFetchRequested.bind(this);
    this._onSuggestionClearRequested  = this._onSuggestionClearRequested.bind(this);
    this._onSuggestionSelected        = this._onSuggestionSelected.bind(this);
    this._onSelectionClicked          = this._onSelectionClicked.bind(this);
  }

  _onChange(e, {newValue}) {
    this.setState({formValue: newValue});
  }

  _loadSuggestions(value) {
    this.setState({isLoading: true});

    const resourceTypeToSearch = this.props.resourceTypeToSearch;
    let promise;
    switch(resourceTypeToSearch) {
      case 'organizations': {
        promise = DatavaseApi.listOrganizations({keyword: value})
        break;
      }
      case 'people': {
        promise = DatavaseApi.listPeople({keyword: value})
        break;
      }
      case 'products': {
        promise = DatavaseApi.listProducts({keyword: value})
        break;
      }
      case 'events': {
        promise = DatavaseApi.listEvents({keyword: value})
        break;
      }
      case 'investables': {
        promise = DatavaseApi.listInvestables(value, 5);
        break;
      }
      case 'cross': {
        promise = DatavaseApi.crossModelSearch(value, 5);
        break;
      }
      default: {
        promise = DatavaseApi.listOrganizations({fields: ['id', 'organization_name'], keyword: value});
      }
    }

    promise.then(res => {
      let suggestions;
      if(resourceTypeToSearch === 'cross') {
        suggestions = res.data.organizations
                              .concat(res.data.people)
                              .concat(res.data.events);
      }else if(resourceTypeToSearch === 'investables') {
        suggestions = res.data.organizations
                              .concat(res.data.people);
      }else{
        suggestions = res.data;
      }
      this.setState({
        isLoading: false,
        suggestions: suggestions,
      });
    }).catch(res => {
      this.setState({isLoading: false});
      console.error(res);
    });
  }

  _onSuggestionFetchRequested({value}) {
    this._loadSuggestions(value);
  }

  _onSuggestionClearRequested() {
    this.setState({suggestions: []});
  }

  _onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    if(this.props.selectSingle) {
      const newSelections = [suggestion]
      this.setState({selections: newSelections, formValue: ''});
      if(this.props.onChange) {
        this.props.onChange(newSelections);
      }
      if(this.props.onAddition) {
        this.props.onAddition(suggestion);
      }
      return;
    }

    if(this.state.selections.map(s => s.id).includes(suggestion.id)) {
      return;
    }

    const newSelections = this.state.selections.concat(suggestion);
    this.setState({selections: newSelections, formValue: ''});

    if(this.props.onChange) {
      this.props.onChange(newSelections);
    }
    if(this.props.onAddition) {
      this.props.onAddition(suggestion);
    }
  }

  _onSelectionClicked(index) {
    if(this.props.locked) { return; }

    if(this.props.selectSingle) {
      const removedSelection = this.state.selections[0];
      const newSelections    = [];
      this.setState({selections: newSelections});
      if(this.props.onChange) {
        this.props.onChange(newSelections);
      }
      if(this.props.onRemoval) {
        this.props.onRemoval(removedSelection);
      }
      return;
    }

    const newSelections = this.state.selections;
    const removedSelection = newSelections.splice(index, 1)[0];
    this.setState({selections: newSelections});

    if(this.props.onChange) {
      this.props.onChange(newSelections);
    }
    if(this.props.onRemoval) {
      this.props.onRemoval(removedSelection);
    }
  }

  render() {
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.state.formValue,
      onChange: this._onChange,
    };

    const selectionPreviews = this.state.selections.map((selection, i) => (
      <div
        style={this.props.previewOuterStyle}
        key={i}
        onClick={()=>{this._onSelectionClicked(i)}}
      >
        {this.props.preview(selection)}
      </div>
    ));

    return(
      <div className="autosuggestOuter" style={outerStyle}>
        {selectionPreviews}
        <div className="autosuggest-form">
          {
            !this.props.locked ?
            <Autosuggest
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this._onSuggestionFetchRequested}
              onSuggestionsClearRequested={this._onSuggestionClearRequested}
              onSuggestionSelected={this._onSuggestionSelected}
              getSuggestionValue={this.props.getSuggestionValue}
              renderSuggestion={this.props.renderSuggestion}
              inputProps={inputProps}
              theme={autosuggestTheme}
            />
            :
            null
          }
        </div>
      </div>
    );
  }
}

