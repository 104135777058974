import React, { Component } from 'react';

import DataGridPanel from './data-grid-panel';
import { getLangCode, prettifyLocation, prettifyDate, abbrPrice, extractAndFormatPersonName } from '../datavase-api';
import i18n from '../../i18n/company-data-grid-panel.json';

export default class CompanyDataGridPanel extends Component {
  render () {
    const langCode = getLangCode();
    const lang = i18n[langCode];
    const cellHeadings = [
      [lang.dateOfFoundation, lang.ceo, lang.headquarter],
      [lang.totalFundingAmount, lang.lastFundingStage, lang.lastFundingAmount],
      [lang.totalEmployees, lang.ipo, lang.growthRate]
    ];

    const company     = this.props.company;
    const director    = this.props.directors[0];
    const fundings    = this.props.fundingRounds;
    const history     = this.props.lastTotalEmployeesHistory;
    const ipo         = this.props.ipo;
    const date        = prettifyDate(company.date_of_foundation, company.precision_of_date_of_foundation);
    const location    = prettifyLocation(company.based_city, company.based_state_prefecture, company.based_country);
    const directorName = extractAndFormatPersonName(director, langCode);

    let numberOfFundings  = '-';
    let lastFundingStage  = '-';
    let lastFundingAmount = '-';
    if(fundings && fundings.length !== 0) {
      numberOfFundings  = fundings.length;
      const lastFunding = fundings.sort((a,b) => a.date_of_investment < b.date_of_investment)[0];
      lastFundingStage  = lastFunding.funding_type_name;
      lastFundingAmount = abbrPrice(lastFunding.amount, lastFunding.currency_code, 'Undisclosed');
    }

    let totalEmployees = '-';
    if(history) {
      totalEmployees = history.total_number_of_employees;
    }

    let stockSymbol = '-';
    if(ipo) {
      stockSymbol = ipo.stock_symbol;
    }

    const cellValues  = [
      [date, directorName, location],
      [numberOfFundings, lastFundingStage, lastFundingAmount],
      [totalEmployees, stockSymbol, '-']
    ];
    return (
      <DataGridPanel
        dataGridHeading={lang.gridTitle}
        dataGridCellHeadings={cellHeadings}
        dataGridCellValues={cellValues}
      />
    );
  }
}

