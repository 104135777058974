import React from "react";
import styled from "styled-components";
import color from "../styles/colors";

import placeholder from '../../assets/datavase_logo_icon_grey.png';

export default function SquareThambnail({
  width,
  height,
  thambImg,
  title,
  rank,
}) {
  return (
    <Wrapper
      width={width || "48px"}
      height={height || "48px"}
    >
      { rank ? <Rank id="rank">{rank}</Rank> : null}
      <ThambWrapper>
        <ThambImg src={thambImg || placeholder} alt={title} />
      </ThambWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Rank = styled.p`
  position: absolute;
  top: -5px;
  left: -5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${color.primary};
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
  line-height: 14px;
  text-align: center;
`

const ThambWrapper = styled.p`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
`;

const ThambImg = styled.img`
  width: 100%;
  height: 100%;
`;
