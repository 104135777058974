import React, {Component} from 'react';
import {Badge, FormControl} from 'react-bootstrap';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import CreateListModal from '../partials/create-list-modal';
import UpgradeToCreateMoreListsModal from '../partials/upgrade-to-create-more-lists-modal';
import * as DatavaseApi from '../datavase-api';
import * as sharedResourcesActions from '../../actions/shared-resources';
import i18n from '../../i18n/sidebar.json';
import '../../css/sidebar.css';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qa_notification_count: 0,
      mylists: [],
      showCreateListModal: false,
      showUpgradeToCreateMoreListsModal: false,
    };
    this._myLists = this._myLists.bind(this);
    this._showCreateListModal = this._showCreateListModal.bind(this);
    this._hideCreateListModal = this._hideCreateListModal.bind(this);
    this._showUpgradeToCreateMoreListsModal = this._showUpgradeToCreateMoreListsModal.bind(this);
    this._hideUpgradeToCreateMoreListsModal = this._hideUpgradeToCreateMoreListsModal.bind(this);
    this._onAddButtonClicked = this._onAddButtonClicked.bind(this);
    this._onListCreated = this._onListCreated.bind(this);
    this._onListFormChange = this._onListFormChange.bind(this);
    this._sidebarLiClassName = this._sidebarLiClassName.bind(this);
  }

    UNSAFE_componentWillMount() {
    this._loadMyLists();
  }
  componentDidMount() {
    DatavaseApi.isLoggedIn().then((res) => DatavaseApi.getNotification().then(
      (res) => {
        if (res !== undefined && res !== null) {
          this.setState({ qa_notification_count: res.data[0].notification_count })
        }
      }
    ))
  }

    UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      mylists: nextProps.sharedResources.lists,
    });
  }

  // TODO: Enable caching using redux
  _loadMyLists() {
    DatavaseApi.isLoggedIn()
      .then(user => {
        return Promise.all([
          DatavaseApi.listLists({ perPage: 100000 }),
          DatavaseApi.fetchOAuthConnectionStatus(),
          DatavaseApi.fetchResearchRequests(),
          DatavaseApi.fetchInformationRemovalRequests(),
        ]);
      })
      .then(res => {
        this.setState({ mylists: res[0].data });
        this.props.sharedResourcesActions.setMylists(res[0].data);
        this.props.sharedResourcesActions.setOAuthConnectionStatus(res[1].data);
        this.props.sharedResourcesActions.setResearchRequests(res[2].data);
        this.props.sharedResourcesActions.setInformationRemovalRequests(res[3].data);
      })
      .catch(res => {
        console.error(res);
      });
  }

  _onListFormChange(e) {
    const keyword = e.target.value;
    DatavaseApi.listLists({ keyword: keyword, perPage: 100000 })
      .then(res => {
        this.setState({ mylists: res.data });
      })
      .catch(res => {
        console.error("Failed to search lists");
      });
  }

  _showUpgradeToCreateMoreListsModal() {
    this.setState({ showUpgradeToCreateMoreListsModal: true });
  }

  _hideUpgradeToCreateMoreListsModal() {
    this.setState({ showUpgradeToCreateMoreListsModal: false });
  }

  _showCreateListModal() {
    this.setState({ showCreateListModal: true });
  }

  _hideCreateListModal() {
    this.setState({ showCreateListModal: false });
  }

  _onAddButtonClicked() {
    if (DatavaseApi.getUserStatus() === 'Guest') {
      this.props.sharedResourcesActions.addUnauthorizedErrorMessage();
      return;
    }

    if (DatavaseApi.getUserStatus() === 'Free' &&
      this.state.mylists.length >= 1) {
      this._showUpgradeToCreateMoreListsModal();
    } else {
      this._showCreateListModal();
    }
  }

  _onListCreated() {
    this._loadMyLists();
    this._hideCreateListModal();
  }

  _myLists() {
    const mylists = this.state.mylists;
    return mylists.map(list => {
      const url = `/lists/${list.id}`;
      const listName = list.list_name;
      return (
        <li key={list.id}>
          <Link to={url} className="sidebar-link-button">
            # {listName}
          </Link>
        </li>
      );
    });
  }

  _sidebarLiClassName(pathCategory) {
    return this.props.pathCategory === pathCategory ? "current-selected" : "";
  }

  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];
    const sidebarVisibilityClassName = this.props.visible ? "sidebar-shown" : "sidebar-hidden";

    const userId = DatavaseApi.getUserId();
    //console.log(userId);
    let userUpdate = null;
    if (!isNaN(userId)) {
      userUpdate = (
        <li className={this._sidebarLiClassName('mypage')}>
          <Link to={`/mypage/${userId}`} className="sidebar-link-button">
            <i className="material-icons sidebar-link-button-icon">account_circle</i>{lang.accountSettings}
          </Link>
        </li>);
    }

    return (
      <Wrapper id="sidebar" className={sidebarVisibilityClassName}>
        <CreateListModal
          show={this.state.showCreateListModal}
          onCancelClick={this._hideCreateListModal}
          onSuccess={this._onListCreated}
        />
        <UpgradeToCreateMoreListsModal
          show={this.state.showUpgradeToCreateMoreListsModal}
          onCancelClick={this._hideUpgradeToCreateMoreListsModal}
        />
        <ul>
          {userUpdate}
          <li>
            <hr />
          </li>
          <li className={this._sidebarLiClassName('qa_boards')}>
            <Link to="/qa_boards" className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">question_answer</i>
              <p style={{ flex: '1', margin: 0 }}>
                Q&A{this.state.qa_notification_count !== 0 ? <Badge style={{ backgroundColor: "#d9534f", height: "18px", marginLeft: "1em", marginTop: "-1px", paddingTop: "4px", borderColor: "#d43f3a", color: "white" }}>{this.state.qa_notification_count}</Badge> : null}
              </p>
              <i className="material-icons sidebar-link-button-icon">lock</i>
            </Link>
          </li>
          <li className={this._sidebarLiClassName('articles')}>
            <Link to="/articles" className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">library_books</i>{lang.reports}
            </Link>
          </li>
          <li className={this._sidebarLiClassName('companies')}>
            <Link to="/companies" className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">business</i>
              <p style={{flex: '1', margin: 0}}>{lang.companies}</p>
              <i className="material-icons sidebar-link-button-icon">lock</i>
            </Link>
          </li>
          <li className={this._sidebarLiClassName('investors')}>
            <Link to="/investors" className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">monetization_on</i>
              <p style={{flex: '1', margin: 0}}>{lang.investors}</p>
              <i className="material-icons sidebar-link-button-icon">lock</i>
            </Link>
          </li>
          <li className={this._sidebarLiClassName('recruits')}>
            <Link to="/recruits/" className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">star_half</i>
              <p style={{flex: '1', margin: 0}}>求人情報</p>
              <i className="material-icons sidebar-link-button-icon">lock</i>
            </Link>
          </li>
          <li>
            <hr/>
          </li>
          <li className={this._sidebarLiClassName('lists')}>
            <Link to="/mylists" className="sidebar-link-button">
              {lang.mylists}
            </Link>
          </li>
          <li>
            <FormControl
              placeholder={lang.searchLists}
              onChange={this._onListFormChange}
              id="lists-search-form"
            />
          </li>
          <li>
            <span onClick={this._onAddButtonClicked} className="sidebar-link-button">
              <i className="material-icons sidebar-link-button-icon">add</i>{lang.addMylists}
            </span>
          </li>
          {this._myLists()}
        </ul>
      </Wrapper>
    )
  }
}
function mapStateToProps(state) {
  return {
    sharedResources: state.sharedResources,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    sharedResourcesActions: bindActionCreators(sharedResourcesActions, dispatch),
  };
}

const ReduxedSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
export default ReduxedSidebar;


const Wrapper = styled.div`
  z-index: 8888 !important;
  ${media.lessThan("medium")`
    margin-top: -22px;
  `}
`;
