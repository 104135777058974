import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import InvestmentsHistoryTable    from './investments-history-table';
import { getLangCode } from '../datavase-api';
import i18n from '../../i18n/person-history-details-panel.json';

export default class PersonHistoryDetailsPanel extends Component {
  render() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const investments   = this.props.investments;

    return(
      <div>
        {
          investments.length !== 0 ?
          <Panel id="company-history-details">
            <Tabs id="investments-history-tabs">
              <Tab eventKey={1} title={lang.investmentsTableTitle}>
                <Panel className="side-padded-panel">
                  <InvestmentsHistoryTable investments={investments}/>
                </Panel>
              </Tab>
            </Tabs>
          </Panel>
          : null
        }
      </div>
    );
  }
}

