import React, { Component } from 'react';

import DataGridPanel from './data-grid-panel';
import { getLangCode, prettifyDate, prettifyLocation, abbrPrice, extractAndFormatPersonName } from '../datavase-api';
import i18n from '../../i18n/investor-data-grid-panel.json';

export default class InvestorDataGridPanel extends Component {
  render () {
    const langCode = getLangCode();
    const lang      = i18n[langCode];
    const cellHeadings = [
      [lang.dateOfFoundation, lang.ceo, lang.headquarter],
      [lang.numberOfFundraisings, lang.fundVolume, lang.investmentStage],
      [lang.exits, lang.numberOfInvestments, lang.numberOfLeadInvestments]
    ];

    const investor      = this.props.investor;
    const director      = this.props.directors[0];
    const fundraisings  = this.props.fundraisings;
    const exits         = this.props.exits;
    const investments   = this.props.investments;
    const date          = prettifyDate(investor.date_of_foundation, investor.precision_of_date_of_foundation);
    const location      = prettifyLocation(investor.based_city, investor.based_state_prefecture, investor.based_country);

    let directorName = '-';
    if(director) {
      directorName = extractAndFormatPersonName(director, langCode);
    }

    let fundVolume = '-';
    let numberOfFundraisings = '-';
    if(fundraisings && fundraisings.length !== 0) {
      numberOfFundraisings = fundraisings.length;
      let lastFundraising = fundraisings.sort((a,b) => a.date_of_investment < b.date_of_investment)[0];
      fundVolume = abbrPrice(lastFundraising.amount, lastFundraising.currency_code, 'Undisclosed');
    }

    let investmentStages = '-';
    if(investments && investments.length !== 0) {
      let types = Array.from(new Set(investments.map(i => i.funding_type_name)));
      types.sort((a, b) => !a.localeCompare(b));
      investmentStages = types.join(', ').replace('VC Fundraising', 'LP');
    }

    let numberOfExits = '-';
    if(exits && exits.ipos.length + exits.acquisitions.length !== 0) {
      numberOfExits = exits.ipos.length + exits.acquisitions.length;
    }

    let numberOfInvestments = '-';
    if(investments && investments.length !== 0) {
      numberOfInvestments = investments.length;
    }

    const cellValues = [
      [date, directorName, location],
      [numberOfFundraisings, fundVolume, investmentStages],
      [numberOfExits, numberOfInvestments, '-']
    ];

    return (
      <DataGridPanel
        dataGridHeading={lang.gridTitle}
        dataGridCellHeadings={cellHeadings}
        dataGridCellValues={cellValues}
      />
    );
  }
}

