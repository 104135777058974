import React, { Component } from 'react';
import { Table, Col } from 'react-bootstrap';

import ContributionsStatsPieChart from './contributions-stats-pie-chart';
import placeholder      from '../../assets/datavase_logo_icon_grey.png';
import admin_icon       from '../../assets/datavase_logo_icon.png';
import * as DatavaseApi from '../datavase-api';
import i18n             from '../../i18n/contributor-details-view.json';

export default class ContributorDetailsView extends Component {
  render() {
    const langCode = DatavaseApi.getLangCode();
    const lang     = i18n[langCode];

    const { contributor, subjectResourceDomainName } = this.props;
    const contributionCount = contributor.contribution_count;
    const praise            = contributor.praise;
    const softBlame         = contributor.soft_blame;

    let screenName, img, domainName;
    let borderStyle = '2px solid #aaa';
    if(contributor.contributor_type === 'Admin') {
      img         = admin_icon;
      screenName  = 'datavase.io';
      domainName  = 'datavase.io';
      borderStyle = '2px solid #669287';
    }else{
      img        = contributor.thumb_profile_image_url || placeholder;
      screenName = contributor.screen_name || 'Anonymous User';
      domainName = contributor.domain_name;
      if(subjectResourceDomainName === domainName) {
        borderStyle = '2px solid #BE3D33';
      }
    }

    return(
      contributor.contributor_type !== 'Admin' ?
        <div className="clearfix">
          <Col sm={12} md={6} lg={6}>
            <div style={{marginBottom: '10px'}}>
              <img
                alt={screenName}
                src={img}
                style={{width: '50px', borderRadius: '25px', marginRight: '10px', border: borderStyle}}
              />
              <span style={{lineHeight: '50px', fontWeight: 'bold', fontSize: '20px'}}>{screenName}</span>
              {
                domainName === subjectResourceDomainName ?
                <span style={{lineHeight: '50px', fontSize: '20px'}}>{' '}({domainName})</span>
                : null
              }
            </div>
            <Table>
              <tbody>
                <tr style={{borderBottom: '1px solid #ddd'}}>
                  <td style={{paddingRight: '10px', fontWeight: 'bold'}}>{lang.contributionCount}</td>
                  <td>{contributionCount}</td>
                </tr>
                <tr style={{borderBottom: '1px solid #ddd'}}>
                  <td style={{paddingRight: '10px', fontWeight: 'bold'}}>{lang.praiseCount}</td>
                  <td>{praise}</td>
                </tr>
                <tr style={{borderBottom: '1px solid #ddd'}}>
                  <td style={{paddingRight: '10px', fontWeight: 'bold'}}>{lang.blameCount}</td>
                  <td>{softBlame}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm={12} md={6} lg={6}>
            {
              praise + softBlame !== 0 ?
              <ContributionsStatsPieChart
                chartName="contributor-stats-pie"
                contributor={contributor}
                height={180}
                width={180}
              />
              :
              null
            }
          </Col>
        </div>
      :
      null
    );
  }
}
