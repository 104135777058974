import * as SharedResources from '../constants/shared-resources';

const initialState = {
  url: '/',
  lists: [],
  researchRequests: [],
  informationRemovalRequests: [],
  oauthStatus: {
    chatwork: null,
    slack: null
  },
  error: null,
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SharedResources.SET_URL: {
      return Object.assign({}, state, {
        url: action.url
      });
    }
    case SharedResources.SET_MYLISTS: {
      return Object.assign({}, state, {
        lists: action.lists,
      });
    }
    case SharedResources.SET_INFORMATION_REMOVAL_REQUESTS: {
      return Object.assign({}, state, {
        informationRemovalRequests: action.informationRemovalRequests,
      });
    }
    case SharedResources.SET_RESEARCH_REQUESTS: {
      return Object.assign({}, state, {
        researchRequests: action.researchRequests,
      });
    }
    case SharedResources.SET_OAUTH_CONNECTION_STATUS: {
      return Object.assign({}, state, {
        oauthStatus: action.status,
      });
    }
    case SharedResources.ADD_BAD_REQUEST_ERROR_MESSAGE:
    case SharedResources.ADD_UNAUTHORIZED_ERROR_MESSAGE:
    case SharedResources.ADD_FORBIDDEN_ERROR_MESSAGE:
    case SharedResources.ADD_SERVER_ERROR_MESSAGE:
    case SharedResources.ADD_RAW_ERROR_MESSAGE: {
      return Object.assign({}, state, {
        error: action.error,
      });
    }
    case SharedResources.FLUSH_ERROR_MESSAGE: {
      return Object.assign({}, state, {
        error: null
      });
    }
    default: {
      return state;
    }
  }
}
