import React, {Component} from 'react';
import {Button, Panel} from 'react-bootstrap';
import CreateOrganizationReviewModal from './create-organization-review-modal';
import BlockSpinner from './block-spinner';

import Review from './review';
import i18n from '../../i18n/review-lists-panel.json';
import * as DatavaseApi from '../datavase-api';

import '../../css/organization-review-lists-panel.css';
import reviewLock from '../../assets/review_lock.png';

export default class OrganizationReviewListsPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      isReviewModalOpen: false,
      loading: true,
      reviews: []
    };

    this._openReviewModal = this._openReviewModal.bind(this);
    this._closeReviewModal = this._closeReviewModal.bind(this);
  }

  componentDidMount() {
    const id = this.props.id;
    DatavaseApi.fetchOrganizationReviews(id)
        .then(res => {
            this.setState({
                reviews: res.data.organization_reviews,
                loading: false,
            });
        }).catch((res) => {
    });
  }

  _openReviewModal(){
    this.setState({isReviewModalOpen: true});
  }

  _closeReviewModal(){
    this.setState({isReviewModalOpen: false});
  }

  render() {
    if(this.state.loading) {
      return(
          <Panel>
              <BlockSpinner visiable/>
          </Panel>
      );
    }

    const langCode = DatavaseApi.getLangCode();
    const lang = i18n[langCode];

    let userStatus = DatavaseApi.getUserStatus();
    let dataCount = this.state.reviews.length;
    let reviewData = this.state.reviews;

    if (userStatus === 'Free' || userStatus === 'Guest') {
      reviewData = this.state.reviews.slice(0, 3);
    }

      const reviews = reviewData.map((review, _i) => (this.props.restrict === review.position || this.props.restrict === -1 || (this.props.restrict === 99 && review.position === null)) ?
          <Review review={review} resource_uri={this.props.resource_uri}
                  key={review.id}/> : null);
    const unlockPage = Array(dataCount - reviewData.length).fill(undefined).map((_unlock, i) => (
        <div style={{
            background: '#1b2f3d',
            width: '100%',
            height: '300px',
            margin: '10px 0',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        }} key={i} onClick={() => {
            window.location.href = '/upgrade'
        }}>
            <div style={{textAlign: 'center', color: '#fff', width: '100%'}}>
                <div style={{textAlign: 'center', color: '#ccc', width: '100%'}}>
                    <img src={reviewLock} alt="review_lock" style={{height: '100px'}}/>
                </div>
                <p style={{
                    background: '#20786e',
                    width: 'max-content',
                    margin: '0 auto',
                    padding: '5px 20px'
                }}>{lang.getAccess}</p>
            </div>
        </div>
    ));

    return(
        <Panel style={{padding: "0"}}>
            <CreateOrganizationReviewModal
                id={this.props.id}
                show={this.state.isReviewModalOpen}
                onCancel={this._closeReviewModal}
                organization
            />
            {
                this.state.reviews.length === 0 ?
                    <span className="no-review">
            {lang.noReview}
          </span> : null
            }
            <Button
                bsStyle="success"
                className="modal-button"
                onClick={this._openReviewModal}
            >
                口コミを投稿する
            </Button>
            {reviews}
            {unlockPage}
        </Panel>
    );
  }
}
