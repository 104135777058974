// Convert dash splited words to camelCase
export default function camelCase(str) {
  let res = str.split('_');
  for (let i = 0; i < res.length; i++) {
    res[i] = res[i].toLowerCase();
    if (i !== 0) {
      res[i] = res[i][0].toUpperCase() + res[i].substr(1);
    }
  }
  return res.join('');
}
