import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import ResearchRequestPanel       from './research-request-panel';
import FundraisingsHistoryGraph   from './fundraisings-history-graph';
import NumberOfInvestmentsGraph   from './number-of-investments-graph';
import { getLangCode }            from '../datavase-api';
import i18n                       from '../../i18n/investor-graph-panel.json';

export default class InvestorGraphsPanel extends Component {
  _fundraisings() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const investor      = this.props.investor;
    const fundraisings  = this.props.fundraisings;

    if(fundraisings.length !== 0) {
      return (
        <Panel id="investor-graphs">
          <Tabs id="details-graphs-tabs">
            <Tab eventKey={1} title={lang.fundVolumeGraphTitle}>
              <Panel className="side-padded-panel">
                <FundraisingsHistoryGraph
                  graphName="a"
                  fundraisings={fundraisings}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(investor.might_have_fundings) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.fundVolumeGraphTitle}
          resourceType={'organizations'}
          id={investor.id}
          graph
        />
      );
    }else{
      return null;
    }
  }

  _investmentVolume() {
    const langCode    = getLangCode();
    const lang        = i18n[langCode];
    const investments = this.props.investments;
    const investor      = this.props.investor;

    //収集リクエストの数でカウントして、収集リクエストがどっちもの場合はアップグレード指示を出すか？でも80%のカウントが難しいよな
    if(investments.length !== 0) {
      return(
        <Panel id="investment-volume-graph-panel">
          <Tabs id="investment-volume-graph-tabs">
            <Tab eventKey={1} title={lang.investmentVolumeGraphTitle}>
              <Panel className="side-padded-panel">
                <NumberOfInvestmentsGraph
                  graphName="b"
                  investments={investments}
                />
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(investor.might_have_investments) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.investmentVolumeGraphTitle}
          resourceType={'organizations'}
          id={investor.id}
          graph
        />
      );
    }else{
      return null;
    }
  }

  render() {
    return(
      <div>
        {this._fundraisings()}
        {this._investmentVolume()}
      </div>
    );
  }
}


