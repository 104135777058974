import React, { Component } from 'react';
import { Panel, Tabs, Tab } from 'react-bootstrap';

import ResearchRequestPanel       from './research-request-panel';
import FundingRoundsHistoryTable  from './funding-rounds-history-table';
import InvestmentsHistoryTable    from './investments-history-table';
import AcquisitionsHistoryTable   from './acquisitions-history-table';
import { getLangCode }            from '../datavase-api';
import i18n from '../../i18n/company-history-details-panel.json';

export default class CompanyHistoryDetailsPanel extends Component {
  _fundingRounds() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const company       = this.props.company;
    const fundingRounds = this.props.fundingRounds;

    if(fundingRounds.length !== 0) {
      return(
        <Panel id="company-history-details">
          <Tabs id="funding-rounds-history-tabs">
            <Tab eventKey={1} title={lang.fundingRoundsTableTitle}>
              <Panel className="side-padded-panel">
                <FundingRoundsHistoryTable fundingRounds={fundingRounds}/>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(company.might_have_fundings === true) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.fundingRoundsTableTitle}
          resourceType={'organizations'}
          id={company.id}
          datatable
        />
      );
    }else{
      return null;
    }
  }

  _investments() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const company       = this.props.company;
    const investments   = this.props.investments;

    if(investments.length !== 0) {
      return(
        <Panel id="company-history-details">
          <Tabs id="investments-history-tabs">
            <Tab eventKey={1} title={lang.investmentsTableTitle}>
              <Panel className="side-padded-panel">
                <InvestmentsHistoryTable investments={investments}/>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(company.might_have_fundings === true) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.investmentsTableTitle}
          resourceType={'organizations'}
          id={company.id}
          datatable
        />
      );
    }else{
      return null;
    }
  }

  _acquisitions() {
    const langCode      = getLangCode();
    const lang          = i18n[langCode];
    const company       = this.props.company;
    const acquisitions  = this.props.acquisitions;

    if(acquisitions.length !== 0) {
      return(
        <Panel id="company-history-details">
          <Tabs id="acquisitions-history-tabs">
            <Tab eventKey={2} title={lang.acquisitionsTableTitle}>
              <Panel className="side-padded-panel">
                <AcquisitionsHistoryTable acquisitions={acquisitions}/>
              </Panel>
            </Tab>
          </Tabs>
        </Panel>
      );
    }else if(company.might_have_fundings === true) {
      return(
        <ResearchRequestPanel
          researchRequested={this.props.researchRequested}
          title={lang.acquisitionsTableTitle}
          resourceType={'organizations'}
          id={company.id}
          datatable
        />
      );
    }else{
      return null;
    }
  }

  render() {
    return(
      <div>
        {this._fundingRounds()}
        {this._investments()}
        {this._acquisitions()}
      </div>
    );
  }
}
